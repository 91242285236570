import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import { DotLoader } from '~components/DotLoader';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledSwitch from '~components/Form/ControlledSwitch';
import SectionCard from '~components/SectionCard';
import useDebounce from '~hooks/useDebounce';
import { getCampaignById, getRoutingProfiles } from '~pages/CampaignManagement/api';
import useDiallerGroupCampaignSearch from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/useDiallerGroupCampaignSearch';
import { DiallerGroupCampaign } from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/useDiallerGroupCampaignSearch/domain';
import {
  Campaign,
  CampaignType,
  DiallerGroup,
  DiallerType,
  RoutingProfile,
  UpdateDiallerGroup,
  typeIdsToDisplayText,
} from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { findIndexByProperty } from '~utils/Functions';

interface Form {
  name: string;
  description: string;
  diallerType: DiallerType | null;
  campaignType: CampaignType | null;
  defaultCampaign: DiallerGroupCampaign | null;
  isPaused: boolean;
  enableManualOutboundWhenAvailable: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  systemDisposedInboundCalls: boolean;
  // State for checkbox, not to be sent to backend
  enableVoicemailMessage: boolean;
  routingProfiles: { label: string; value: string }[];
  voicemailMessageARN: string;
}

interface GeneralSettingsFormProps {
  diallerGroup: DiallerGroup;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit?: () => void;
  update: (data: Partial<UpdateDiallerGroup>) => Promise<void>;
}

const diallerTypeList = [
  {
    label: 'Connect',
    value: DiallerType.Connect,
  },
  {
    label: 'SIP',
    value: DiallerType.SIP,
  },
];

const asteriskCampaignTypeList = [
  {
    label: 'Predictive',
    value: CampaignType.Predictive,
  },
];

const connectCampaignTypeList = [
  {
    label: 'Preview',
    value: CampaignType.Preview,
  },
  {
    label: 'Predictive',
    value: CampaignType.Predictive,
  },
];

const GeneralSettingsForm = ({ diallerGroup, isEdit, submitting, toggleEdit, update }: GeneralSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const appConfig = useAppConfiguration();
  const [defaultCampaign, setDefaultCampaign] = useState<DiallerGroupCampaign | undefined>(undefined);
  const [campaignSearch, setCampaignSearch] = useState<string>('');
  const debouncedCampaignSearch = useDebounce(campaignSearch, 500);
  const [routingProfiles, setRoutingProfiles] = useState<RoutingProfile[]>([]);
  const {
    loading: fetchingCampaigns,
    list: campaigns,
    error: fetchingCampaignsError,
    intersectionObserverRef: lastDataElement,
  } = useDiallerGroupCampaignSearch(diallerGroup.id, {
    search: debouncedCampaignSearch,
    archived: false,
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      isPaused: false,
      defaultCampaign: null,
      diallerType: null,
      campaignType: null,
      enableManualOutboundWhenAvailable: false,
      manualOutboundRequireDetails: false,
      enablePreparedOutboundHangup: false,
      enableManualOutboundWhenOffline: false,
      systemDisposedInboundCalls: false,
      enableVoicemailMessage: false,
      voicemailMessageARN: '',
      routingProfiles: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const defaultCampaignWatch = watch('defaultCampaign');
  const diallerTypeWatch = watch('diallerType');
  const campaignTypeWatch = watch('campaignType');
  const enableManualOutboundWhenAvailableWatch = watch('enableManualOutboundWhenAvailable');
  const enableManualOutboundWhenOfflineWatch = watch('enableManualOutboundWhenOffline');
  const showManualOutboundOptions = enableManualOutboundWhenAvailableWatch || enableManualOutboundWhenOfflineWatch;
  const enableVoicemailMessageWatch = watch('enableVoicemailMessage');
  const isConnectPredictive = diallerTypeWatch == DiallerType.Connect && campaignTypeWatch == CampaignType.Predictive;

  // Fetch details on default campaign
  useEffect(() => {
    (async () => {
      let campaign: Campaign | undefined;
      try {
        campaign = await getCampaignById(diallerGroup.defaultCampaignId);
      } catch (e) {
        console.log('! failed to fetch default campaign');
        return;
      }

      if (campaign) {
        // Massage into required format from the dropdown,
        setDefaultCampaign({
          entryId: 0,
          campaignId: campaign?.id,
          name: campaign?.name,
          isActive: campaign.isActive,
          archived: campaign.archived,
          startTime: null,
          endTime: null,
          priority: 0,
          isDefaultCampaign: true,
          connectSourcePhoneNumber: '',
        });
      }
    })();
  }, [diallerGroup.defaultCampaignId]);

  let campaignTypeList: { label: string; value: string }[] = [];
  if (diallerTypeWatch == DiallerType.SIP) {
    campaignTypeList = asteriskCampaignTypeList;
  }
  if (diallerTypeWatch == DiallerType.Connect) {
    campaignTypeList = connectCampaignTypeList;
  }

  // Yuck, thx mui AutoComplete component for this, but let's set the default campaign value when edit mode is initiated
  // IF we have found a default campaign
  useEffect(() => {
    if (isEdit && defaultCampaign !== undefined) {
      setValue('defaultCampaign', defaultCampaign);
    }
  }, [isEdit, defaultCampaign]);

  // sip preview: coming soon!
  useEffect(() => {
    if (isEdit && diallerTypeWatch == DiallerType.SIP && campaignTypeWatch == CampaignType.Preview) {
      setValue('campaignType', CampaignType.Predictive);
    }
  }, [isEdit, diallerTypeWatch]);

  // predictive campaigns can't currently have manual outbound while automated calls are placed for the agent.
  useEffect(() => {
    if (isEdit && campaignTypeWatch == CampaignType.Predictive) {
      setValue('enableManualOutboundWhenAvailable', false);
    }
  }, [isEdit, campaignTypeWatch]);

  useEffect(() => {
    const loadRoutingProfiles = async () => {
      try {
        const routingProfileList = await getRoutingProfiles();
        setRoutingProfiles(routingProfileList);
      } catch (e) {
        console.log('! failed to fetch routing profiles');
      }
    };

    if (routingProfiles.length === 0 && !appConfig.aws.externallyManagedRoutingProfile) {
      loadRoutingProfiles();
    }
  }, [isEdit]);

  const selectedRoutingProfileItems = useMemo(
    () =>
      diallerGroup.routingProfiles.map((value: any) => {
        const index = findIndexByProperty(routingProfiles, 'id', value);
        const label = routingProfiles[index]?.name || 'Unknown';
        return { label, value };
      }),
    [routingProfiles, diallerGroup?.routingProfiles],
  );

  // Fill all static group related config
  useEffect(() => {
    setValue('name', diallerGroup.name);
    setValue('description', diallerGroup.description || '');
    setValue('isPaused', !diallerGroup.isActive);
    setValue('diallerType', diallerGroup.diallerType as DiallerType);
    setValue('campaignType', diallerGroup.campaignType as CampaignType);
    // Note: disabled as part of predictive
    setValue(
      'enableManualOutboundWhenAvailable',
      diallerGroup.campaignType === CampaignType.Predictive ? false : diallerGroup.enableManualOutbound,
    );
    setValue('manualOutboundRequireDetails', diallerGroup.manualOutboundRequireDetails);
    setValue('enablePreparedOutboundHangup', diallerGroup.enablePreparedOutboundHangup);
    setValue('enableManualOutboundWhenOffline', diallerGroup.enableManualOutboundWhenOffline);
    setValue('systemDisposedInboundCalls', diallerGroup.systemDisposedInboundCalls);
    setValue('enableVoicemailMessage', Boolean(diallerGroup.voicemailMessageARN));
    setValue('routingProfiles', selectedRoutingProfileItems);
    setValue('voicemailMessageARN', diallerGroup.voicemailMessageARN || '');

    return () => {
      reset();
    };
  }, [isEdit]);

  useEffect(() => {
    if (!showManualOutboundOptions) {
      setValue('manualOutboundRequireDetails', false);
      setValue('enablePreparedOutboundHangup', false);
    }
  }, [showManualOutboundOptions]);

  const onSubmit = handleSubmit((data: Form) => {
    let submitData: Partial<UpdateDiallerGroup> = {
      name: data.name,
      description: data.description,
      isActive: !data.isPaused,
      // Rule on field dictates that default campaign cannot be null on submit, so telling typescript to ignore error
      defaultCampaignId: data.defaultCampaign!.campaignId,
      diallerType: data.diallerType!,
      campaignType: data.campaignType!,
      enableManualOutbound: data.enableManualOutboundWhenAvailable,
      manualOutboundRequireDetails: data.manualOutboundRequireDetails,
      enablePreparedOutboundHangup: data.enablePreparedOutboundHangup,
      enableManualOutboundWhenOffline: data.enableManualOutboundWhenOffline,
      systemDisposedInboundCalls: data.systemDisposedInboundCalls,
      routingProfiles: data.routingProfiles.map(({ value }) => value),
      voicemailMessageARN: null,
    };

    if (data.enableVoicemailMessage) {
      submitData = {
        ...submitData,
        voicemailMessageARN: data.voicemailMessageARN,
      };
    }

    update(submitData);
  });

  const onCampaignSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCampaignSearch(e.target.value);
  };

  const onCampaignSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setCampaignSearch('');
  };

  const noOptionsText = useMemo(() => {
    if (fetchingCampaigns) {
      return <DotLoader align='center' />;
    }

    if (fetchingCampaignsError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load campaigns
        </Typography>
      );
    }

    return undefined;
  }, [fetchingCampaigns, fetchingCampaignsError]);

  const routingProfileChips = useMemo(
    () =>
      selectedRoutingProfileItems.map(({ label, value }) => {
        return (
          <Chip
            key={value}
            sx={{ minWidth: 100, maxWidth: 200, margin: '4px' }}
            title={value}
            variant='filled'
            color='primary'
            label={label}
          />
        );
      }),
    [selectedRoutingProfileItems],
  );

  const routingProfileDisplay =
    routingProfileChips.length > 0 ? <Box sx={{ marginLeft: '-4px' }}>{routingProfileChips}</Box> : null;

  const filteredList = useMemo(() => {
    if (!defaultCampaignWatch) {
      return campaigns;
    }

    let checkByCampaignId: { [key: string]: boolean } = {};
    // Let's remove duplicates and default campaign from selection list
    return campaigns.filter((item) => {
      const isDuplicate = checkByCampaignId[item.campaignId];
      checkByCampaignId[item.campaignId] = true;
      return !isDuplicate && item.campaignId !== defaultCampaignWatch.campaignId;
    });
  }, [campaigns, defaultCampaignWatch]);

  const diallerTypeListDisplay = diallerTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const campaignTypeListDisplay = campaignTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  const routingProfileOptions = useMemo(() => {
    return routingProfiles.map((item) => {
      return { label: item.name, value: item.id };
    });
  }, [routingProfiles]);

  let campaignTypeHelperText = errors.campaignType?.message;
  if (isConnectPredictive && diallerGroup.needsOutboundNumber > 0 && !campaignTypeHelperText) {
    campaignTypeHelperText = `${diallerGroup.needsOutboundNumber} campaign${
      diallerGroup.needsOutboundNumber > 1 ? 's' : ''
    } associated with this group needs a source number configured before this group can be used in a predictive manner.`;
  }

  return (
    <SectionCard title='General' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            title='Is paused'
            value={diallerGroup.isActive ? 'No' : 'Yes'}
          />
          <DataItem stacked={isExtraSmall} title='Dialler group name' value={diallerGroup.name} />
          <DataItem
            stacked={isExtraSmall}
            title='Dialler type'
            value={typeIdsToDisplayText[diallerGroup.diallerType]}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Campaign type'
            value={typeIdsToDisplayText[diallerGroup.campaignType]}
          />
          <DataItem stacked={isExtraSmall} title='Description' value={diallerGroup.description || '-'} />
          <DataItem stacked={isExtraSmall} title='Default campaign' value={defaultCampaign?.name || '-'} />
          {!appConfig.aws.externallyManagedRoutingProfile && (
            <DataItem stacked={isExtraSmall} title='Routing profiles' value={routingProfileDisplay} />
          )}

          <DataItem
            stacked={isExtraSmall}
            title='Enable manual outbound when offline'
            tooltip='Allows agents to perform manual outbound calls when in an offline or not routable state.'
            value={diallerGroup.enableManualOutboundWhenOffline ? 'Yes' : 'No'}
          />

          {/* TODO: Remove check when we support manual outbound within predictive */}
          {diallerGroup.diallerType !== DiallerType.SIP && (
            <>
              {diallerGroup.campaignType !== CampaignType.Predictive && (
                <DataItem
                  stacked={isExtraSmall}
                  title='Enable manual outbound when available'
                  tooltip='Allows agents to perform manual outbound calls when in an available state.'
                  value={diallerGroup.enableManualOutbound ? 'Yes' : 'No'}
                />
              )}

              {(diallerGroup.enableManualOutbound === true || diallerGroup.enableManualOutboundWhenOffline) && (
                <>
                  <DataItem
                    stacked={isExtraSmall}
                    title='Manual outbound require details'
                    value={diallerGroup.manualOutboundRequireDetails ? 'Yes' : 'No'}
                  />

                  <DataItem
                    stacked={isExtraSmall}
                    title='Enable prepared outbound hangup'
                    tooltip='Allows agents to hangup calls they have manually prepared before it has been connected.'
                    value={diallerGroup.enablePreparedOutboundHangup ? 'Yes' : 'No'}
                  />
                </>
              )}

              <DataItem
                stacked={isExtraSmall}
                title='System dispose inbound calls'
                tooltip='Generic system disposition is provided to all inbound calls.'
                value={diallerGroup.systemDisposedInboundCalls ? 'Yes' : 'No'}
              />

              <DataItem
                stacked={isExtraSmall}
                title='Voicemail message ARN'
                tooltip='Allows agents to leave an automated voicemail message when prompted by a customers voicemail.'
                value={diallerGroup.voicemailMessageARN || 'Not Enabled'}
              />
            </>
          )}
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledSwitch name='isPaused' label='Is paused?' control={control} disabled={submitting} />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Name is required.',
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Name'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Description'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='defaultCampaign'
                control={control}
                rules={{
                  required: 'Default Campaign is required.',
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    options={filteredList}
                    noOptionsText={noOptionsText}
                    disabled={submitting}
                    getOptionLabel={(option) => option?.name || ''}
                    renderOption={(props, option) => (
                      <li {...props} ref={lastDataElement} key={option.campaignId}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Default campaign'
                        variant='outlined'
                        required={true}
                        error={Boolean(errors.defaultCampaign)}
                        helperText={errors.defaultCampaign?.message}
                        onBlur={onCampaignSearchBlur}
                        onChange={onCampaignSearchChange}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            {!appConfig.aws.externallyManagedRoutingProfile && (
              <Grid item xs={12}>
                <Controller
                  name='routingProfiles'
                  control={control}
                  rules={{
                    required: 'At least one routing profile must be selected.',
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      onChange={(e, data) => {
                        field.onChange(data);
                      }}
                      options={routingProfileOptions}
                      noOptionsText={noOptionsText}
                      multiple
                      filterSelectedOptions
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disabled={submitting}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Routing Profiles'
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.routingProfiles)}
                          helperText={errors.routingProfiles?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                name='diallerType'
                control={control}
                rules={{
                  required: 'Dialler type is required.',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    variant='outlined'
                    label='Dialler type'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.diallerType)}
                    helperText={errors.diallerType?.message}
                    value={field.value || ''}>
                    {diallerTypeListDisplay}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='campaignType'
                control={control}
                rules={{
                  required: 'Campaign type is required.',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    variant='outlined'
                    label='Campaign type'
                    disabled={submitting || campaignTypeListDisplay.length === 0}
                    required={true}
                    error={Boolean(errors.campaignType)}
                    helperText={campaignTypeHelperText}
                    value={field.value || ''}>
                    {campaignTypeListDisplay}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledCheckbox
                name='enableManualOutboundWhenOffline'
                label='Enable manual outbound when offline?'
                control={control}
                disabled={submitting}
              />
            </Grid>

            {/* TODO: Remove check when we support manual outbound within predictive */}
            {diallerGroup.diallerType !== DiallerType.SIP && (
              <>
                {diallerGroup.campaignType !== CampaignType.Predictive && (
                  <Grid item xs={12}>
                    <ControlledCheckbox
                      name='enableManualOutboundWhenAvailable'
                      label='Enable manual outbound when available?'
                      control={control}
                      disabled={submitting}
                    />
                  </Grid>
                )}

                {showManualOutboundOptions && (
                  <>
                    <Grid item xs={12}>
                      <ControlledCheckbox
                        name='manualOutboundRequireDetails'
                        label='Manual outbound require details?'
                        control={control}
                        disabled={submitting}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ControlledCheckbox
                        name='enablePreparedOutboundHangup'
                        label='Enable prepared outbound hangup?'
                        control={control}
                        disabled={submitting}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='systemDisposedInboundCalls'
                    label='System dispose inbound calls?'
                    control={control}
                    disabled={submitting}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ControlledCheckbox
                    name='enableVoicemailMessage'
                    label='Enable voicemail message?'
                    control={control}
                    disabled={submitting}
                  />
                </Grid>

                {enableVoicemailMessageWatch && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name='voicemailMessageARN'
                        control={control}
                        rules={{
                          required: 'Voicemail message ARN is required.',
                          validate: (value) => {
                            if (!value || (value && value.length === 0)) return undefined;

                            if (!value.startsWith('arn:')) {
                              return 'Voicemail message ARN must be a valid ARN.';
                            }

                            return undefined;
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant='outlined'
                            label='Voicemail message ARN'
                            disabled={submitting}
                            required={true}
                            error={Boolean(errors.voicemailMessageARN)}
                            helperText={errors.voicemailMessageARN?.message}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default GeneralSettingsForm;
