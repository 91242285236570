import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/system/Box';
import React, { MouseEvent, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';

import { DispositionAttribute } from '../../domain';

interface AttributeCardProps {
  dispositionAttribute: DispositionAttribute;
  onEdit: () => void;
  onDelete: () => void;
}

const AttributeCard = ({ dispositionAttribute, onEdit, onDelete }: AttributeCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const triggerEdit = () => {
    setAnchorEl(null);
    onEdit();
  };

  const triggerDelete = () => {
    onDelete();
    setDeleteConfirmModalOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setAnchorEl(null);
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmModalOpen(false);
  };

  return (
    <>
      <OberonCard
        titleFontWeight={700}
        title={dispositionAttribute.attribute}
        subHeader={
          <Box sx={{ marginLeft: -0.5 }}>
            {dispositionAttribute.values &&
              dispositionAttribute.values.map((value: string, index: number) => (
                <Chip
                  key={index}
                  sx={{ minWidth: 100, maxWidth: 200, margin: 0.5 }}
                  label={value}
                  variant='filled'
                  color='primary'
                />
              ))}
          </Box>
        }
        action={
          <>
            <IconButton aria-controls='agent-menu' aria-haspopup='true' onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>

            <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={triggerEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteConfirmationOpen}>Delete</MenuItem>
            </Menu>
          </>
        }
      />

      <ActionDialog
        open={deleteConfirmModalOpen}
        title='Are you sure you want to do this?'
        content={`You are about to delete the attribute ${dispositionAttribute.attribute}.`}
        onClose={handleDeleteConfirmationClose}
        onAccept={triggerDelete}
        primaryActionTitle='Delete'
      />
    </>
  );
};

export default AttributeCard;
