import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';

type Props = {
  color: string;
  title: string;
  count: number;
  total: number;
};

const CustomizedCardContent = styled(CardContent)({
  'display': 'flex',
  'justifyContent': 'space-between',
  'alignItems': 'center',
  'flexDirection': 'row',
  'paddingTop': 12,
  'paddingLeft': 16,
  'paddingRight': 16,
  'paddingBottom': 12,
  // Override of material UI stupid non consistant padding for element
  ':last-child': {
    paddingBottom: 12,
  },
});

export const CountTotalCard = ({ color, title, count, total }: Props) => {
  const percentage = useMemo(() => {
    // If total agents available is zero we don't want to divide by zero
    if (total === 0) return 0;

    return (count / total) * 100;
  }, [count, total]);

  return (
    <Card style={{ borderLeft: `8px solid ${color}` }}>
      <CustomizedCardContent>
        <Typography fontSize={16} fontWeight={700} variant='body2' align='center'>
          {title}
        </Typography>

        <Box position='relative' display='inline-flex' justifyContent='center' alignItems='center'>
          <CircularProgress style={{ color: color }} variant='determinate' value={percentage} />

          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position='absolute'
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <div style={{ width: '60%' }}>
              <Typography marginBottom={'-1px'} fontSize={9} display='block' variant='caption' align='center'>
                {count}
              </Typography>

              <Divider />

              <Typography marginBottom={'-1px'} fontSize={9} display='block' variant='caption' align='center'>
                {total}
              </Typography>
            </div>
          </Box>
        </Box>
      </CustomizedCardContent>
    </Card>
  );
};

export default CountTotalCard;
