import TextField from '@mui/material/TextField';
import React from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';

interface ControlledTextFieldProps extends UseControllerProps<any, any> {
  label: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  rows?: number;
}

const ControlledTextField = ({
  label,
  disabled,
  required,
  error,
  helperText,
  rows,
  ...controllerProps
}: ControlledTextFieldProps) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          multiline
          rows={rows}
          variant='outlined'
          label={label}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default ControlledTextField;
