import React from 'react';

interface VideoProps {
  id?: string;
  src?: string;
  canDownload?: boolean;
}

const Video = ({ id, src, canDownload }: VideoProps) => {
  const audioProps = {
    id: id,
    src: src,
    controls: true,
    controlsList: canDownload ? undefined : 'nodownload',
  };

  return (
    <div style={{ width: '100%' }}>
      <video style={{ width: '100%' }} {...audioProps}>
        Your browser does not support HTML video.
      </video>
    </div>
  );
};

export default Video;
