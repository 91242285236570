import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import AsyncLoader from '~components/AsyncLoader';
import { DataItem } from '~components/DataItem';
import ControlledSwitch from '~components/Form/ControlledSwitch';
import SectionCard from '~components/SectionCard';
import { useNotification } from '~providers/NotificationProvider';

import useGlobalSettings from './useGlobalSettings';
import { GlobalSettings } from './useGlobalSettings/domain';

type Props = {
  isEdit: boolean;
  toggleEdit?: () => void;
};

export default ({ isEdit, toggleEdit }: Props) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { pushNotification } = useNotification();
  const { loading, settings, update } = useGlobalSettings({ shouldFetch: true });
  const [submitting, setSubmitting] = useState(false);
  const { handleSubmit, control } = useForm<GlobalSettings>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const onSubmitFn = handleSubmit(async (data: Partial<GlobalSettings>) => {
    if (!toggleEdit) return;

    try {
      setSubmitting(true);
      await update(data);
    } catch (e) {
      pushNotification('error', `Failed to update global settings.`);
    } finally {
      setSubmitting(false);
    }

    pushNotification('success', 'You have successfully updated the global settings.');
    toggleEdit();
  });

  return (
    <SectionCard title='Global' onEdit={toggleEdit}>
      <AsyncLoader isLoading={loading}>
        {settings && !isEdit && (
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            title='Is dialling paused'
            value={settings.isDiallingPaused ? 'Yes' : 'No'}
          />
        )}
        {settings && isEdit && (
          <form onSubmit={onSubmitFn} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ControlledSwitch
                  name='isDiallingPaused'
                  label='Is dialling paused?'
                  control={control}
                  disabled={submitting}
                  defaultValue={settings.isDiallingPaused}
                />
              </Grid>

              <Grid sx={{ textAlign: 'right' }} item xs={12}>
                <Button onClick={toggleEdit}>Cancel</Button>

                <LoadingButton
                  sx={{ marginLeft: 1 }}
                  type='submit'
                  variant='contained'
                  disableElevation
                  loading={submitting}
                  color='primary'>
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </AsyncLoader>
    </SectionCard>
  );
};
