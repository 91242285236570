import * as j from '@mojotech/json-type-validation';

import { agentStatusToColor, callOrAgentToColor, camelCaseToTitleCase, timeFrom } from './helpers';

export interface Agent {
  key: string;
  color: string;
  address: string;
  username: string;
  status: string;
  timeInStatus: string;
  isAgentPrimaryChannel: boolean;
}

export interface Customer {
  key: string;
  color: string;
  address: string;
  status: string;
  timeInStatus: string;
}

export interface Lead {
  attributes: { [key: string]: string } | null;
  leadId: number;
  listId: number;
  campaignId: number;
  externalId: string;
  endpoint: string;
  name: string;
  timezone: string;
  requiredSkills: string;
}

export interface Call {
  id: string;
  color: string;
  bridges: { [key: string]: string } | null;
  address: string;
  callTime: string;
  lead: Lead;
  agentChannels: Agent[];
  customerChannels: Customer[];
}

interface SystemConfig {
  customerHoldDropTime: number;
  graphBuckets: number;
  maxLinesPerAgent: number;
  numReservedAgents: number;
  pauseDialling: boolean;
  predictiveLookaheadTime: number;
  ringTimeSeconds: number;
  timeBetweenCallChecks: number;
}

interface AgentStatusCount {
  status: string;
  color: string;
  count: number;
}

export interface AsteriskStats {
  agentTimeInQueue: { [key: string]: number };
  handleTime: { [key: string]: number };
  systemConfig: SystemConfig;
  vars: { [key: string]: string };
  agentStatusCounts: AgentStatusCount[];
  agents: Agent[];
  calls: Call[];
  groupId: number;
  groupName: string;
  noLeads: boolean;
}

export const AsteriskStatsDecoder: j.Decoder<AsteriskStats> = j
  .object({
    agentTimeInQueue: j.dict(j.number()),
    agents: j.dict(
      j.object({
        ConnectDTMF: j.string(),
        address: j.string(),
        afterCallWorkLimit: j.number(),
        callId: j.string(),
        key: j.string(),
        lastACWTime: j.union(j.string(), j.constant(null)),
        lastCallTime: j.union(j.string(), j.constant(null)),
        recordingStartedTime: j.union(j.string(), j.constant(null)),
        stateHistory: j.array(
          j.object({
            changeTime: j.string(),
            previousState: j.string(),
            state: j.string(),
          }),
        ),
        username: j.string(),
        isAgentPrimaryChannel: j.boolean(),
      }),
    ),
    calls: j.dict(
      j.object({
        address: j.string(),
        bridges: j.union(j.dict(j.string()), j.constant(null)),
        id: j.string(),
        callTime: j.string(),
        lead: j.object({
          lead_id: j.number(),
          list_id: j.number(),
          campaign_id: j.number(),
          external_id: j.string(),
          endpoints: j.union(j.array(j.string()), j.constant(null)),
          lead_name: j.string(),
          timezone: j.string(),
          attributes: j.union(j.dict(j.string()), j.constant(null)),
          required_skills: j.string(),
        }),
        customerChannels: j.dict(
          j.object({
            ConnectDTMF: j.string(),
            address: j.string(),
            callId: j.string(),
            key: j.string(),
            stateHistory: j.array(
              j.object({
                changeTime: j.string(),
                previousState: j.string(),
                state: j.string(),
              }),
            ),
          }),
        ),
        agentControls: j.dict(
          j.object({
            username: j.string(),
            channel: j.object({
              ConnectDTMF: j.string(),
              address: j.string(),
              afterCallWorkLimit: j.number(),
              callId: j.string(),
              key: j.string(),
              lastACWTime: j.union(j.string(), j.constant(null)),
              lastCallTime: j.union(j.string(), j.constant(null)),
              recordingStartedTime: j.union(j.string(), j.constant(null)),
              stateHistory: j.array(
                j.object({
                  changeTime: j.string(),
                  previousState: j.string(),
                  state: j.string(),
                }),
              ),
              username: j.string(),
              isAgentPrimaryChannel: j.boolean(),
            }),
          }),
        ),
      }),
    ),
    configuration: j.object({
      customerHoldDropTime: j.number(),
      graphBuckets: j.number(),
      maxLinesPerAgent: j.number(),
      numReservedAgents: j.number(),
      pauseDialling: j.boolean(),
      predictiveLookaheadTime: j.number(),
      ringTimeSeconds: j.number(),
      timeBetweenCallChecks: j.number(),
    }),
    handlingTime: j.dict(j.number()),
    noLeads: j.boolean(),
    groupName: j.string(),
    groupId: j.number(),
    agentStates: j.array(j.string()),
    vars: j.dict(j.string()),
  })
  .map((item) => {
    let statusCountMap = item.agentStates.map((state: string) => ({
      status: state,
      color: agentStatusToColor(state),
      count: 0,
    }));

    statusCountMap = [
      ...statusCountMap,
      {
        status: 'Other',
        color: agentStatusToColor('Other'),
        count: 0,
      },
    ];

    return {
      agentTimeInQueue: item.agentTimeInQueue,
      handleTime: item.handlingTime,
      systemConfig: {
        customerHoldDropTime: item.configuration.customerHoldDropTime / 1000000000,
        graphBuckets: item.configuration.graphBuckets,
        maxLinesPerAgent: item.configuration.maxLinesPerAgent,
        numReservedAgents: item.configuration.numReservedAgents,
        pauseDialling: item.configuration.pauseDialling,
        predictiveLookaheadTime: item.configuration.predictiveLookaheadTime / 1000000000,
        ringTimeSeconds: item.configuration.ringTimeSeconds,
        timeBetweenCallChecks: item.configuration.timeBetweenCallChecks / 1000000000,
      },
      vars: Object.keys(item.vars).reduce(
        (state, key) => ({
          ...state,
          [camelCaseToTitleCase(key)]: item.vars[key],
        }),
        {},
      ),
      agentStatusCounts: Object.keys(item.agents).reduce((state, currentVal) => {
        const statusKey = item.agents[currentVal].stateHistory[item.agents[currentVal].stateHistory.length - 1].state;
        let index = state.findIndex((item) => item.status === statusKey);

        if (index === -1) {
          // Let's roll up all unknown agent states into the hard coded "Other" status
          index = state.findIndex((item) => item.status === 'Other');
        }

        state[index].count = state[index].count + 1;
        return state;
      }, statusCountMap),
      agents: Object.keys(item.agents).map((key) => ({
        key: item.agents[key].key,
        color: callOrAgentToColor(item.agents[key].key),
        address: item.agents[key].address,
        username: item.agents[key].username,
        status: item.agents[key].stateHistory[item.agents[key].stateHistory.length - 1].state,
        timeInStatus: timeFrom(item.agents[key].stateHistory[item.agents[key].stateHistory.length - 1].changeTime),
        isAgentPrimaryChannel: item.agents[key].isAgentPrimaryChannel,
      })),
      calls: Object.keys(item.calls).map((key) => ({
        id: item.calls[key].id,
        color: callOrAgentToColor(item.calls[key].id),
        bridges: item.calls[key].bridges,
        address: item.calls[key].address,
        callTime: timeFrom(item.calls[key].callTime),
        lead: {
          leadId: item.calls[key].lead.lead_id,
          listId: item.calls[key].lead.list_id,
          campaignId: item.calls[key].lead.campaign_id,
          externalId: item.calls[key].lead.external_id,
          endpoint: item.calls[key].lead.endpoints?.[0] || '',
          name: item.calls[key].lead.lead_name,
          timezone: item.calls[key].lead.timezone,
          attributes: item.calls[key].lead.attributes,
          requiredSkills: item.calls[key].lead.required_skills,
        },
        agentChannels: Object.keys(item.calls[key].agentControls).map((ak) => ({
          key: item.calls[key].agentControls[ak].channel.key,
          color: callOrAgentToColor(item.calls[key].agentControls[ak].channel.key),
          address: item.calls[key].agentControls[ak].channel.address,
          username: item.calls[key].agentControls[ak].username,
          status:
            item.calls[key].agentControls[ak].channel.stateHistory[
              item.calls[key].agentControls[ak].channel.stateHistory.length - 1
            ].state,
          timeInStatus: timeFrom(
            item.calls[key].agentControls[ak].channel.stateHistory[
              item.calls[key].agentControls[ak].channel.stateHistory.length - 1
            ].changeTime,
          ),
          isAgentPrimaryChannel: item.calls[key].agentControls[ak].channel.isAgentPrimaryChannel,
        })),
        customerChannels: Object.keys(item.calls[key].customerChannels).map((ck) => ({
          key: item.calls[key].customerChannels[ck].key,
          color: callOrAgentToColor(item.calls[key].customerChannels[ck].key),
          address: item.calls[key].customerChannels[ck].address,
          status:
            item.calls[key].customerChannels[ck].stateHistory[
              item.calls[key].customerChannels[ck].stateHistory.length - 1
            ].state,
          timeInStatus: timeFrom(
            item.calls[key].customerChannels[ck].stateHistory[
              item.calls[key].customerChannels[ck].stateHistory.length - 1
            ].changeTime,
          ),
        })),
      })),
      noLeads: item.noLeads,
      groupId: item.groupId,
      groupName: item.groupName,
    };
  });

export const AsteriskAllStatsDecoder: j.Decoder<AsteriskStats[]> = j.array(AsteriskStatsDecoder);
