import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';

// TODO: We lose type saftey here but not sure how else to handle this
// Fix once figured out
interface ControlledCheckboxProps extends UseControllerProps<any, any> {
  label: string;
  disabled?: boolean;
  helperText?: string;
}

const ControlledCheckbox = ({ label, disabled, helperText, ...controllerProps }: ControlledCheckboxProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <Controller
            {...controllerProps}
            render={({ field }) => (
              <Checkbox
                {...field}
                disabled={disabled}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
              />
            )}
          />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};

export default ControlledCheckbox;
