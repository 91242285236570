import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard as ReactCTP } from 'react-copy-to-clipboard';

export type IconSize = 'inherit' | 'large' | 'medium' | 'small';
export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface CopyToClipboardProps {
  value: string;
  showSuccessMs?: number; // time showing success icon instead of copy icon
  iconSize?: IconSize;
  tooltipPlacement?: TooltipPlacement;
  onCopy?: () => void;
}

export const CopyToClipboard = ({ value, showSuccessMs, iconSize, tooltipPlacement, onCopy }: CopyToClipboardProps) => {
  const resolvedDurationMs = showSuccessMs || 2000;
  const resolvedIconSize = iconSize || 'small';
  const resolvedTooltipPlacement = tooltipPlacement || 'top';

  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    return function CopyToClipboardCleanup() {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const defaultOnClick = () => {
    setCopied(true);
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, resolvedDurationMs);
  };

  const resolvedOnCopy = useCallback(() => {
    if (onCopy) {
      onCopy();
    } else {
      defaultOnClick();
    }
  }, [onCopy]);

  return (
    <span style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={(e: MouseEvent) => e.stopPropagation()}>
      <ReactCTP text={value} onCopy={resolvedOnCopy}>
        {copied ? (
          <CheckOutlinedIcon color='success' />
        ) : (
          <Tooltip title='Copy to clipboard' arrow placement={resolvedTooltipPlacement}>
            <ContentCopyIcon sx={{ cursor: 'pointer', marginLeft: 1, display: 'block' }} fontSize={resolvedIconSize} />
          </Tooltip>
        )}
      </ReactCTP>
    </span>
  );
};
