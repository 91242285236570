import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef, useState } from 'react';

import { Agent } from '~pages/SystemManagement/domain';

import { getAgent } from './api';

const useAgent = (username: string, shouldFetch: boolean = true) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    setAgent(undefined);
  }, [username, shouldFetch]);

  useEffect(() => {
    const load = async (username: string) => {
      setLoading(true);
      setError(false);

      let resp: Agent | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAgent(username, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setAgent(resp);
      setLoading(false);
    };

    // No point searching for an agent if the identifier is not set
    if (username === '') {
      setLoading(false);
      return;
    }

    if (shouldFetch) {
      load(username);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [username, shouldFetch]);

  return { loading, error, agent };
};

export default useAgent;
