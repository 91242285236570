import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getDeobfucatedValue } from './api';

type Props = {
  token: string;
};

export const DeobfuscateValue = ({ token }: Props) => {
  const [deobfuscatedValue, setDeobfuscatedValue] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const getValue = async () => {
    setLoading(true);
    setError('');
    let value: string;

    try {
      value = await getDeobfucatedValue(token);
    } catch (e) {
      console.error((e as APIError | UnsupportedStructureError).message);
      setError('Unable to get decoded value');
      return;
    } finally {
      setLoading(false);
    }

    setDeobfuscatedValue(value);
  };

  const clearValue = () => {
    setDeobfuscatedValue(undefined);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography fontSize={14} sx={{ wordBreak: 'break-word', fontSize: 14 }} align='left' color='textSecondary'>
          {deobfuscatedValue || token}
        </Typography>

        {!deobfuscatedValue && (
          <IconButton sx={{ marginLeft: 0.5 }} size='small' disabled={loading} onClick={getValue}>
            {!loading && <VisibilityIcon fontSize='small' />}
            {loading && <CircularProgress size={20} />}
          </IconButton>
        )}

        {deobfuscatedValue && (
          <IconButton sx={{ marginLeft: 0.5 }} size='small' onClick={clearValue}>
            <VisibilityOffIcon fontSize='small' />
          </IconButton>
        )}
      </Box>

      {error && (
        <Typography sx={{ wordBreak: 'break-word', marginTop: -1 }} fontSize={10} align='left' color='red'>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default DeobfuscateValue;
