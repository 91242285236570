import HelpIcon from '@mui/icons-material/Help';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ChannelStats } from '~services/AsyncStatsManager/domain';
import { getDurationFromSeconds } from '~utils/Functions';

import { channelIdToColor, channelIdToIcon, channelIdToName } from '../helper';

type Props = {
  channel: ChannelStats;
};

const ChannelInfo = ({ channel }: Props) => {
  const Icon = channelIdToIcon[channel.channel] || HelpIcon;
  const title = channelIdToName[channel.channel] || channel.channel;
  const color = channelIdToColor[channel.channel] || '#000000';

  return (
    <Card style={{ borderLeft: `8px solid ${color}` }}>
      <CardContent
        sx={{
          'paddingTop': '12px',
          'paddingLeft': '16px',
          'paddingRight': '16px',
          'paddingBottom': '12px',
          // Override of material UI stupid non-consistent padding for element
          ':last-child': {
            paddingBottom: '12px',
          },
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-Start', alignItems: ' center' }}>
          {Icon && (
            <Avatar alt={title} sx={{ backgroundColor: color, marginRight: 2 }}>
              <Icon />
            </Avatar>
          )}

          <Box>
            <Typography fontSize={16} fontWeight={700} variant='body2' align='left'>
              {title}
            </Typography>

            <Typography fontSize={12} display='block' variant='caption' align='left'>
              <b>In Queue:</b> {channel.conversationsInQueue}
            </Typography>

            <Typography fontSize={12} display='block' variant='caption' align='left'>
              <b>Abandoned:</b> {channel.conversationsAbandonedInQueue}
            </Typography>

            <Typography fontSize={12} display='block' variant='caption' align='left'>
              <b>AVG. Wait Time:</b> {getDurationFromSeconds(channel.averageWaitTimeSec)}
            </Typography>

            <Typography fontSize={12} display='block' variant='caption' align='left'>
              <b>AVG. Handling Time:</b> {getDurationFromSeconds(channel.averageHandlingTimeSec)}
            </Typography>

            <Typography fontSize={12} display='block' variant='caption' align='left'>
              <b>AVG. First Response Time:</b> {getDurationFromSeconds(channel.averageFirstResponseTimeSec)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ChannelInfo;
