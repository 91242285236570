import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import React from 'react';

import { DataItem } from '~components/DataItem';
import OberonDialog from '~components/OberonDialog';
import { Callback } from '~pages/Callbacks/useCallbacks/domain';

interface CallbackDetailsModalProps {
  open: boolean;
  callback?: Callback;
  onClose: () => void;
}

const CallbackDetailsModal = ({ open, callback, onClose }: CallbackDetailsModalProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const name = callback?.leadName || '';
  const callbackId = callback?.id;
  const scheduledTimestamp = callback?.scheduled
    ? DateTime.fromISO(callback.scheduled, { zone: callback.timezone }).toFormat('FFF')
    : 'N/A';
  const forAgent = callback?.forAgent || 'Assigned to group';
  const endpoint = callback?.endpoint || 'N/A';
  const timezone = callback?.timezone || 'N/A';
  const notes = callback?.notes || 'No notes provided.';
  const campaignName = callback?.campaignName || 'N/A';
  const listName = callback?.listName || 'N/A';
  let nextAttemptTimestamp = undefined;

  if (callback && callback.nextAttemptTimestamp != null && callback.nextAttemptTimestamp !== callback.scheduled) {
    nextAttemptTimestamp = DateTime.fromISO(callback.nextAttemptTimestamp, { zone: callback.timezone }).toFormat('FFF');
  }

  return (
    <OberonDialog
      open={open}
      closeOnBackdropClick
      onClose={onClose}
      title={name}
      content={
        <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataItem stacked disableMargin={!isExtraSmall} title='Callback ID' value={callbackId} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem
              stacked
              disableMargin={!isExtraSmall}
              title='Scheduled'
              value={
                <>
                  <Typography
                    fontSize={14}
                    sx={{ wordBreak: 'break-word', flex: '1 1 100%', fontSize: 14 }}
                    align='left'
                    color='textSecondary'>
                    {scheduledTimestamp}
                  </Typography>

                  {nextAttemptTimestamp ? (
                    <Typography
                      fontSize={14}
                      sx={{ wordBreak: 'break-word', flex: '1 1 100%', fontSize: 14 }}
                      align='left'
                      color='textSecondary'>
                      (Automatically rescheduled to {nextAttemptTimestamp})
                    </Typography>
                  ) : null}
                </>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked title='Timezone' value={timezone} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked title='For Agent' value={forAgent} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked title='Endpoint' value={endpoint} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked disableMargin={!isExtraSmall} title='Campaign' value={campaignName} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DataItem stacked disableMargin={!isExtraSmall} title='List' value={listName} />
          </Grid>

          <Grid item xs={12}>
            <DataItem stacked title='Notes' value={notes} />
          </Grid>
        </Grid>
      }
    />
  );
};

export default CallbackDetailsModal;
