// Crockford's Base32
const base32Decode = (value: string) => {
  const dict = '0123456789ABCDEFGHJKMNPQRSTVWXYZ';
  const vUpper = value.toUpperCase();
  let out = 0;

  for (let x = 0; x < value.length; x++) {
    out <<= 5;
    out |= dict.indexOf(vUpper.charAt(x), 0);
  }

  return out;
};

const getHashCode = (input: string | undefined | null): number => {
  if (!input) return 0;

  let hash = 0;

  for (let i = 0; i < input.length; i++) {
    hash += input.charCodeAt(i) * (i + 1);
  }

  return hash;
};

// Pastel based off of status value
export const agentStatusToColor = (value: string) => {
  const stringHash = getHashCode(value);
  const hue = ~~(stringHash / 360) * 15;

  return `hsl(${hue}, 75%, 75%)`;
};

export const callOrAgentToColor = (value: string) => {
  if (value.length < 14) {
    return 'black';
  }

  const randBytes = value.substr(10, 4);
  const hue = (base32Decode(randBytes) % 18) * 20;
  const sat = (base32Decode(randBytes.split('').reverse().join('')) % 4) * 15;

  let lum = '30%';

  if (value.endsWith('agnt')) {
    lum = '75%';
  }

  return 'hsl(' + hue + ', ' + (55 + sat) + '%, ' + lum + ')';
};

export const timeFrom = (startTime: string) => {
  const stateElapsedTime = Math.round((Date.now() - new Date(startTime).getTime()) / 1000);
  const sec = stateElapsedTime % 60;
  const mins = Math.floor(stateElapsedTime / 60);
  const minsS = mins;
  let secS = sec.toString();

  if (sec < 10) {
    secS = '0' + secS;
  }

  return minsS + ':' + secS;
};

export const camelCaseToTitleCase = (value: string) => {
  // just return current value if non existent
  if (value === null || value === undefined) return value;

  let formattedValue = value.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, '$1');
  formattedValue = formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);

  return formattedValue;
};
