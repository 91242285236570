import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useAgentList from '~hooks/useAgentList';
import useDebounce from '~hooks/useDebounce';
import useDiallerGroupRoutingProfiles, {
  isDiallerGroupRoutingProfileList,
} from '~hooks/useDiallerGroupRoutingProfiles';
import { UpdateAgentGroupAssignment } from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupAgents/useGroupAssignedAgentSearch/domain';
import { RoutingProfile } from '~pages/CampaignManagement/domain';
import { Agent } from '~pages/SystemManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

interface AssignAgentModalProps {
  open: boolean;
  submitting: boolean;
  diallerGroupId: number;
  onAccept: (data: UpdateAgentGroupAssignment) => void;
  onClose: () => void;
}

interface Form {
  agents: Agent[];
  routingProfile: string;
}

const AssignAgentModal = ({ open, submitting, diallerGroupId, onAccept, onClose }: AssignAgentModalProps) => {
  const appConfig = useAppConfiguration();
  const { accessFilter } = useUserPreferences();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const {
    loading: agentFetching,
    agents,
    error: agentFetchError,
    intersectionObserverRef: lastDataElement,
  } = useAgentList(debouncedSearch, {
    allocated: false,
    shouldFetch: open,
    accessFilterId: accessFilter?.id,
    disabled: false,
  });
  const { loading: groupRoutingProfileFetching, data: groupRoutingProfile } = useDiallerGroupRoutingProfiles({
    diallerGroupId: diallerGroupId,
    shouldFetch: open,
  });
  const routingProfiles =
    groupRoutingProfile && !isDiallerGroupRoutingProfileList(groupRoutingProfile)
      ? groupRoutingProfile.routingProfiles
      : [];
  const routingProfileList = routingProfiles.map((item: RoutingProfile) => item.id);
  const isLoading = submitting || agentFetching || groupRoutingProfileFetching;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      agents: [],
      routingProfile: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const agentsWatch = watch('agents');

  useEffect(() => {
    return () => {
      reset();
    };
  }, [open]);

  const onSubmit = handleSubmit(async (data: Form) => {
    const submitData = {
      agents: data.agents.map((item) => item.username),
      routingProfile: data.routingProfile,
    };

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  // Updates search state as well as manages when it should be updated
  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const onSearchBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    setSearch('');
  }, []);

  const agentSearchNoOptionsText = useMemo(() => {
    if (agentFetching) {
      return <DotLoader align='center' />;
    }

    if (agentFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load agents
        </Typography>
      );
    }

    return undefined;
  }, [agentFetching, agentFetchError]);

  // We manually filter out already selected options as the Mui Autocomplete component option to do this is dumb,
  // and there are no ways to override it. Note `filterOptions` is only for search, not base display on initial open
  const agentFilteredList = useMemo(() => {
    if (!agentsWatch) {
      return agents;
    }

    return agents.filter((agent) => {
      return !agentsWatch.find((item) => item.username === agent.username);
    });
  }, [agents, agentsWatch]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Assign Agents'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='agents'
              control={control}
              rules={{
                required: 'At least one agent needs to be selected.',
              }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  multiple
                  fullWidth
                  options={agentFilteredList}
                  noOptionsText={agentSearchNoOptionsText}
                  disabled={submitting}
                  getOptionLabel={(option) => option?.fullName || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastDataElement} key={option.username}>
                      <div>
                        <Typography variant='body2' color='textPrimary' component='p'>
                          {option.fullName}
                        </Typography>

                        <Typography variant='caption' color='textSecondary' component='p'>
                          {option.username}
                        </Typography>
                      </div>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Agents'
                      variant='outlined'
                      required={true}
                      error={Boolean(errors.agents)}
                      helperText={errors.agents?.message}
                      onBlur={onSearchBlur}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>

          {!appConfig.aws.externallyManagedRoutingProfile && (
            <Grid item xs={12}>
              <Controller
                name='routingProfile'
                control={control}
                rules={{
                  required: 'Routing profile is Required.',
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    fullWidth
                    options={routingProfileList}
                    filterSelectedOptions
                    disabled={submitting}
                    getOptionLabel={(option) => routingProfiles.find((item) => item.id === option)?.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Routing Profiles'
                        variant='outlined'
                        required={true}
                        error={Boolean(errors.routingProfile)}
                        helperText={errors.routingProfile?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Assign
          </LoadingButton>
        </>
      }
    />
  );
};

export default AssignAgentModal;
