import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import useAgent from '~hooks/useAgent';
import useAgentList from '~hooks/useAgentList';
import useDebounce from '~hooks/useDebounce';
import { Callback, UpdateCallback } from '~pages/Callbacks/useCallbacks/domain';
import { getCampaignById, getPublicHolidays } from '~pages/CampaignManagement/api';
import { DiallingHours, PublicHoliday } from '~pages/CampaignManagement/domain';
import { Agent } from '~pages/SystemManagement/domain';
import { AttemptCreationContext } from '~providers/AttemptProvider/domain';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

interface Form {
  forAgent: Agent | null;
  scheduled: string;
}

interface EditCallbackModalProps {
  open: boolean;
  submitting: boolean;
  callback?: Callback;
  onAccept: (callbackId: number, data: UpdateCallback) => void;
  onClose: () => void;
}

const fetchPublicHolidaysOrEmptyArray = async (timezone: string) => {
  try {
    return await getPublicHolidays(timezone);
  } catch (e) {
    return [];
  }
};

const fetchDiallingHoursOrUndefined = async (campaignId: number) => {
  try {
    const campaign = await getCampaignById(campaignId);

    if (
      campaign !== undefined &&
      campaign.leadEngineSettings !== undefined &&
      campaign.leadEngineSettings.diallingHours !== undefined
    ) {
      return campaign.leadEngineSettings.diallingHours;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

// Used for disabling dates within disableDates function
const dayToWeekdayNumber: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// Used for showing error messages for selected date range within onValidation function
const weekdayNumberToDay: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const EditCallbackModal = ({ open, submitting, callback, onAccept, onClose }: EditCallbackModalProps) => {
  const { accessFilter } = useUserPreferences();
  const [search, setSearch] = useState<{ agent: string }>({ agent: '' });
  const [fetchingFormData, setFetchingFormData] = useState<boolean>(false);
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([]);
  const [diallingHours, setDiallingHours] = useState<DiallingHours | undefined>(undefined);
  const debouncedSearchAgent = useDebounce(search.agent, 500);
  const {
    loading: fetchingAgents,
    agents,
    intersectionObserverRef: lastAgentDataElement,
  } = useAgentList(debouncedSearchAgent, {
    shouldFetch: open || Boolean(debouncedSearchAgent),
    accessFilterId: accessFilter?.id,
    disabled: false,
  });
  const { loading: fetchingAgent, agent } = useAgent(callback?.forAgent ?? '', open);
  const isLoading = submitting || fetchingFormData || fetchingAgent || fetchingAgents;

  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      forAgent: null,
      scheduled: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const isInQueueCallback = callback?.addedAttemptCreationContext === AttemptCreationContext.ManagedInboundQueue;

  // Old data fetch methods
  useEffect(() => {
    (async () => {
      if (open && callback) {
        setFetchingFormData(true);
        setDiallingHours(await fetchDiallingHoursOrUndefined(callback.campaignId));
        setPublicHolidays(await fetchPublicHolidaysOrEmptyArray(callback.timezone));
        setFetchingFormData(false);
      }
    })();
  }, [open, callback]);

  useEffect(() => {
    if (open && callback) {
      setValue('scheduled', callback.scheduled);
      setValue('forAgent', agent || null);
    }

    // Reset form on close
    return function cleanupEditCallbackModal() {
      reset();
    };
  }, [open, callback, agent]);

  const disableDates = (date: DateTime | null) => {
    if (diallingHours !== undefined) {
      // Disable public holidays if not allowed
      if (diallingHours.allowPublicHolidays === false) {
        if (publicHolidays.length === 0) return false;

        for (const ph of publicHolidays) {
          if (date && ph.year === date.year && ph.month === date.month && ph.day === date.day) {
            return true;
          }
        }
      }

      // Disable specific day of the week if no dialling day hours exist
      for (const day in diallingHours?.diallingDays) {
        if (diallingHours?.diallingDays[day] === null && dayToWeekdayNumber[day] === date?.weekday) {
          return true;
        }
      }
    }

    return false;
  };

  const invalidTimeSelection = (value: string): string | undefined => {
    if (value === null || value === undefined) {
      return undefined;
    }

    // If this is undefined we assume everything is fair game (even if an error occurs fetching this :/)
    if (diallingHours === undefined) {
      return undefined;
    }

    const date = DateTime.fromISO(value as string);
    const diallingDay = diallingHours.diallingDays[weekdayNumberToDay[date.weekday]];

    // As this value is null the day should already be disabled within the disableDates function.
    // If for any reason that function bugs out, this logic here should serve as a secondary
    // stop gap to prevent selecting this day/ time
    if (diallingDay === null) {
      return undefined;
    }

    // If select time is between the hours blocks we allow anything and say its valid
    if (date.hour > diallingDay.startTimeHour && date.hour < diallingDay.endTimeHour) {
      return undefined;
    }

    // Start and end edge cases
    if (
      (date.hour === diallingDay.startTimeHour && date.minute >= diallingDay.startTimeMin) ||
      (date.hour === diallingDay.endTimeHour && date.minute < diallingDay.endTimeMin)
    ) {
      return undefined;
    }

    const startHour = diallingDay.startTimeHour < 10 ? `0${diallingDay.startTimeHour}` : diallingDay.startTimeHour;
    const endHour = diallingDay.endTimeHour < 10 ? `0${diallingDay.endTimeHour}` : diallingDay.endTimeHour;
    const startMinute = diallingDay.startTimeMin < 10 ? `0${diallingDay.startTimeMin}` : diallingDay.startTimeMin;
    const endMinute = diallingDay.endTimeMin < 10 ? `0${diallingDay.endTimeMin}` : diallingDay.endTimeMin;
    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    return `Callback schedule time must be between ${startTime} and ${endTime}`;
  };

  const onSubmit = handleSubmit(async (data: Form) => {
    // callback should not be undefined, if it reaches here as undefined we have a bigger issue...
    const timezone = callback!.timezone;
    const username = data.forAgent !== null && data.forAgent !== undefined ? data.forAgent.username : null;
    const submitData: UpdateCallback = {
      scheduled: DateTime.fromISO(data.scheduled, { zone: timezone }).toISO(),
      forAgent: isInQueueCallback ? null : username,
    };

    try {
      await onAccept(callback?.id as number, submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  // Updates search state as well as manages when it should be updated
  const onSearchChange = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setSearch((prev) => ({ ...prev, [key]: e.target.value }));
    },
    [],
  );

  const onSearchBlur = useCallback(
    (key: string) => (e: FocusEvent<HTMLInputElement>) => {
      setSearch((prev) => ({ ...prev, [key]: '' }));
    },
    [],
  );

  const scheduledHelpTextDefault =
    callback !== undefined ? `(${callback?.leadName}'s time in ${callback?.timezone})` : `(local time is unknown)`;
  const scheduledHelperText = errors.scheduled ? errors.scheduled?.message : scheduledHelpTextDefault;
  const forAgentHelperText =
    '(Note: If an agent is not selected the callback will be assigned to the group of agents currently assigned to the campaign the lead is a part of. If there are no agents servicing the campaign, this callback will never be dialled.)';

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={isInQueueCallback ? `Edit Callback (In Queue Callback)` : `Edit Callback`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='scheduled'
              control={control}
              rules={{
                required: 'A callback date/ time is required',
                validate: invalidTimeSelection,
              }}
              render={({ field }) => (
                <DateTimePicker
                  disableMaskedInput
                  label='Callback Schedule'
                  inputFormat='dd/MM/yyyy hh:mm a'
                  disabled={isLoading}
                  disablePast={true}
                  shouldDisableDate={disableDates}
                  ampm={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant='outlined'
                      required={true}
                      error={Boolean(errors.scheduled)}
                      helperText={scheduledHelperText}
                    />
                  )}
                  {...field}
                />
              )}
            />
          </Grid>

          {!isInQueueCallback && (
            <Grid item xs={12}>
              <Controller
                name='forAgent'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, data) => field.onChange(data)}
                    fullWidth
                    options={agents}
                    filterSelectedOptions
                    disabled={isLoading}
                    getOptionLabel={(option) => option?.fullName || ''}
                    renderOption={(props, option) => (
                      <li {...props} ref={lastAgentDataElement} key={option.username}>
                        <div>
                          <Typography variant='body2' color='textPrimary' component='p'>
                            {option.fullName}
                          </Typography>

                          <Typography variant='caption' color='textSecondary' component='p'>
                            {option.username}
                          </Typography>
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='For Agent'
                        variant='outlined'
                        onBlur={onSearchBlur('agent')}
                        onChange={onSearchChange('agent')}
                        helperText={forAgentHelperText}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditCallbackModal;
