import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip from '@mui/material/Chip';
import { amber, red } from '@mui/material/colors';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, {
  ForwardedRef,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

import OberonCard from '~components/OberonCard';
import ToggleAction, { ToggleActionProps } from '~components/ToggleAction';

interface Props {
  name: string;
  isDefaultCampaign?: boolean;
  active: boolean;
  archived: boolean;
  to?: string;
  toggleAction?: ToggleActionProps['action'];
  toggleCallback?: ToggleActionProps['callback'];
  statisticItems?: { icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>; text: string }[];
  menuItems?: { action: () => void; name: string }[];
  needsSourceNumberConfiguration?: boolean;
}

const CampaignCard = forwardRef(
  (
    {
      name,
      isDefaultCampaign,
      active,
      archived,
      to,
      toggleAction,
      toggleCallback,
      statisticItems,
      menuItems,
      needsSourceNumberConfiguration,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [isActive, setIsActive] = useState(active);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const activeAndNotArchived = isActive && !archived;
    const borderAndStatusBackgroundColor = archived ? grey[600] : isActive ? green[600] : amber[600];
    const defaultListColor = activeAndNotArchived ? grey[600] : red[600];
    const statusName = archived ? 'Archived' : isActive ? 'Active' : 'Paused';

    const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
    }, []);

    const handleMenuClose = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(null);
    }, []);

    const handleActionClick = useCallback((e: MouseEvent<HTMLLIElement>, callback: () => void) => {
      e.stopPropagation();
      setAnchorEl(null);
      callback();
    }, []);

    const toggleActive = useMemo(
      () =>
        toggleAction && (
          <ToggleAction
            key='toggleActive'
            checked={isActive}
            action={async (v) => {
              setIsActive(v);
              await toggleAction(v);
            }}
            callback={(v) => {
              if (toggleCallback) {
                toggleCallback(v);
              }
              setIsActive(v);
            }}
          />
        ),
      [isActive, toggleAction, toggleCallback],
    );

    const menuList = useMemo(
      () =>
        menuItems && (
          <Fragment key='menuList'>
            <IconButton
              aria-controls='agent-menu'
              aria-haspopup='true'
              onKeyPress={handleMenuOpen}
              onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
            <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {menuItems.map((item, index) => (
                <MenuItem key={index} onClick={(e) => handleActionClick(e, item.action)}>
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </Fragment>
        ),
      [menuItems, anchorEl],
    );

    const statisticList = useMemo(() => {
      if (!statisticItems) {
        return [];
      }

      return statisticItems.map((item, index) => (
        <Typography
          key={index}
          marginLeft={index === 0 ? 0 : 1}
          marginRight={index === statisticItems.length - 1 ? 0 : 1}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          variant='caption'
          color='textSecondary'>
          {item.icon && <item.icon sx={{ marginRight: 0.5 }} fontSize='small' />}
          {item.text}
        </Typography>
      ));
    }, [statisticItems]);

    return (
      <OberonCard
        ref={ref}
        to={to}
        titleFontWeight={400}
        title={name}
        subHeader={
          <>
            <Chip
              sx={{
                marginTop: 2,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: borderAndStatusBackgroundColor,
              }}
              label={statusName}
            />

            {isDefaultCampaign && (
              <Chip
                sx={{
                  marginTop: 2,
                  marginLeft: 1,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  borderRadius: 1,
                  height: 20,
                  lineHeight: '21px',
                  color: '#ffffff',
                  fontWeight: 700,
                  backgroundColor: defaultListColor,
                }}
                label='Default Campaign'
              />
            )}

            {needsSourceNumberConfiguration && (
              <Chip
                sx={{
                  marginTop: 2,
                  marginLeft: 1,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  borderRadius: 1,
                  height: 20,
                  lineHeight: '21px',
                  color: '#ffffff',
                  fontWeight: 700,
                }}
                color='warning'
                label='Missing source number configuration'
              />
            )}
          </>
        }
        footerBorderColor={borderAndStatusBackgroundColor}
        action={[toggleActive, menuList]}
        footer={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>{statisticList}</Box>
          </Box>
        }
      />
    );
  },
);

export default CampaignCard;
