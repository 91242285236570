import SocketManagerBase from '~services/SocketManagerBase';

import { AgentStateUpdate, ServerMessage, ServerMessageResponseDecoder } from './domain';

interface AgentStatesCallbacks {
  onConnected: () => void;
  onMessage: (socketMessage: ServerMessage) => void;
  onDisconnected: (closeCode: number) => void;
}

export class AgentStatesManager extends SocketManagerBase {
  private heartbeatIntervalRef: number | undefined;
  private callbacks: AgentStatesCallbacks = {
    onConnected: () => {},
    onMessage: () => {},
    onDisconnected: () => {},
  };

  constructor(address: string, callbacks: Partial<AgentStatesCallbacks>) {
    super('AgentStatesManager', address);

    // Assign optional functions else default to empty default values
    this.callbacks.onConnected = callbacks.onConnected || this.callbacks.onConnected;
    this.callbacks.onMessage = callbacks.onMessage || this.callbacks.onMessage;
    this.callbacks.onDisconnected = callbacks.onDisconnected || this.callbacks.onDisconnected;
  }

  /** sends agent state data */
  public sendAgentState = (agentState: AgentStateUpdate): void => {
    console.log('AgentStatesManager action send agent state triggered.');

    if (this.ws === undefined) {
      console.error('sendAgentState: websocket missing.');
      return;
    }

    const req = {
      agent_state_update: {
        agent_state: agentState.state,
        agent_state_type: agentState.stateType,
        contact_state_type: agentState.contactStateType || '',
        routing_profile: agentState.routingProfile,
        has_async: agentState.hasAsync,
        has_voice: agentState.hasVoice,
        in_voice_call: agentState.inVoiceCall,
        messaging_concurrency: agentState.messagingConcurrency,
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Event that is triggered on the successful connection of the websocket */
  protected onOpen = (): void => {
    if (this.ws === undefined) {
      console.error('onOpen: websocket missing.');
      return;
    }

    // Post heartbeat to let the socket know we are still here
    this.heartbeatIntervalRef = window.setInterval(() => {
      const req = {
        heartbeat: '',
      };

      this.send(JSON.stringify(req));
    }, 15_000);

    this.callbacks.onConnected();
  };

  /** Event handler that manages all responses from the connected websocket */
  protected onMessage = (messageEvent: MessageEvent): void => {
    let msg: ServerMessage;

    try {
      const rawMessage = JSON.parse(messageEvent.data);
      msg = ServerMessageResponseDecoder.runWithException(rawMessage);
    } catch (e) {
      console.error('onMessage: Unable to decode message payload: ', e);
      return;
    }

    this.callbacks.onMessage(msg);
  };

  /** Event handler that is triggered if the websocket is closed/ ended */
  protected onClose = (closeEvent: CloseEvent): void => {
    console.log('AgentStatesManager websocket closed with code: ', closeEvent.code);

    clearInterval(this.heartbeatIntervalRef);
    this.ws = undefined;
    this.callbacks.onDisconnected(closeEvent.code);
  };
}
