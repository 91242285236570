import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import DispositionCard from '~components/DispositionCard';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import {
  createAsyncQueueDisposition,
  getAsyncQueueDispositions,
  removeAsyncQueueDisposition,
  updateAsyncQueueDisposition,
} from '~pages/AsyncManagement/api';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { QueueDisposition, UpdateQueueDisposition } from '../../domain';
import CreateEditQueueDispositionModal from './CreateEditQueueDispositionModal';
import QueueDispositionDetailsModal from './QueueDispositionDetailsModal';

interface QueueDispositionsProps {
  queue: string;
}

// Guard for checking if object is type of interface CreateDisposition
const isCreateQueueDisposition = (item: QueueDisposition | UpdateQueueDisposition): item is QueueDisposition => {
  return (item as QueueDisposition).code !== undefined;
};

// Guard for checking if object is type of interface UpdateDisposition
const isUpdateQueueDisposition = (item: QueueDisposition | UpdateQueueDisposition): item is UpdateQueueDisposition => {
  return (item as UpdateQueueDisposition).originalCode !== undefined;
};

const QueueDispositions = ({ queue }: QueueDispositionsProps) => {
  const { pushNotification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [dispositions, setDispositions] = useState<QueueDisposition[]>([]);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [createDispositionModalOpen, setCreateDispositionModalOpen] = useState<boolean>(false);
  const [selectedDispositionRef, setSelectedDispositionRef] = useState<{ code: string; subCode: string } | undefined>(
    undefined,
  );
  const [editableDispositionRef, setEditableDispositionRef] = useState<{ code: string; subCode: string } | undefined>(
    undefined,
  );
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const selectedDisposition = useMemo(
    () =>
      dispositions.find(
        (item) => item.code === selectedDispositionRef?.code && item.subCode === selectedDispositionRef?.subCode,
      ),
    [selectedDispositionRef],
  );

  const editableDisposition = useMemo(
    () =>
      dispositions.find(
        (item) => item.code === editableDispositionRef?.code && item.subCode === editableDispositionRef?.subCode,
      ),
    [editableDispositionRef],
  );

  const fetchDispositions = async () => {
    setLoading(true);
    setError(false);

    let resp: QueueDisposition[] | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAsyncQueueDispositions(queue, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setDispositions(resp);
    setLoading(false);
  };

  const addDisposition = async (disposition: QueueDisposition) => {
    setSubmittingData(true);

    try {
      await createAsyncQueueDisposition(queue, disposition);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      setSubmittingData(false);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    setSubmittingData(false);
    closeCreateEditModal();
    pushNotification('success', `Created Disposition ${disposition.title}`);
    fetchDispositions();
  };

  const updateDisposition = async (updateDisposition: UpdateQueueDisposition) => {
    setSubmittingData(true);

    try {
      await updateAsyncQueueDisposition(queue, updateDisposition);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      setSubmittingData(false);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    setSubmittingData(false);
    closeCreateEditModal();
    pushNotification('success', `Created Disposition ${updateDisposition.disposition.title}`);
    fetchDispositions();
  };

  const onCreateEditAccept = async (data: QueueDisposition | UpdateQueueDisposition) => {
    if (isCreateQueueDisposition(data)) {
      await addDisposition(data);
    }

    if (isUpdateQueueDisposition(data)) {
      await updateDisposition(data);
    }
  };

  const removeDisposition = (code: string, subCode: string, name: string) => async () => {
    try {
      await removeAsyncQueueDisposition(queue, code, subCode);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    pushNotification('success', `Removed Disposition ${name}`);
    fetchDispositions();
  };

  const openViewModal = (code: string, subCode: string) => () => {
    setSelectedDispositionRef({ code, subCode });
  };

  const closeViewModal = () => {
    setSelectedDispositionRef(undefined);
  };

  const openCreateModal = () => {
    setCreateDispositionModalOpen(true);
  };

  const openEditModal = (code: string, subCode: string) => () => {
    setEditableDispositionRef({ code, subCode });
  };

  const closeCreateEditModal = () => {
    setCreateDispositionModalOpen(false);
    setEditableDispositionRef(undefined);
  };

  useEffect(() => {
    fetchDispositions();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, []);

  const displayList = useMemo(
    () =>
      dispositions.map((item, index) => {
        return (
          <DispositionCard
            key={`${item.code}-${item.subCode}`}
            code={item.code}
            subCode={item.subCode}
            title={item.title}
            outcome={item.outcome}
            canDelete={dispositions.length > 1}
            onEdit={openEditModal(item.code, item.subCode)}
            onClick={openViewModal(item.code, item.subCode)}
            onDelete={removeDisposition(item.code, item.subCode, item.title)}
          />
        );
      }),
    [dispositions],
  );

  return (
    <AsyncLoader isLoading={loading && dispositions.length === 0}>
      <Grid container spacing={1} alignContent='center'>
        {dispositions.length === 0 && <EmptyState type='no-items-2' text='No dispositions available' />}

        {dispositions.length > 0 && (
          <>
            <Hidden smDown>
              <Grid item md={9}></Grid>
            </Hidden>

            <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                fullWidth
                startIcon={<AddIcon />}
                onClick={openCreateModal}>
                Add Disposition
              </Button>
            </Grid>

            <Grid item xs={12}>
              <List>{displayList}</List>
              {loading && dispositions.length > 0 && <DotLoader align='center' />}
              {!loading && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  No more results to display
                </Typography>
              )}
              {error && dispositions.length > 0 && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  Failed to load queues
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <QueueDispositionDetailsModal
        open={Boolean(selectedDisposition)}
        disposition={selectedDisposition}
        onClose={closeViewModal}
      />

      <CreateEditQueueDispositionModal
        open={createDispositionModalOpen || Boolean(editableDisposition)}
        disposition={editableDisposition}
        submitting={submittingData}
        onClose={closeCreateEditModal}
        onAccept={onCreateEditAccept}
      />
    </AsyncLoader>
  );
};

export default QueueDispositions;
