import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SentimentPositiveIcon from '@mui/icons-material/InsertEmoticon';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentNegativeIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import Box from '@mui/system/Box';
import { findAll } from 'highlight-words-core';
import React, { useCallback, useState } from 'react';

import { assertUnreachable } from '~utils/Functions';

import { SentimentType, TranscriptRecord } from '../domain';

interface TranscriptCarouselProps {
  transcripts: TranscriptRecord[];
  phrases: string[];
}

type BubbleContainerProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement>;

const BubbleContainer = styled('div')<BubbleContainerProps>(() => ({
  'padding': 16,
  'borderRadius': 5,
  '&:last-of-type': {
    marginBottom: 0,
  },
  'color': grey[900],
  'backgroundColor': grey['A200'],
}));

const BubbleMessage = styled('div')({
  'position': 'relative',
  'fontSize': 14,
  'fontWeight': 400,
  'margin': 0,
  'wordBreak': 'break-word',
  'whiteSpace': 'pre-wrap',
  '& mark': {
    background: 'yellow',
  },
});

const TranscriptCarousel = ({ transcripts, phrases }: TranscriptCarouselProps) => {
  // Fast escape if array is empty
  if (transcripts.length === 0) return null;
  const [index, setIndex] = useState<number>(0);
  const transcript = transcripts[index];
  let SentimentIcon = SentimentNeutralIcon;
  let color: string = blueGrey[500];

  switch (transcript.sentiment) {
    case SentimentType.Positive: {
      SentimentIcon = SentimentPositiveIcon;
      color = green[500];
      break;
    }
    case SentimentType.Neutral: {
      SentimentIcon = SentimentNeutralIcon;
      color = blueGrey[500];
      break;
    }
    case SentimentType.Negative: {
      SentimentIcon = SentimentNegativeIcon;
      color = red[500];
      break;
    }
    case SentimentType.Mixed: {
      SentimentIcon = SentimentNeutralIcon;
      color = blueGrey[500];
      break;
    }
    default: {
      assertUnreachable(transcript.sentiment);
      break;
    }
  }

  const onPrevious = useCallback(() => {
    if (index === 0) return;
    setIndex((prev) => prev - 1);
  }, [index, transcripts]);

  const onNext = useCallback(() => {
    if (index === transcripts.length - 1) return;
    setIndex((prev) => prev + 1);
  }, [index, transcripts]);

  const highlightedText = findAll({
    searchWords: phrases,
    textToHighlight: transcript.content,
  })
    .map((chunk) => {
      const { end, highlight, start } = chunk;
      const text = transcript.content.substr(start, end - start);
      if (highlight) {
        return `<mark>${text}</mark>`;
      } else {
        return text;
      }
    })
    .join('');

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p
          style={{
            flex: '1 1 50%',
            fontSize: 14,
            fontWeight: 500,
            margin: '8px 0',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: grey[900],
          }}>
          {transcript.participantRole}
        </p>
      </Box>

      <BubbleContainer>
        <BubbleMessage>
          <SentimentIcon sx={{ position: 'absolute', left: 0, top: 0, color: color }} />
          <Box sx={{ marginLeft: 4 }} dangerouslySetInnerHTML={{ __html: highlightedText }} />
        </BubbleMessage>
      </BubbleContainer>

      <Box sx={{ marginTop: 0.5, display: 'flex', justifyContent: ' flex-end', alignItems: 'center' }}>
        <IconButton disabled={index === 0} onClick={onPrevious} size='small'>
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton disabled={index === transcripts.length - 1} onClick={onNext} size='small'>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TranscriptCarousel;
