import axios, { CancelTokenSource } from 'axios';

import { UserAccessFilter, UserAccessFilterResponseDecoder } from '~providers/UserPreferencesProvider/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

export const getUserAccessFilter = async (cancelToken?: CancelTokenSource): Promise<UserAccessFilter | null> => {
  const path = `/api/access-filter-config/get-users`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = UserAccessFilterResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const updateUserAccessFilter = async (accessFilterId: number | undefined): Promise<void> => {
  const path = `/api/access-filter-config/set-users`;
  const body = {
    access_filter_id: accessFilterId,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
