import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AttemptDetails, LeadAttemptResponseDecoder } from './domain';

export const getNextAttempt = async (
  diallerGroupId: number,
  cancelToken?: CancelTokenSource,
): Promise<AttemptDetails> => {
  const path = `/api/group/${diallerGroupId}/attempt/next`;
  let resp;

  try {
    resp = await axios.request({
      method: 'POST',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = LeadAttemptResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createLeadAttemptDetails = async (
  campaignId: number,
  endpoint: string,
  leadName: string,
  externalId: string,
  timezone: string,
): Promise<AttemptDetails> => {
  const path = `/api/campaign/${campaignId}/lead/create-lead-for-manual-outbound`;
  let resp;
  const data = {
    endpoint: endpoint,
    lead_name: leadName,
    external_id: externalId,
    timezone: timezone,
  };

  try {
    resp = await axios.request({
      method: 'POST',
      url: path,
      data: data,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = LeadAttemptResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getReturnCallAttemptDetails = async (
  campaignId: number,
  endpoint: string,
  contactId: string,
): Promise<AttemptDetails> => {
  const path = `/api/campaign/${campaignId}/endpoint/${endpoint}/return-call-attempt?contactId=${contactId}`;

  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        const decoded = LeadAttemptResponseDecoder.run(e.response!.data);

        if (decoded.ok === false) {
          const err = new UnsupportedStructureError(decoded.error.message);

          console.error(decoded.error);
          console.error(err);
          throw err;
        }

        return decoded.result;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = LeadAttemptResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getInternalTransferAttemptDetails = async (
  campaignId: number,
  endpoint: string,
): Promise<AttemptDetails> => {
  const path = `/api/campaign/${campaignId}/endpoint/${endpoint}/internal-transfer-attempt`;

  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        const decoded = LeadAttemptResponseDecoder.run(e.response!.data);

        if (decoded.ok === false) {
          const err = new UnsupportedStructureError(decoded.error.message);

          console.error(decoded.error);
          console.error(err);
          throw err;
        }

        return decoded.result;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = LeadAttemptResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getAssignedLeadAttempt = async (cancelToken?: CancelTokenSource) => {
  const path = '/api/group-config/my-assigned-leads';
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        const decoded = LeadAttemptResponseDecoder.run(e.response!.data);

        if (decoded.ok === false) {
          const err = new UnsupportedStructureError(decoded.error.message);

          console.error(decoded.error);
          console.error(err);
          throw err;
        }

        return decoded.result;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = LeadAttemptResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
