import * as j from '@mojotech/json-type-validation';

export interface UserAccessFilter {
  id: number;
  name: string;
  archived: string | undefined;
}

const UserAccessFilterDecoder: j.Decoder<UserAccessFilter> = j
  .object({
    access_filter_id: j.number(),
    name: j.string(),
    archived: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    id: item.access_filter_id,
    name: item.name,
    archived: item.archived || undefined,
  }));

export const UserAccessFilterResponseDecoder: j.Decoder<UserAccessFilter | null> = j
  .object({
    access_filter: j.union(UserAccessFilterDecoder, j.constant(null)),
  })
  .map((item) => item.access_filter);
