import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import React, { useState } from 'react';

interface StatusSelectorProps {
  items: string[];
  disabled?: boolean;
  onChange: (stateName: string) => void;
}

const SelectorButton = styled(Button)({
  'display': 'flex',
  'justifyContent': 'space-between',
  'alignItems': 'center',
  'fontSize': 14,
  'padding': '6px 16px',
  'fontWeight': 400,
  'lineHeight': 1.334,
  'textTransform': 'none',
  'whiteSpace': 'nowrap',
  'letterSpacing': '0',
  'backgroundColor': 'rgba(255, 255, 255, .10)',
  'color': '#ffffff',
  ':hover': {
    backgroundColor: 'rgba(255, 255, 255, .20)',
  },
  ':disabled': {
    color: 'rgba(255, 255, 255, .5)',
    backgroundColor: 'rgba(255, 255, 255, .10)',
  },
});

const StatusSelector = ({ items = [], disabled, onChange }: StatusSelectorProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const onChangeFn = (label: string) => {
    onChange(label);
    setOpen(false);
  };

  const records = items.map((record, index) => {
    return (
      <ListItem key={index} button onClick={() => onChangeFn(record)}>
        <ListItemText primary={record} />
      </ListItem>
    );
  });

  return (
    <Box sx={{ width: 200, position: 'relative', margin: '8px 0' }}>
      <>
        <SelectorButton fullWidth variant='text' disabled={disabled} disableElevation onClick={handleClick}>
          Change Status
          <KeyboardArrowDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
        </SelectorButton>

        {open && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper sx={{ width: '100%', height: 300, position: 'absolute', overflow: 'auto', zIndex: 'drawer' }}>
              <List
                component='nav'
                sx={{ width: '100%', maxWidth: 360, backgroundColor: '#ffffff' }}
                aria-label='contacts'>
                {records}
              </List>
            </Paper>
          </ClickAwayListener>
        )}
      </>
    </Box>
  );
};

export default StatusSelector;
