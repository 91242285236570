import * as j from '@mojotech/json-type-validation';

export interface ContactContext {
  agentUsername: string;
  dispositionCode: string;
  dispositionSubCode: string;
  dispositionTitle: string;
  initiationMethod: string;
  connectedToSystem: string;
  callbackNotes: string | undefined;
  attributes: { [key: string]: string };
}

const GetContactContextItemResponseDecoder: j.Decoder<ContactContext> = j
  .object({
    agent_username: j.union(j.string(), j.constant(null)),
    disposition_code: j.union(j.string(), j.constant(null)),
    disposition_sub_code: j.union(j.string(), j.constant(null)),
    disposition_title: j.union(j.string(), j.constant(null)),
    initiation_method: j.union(j.string(), j.constant(null)),
    connected_to_system: j.union(j.string(), j.constant(null)),
    callback_notes: j.union(j.string(), j.constant(null)),
    attributes: j.dict(j.string()),
  })
  .map((item: any) => ({
    agentUsername: item.agent_username,
    dispositionCode: item.disposition_code,
    dispositionSubCode: item.disposition_sub_code,
    dispositionTitle: item.disposition_title,
    initiationMethod: item.initiation_method,
    connectedToSystem: item.connected_to_system,
    callbackNotes: item.callback_notes || undefined,
    attributes: item.attributes,
  }));

export const GetContactContextResponseDecoder: j.Decoder<ContactContext[]> = j
  .object({
    contact_context: j.array(GetContactContextItemResponseDecoder),
  })
  .map((item: any): ContactContext[] => item.contact_context);
