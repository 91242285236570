import { Organisation } from '~organisations/domain';

import genericAdditionalFields from './additionalOutcomeCaptureFields/generic';
import logo from './images/logo.svg';

const doordashConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '2': genericAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {},
};

export default doordashConfig;
