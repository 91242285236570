import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Controller } from 'react-hook-form';

import { AdditionalFieldsProps } from '~pages/Dialler/ConnectDialler/AsyncView/MessageContainer/Conversation';

const AdditionalFields = ({ control, formSubmitting, errors }: AdditionalFieldsProps) => {
  return (
    <Grid item xs={12}>
      <Controller
        name='oms_id'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant='outlined'
            label='OMS ID'
            disabled={formSubmitting}
            error={Boolean(errors.oms_id)}
            helperText={errors.oms_id?.message}
          />
        )}
      />
    </Grid>
  );
};

export default AdditionalFields;
