import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import OberonCard from '~components/OberonCard';
import OberonDialog from '~components/OberonDialog';
import useDebounce from '~hooks/useDebounce';
import useMessageTemplate from '~pages/AsyncManagement/QueueDetails/MessageTemplates/useMessageTemplate';

interface Query {
  search: string;
  filter: string | '';
}

interface MessageTemplateModalProps {
  open: boolean;
  queue: string;
  onSelect: (message: string) => void;
  onClose: () => void;
}

const queryDefault: Query = Object.freeze({
  search: '',
  filter: '',
});

const MessageTemplateModal = ({ open, queue, onSelect, onClose }: MessageTemplateModalProps) => {
  const [boxRef, { height: boxHeight }] = useMeasure();
  const [query, setQuery] = useState<Query>(queryDefault);
  const debouncedSearch = useDebounce(query.search, 500);
  const { messageTemplates, categories, loading, error, hasMore, getNextPage } = useMessageTemplate(
    queue,
    debouncedSearch,
    query.filter,
  );
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const noSearchOrFilterSet = query.search === '' && query.filter === '';
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const resetQuery = useCallback(() => {
    setQuery(queryDefault);
  }, []);

  const onQueryChange = useCallback((e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  }, []);

  // Resets query search on unmount
  useEffect(() => {
    return () => {
      resetQuery();
    };
  }, []);

  let filterListDisplay = [
    <MenuItem key='none-index' value=''>
      None
    </MenuItem>,
  ];
  filterListDisplay = [
    ...filterListDisplay,
    ...categories.map((val, index) => (
      <MenuItem key={index} value={val}>
        {val}
      </MenuItem>
    )),
  ];

  const templateList = messageTemplates.map((item, index) => (
    <OberonCard
      ref={index === messageTemplates.length - 1 ? lastDataElement : undefined}
      key={index}
      reverseTitles
      onClick={() => onSelect(item.template)}
      titleFontWeight={700}
      title={item.title}
      subHeader={item.category}
      footer={
        <Box sx={{ backgroundColor: 'rgb(0 0 0 / 5%)', padding: 2, borderRadius: 2, whiteSpace: 'pre-wrap' }}>
          <code>{item.template}</code>
        </Box>
      }
    />
  ));

  return (
    <OberonDialog
      open={open}
      title='Message templates'
      onClose={onClose}
      closeOnBackdropClick
      disableContentPadding
      content={
        <Box sx={{ height: 500 }}>
          <Box ref={boxRef}>
            <Grid sx={{ padding: 1 }} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Search'
                  id='search'
                  name='search'
                  onChange={onQueryChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  id='filter'
                  name='filter'
                  label='Template Category'
                  value={query.filter}
                  disabled={categories.length === 0}
                  onChange={onQueryChange}>
                  {filterListDisplay}
                </TextField>
              </Grid>
            </Grid>

            <Divider component='hr' />
          </Box>

          <Box sx={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto', padding: '8px 16px' }}>
            <AsyncLoader isLoading={loading && messageTemplates.length === 0}>
              {messageTemplates.length > 0 && (
                <>
                  <List>{templateList}</List>
                  {loading && messageTemplates.length > 0 && <DotLoader align='center' />}

                  {!loading && !hasMore && (
                    <Typography variant='body2' align='center' color='textSecondary'>
                      No more results to display
                    </Typography>
                  )}

                  {error && messageTemplates.length > 0 && (
                    <Typography variant='body2' align='center' color='textSecondary'>
                      Failed to load message templates
                    </Typography>
                  )}
                </>
              )}

              {messageTemplates.length == 0 && !noSearchOrFilterSet && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  No message templates found matching your search criteria
                </Typography>
              )}

              {messageTemplates.length === 0 && noSearchOrFilterSet && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  No message templates available for queue {queue}
                </Typography>
              )}
            </AsyncLoader>
          </Box>
        </Box>
      }
      actionFooter={
        <Button variant='text' onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default MessageTemplateModal;
