import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import Transfers from '~components/Transfers';
import { ConnectVoiceContact, ContactStateType } from '~providers/ConnectProvider/domain';

import ActionButton from '../ActionButton';
import ConnectVoiceConnectionCard from './ConnectVoiceConnection';

interface ContactCallActionsPanelProps {
  onPrepareLead?: () => void;
  onDial?: () => Promise<void>;
  onAnswer: () => Promise<void>;
  onMute: () => Promise<void>;
  onHold: () => Promise<void>;
  onResume: () => Promise<void>;
  onHangUp: (preContactConnectionHangUp: boolean, connectionHangUpFunc?: () => Promise<void>) => Promise<void>;
  onSuspendCallRecording?: () => Promise<void>;
  onResumeCallRecording?: () => Promise<void>;
  onVoicemailMessage?: () => Promise<void>;
  diallerGroupName: string;
  contact?: ConnectVoiceContact;
  isContactOnHold: boolean;
  isCallRecordingPaused: boolean;
  isPrepareLeadDisabled: boolean;
  // Used to indicate weather we fully disable the answer button for users that have auto answer enabled
  // against them in amazon connect
  isSoftphoneAutoAnswerEnabled: boolean;
  isDialDisabled?: boolean;
  isAnswerDisabled?: boolean;
  isVoicemailMessageDisabled?: boolean;
  isMuteDisabled?: boolean;
  isHoldDisabled?: boolean;
  isResumeDisabled?: boolean;
  isHangUpDisabled?: boolean;
  isResumeCallRecordingDisabled?: boolean;
  isSuspendCallRecordingDisabled?: boolean;
  // Used to determine if we can preform prepare manual outbound actions when an attempt exists or not
  hasAttempt: boolean;
}

const CustomizedCardHeader = styled(CardHeader)(({ theme }) => ({
  'borderBottom': '1px solid rgba(0, 0, 0, 0.12)',
  '& .MuiCardHeader-title': {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
  },
}));

const ContactCallActionsPanel = ({
  onPrepareLead,
  onDial,
  onAnswer,
  onMute,
  onHold,
  onResume,
  onHangUp,
  onSuspendCallRecording,
  onResumeCallRecording,
  onVoicemailMessage,
  diallerGroupName,
  contact,
  isContactOnHold,
  isCallRecordingPaused,
  isPrepareLeadDisabled,
  isDialDisabled,
  isAnswerDisabled,
  isVoicemailMessageDisabled,
  isMuteDisabled,
  isHoldDisabled,
  isResumeDisabled,
  isHangUpDisabled,
  isResumeCallRecordingDisabled,
  isSuspendCallRecordingDisabled,
  isSoftphoneAutoAnswerEnabled,
  hasAttempt,
}: ContactCallActionsPanelProps) => {
  // we care about active connections only, inactive connection can be removed from array when new connection is added
  const activeConnections = contact?.connections ? contact.connections : [];
  const initialConnectionTimestamp = contact?.statusTimestamp;
  const preContactConnectionHangUp = contact?.statusType !== ContactStateType.Connected;

  // active connections for UI, agent's own connection is excluded
  const contactConnections =
    contact !== undefined
      ? activeConnections.map((connection) => (
          <ConnectVoiceConnectionCard
            key={connection.connectionId}
            connection={connection}
            initialConnectionTimestamp={initialConnectionTimestamp}
            isConference={contact?.connections.length > 1}
            isHangUpDisabled={isHangUpDisabled}
            onHangUp={() => onHangUp(preContactConnectionHangUp, connection.endConnection)}
          />
        ))
      : [];

  return (
    <>
      <Card elevation={3}>
        <CustomizedCardHeader avatar={<BallotOutlinedIcon style={{ display: 'block' }} />} title={diallerGroupName} />

        <CardContent
          sx={{
            ':last-child': {
              paddingBottom: 2,
            },
          }}>
          {onPrepareLead && (
            <ActionButton
              sx={{ marginTop: 0 }}
              fullWidth
              colorRange={blue}
              variant='contained'
              disableElevation
              disabled={isPrepareLeadDisabled || hasAttempt}
              onClick={onPrepareLead}>
              Prepare Outbound Call
            </ActionButton>
          )}

          {onVoicemailMessage && (
            <ActionButton
              fullWidth
              colorRange={blue}
              variant='contained'
              disableElevation
              disabled={isVoicemailMessageDisabled}
              onClick={onVoicemailMessage}>
              Leave Voicemail Message
            </ActionButton>
          )}

          {onDial && (
            <ActionButton
              fullWidth
              colorRange={green}
              variant='contained'
              disableElevation
              disabled={isDialDisabled}
              onClick={onDial}>
              Dial
            </ActionButton>
          )}

          <ActionButton
            sx={isSoftphoneAutoAnswerEnabled ? { marginBottom: 0 } : undefined}
            fullWidth
            colorRange={green}
            variant='contained'
            disableElevation
            disabled={isAnswerDisabled || isSoftphoneAutoAnswerEnabled}
            onClick={onAnswer}>
            Answer
          </ActionButton>

          {isSoftphoneAutoAnswerEnabled && (
            <Typography marginBottom={1} variant='caption' textAlign='center' paragraph>
              (Auto answer is enabled for you)
            </Typography>
          )}

          <ActionButton
            fullWidth
            colorRange={grey}
            variant='contained'
            disableElevation
            disabled={isMuteDisabled}
            onClick={onMute}>
            Mute
          </ActionButton>

          {!isContactOnHold && (
            <ActionButton
              fullWidth
              colorRange={blueGrey}
              variant='contained'
              disableElevation
              disabled={isHoldDisabled}
              onClick={onHold}>
              Hold
            </ActionButton>
          )}

          {isContactOnHold && (
            <ActionButton
              fullWidth
              colorRange={blue}
              variant='contained'
              disableElevation
              disabled={isResumeDisabled}
              onClick={onResume}>
              Resume
            </ActionButton>
          )}

          {onSuspendCallRecording && !isCallRecordingPaused && (
            <ActionButton
              fullWidth
              colorRange={blueGrey}
              variant='contained'
              disableElevation
              disabled={isSuspendCallRecordingDisabled}
              onClick={onSuspendCallRecording}>
              Pause Call Recording
            </ActionButton>
          )}

          {onResumeCallRecording && isCallRecordingPaused && (
            <>
              <ActionButton
                fullWidth
                colorRange={blue}
                variant='contained'
                disableElevation
                disabled={isResumeCallRecordingDisabled}
                onClick={onResumeCallRecording}>
                Resume Call Recording
              </ActionButton>

              {isCallRecordingPaused && (
                <Typography marginBottom={1} variant='caption' textAlign='center' paragraph>
                  (Call Recording is paused)
                </Typography>
              )}
            </>
          )}

          <Transfers contact={contact} />

          <ActionButton
            fullWidth
            colorRange={red}
            variant='contained'
            disableElevation
            disabled={isHangUpDisabled}
            onClick={() => onHangUp(preContactConnectionHangUp)}>
            Hang Up
          </ActionButton>

          {contactConnections.length > 0 && (
            <>
              <Box sx={{ margin: '15px 0 5px 0', fontSize: '16', fontWeight: 'bold' }}>Connections</Box>
              {contactConnections}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ContactCallActionsPanel;
