import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { MUIStyledCommonProps, Theme } from '@mui/system';

type OberonSwitchProps = MUIStyledCommonProps<Theme> & SwitchProps & { baseColor?: string; checkedColor?: string };

const OberonSwitch = styled(Switch, {
  shouldForwardProp: (prop: string) => !['baseColor', 'checkedColor'].includes(prop),
})<OberonSwitchProps>((i) => {
  const { baseColor = grey[500], checkedColor = green[500] } = i;
  return {
    '& .MuiSwitch-switchBase': {
      'color': baseColor,
      '& + .MuiSwitch-track': {
        backgroundColor: baseColor,
      },
      '&.Mui-checked': {
        'color': checkedColor,
        '& + .MuiSwitch-track': {
          backgroundColor: checkedColor,
        },
      },
    },
  };
});

export default OberonSwitch;
