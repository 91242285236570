import axios, { CancelTokenSource } from 'axios';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { createCallback, getCallbacks, getCallbacksByNextUrl, removeCallback, updateCallback } from './api';
import {
  Callback,
  CallbackFilterValues,
  CallbackResponse,
  CreateCallback,
  FilterOptions,
  SortColumnValues,
  SortOrder,
  UpdateCallback,
} from './domain';

type Options = FilterOptions & {
  accessFilterId?: number;
  shouldFetch?: boolean;
};

export const useCallbacks = (
  options: Options = {
    search: undefined,
    filter: undefined,
    accessFilterId: undefined,
    listId: undefined,
    campaignId: undefined,
    agentUsername: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    sortColumn: undefined,
    sortOrder: undefined,
    assignedToGroup: undefined,
    shouldFetch: true,
  },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<Callback[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const search = options.search;
  const filter = options.filter;
  const accessFilterId = options.accessFilterId;
  const listId = options.listId;
  const campaignId = options.campaignId;
  const agentUsername = options.agentUsername;
  const dateFrom = options.dateFrom;
  const dateTo = options.dateTo;
  const sortColumn = options.sortColumn;
  const sortOrder = options.sortOrder;
  const assignedToGroup = options.assignedToGroup;
  const shouldFetch = options.shouldFetch ?? true;
  const filterDependencies = [
    search,
    filter,
    accessFilterId,
    listId,
    campaignId,
    agentUsername,
    dateFrom,
    dateTo,
    sortColumn,
    sortOrder,
    assignedToGroup,
  ];

  const downloadLink = useMemo(() => {
    // We use searchParams to generate this link as we want the download to be an
    // exact representation of what is currently shown on the screen
    const searchQuery = queryString.stringify({
      search: search || undefined,
      filter: filter || undefined,
      access_filter_id: accessFilterId || undefined,
      list_id: listId || undefined,
      campaign_id: campaignId || undefined,
      agent_username: agentUsername || undefined,
      date_from: dateFrom || undefined,
      date_to: dateTo || undefined,
      sort_column: sortColumn || undefined,
      sort_order: sortOrder || undefined,
      assigned_to_group: assignedToGroup ?? undefined,
    });

    return `/api/callbacks/download?${searchQuery}`;
  }, filterDependencies);

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: CallbackResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getCallbacksByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.callbacks]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: CallbackResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getCallbacks(
        search,
        filter,
        accessFilterId,
        listId,
        campaignId,
        agentUsername,
        dateFrom,
        dateTo,
        sortColumn,
        sortOrder,
        assignedToGroup,
        axiosCancelRef.current,
      );
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.callbacks);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, filterDependencies);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const create = useCallback(
    async (data: CreateCallback) => {
      try {
        await createCallback(data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  const update = useCallback(
    async (callbackId: number, data: UpdateCallback) => {
      try {
        await updateCallback(callbackId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  const remove = useCallback(
    async (callbackId: number) => {
      try {
        await removeCallback(callbackId);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  useEffect(() => {
    setList([]);
  }, [...filterDependencies, shouldFetch]);

  useEffect(() => {
    const load = async (
      search?: string,
      filter?: CallbackFilterValues,
      accessFilterId?: number,
      listId?: number[],
      campaignId?: number[],
      agentUsername?: string[],
      dateFrom?: string,
      dateTo?: string,
      sortColumn?: SortColumnValues,
      sortOrder?: SortOrder,
      assignedToGroup?: boolean,
    ) => {
      setLoading(true);
      setError(false);

      let resp: CallbackResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getCallbacks(
          search,
          filter,
          accessFilterId,
          listId,
          campaignId,
          agentUsername,
          dateFrom,
          dateTo,
          sortColumn,
          sortOrder,
          assignedToGroup,
          axiosCancelRef.current,
        );
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.callbacks]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(
        search,
        filter,
        accessFilterId,
        listId,
        campaignId,
        agentUsername,
        dateFrom,
        dateTo,
        sortColumn,
        sortOrder,
        assignedToGroup,
      );
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [...filterDependencies, shouldFetch]);

  return { loading, error, list, hasMore, intersectionObserverRef, create, remove, update, downloadLink };
};

export default useCallbacks;
