import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import blueGrey from '@mui/material/colors/blueGrey';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import React, { MouseEvent, useState } from 'react';

interface LoadingButtonDropdownProps {
  title: string;
  items: string[];
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  onChange: (stateName: string) => void;
}

const LoadingButtonCustomised = styled(LoadingButton)(({ theme }) => ({
  'backgroundColor': blueGrey[800],
  'color': '#ffffff',
  ':hover': {
    backgroundColor: blueGrey[700],
  },
  ':disabled': {
    color: theme.palette.getContrastText(`${blueGrey[800]}80`),
    backgroundColor: `${blueGrey[800]}80`,
  },
}));

const LoadingButtonDropdown = ({
  title,
  items = [],
  disabled,
  loading,
  fullWidth,
  onChange,
}: LoadingButtonDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (value: string) => {
    onChange(value);
    setAnchorEl(null);
  };

  const records = items.map((value, index) => {
    return (
      <MenuItem key={index} onClick={() => onItemClick(value)}>
        {value}
      </MenuItem>
    );
  });

  return (
    <>
      <LoadingButtonCustomised
        fullWidth={fullWidth}
        variant='text'
        disabled={disabled}
        loading={loading}
        disableElevation
        onClick={onOpen}>
        {title}
        <KeyboardArrowDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </LoadingButtonCustomised>

      <Menu
        anchorEl={anchorEl}
        MenuListProps={{ sx: { width: anchorEl && anchorEl.offsetWidth } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={onClose}>
        {records}
      </Menu>
    </>
  );
};

export default LoadingButtonDropdown;
