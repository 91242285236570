import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const PhoneWarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <defs>
        <path
          d='M6.62,10.79 C8.06,13.62 10.38,15.93 13.21,17.38 L15.41,15.18 C15.68,14.91 16.08,14.82 16.43,14.94 C17.55,15.31 18.76,15.51 20,15.51 C20.55,15.51 21,15.96 21,16.51 L21,20 C21,20.55 20.55,21 20,21 C10.61,21 3,13.39 3,4 C3,3.45 3.45,3 4,3 L7.5,3 C8.05,3 8.5,3.45 8.5,4 C8.5,5.25 8.7,6.45 9.07,7.57 C9.18,7.92 9.1,8.31 8.82,8.59 L6.62,10.79 Z'
          id='path-1'></path>
      </defs>
      <g id='icon/communication/phone_24px' stroke='none' strokeWidth='1' fillRule='evenodd'>
        <path
          d='M14,13.3 C13.28,13.3 12.7,12.72 12.7,12 C12.7,11.28 13.28,10.7 14,10.7 C14.72,10.7 15.3,11.28 15.3,12 C15.3,12.72 14.72,13.3 14,13.3 Z'
          id='Path'
          fillRule='nonzero'></path>
        <polygon id='Path' fillRule='nonzero' points='15 9 13 9 13 3 15 3'></polygon>
        <mask id='mask-2'>
          <use xlinkHref='#path-1'></use>
        </mask>
        <g fillRule='nonzero'></g>
        <g id='Group' mask='url(#mask-2)'>
          <g id='↳-Color'>
            <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

// Have to set this static property: https://mui.com/material-ui/guides/composition/
PhoneWarningIcon.muiName = 'PhoneWarningIcon';

export default PhoneWarningIcon;
