import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useCallback, useState } from 'react';

import OberonCard from '~components/OberonCard';
import { AsyncQueue } from '~pages/AsyncManagement/domain';

interface QueueCardProps {
  queue: AsyncQueue;
  to?: string;
  onEdit: () => void;
}

const QueueCard = forwardRef(({ queue, to, onEdit }: QueueCardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleEdit = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      onEdit();
    },
    [onEdit],
  );

  return (
    <>
      <OberonCard
        ref={ref}
        to={to}
        titleFontWeight={400}
        title={queue.title}
        subHeader={
          <>
            <Chip
              sx={{
                marginTop: 0.5,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                marginRight: 1,
              }}
              color='primary'
              label={queue.channelType}
            />
            <Chip
              sx={{
                marginTop: 0.5,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
                backgroundColor: !queue.isPublic ? grey[600] : undefined,
              }}
              color={queue.isPublic ? 'success' : undefined}
              label={queue.isPublic ? 'Visible to customer' : 'Hidden from customer'}
            />
          </>
        }
        action={
          <>
            <IconButton
              aria-controls='message-template-menu'
              aria-haspopup='true'
              onKeyPress={handleMenuOpen}
              onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
            <Menu id='queue-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
            </Menu>
          </>
        }
      />
    </>
  );
});

export default QueueCard;
