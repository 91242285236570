import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions/transition';
import React, { ReactElement, ReactNode, Ref, forwardRef } from 'react';

interface ActionDialogProps {
  open: boolean;
  title: ReactNode;
  content: ReactNode;
  primaryActionTitle?: string;
  loading?: boolean;
  disableContentPadding?: boolean;
  onClose: () => void;
  onAccept?: () => void;
}

const SlideTransition = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => {
    return <Slide direction='up' ref={ref} {...props} />;
  },
);

const ActionDialog = ({
  open,
  title,
  content,
  primaryActionTitle,
  loading,
  disableContentPadding,
  onClose,
  onAccept,
}: ActionDialogProps) => {
  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={SlideTransition}
      onClose={onClose}
      aria-labelledby='action-dialog-slide-title'
      aria-describedby='action-dialog-slide-description'>
      <DialogTitle id='action-dialog-slide-title'>{title}</DialogTitle>
      <DialogContent sx={{ padding: disableContentPadding ? 0 : '16px 24px' }} dividers={true}>
        {typeof content === 'string' && (
          <DialogContentText id='action-dialog-slide-description'>{content}</DialogContentText>
        )}
        {typeof content !== 'string' && content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          close
        </Button>

        {onAccept && primaryActionTitle && (
          <LoadingButton
            variant='contained'
            disableElevation
            onClick={onAccept}
            loading={loading}
            disabled={loading}
            color='primary'>
            {primaryActionTitle}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
