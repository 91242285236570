import CloseIcon from '@mui/icons-material/Close';
import grey from '@mui/material/colors/grey';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { FormEvent, ReactElement, ReactNode, Ref, forwardRef } from 'react';

type MaxWidthType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

interface OberonDialogProps {
  open: boolean;
  title: string;
  subHeader?: ReactNode;
  content: ReactNode;
  actionFooter?: ReactNode;
  /** Controls whether the modal can be closed on clicking
   * the backdrop OR hitting escape when `onClose` is set  */
  closeOnBackdropClick?: boolean;
  maxWidth?: MaxWidthType;
  disableContentPadding?: boolean;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  onClose?: () => void;
}

interface MaybeFormWrapperProps {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
}

const MaybeFormWrapper = ({ onSubmit, children }: MaybeFormWrapperProps) => {
  if (onSubmit === undefined) return <>{children}</>;

  return (
    <form onSubmit={onSubmit} noValidate style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
      {children}
    </form>
  );
};

const SlideTransition = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => {
    return <Slide direction='up' ref={ref} {...props} />;
  },
);

const OberonDialog = ({
  open,
  title,
  subHeader,
  content,
  actionFooter,
  closeOnBackdropClick,
  maxWidth,
  disableContentPadding,
  onSubmit,
  onClose,
}: OberonDialogProps) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={maxWidth || 'sm'}
      scroll='paper'
      onClose={closeOnBackdropClick ? onClose : undefined}
      TransitionComponent={SlideTransition}>
      <MaybeFormWrapper onSubmit={onSubmit}>
        <DialogTitle>
          {title}

          {subHeader && <div>{subHeader}</div>}

          {onClose && (
            <IconButton
              aria-label='close'
              sx={{ position: 'absolute', right: '8px', top: '8px', color: grey[500] }}
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>

        <DialogContent sx={{ padding: disableContentPadding ? 0 : '16px 24px' }} dividers={true}>
          {content}
        </DialogContent>

        {actionFooter && <DialogActions>{actionFooter}</DialogActions>}
      </MaybeFormWrapper>
    </Dialog>
  );
};

export default OberonDialog;
