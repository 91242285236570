import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  addAgentsToGroup,
  getGroupAssignedAgents,
  getGroupAssignedAgentsByNextUrl,
  removeAgentFromGroupByUsername,
} from './api';
import { AgentsResponse, AssignedAgents, UpdateAgentGroupAssignment } from './domain';

const useGroupAssignedAgentSearch = (diallerGroupId: number, search: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<AssignedAgents[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: AgentsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getGroupAssignedAgentsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.agents]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: AgentsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getGroupAssignedAgents(diallerGroupId, search, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.agents);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [diallerGroupId, search]);

  const add = useCallback(
    async (data: UpdateAgentGroupAssignment) => {
      try {
        await addAgentsToGroup(diallerGroupId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  const remove = useCallback(
    async (username: string) => {
      try {
        await removeAgentFromGroupByUsername(diallerGroupId, username);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  useEffect(() => {
    setList([]);
  }, [diallerGroupId, search]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);

      let resp: AgentsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getGroupAssignedAgents(diallerGroupId, search, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.agents]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [diallerGroupId, search]);

  return { loading, error, list, hasMore, getNextPage, add, remove };
};

export default useGroupAssignedAgentSearch;
