import * as j from '@mojotech/json-type-validation';

export interface DiallingHourProfile {
  label: string;
  value: string;
}

const DiallingHourProfileDecoder: j.Decoder<DiallingHourProfile> = j.object({
  label: j.string(),
  value: j.string(),
});

export const DiallingHourProfileResponseDecoder: j.Decoder<DiallingHourProfile[]> = j
  .object({
    dialling_hour_profiles: j.array(DiallingHourProfileDecoder),
  })
  .map((resp) => resp.dialling_hour_profiles || []);
