import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import useDiallingHourProfiles from '~hooks/useDiallingHourProfiles';
import {
  CampaignFilter,
  CampaignFilterType,
  CampaignFilterTypeValues,
  CreateCampaignFilter,
  UpdateCampaignFilter,
} from '~pages/CampaignManagement/CampaignDetails/useCampaignFilters/domain';

interface CreateEditFilterModalProps {
  open: boolean;
  submitting: boolean;
  filter?: CampaignFilter;
  filterType: CampaignFilterTypeValues;
  onAccept: (data: CreateCampaignFilter | UpdateCampaignFilter) => void;
  onClose: () => void;
}

interface Form {
  name: string;
  field: string;
  match: string;
  diallingHourProfile: string | null;
}

const CreateEditFilterModal = ({
  open,
  submitting,
  filter,
  filterType,
  onAccept,
  onClose,
}: CreateEditFilterModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(filter);
  const { list: diallingHourProfileOptions } = useDiallingHourProfiles({
    shouldFetch: open && filterType === CampaignFilterType.EndpointDiallingHours,
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      field: '',
      match: '',
      diallingHourProfile: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (open && filter) {
      setValue('name', filter.name);
      setValue('field', filter.field);
      setValue('match', filter.match);

      if (filterType === CampaignFilterType.EndpointDiallingHours) {
        setValue('diallingHourProfile', filter.diallingHourProfile);
      }
    }

    return () => {
      reset();
    };
  }, [filter]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: CreateCampaignFilter | UpdateCampaignFilter = {
      name: data.name,
      field: data.field,
      match: data.match,
      type: filterType,
      diallingHourProfile: null,
    };

    if (filterType === CampaignFilterType.EndpointDiallingHours) {
      submitData = {
        ...submitData,
        // Only supported field for this filter type, API will error if anything but this is set
        field: 'type',
        diallingHourProfile: data.diallingHourProfile,
      };
    }

    try {
      await onAccept(submitData);

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  const diallingHourProfileDisplayList = useMemo(() => {
    return diallingHourProfileOptions.map((item) => <MenuItem value={item.value}>{item.label}</MenuItem>);
  }, [JSON.stringify(diallingHourProfileOptions)]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} ${
        filterType === CampaignFilterType.EndpointDiallingHours ? 'Endpoint Type Setting' : 'Filter'
      }`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{ required: 'Filter name is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  label='Filter Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>

          {filterType === CampaignFilterType.LeadAttribute && (
            <Grid item xs={12}>
              <Controller
                name='field'
                control={control}
                rules={{ required: 'Filter field is required.' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant='outlined'
                    label='Filter Field'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.field)}
                    helperText={errors.field?.message}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name='match'
              control={control}
              rules={{ required: 'Filter match is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  label='Filter Match'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.match)}
                  helperText={errors.match?.message}
                />
              )}
            />
          </Grid>

          {filterType === CampaignFilterType.EndpointDiallingHours && (
            <Grid item xs={12}>
              <Controller
                name='diallingHourProfile'
                control={control}
                rules={{ required: 'Dialling hour profile field is required.' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    variant='outlined'
                    label='Dialling Hour profile'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.diallingHourProfile)}
                    helperText={errors.diallingHourProfile?.message}>
                    {diallingHourProfileDisplayList}
                  </TextField>
                )}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={submitting} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={submitting}
            loading={submitting}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditFilterModal;
