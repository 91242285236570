import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import EmptyState from '~components/EmptyState';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import { useRoutes } from '~providers/RouteProvider';

const NotFound = () => {
  const navigate = useNavigate();
  const { defaultLoginPage } = useRoutes();
  const setPageTitleProps = useSetPageTitleProps();
  // Since starting route depends on user permissions we use their default login page as the return to page for a
  // page not found error
  const returnRoute = defaultLoginPage;

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Not Found' });
  }, []);

  const onAction = () => {
    if (returnRoute) {
      navigate(returnRoute.path);
    } else {
      console.error('onAction: defaultLoginRoute not defined.');
    }
  };

  return (
    <EmptyState
      type='not-found'
      text='Sorry!'
      subText={`We are unable to find the page you are looking for. Please click the button below to go back to your ${returnRoute?.title}.`}
      action={onAction}
      actionText={`To ${returnRoute?.title}`}
    />
  );
};

export default NotFound;
