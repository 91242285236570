type WebSocketMessage = string | ArrayBuffer | SharedArrayBuffer | Blob | ArrayBufferView;

abstract class SocketManagerBase {
  private readonly socketName: string;
  protected readonly address: string;
  protected ws: WebSocket | undefined = undefined;
  private messageQueue: WebSocketMessage[] = [];

  protected constructor(socketName: string, address: string) {
    this.socketName = socketName;
    this.address = address;
  }

  public connect = (): void => {
    if (this.ws !== undefined) {
      console.warn(`${this.socketName} already connecting or connected`);
      return;
    }

    console.log(`Connecting ${this.socketName} Websocket.`);

    this.ws = new WebSocket(this.address);

    this.ws.onopen = this.onOpen;
    this.ws.onmessage = this.onMessage;
    this.ws.onerror = this.onError;
    this.ws.onclose = this.onClose;
  };

  public disconnect = (): void => {
    console.log(`Disconnecting ${this.socketName} Websocket.`);

    if (this.ws === undefined) {
      console.error(
        `disconnect: Unable to disconnect websocket as it does not exist. Has the ${this.socketName} already been disconnected?.`,
      );
      return;
    }

    this.ws.close(1000);
    this.ws = undefined;
  };

  protected abstract onOpen: ((event: Event) => any) | null;
  protected abstract onMessage: ((event: MessageEvent) => any) | null;

  protected send = (data: WebSocketMessage) => {
    if (this.ws !== undefined && this.ws.readyState === 1) {
      // Let's check and see if we have queued messages to send before attempting to send the latest request
      try {
        this.sendQueuedMessages(true);
      } catch (e) {
        // Back out as we have had a failure
        return;
      }

      try {
        this.ws.send(data);
      } catch (e) {
        console.error(e);
        this.messageQueue = [...this.messageQueue, data];
      }
    } else {
      this.messageQueue = [...this.messageQueue, data];
    }
  };

  /* This will send all currently queued socket messages that failed to sent from a previous action */
  public sendQueuedMessages = (throwException: boolean = false) => {
    if (this.ws !== undefined && this.ws.readyState === 1 && this.messageQueue.length > 0) {
      let sentMessageIndex: number[] = [];
      try {
        for (let i = 0; i < this.messageQueue.length; i++) {
          this.ws.send(this.messageQueue[i]);
          sentMessageIndex = [...sentMessageIndex, i];
        }
      } catch (e) {
        // Clean up any messages that were possibly sent before  an error occurred
        this.messageQueue = this.messageQueue.filter((_, index) => {
          return !sentMessageIndex.includes(index);
        });

        console.error(e);

        // Optional error throw dependant on requirements
        if (throwException) {
          throw e;
        }
      }

      // Cleanup all sent messages
      this.messageQueue = [];
    }
  };

  /** Event handler that is triggered if an error occurs on the websocket */
  private onError = (event: Event): void => {
    console.error(`${this.socketName} websocket Error: `, event);

    this.ws = undefined;
  };

  protected abstract onClose: ((event: CloseEvent) => any) | null;
}

export default SocketManagerBase;
