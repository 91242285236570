import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';

import OberonDialog from '~components/OberonDialog';
import { TabPanel } from '~components/TabPanel';
import { TransferTarget } from '~services/AsyncManager/domain';

import AgentTransferPanel from './AgentTransferPanel';
import QueueTransferPanel from './QueueTransferPanel';

enum TabType {
  Queue,
  Agent,
}

interface TransferModalProps {
  open: boolean;
  onTransferTo: (transferTarget: TransferTarget, value: string) => void;
  onClose: () => void;
}

const TransferModal = ({ open, onTransferTo, onClose }: TransferModalProps) => {
  const [transferTab, setTransferTab] = useState<number>(TabType.Queue);

  return (
    <OberonDialog
      open={open}
      title='Transfers'
      onClose={onClose}
      closeOnBackdropClick
      disableContentPadding
      content={
        <>
          <Tabs
            variant='scrollable'
            indicatorColor='primary'
            orientation='horizontal'
            value={transferTab}
            onChange={(_, value) => setTransferTab(value)}>
            <Tab label='Queue' value={TabType.Queue} />
            <Tab label='Agent' value={TabType.Agent} />
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel style={{ height: 500 }} disablePadding value={transferTab} index={TabType.Queue}>
            <QueueTransferPanel onTransferToQueue={(queue: string) => onTransferTo(TransferTarget.Queue, queue)} />
          </TabPanel>

          <TabPanel style={{ height: 500 }} disablePadding value={transferTab} index={TabType.Agent}>
            <AgentTransferPanel onTransferToAgent={(agent: string) => onTransferTo(TransferTarget.Agent, agent)} />
          </TabPanel>
        </>
      }
      actionFooter={
        <Button variant='text' onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default TransferModal;
