import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Fragment } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import useAsyncTranscript from '~components/ConversationTranscript/useAsyncTranscript';
import MessageBubble from '~pages/Dialler/ConnectDialler/AsyncView/MessageContainer/Conversation/MessageBubble';
import { AuthorRole, MessageType } from '~services/AsyncManager/domain';

interface ConversationTranscriptProps {
  conversationUUID: string;
}

const ConversationTranscript = ({ conversationUUID }: ConversationTranscriptProps) => {
  const { data, loading, error } = useAsyncTranscript(conversationUUID);

  const messageDisplay = data.map((message) => {
    let messageContent;

    if (message.type === MessageType.Message) {
      messageContent = (
        <MessageBubble
          role={message.authorRole === AuthorRole.Agent ? 'agent' : 'customer'}
          date={message.sentTimestamp}
          name={message.name}
          content={message.text}
        />
      );
    }

    if (message.type === MessageType.Event) {
      messageContent = (
        <Typography
          sx={{ wordBreak: 'break-word' }}
          margin='8px 0'
          color='#8c8c8c'
          fontSize={12}
          fontWeight={400}
          textAlign='center'
          component='p'>
          {message.text}
        </Typography>
      );
    }

    return <Fragment key={message.id}>{messageContent}</Fragment>;
  });

  return (
    <AsyncLoader isLoading={loading}>
      {!loading && error && (
        <Typography sx={{ padding: 2 }} variant='body1' component='p'>
          {error}
        </Typography>
      )}

      {!loading && !error && messageDisplay.length === 0 && (
        <Typography sx={{ padding: 2 }} variant='body1' component='p'>
          No messages exist for this conversation
        </Typography>
      )}

      {!loading && !error && messageDisplay.length > 0 && (
        <Box sx={{ height: '100%', minHeight: 200, maxHeight: 400, overflow: 'auto', padding: 2 }}>
          {messageDisplay}
        </Box>
      )}
    </AsyncLoader>
  );
};

export default ConversationTranscript;
