import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PhoneIcon from '@mui/icons-material/Phone';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

import ContentSpacer from '~components/ContentSpacer';
import CountTotalCard from '~components/CountTotalCard';
import InfoCard from '~components/InfoCard';
import { getDurationFromSeconds, pluraliseWord } from '~utils/Functions';

import { LeadList, LeadStatusType, leadStatusColor } from '../../domain';

interface LeadListSummaryProps {
  leadList: LeadList;
}

const LeadListSummary = ({ leadList }: LeadListSummaryProps) => {
  const leadCounts = Object.entries(leadList.statusCounts).map(([k, v]) => (
    <Grid key={k} item xs={12} md={3} sx={{ textTransform: 'capitalize' }}>
      <CountTotalCard
        color={leadStatusColor[k as LeadStatusType].baseColor}
        title={k.replace(/-/gi, ' ')}
        count={v}
        total={leadList.totalLeads}
      />
    </Grid>
  ));

  return (
    <>
      <ContentSpacer spacing={3}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <InfoCard variant='info' icon={DonutLargeIcon} text={leadList?.progress.toFixed(2)} subText='% progress' />
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard
              variant='info'
              icon={DonutLargeIcon}
              text={leadList?.penetrationRate.toFixed(2)}
              subText='% penetration rate'
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard variant='info' icon={WatchLaterIcon} text={getDurationFromSeconds(leadList?.aht)} subText='aht' />
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard
              variant='info'
              icon={ContactPhoneIcon}
              text={leadList?.totalLeads.toLocaleString()}
              subText='total leads'
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard
              variant='info'
              icon={PhoneIcon}
              text={leadList?.numCompleted.toLocaleString()}
              subText={`${pluraliseWord(leadList?.numCompleted, 'lead')} called`}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard
              variant='info'
              icon={PhoneIcon}
              text={leadList?.numAttempts.toLocaleString()}
              subText={`${pluraliseWord(leadList?.numAttempts, 'lead')} attempted`}
            />
          </Grid>
        </Grid>
      </ContentSpacer>

      <ContentSpacer spacing={3}>
        <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
          Leads In Status
        </Typography>
        <Grid container spacing={2}>
          {leadCounts}
        </Grid>
      </ContentSpacer>
    </>
  );
};

export default LeadListSummary;
