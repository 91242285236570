import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      oms_id: {
        value: '',
        validators: [],
      },
    });

    return () => {
      form.removeSchemaProperties(['oms_id']);
    };
  }, []);

  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        variant='outlined'
        id='oms_id'
        name='oms_id'
        label='OMS ID'
        disabled={formSubmitting}
        value={form.fields?.oms_id?.value || ''}
        error={Boolean(form.errors.oms_id)}
        helperText={form.errors.oms_id && form.errors.oms_id[0]!}
        onChange={form.handleInputChange}
      />
    </Grid>
  );
};

export default OutcomesForm;
