import * as j from '@mojotech/json-type-validation';

export enum StreamType {
  AudioAgentToDialler = 'audio-agent-to-dialler',
  AudioDiallerToAgent = 'audio-dialler-to-agent',
}

export interface MetricItem {
  timestamp: string;
  agentUsername: string;
  streamType: string;
  diallerType: string;
  recordCount: number;
  packetsLost: number;
  packetsCount: number;
  jitterBufferMillis: number;
  roundTripTimeMillis: number;
}

export interface MetricsResponse {
  metrics: MetricItem[];
}

const GetUserMetricsItemResponseDecoder: j.Decoder<MetricItem> = j
  .object({
    time_bucket: j.string(),
    agent_username: j.string(),
    stream_type: j.string(),
    dialler_type: j.string(),
    record_count: j.number(),
    jitter_buffer_milliseconds: j.number(),
    round_trip_time_milliseconds: j.number(),
    packets_lost: j.number(),
    packets_count: j.number(),
  })
  .map((item: any) => ({
    timestamp: item.time_bucket,
    agentUsername: item.agent_username,
    streamType: item.stream_type,
    diallerType: item.dialler_type,
    recordCount: item.record_count,
    packetsLost: item.packets_lost,
    packetsCount: item.packets_count,
    jitterBufferMillis: item.jitter_buffer_milliseconds,
    roundTripTimeMillis: item.round_trip_time_milliseconds,
  }));

export const GetUserMetricsResponseDecoder: j.Decoder<MetricsResponse> = j
  .object({
    results: j.array(GetUserMetricsItemResponseDecoder),
  })
  .map((item: any) => ({
    metrics: item.results,
  }));
