import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useMemo, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import CreateDiallerGroupModal from '~pages/CampaignManagement/DiallerGroupList/CreateDiallerGroupModal';
import useDiallerGroupSearch from '~pages/CampaignManagement/DiallerGroupList/useDiallerGroupSearch';
import { CreateDiallerGroup } from '~pages/CampaignManagement/domain';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import GroupCard from './GroupCard';

// Active is archived false, Archived is archived true
const enum FilterType {
  None = '',
  Active = 'active',
  Paused = 'paused',
  Archived = 'archived',
}

interface Query {
  search: string;
  filter: FilterType;
}

const filterList: { [key in FilterType]: { label: string; archived?: boolean; isActive?: boolean } } = {
  [FilterType.None]: { label: 'None' },
  [FilterType.Active]: { label: 'Active', archived: false, isActive: true },
  [FilterType.Paused]: { label: 'Paused', archived: false, isActive: false },
  [FilterType.Archived]: { label: 'Archived', archived: true },
};

const DiallerGroupList = () => {
  const { hasScope } = useAuth();
  const { accessFilter } = useUserPreferences();
  const { pushNotification } = useNotification();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({ search: '', filter: FilterType.None });
  const debouncedSearch = useDebounce(query.search, 500);
  const filter = filterList[query.filter];
  const {
    loading,
    error,
    list,
    hasMore,
    create,
    intersectionObserverRef: lastDataElement,
  } = useDiallerGroupSearch(debouncedSearch, {
    accessFilterId: accessFilter?.id,
    archived: filter.archived,
    isActive: filter.isActive,
  });
  const noSearchOrFilterSet = query.search === '' && query.filter === FilterType.None;

  const onQueryChange = async (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const toggleCreateModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const addDiallerGroup = async (data: CreateDiallerGroup) => {
    setSubmittingData(true);

    try {
      await create(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created dialler group ${data.name}`);
    setCreateModalOpen(false);
  };

  const displayList = useMemo(
    () =>
      list.map((item, index) => (
        <GroupCard key={item.id} ref={index === list.length - 1 ? lastDataElement : null} group={item} />
      )),
    [list, lastDataElement],
  );

  const filterListsDisplay = useMemo(
    () =>
      Object.entries(filterList).map(([k, v]) => (
        <MenuItem key={k} value={k}>
          {v.label}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 1 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Filter'
            value={query.filter}
            onChange={onQueryChange}>
            {filterListsDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}></Grid>
        </Hidden>

        {hasScope(AccessScope.CanCreateGroup) && (
          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateModal}>
              Create Group
            </Button>
          </Grid>
        )}
      </Grid>

      <AsyncLoader isLoading={loading && list.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load groups
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No groups found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {list.length === 0 && noSearchOrFilterSet && !accessFilter && (
              <EmptyState type='no-items-3' text='No groups currently exist' />
            )}

            {list.length === 0 && noSearchOrFilterSet && accessFilter && (
              <EmptyState
                type='no-records-found'
                text={`${accessFilter.name} access filter does not have any groups assigned to it`}
              />
            )}
          </Grid>
        </Grid>

        <CreateDiallerGroupModal
          open={createModalOpen}
          submitting={submittingData}
          onClose={toggleCreateModal}
          onAccept={addDiallerGroup}
        />
      </AsyncLoader>
    </>
  );
};

export default DiallerGroupList;
