import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import EndpointDiallingHourFilterCard from '~pages/CampaignManagement/CampaignDetails/CampaignFilters/EndpointDiallingHourFilterCard';
import useCampaignFilters from '~pages/CampaignManagement/CampaignDetails/useCampaignFilters';
import {
  CampaignFilterType,
  CampaignFilterTypeValues,
  CreateCampaignFilter,
  UpdateCampaignFilter,
} from '~pages/CampaignManagement/CampaignDetails/useCampaignFilters/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import CreateEditFilterModal from './CreateEditFilterModal';
import LeadAttributeFilterCard from './LeadAttributeFilterCard';

type Query = {
  search?: string;
};

type Props = {
  filterType: CampaignFilterTypeValues;
};

const CampaignFilters = ({ filterType }: Props) => {
  const { pushNotification } = useNotification();
  const { campaignId } = useParams() as { campaignId: string };
  const [createFilterModalOpen, setCreateFilterModalOpen] = useState<boolean>(false);
  const [editableRef, setEditableRef] = useState<number | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({
    search: undefined,
  });
  const debouncedSearch = useDebounce(query.search, 500);
  const {
    list: filters,
    loading,
    error,
    hasMore,
    update,
    remove,
    create,
    intersectionObserverRef: lastDataElement,
  } = useCampaignFilters(+campaignId, {
    search: debouncedSearch,
    filter: filterType,
  });
  const editableFilter = useMemo(() => filters.find((item) => item.id === editableRef), [editableRef]);
  const noSearchOrFilterSet = !query.search;

  const openCreateFilterModal = () => {
    setCreateFilterModalOpen(true);
  };

  const closeCreateEditModal = () => {
    setCreateFilterModalOpen(false);
    setEditableRef(undefined);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const createFilter = async (data: CreateCampaignFilter) => {
    setSubmittingData(true);
    try {
      await create(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    let msg = `You have successfully create filter ${data.name}.`;
    if (filterType === CampaignFilterType.EndpointDiallingHours) {
      msg = `You have successfully create endpoint type setting ${data.name}.`;
    }

    pushNotification('success', msg);
    setCreateFilterModalOpen(false);
  };

  const updateFilter = (filterId: number) => async (data: UpdateCampaignFilter) => {
    setSubmittingData(true);

    try {
      await update(filterId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    let msg = `You have successfully updated filter ${data.name}.`;
    if (filterType === CampaignFilterType.EndpointDiallingHours) {
      msg = `You have successfully updated endpoint type setting ${data.name}.`;
    }

    pushNotification('success', msg);
    setEditableRef(undefined);
  };

  const onAccept = useCallback(
    (filterId?: number) => async (data: CreateCampaignFilter | UpdateCampaignFilter) => {
      if (filterId !== undefined) {
        await updateFilter(filterId)(data);
      } else {
        await createFilter(data);
      }
    },
    [editableFilter],
  );

  const removeFilter = (filterId: number, name: string) => async () => {
    try {
      await remove(filterId);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    let msg = `You have successfully removed filter ${name}.`;
    if (filterType === CampaignFilterType.EndpointDiallingHours) {
      msg = `You have successfully removed endpoint type setting ${name}.`;
    }

    pushNotification('success', msg);
  };

  const displayList = filters.map((item, index) => {
    const props = {
      ref: index === filters.length - 1 ? lastDataElement : undefined,
      key: item.id,
      filter: item,
      onEdit: () => setEditableRef(item.id),
      onDelete: removeFilter(item.id, item.name),
    };

    let Component = LeadAttributeFilterCard;
    if (filterType === CampaignFilterType.EndpointDiallingHours) {
      Component = EndpointDiallingHourFilterCard;
    }

    return <Component {...props} />;
  });

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={4}>
          <TextField fullWidth variant='outlined' label='Search' id='search' name='search' onChange={onQueryChange} />
        </Grid>

        <Hidden smDown>
          <Grid item md={5}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={openCreateFilterModal}>
            {filterType === CampaignFilterType.EndpointDiallingHours ? 'Create Endpoint Setting' : 'Create Filter'}
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && filters.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {filters.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && filters.length > 0 && <DotLoader align='center' />}
                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}
                {error && filters.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load{' '}
                    {filterType === CampaignFilterType.EndpointDiallingHours ? 'endpoint type settings' : 'filters'}{' '}
                    filters
                  </Typography>
                )}
              </>
            )}

            {filters.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text={
                  filterType === CampaignFilterType.EndpointDiallingHours
                    ? 'No endpoint type settings found matching your search criteria'
                    : 'No filters found matching your search criteria'
                }
                subText='Try alternate words or selections.'
              />
            )}

            {filters.length === 0 && noSearchOrFilterSet && (
              <EmptyState
                type='no-items-2'
                text={
                  filterType === CampaignFilterType.EndpointDiallingHours
                    ? 'No endpoint type settings available'
                    : 'No filters available'
                }
              />
            )}
          </Grid>
        </Grid>
      </AsyncLoader>

      <CreateEditFilterModal
        open={createFilterModalOpen || Boolean(editableFilter)}
        filter={editableFilter}
        filterType={filterType}
        submitting={submittingData}
        onClose={closeCreateEditModal}
        onAccept={onAccept(editableRef)}
      />
    </>
  );
};

export default CampaignFilters;
