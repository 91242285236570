import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useState } from 'react';
import useMeasure from 'react-use-measure';

import AsyncLoader from '~components/AsyncLoader';
import DialPhoneNumberInput from '~components/DialPhoneNumberInput';
import { DotLoader } from '~components/DotLoader';
import useDebounce from '~hooks/useDebounce';
import useTransfers from '~hooks/useTransfers';
import { TransferType, TransferTypeValues } from '~hooks/useTransfers/domain';
import { ConnectVoiceContact } from '~providers/ConnectProvider/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

type Props = {
  contact?: ConnectVoiceContact;
  maxConnectionCapacityReached: boolean;
  onCloseTransferModal: () => void;
};

const CustomizedListItem = styled(ListItem)(({ theme }) => ({
  padding: 16,
}));

const CustomizedListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    userSelect: 'none',
    display: 'block',
    fontSize: 16,
    paddingRight: 50,
    minWidth: 0,
    textOverflow: 'ellipsis',

    /* Required for text-overflow to do anything */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& .MuiListItemText-secondary': {
    userSelect: 'none',
    display: 'block',
    paddingRight: 50,
    minWidth: 0,
    textOverflow: 'ellipsis',
  },
}));

const ExternalTransfer = ({ contact, maxConnectionCapacityReached, onCloseTransferModal }: Props) => {
  const logRocket = useLogRocket();
  const { pushNotification } = useNotification();
  const [boxRef, { height: boxHeight }] = useMeasure();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const {
    loading,
    error,
    list,
    hasMore,
    intersectionObserverRef: lastDataElement,
  } = useTransfers({ search: debouncedSearch });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const triggerExternalTransfer = async (type: TransferTypeValues, phoneNumber: string) => {
    if (contact === undefined) {
      console.error('triggerExternalTransfer: contact does not exist');
      return;
    }

    if (maxConnectionCapacityReached) {
      console.error('Conference at capacity');
      pushNotification('error', 'Conference at capacity');
      return;
    }

    try {
      switch (type) {
        case TransferType.PhoneNumber: {
          await contact.initiateExternalTransfer(phoneNumber);
          break;
        }
        case TransferType.ARN: {
          await contact.conferenceTransfer(phoneNumber);
          break;
        }
        default: {
          console.error('triggerExternalTransfer: Found unexpected transfer type');
          pushNotification('error', 'Unable to trigger transfer as endpoint is invalid');
          return;
        }
      }
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    logRocket.trackEvent('transfer');
    logRocket.trackEvent('external_transfer');

    onCloseTransferModal();
  };

  const triggerDirectTransfer = async (phoneNumber: string) => {
    await triggerExternalTransfer(TransferType.PhoneNumber, phoneNumber);
  };

  const transferItemList = list.map((item, index) => (
    <CustomizedListItem
      ref={index === list.length - 1 ? lastDataElement : null}
      key={index}
      divider={index !== list.length - 1}>
      <CustomizedListItemText primary={item.name} secondary={item.description} />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => triggerExternalTransfer(item.type, item.endpoint)}
          size='medium'
          aria-label={`Transfer call to ${item.name}`}>
          <PhoneForwardedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </CustomizedListItem>
  ));

  return (
    <>
      <div ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <DialPhoneNumberInput
              id='directTransfer'
              name='directTransfer'
              label='Direct Transfer'
              onClick={triggerDirectTransfer}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              style={{ marginTop: 8 }}
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </div>

      <div style={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        <AsyncLoader isLoading={loading}>
          {list.length > 0 && (
            <>
              <List sx={{ padding: 0 }}>{transferItemList}</List>
              {loading && list.length > 0 && <DotLoader align='center' />}

              {!loading && !hasMore && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  No more results to display
                </Typography>
              )}

              {error && list.length > 0 && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  Failed to load transfer destinations
                </Typography>
              )}
            </>
          )}

          {list.length === 0 && (
            <>
              {!error && !debouncedSearch && (
                <Typography marginTop={1} variant='body1' component='p' align='center'>
                  No transfer destinations currently exist
                </Typography>
              )}

              {!error && debouncedSearch && (
                <Typography marginTop={1} variant='body1' component='p' align='center'>
                  No search results found.
                </Typography>
              )}

              {error && (
                <Typography variant='body2' align='center' color='textSecondary'>
                  Failed to load transfer destinations
                </Typography>
              )}
            </>
          )}
        </AsyncLoader>
      </div>
    </>
  );
};

export default ExternalTransfer;
