import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useDebounce from '~hooks/useDebounce';
import useSystemEndpointList, { DiallerType } from '~hooks/useSystemEndpointList';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

import { CreateCampaign } from '../../domain';

interface CreateCampaignModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateCampaign) => void;
  onClose: () => void;
}

interface ListItem {
  id: number;
  name: string;
}

interface Form {
  name: string;
  description: string;
  sipSourcePhoneNumber: string;
  connectSourcePhoneNumber: string;
  accessFilters: ListItem[];
}

const CreateCampaignModal = ({ open, submitting, onAccept, onClose }: CreateCampaignModalProps) => {
  const { accessFilter } = useUserPreferences();
  const appConfig = useAppConfiguration();
  const [searchAccessFilter, setSearchAccessFilter] = useState<string>('');
  const debouncedSearchAccessFilter = useDebounce(searchAccessFilter, 500);
  const {
    loading: accessFilterFetching,
    error: accessFilterFetchError,
    list: accessFilters,
    intersectionObserverRef: lastAccessFilterDataElement,
  } = useAccessFilterSearch(debouncedSearchAccessFilter, { archived: false, shouldFetch: open });
  const {
    loading: fetchingSipEndpoints,
    list: sipEndpoints,
    intersectionObserverRef: sipObserverRef,
  } = useSystemEndpointList({
    shouldFetch: open && appConfig.extensions.predictive !== undefined,
    diallerType: DiallerType.SIP,
  });
  const {
    loading: fetchingConnectEndpoints,
    list: connectEndpoints,
    intersectionObserverRef: connectObserverRef,
  } = useSystemEndpointList({
    shouldFetch: open && appConfig.extensions.predictive !== undefined,
    diallerType: DiallerType.Connect,
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      sipSourcePhoneNumber: '',
      connectSourcePhoneNumber: '',
      accessFilters: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const isLoading = submitting || accessFilterFetching || fetchingSipEndpoints || fetchingConnectEndpoints;
  const accessFiltersWatch = watch('accessFilters');

  // Manages form based data
  useEffect(() => {
    if (open) {
      if (accessFilter) {
        setValue('accessFilters', [{ id: accessFilter.id, name: accessFilter.name }]);
      }
    }

    return () => {
      reset();
    };
  }, [open]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: CreateCampaign = {
      name: data.name,
      description: data.description,
      accessFilterIds: data.accessFilters.length > 0 ? data.accessFilters.map((item) => item.id) : undefined,
      sipSourcePhoneNumber: data.sipSourcePhoneNumber,
      predictiveSettings: {
        leaveMessageOnAttempt: undefined,
        answeringMachineMessage: undefined,
        allCallbacksAsPreview: undefined,
        connectSourcePhoneNumber: data.connectSourcePhoneNumber,
      },
    };

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchAccessFilter(e.target.value);
  };

  const onSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchAccessFilter('');
  };

  const accessFiltersNoOptionsText = useMemo(() => {
    if (accessFilterFetching) {
      return <DotLoader align='center' />;
    }

    if (accessFilterFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load access filters
        </Typography>
      );
    }

    return undefined;
  }, [accessFilterFetching, accessFilterFetchError]);

  const accessFiltersFilteredList: ListItem[] = useMemo(() => {
    let list: ListItem[] = accessFilters.map((item) => ({ id: item.id, name: item.name }));
    if (accessFilter) {
      list = [...list, { id: accessFilter.id, name: accessFilter.name }];
    }
    if (!accessFiltersWatch) {
      return list;
    }

    return list.filter((listItem) => {
      return !accessFiltersWatch.find((watchItem) => listItem.id === watchItem.id);
    });
  }, [accessFilters, accessFiltersWatch]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Campaign'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Campaign name is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Campaign Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: 'Campaign description is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Campaign Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {appConfig.extensions.predictive !== undefined && sipEndpoints.length > 0 && (
            <Grid item xs={12}>
              <Controller
                name='sipSourcePhoneNumber'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    options={sipEndpoints}
                    disabled={isLoading}
                    disableClearable
                    getOptionLabel={(option) => option || ''}
                    renderOption={(props, option) => (
                      <li {...props} ref={sipObserverRef} key={option}>
                        <Typography variant='body1' color='textPrimary' component='p'>
                          {option}
                        </Typography>
                      </li>
                    )}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        label='Outbound SIP CLI'
                        variant='outlined'
                        error={Boolean(errors.sipSourcePhoneNumber)}
                        helperText={errors.sipSourcePhoneNumber?.message}
                        inputProps={{ ...inputProps, readOnly: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}

          {/* it's not clear yet if we can require a connect source phone number. */}
          {appConfig.extensions.predictive !== undefined && connectEndpoints.length > 0 && (
            <Grid item xs={12}>
              <Controller
                name='connectSourcePhoneNumber'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    options={connectEndpoints}
                    disabled={isLoading}
                    disableClearable
                    getOptionLabel={(option) => option || ''}
                    renderOption={(props, option) => (
                      <li {...props} ref={connectObserverRef} key={option}>
                        <Typography variant='body1' color='textPrimary' component='p'>
                          {option}
                        </Typography>
                      </li>
                    )}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        label='Outbound Connect Predictive CLI'
                        variant='outlined'
                        error={Boolean(errors.connectSourcePhoneNumber)}
                        helperText={errors.connectSourcePhoneNumber?.message}
                        inputProps={{ ...inputProps, readOnly: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name='accessFilters'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  multiple
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={accessFiltersFilteredList}
                  noOptionsText={accessFiltersNoOptionsText}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastAccessFilterDataElement} key={option.id}>
                      <Typography variant='body1' color='textPrimary' component='p'>
                        {option.name}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Access Filters'
                      variant='outlined'
                      onBlur={onSearchBlur}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateCampaignModal;
