import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';

import { useConnect } from '~providers/ConnectProvider';

import LoadingButtonDropdown from '../LoadingButtonDropdown';

type Props = {
  disable: boolean;
  submit: (switchToStatus: string | undefined) => Promise<void>;
};

export const SystemDisposedInboundButtons = ({ disable, submit }: Props) => {
  const { agentStateList } = useConnect();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const onSubmit = async (switchToStatus: string | undefined) => {
    setSubmitting(true);

    try {
      await submit(switchToStatus);
    } catch {
      // do nothing
    }

    setSubmitting(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LoadingButton
          fullWidth
          variant='contained'
          disableElevation
          disabled={disable}
          loading={submitting}
          onClick={() => onSubmit(undefined)}
          color='primary'>
          Clear and continue
        </LoadingButton>
      </Grid>

      <Grid item xs={12}>
        <LoadingButtonDropdown
          title='Go to State'
          fullWidth
          items={agentStateList}
          disabled={disable}
          loading={submitting}
          onChange={(status) => onSubmit(status)}
        />
      </Grid>
    </Grid>
  );
};

export default SystemDisposedInboundButtons;
