import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AccessFilter, UpdateAccessFilter } from '~pages/SystemManagement/domain';

import { archiveAccessFilterById, getAccessFilter, updateAccessFilter } from './api';

const useAccessFilter = (accessFilterId: number) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<AccessFilter | undefined>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const reload = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    let resp: AccessFilter | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getAccessFilter(accessFilterId, axiosCancelRef.current);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setData(resp);
    setLoading(false);
  }, [accessFilterId]);

  const update = useCallback(
    async (data: Partial<UpdateAccessFilter>) => {
      try {
        await updateAccessFilter(accessFilterId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [accessFilterId],
  );

  const archive = useCallback(async () => {
    try {
      await archiveAccessFilterById(accessFilterId);
    } catch (e) {
      // Do nothing
      return Promise.reject(e);
    }

    await reload();
  }, [accessFilterId]);

  useEffect(() => {
    setData(undefined);
  }, [accessFilterId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(undefined);

      let resp: AccessFilter | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getAccessFilter(accessFilterId, axiosCancelRef.current);
      } catch (e) {
        setError((e as Error).message);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setData(resp);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [accessFilterId]);

  return { loading, error, data, reload, update, archive };
};

export default useAccessFilter;
