import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import React from 'react';

import useForm, { ValidatorType } from '~hooks/useForm';
import { ContactAssessmentFormProps } from '~organisations/domain';

const ContactAssessmentForm = ({ formSubmitting, onClose, onSubmit }: ContactAssessmentFormProps) => {
  const { fields, errors, handleInputChange, handleSubmit } = useForm({
    records_updated: {
      value: false,
      validators: [],
    },
    professionalism: {
      value: false,
      validators: [],
    },
    escalation_proccess: {
      value: false,
      validators: [],
    },
    summary: {
      value: '',
      validators: [
        {
          type: ValidatorType.Required,
          message: 'A summary is Required',
        },
      ],
    },
  });

  const onSubmitFn = handleSubmit((data: { [key: string]: any }) => {
    onSubmit(data);
  });

  return (
    <form onSubmit={onSubmitFn} noValidate>
      <FormControlLabel
        control={
          <Checkbox
            id='records_updated'
            name='records_updated'
            disabled={formSubmitting}
            checked={fields.records_updated.value}
            onChange={handleInputChange}
          />
        }
        label='Did the team member update account records?'
      />

      <FormControlLabel
        control={
          <Checkbox
            id='professionalism'
            name='professionalism'
            disabled={formSubmitting}
            checked={fields.professionalism.value}
            onChange={handleInputChange}
          />
        }
        label='Did the team member conduct themselves professionally?'
      />

      <FormControlLabel
        control={
          <Checkbox
            id='escalation_proccess'
            name='escalation_proccess'
            disabled={formSubmitting}
            checked={fields.escalation_proccess.value}
            onChange={handleInputChange}
          />
        }
        label='Did team member adhere to the escalation proccess?'
      />

      <TextField
        fullWidth
        multiline
        rows={4}
        margin='normal'
        variant='outlined'
        id='summary'
        name='summary'
        label='Summary'
        disabled={formSubmitting}
        required={true}
        defaultValue={fields.summary.value}
        error={Boolean(errors.summary)}
        helperText={errors.summary && errors.summary[0]!}
        onChange={handleInputChange}
      />

      <Box sx={{ textAlign: 'right' }}>
        <Button disabled={formSubmitting} onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          sx={{ marginLeft: 1 }}
          type='submit'
          variant='contained'
          disableElevation
          loading={formSubmitting}
          color='primary'>
          Update
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ContactAssessmentForm;
