import * as j from '@mojotech/json-type-validation';

import { LeadStatusType } from '~pages/CampaignManagement/domain';

export enum AttemptStatusType {
  Initiated = 'initiated',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface EndpointAttributes {
  type: string;
  attributes: Record<string, string>;
}

export interface DiallableLead {
  id: number;
  externalId: string;
  name: string;
  status: LeadStatusType;
  timezone: string;
  campaignId: number;
  endpoint: string;
  callbackId: number | undefined;
  callbackNotes: string | undefined;
  attributes: Record<string, any>;
  endpointAttributes: Record<string, EndpointAttributes>;
  requiredSkills: string;
}

export interface OutcomeDisposition {
  contactId: string | undefined;
  dispositionCode: string;
  dispositionSubCode: string;
  hasSystemIssue: boolean;
  systemIssueDescription?: string;
  metadata?: Record<string, string>;
  callback?: Callback;
  exclusionList?: string;
}

export interface Callback {
  forAgent: string | null;
  scheduled: string;
  endpoint: string;
  notes: string;
}

export const GetTimezonesResponseDecoder: j.Decoder<string[]> = j.array(j.string());
