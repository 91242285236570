import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';

export interface SelectItem<T = any> {
  label: string;
  value: T;
}

interface SelectProps {
  id: string;
  name: string;
  defaultValue?: any;
  value?: any;
  size?: 'small' | 'medium';
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  items: SelectItem[];
  title: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  style?: any;
}

const StandardSelectItem = (item: SelectItem, index: number) => (
  <MenuItem key={index} value={item.value}>
    {item.label}
  </MenuItem>
);

/** Deprecated - DO NOT USE AS WE ARE PHASING IT OUT */
const Selectbox = ({
  defaultValue,
  value,
  onChange,
  items,
  id,
  name,
  title,
  disabled,
  required,
  error,
  helperText,
  className,
  style,
  size,
}: SelectProps) => {
  const list = items.map(StandardSelectItem);

  return (
    <TextField
      fullWidth
      className={className}
      select
      size={size}
      variant='outlined'
      id={id}
      name={name}
      label={title}
      error={error}
      style={style}
      defaultValue={defaultValue}
      value={value}
      helperText={helperText}
      onChange={onChange}
      disabled={disabled}
      required={required}>
      {list}
    </TextField>
  );
};

export default Selectbox;
