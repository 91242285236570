import * as j from '@mojotech/json-type-validation';

export interface DiallerGroupCampaign {
  entryId: number;
  campaignId: number;
  name: string;
  isActive: boolean;
  archived: string | null;
  startTime: string | null;
  endTime: string | null;
  priority: number;
  isDefaultCampaign: boolean;
  connectSourcePhoneNumber: string;
}

export type AddCampaignGroupAssignment = Pick<
  DiallerGroupCampaign,
  'campaignId' | 'startTime' | 'endTime' | 'priority'
>;
export type UpdateCampaignGroupAssignment = Pick<
  DiallerGroupCampaign,
  'entryId' | 'startTime' | 'endTime' | 'priority'
>;

export interface DiallerGroupCampaignsResponse {
  nextPageUrl: string | null;
  campaigns: DiallerGroupCampaign[];
}

const DiallerGroupCampaignResponseDecoder: j.Decoder<DiallerGroupCampaign> = j
  .object({
    id: j.number(),
    campaign_id: j.number(),
    name: j.string(),
    is_active: j.boolean(),
    archived: j.union(j.string(), j.constant(null)),
    start_time: j.union(j.string(), j.constant(null)),
    end_time: j.union(j.string(), j.constant(null)),
    priority: j.number(),
    is_default_campaign: j.boolean(),
    connect_source_phone_number: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    entryId: item.id,
    campaignId: item.campaign_id,
    name: item.name,
    isActive: item.is_active,
    archived: item.archived,
    startTime: item.start_time,
    endTime: item.end_time,
    priority: item.priority,
    isDefaultCampaign: item.is_default_campaign,
    connectSourcePhoneNumber: item.connect_source_phone_number || '',
  }));

export const DiallerGroupCampaignsResponseDecoder: j.Decoder<DiallerGroupCampaignsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    campaigns: j.array(DiallerGroupCampaignResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    campaigns: item.campaigns,
  }));
