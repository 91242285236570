import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import { ChangeGroup } from '~hooks/useAgentList/domain';
import useDebounce from '~hooks/useDebounce';
import useDiallerGroupRoutingProfiles, {
  isDiallerGroupRoutingProfileList,
} from '~hooks/useDiallerGroupRoutingProfiles';
import useDiallerGroupSearch from '~pages/CampaignManagement/DiallerGroupList/useDiallerGroupSearch';
import { DiallerGroup, RoutingProfile } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

type Props = {
  open: boolean;
  submitting: boolean;
  onAccept: (data: ChangeGroup | null) => Promise<void>;
  onClose: () => void;
};

interface SearchQueries {
  group: string;
}

interface Form {
  group: DiallerGroup | null;
  routingProfileId: string | null;
}

const getNoOptionsText = (fetching: boolean, fetchError: boolean, fetchErrorText: string) => {
  if (fetching) {
    return <DotLoader align='center' />;
  }

  if (fetchError) {
    return (
      <Typography variant='body2' align='center' color='textSecondary'>
        {fetchErrorText}
      </Typography>
    );
  }

  return undefined;
};

const UpdateGroupModal = ({ open, submitting, onAccept, onClose }: Props) => {
  const appConfig = useAppConfiguration();
  const { accessFilter } = useUserPreferences();
  const [searchQueries, setSearchQueries] = useState<SearchQueries>({
    group: '',
  });
  const debouncedSearchQueries = useDebounce(searchQueries, 500);
  const {
    loading: groupFetching,
    error: groupFetchError,
    list: groups,
    intersectionObserverRef: lastAsyncQueueDataElement,
  } = useDiallerGroupSearch(debouncedSearchQueries.group, {
    accessFilterId: accessFilter?.id,
    shouldFetch: open,
  });
  const { loading: groupRoutingProfileFetching, data: groupRoutingProfileData } = useDiallerGroupRoutingProfiles({
    accessFilterId: accessFilter?.id,
    shouldFetch: open,
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm<Form>({
    defaultValues: {
      group: null,
      routingProfileId: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const groupWatch = watch('group');

  // Any time group changes we should reset this field
  useEffect(() => {
    if (!appConfig.aws.externallyManagedRoutingProfile) {
      setValue('routingProfileId', null);
    }
  }, [groupWatch, appConfig.aws.externallyManagedRoutingProfile]);

  const isLoading = submitting || groupFetching || groupRoutingProfileFetching;
  const groupRoutingProfileList =
    groupRoutingProfileData && isDiallerGroupRoutingProfileList(groupRoutingProfileData) ? groupRoutingProfileData : [];

  const onSubmit = handleSubmit(async (data) => {
    let submitData: ChangeGroup | null = null;

    if (data.group) {
      submitData = {
        groupId: data.group.id,
        routingProfileId: data.routingProfileId,
      };
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const getRoutingProfilesForDiallerGroupId = (diallerGroup: DiallerGroup | null): RoutingProfile[] => {
    if (!diallerGroup) {
      return [];
    }

    if (!groupRoutingProfileList) {
      return [];
    }

    if (isDiallerGroupRoutingProfileList(groupRoutingProfileList)) {
      for (const group of groupRoutingProfileList) {
        if (group.id === diallerGroup.id && groupRoutingProfileList.length > 0) {
          return group.routingProfiles;
        }
      }
      return [];
    }

    return [];
  };

  const onSearchChange = (key: keyof SearchQueries) => (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQueries((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const onSearchBlur = (key: keyof SearchQueries) => (e: FocusEvent<HTMLInputElement>) => {
    setSearchQueries((prev) => ({ ...prev, [key]: '' }));
  };

  const asyncQueuesNoOptionsText = useMemo(
    () => getNoOptionsText(groupFetching, groupFetchError, 'Failed to load queues'),
    [groupFetching, groupFetchError],
  );

  const groupFilteredList = useMemo(() => {
    if (!groupWatch) return groups;

    const group = groups.find((item) => item.id === groupWatch.id);

    if (!group) return groups;

    return groups.filter((item) => item.id !== groupWatch.id);
  }, [groups, groupWatch]);

  const routingProfileDisplay = getRoutingProfilesForDiallerGroupId(groupWatch || null).map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Change Group'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='group'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={groupFilteredList}
                  noOptionsText={asyncQueuesNoOptionsText}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastAsyncQueueDataElement} key={option.id}>
                      <Box>
                        <Typography variant='body1' color='textPrimary' component='p'>
                          {option.name}
                        </Typography>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Groups'
                      variant='outlined'
                      onBlur={onSearchBlur('group')}
                      onChange={onSearchChange('group')}
                    />
                  )}
                />
              )}
            />
          </Grid>

          {!appConfig.aws.externallyManagedRoutingProfile && groupWatch && (
            <Grid item xs={12}>
              <Controller
                name='routingProfileId'
                control={control}
                rules={{ required: 'Routing profile is required.' }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    label='Routing Profile'
                    disabled={isLoading || routingProfileDisplay.length === 0}
                    required={true}
                    error={Boolean(errors.routingProfileId)}
                    helperText={errors.routingProfileId?.message}
                    {...field}>
                    {routingProfileDisplay}
                  </TextField>
                )}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default UpdateGroupModal;
