import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import RouteMap from '~providers/RouteProvider/Routes';

import { useAppConfiguration } from '../AppConfigurationProvider';
import { ExtensionsConfiguration } from '../AppConfigurationProvider/api';
import { useAuth } from '../AuthProvider';
import Page from './Page';
import Routes from './Routes';

interface RouteProviderProps {
  children: ReactNode;
}

type RouteContextProps = {
  defaultLoginPage: Page | undefined;
  routes: Page[];
};

export const RouteContext = createContext<RouteContextProps | undefined>(undefined);

export const useRoutes = (): RouteContextProps => {
  return useContext(RouteContext) as RouteContextProps;
};

const RouteProvider = ({ children }: RouteProviderProps) => {
  const appConfig = useAppConfiguration();
  const { hasPageAccess } = useAuth();
  const location = useLocation();
  const [routes, setRoutes] = useState<Page[]>([]);
  const isExtensionEnabled = (ref: keyof ExtensionsConfiguration) => Boolean(appConfig.extensions[ref]);

  const defaultLoginPage: Page | undefined = useMemo(() => {
    if (hasPageAccess(RouteMap.diallerConfig.viewPolicies)) {
      return RouteMap.diallerConfig;
    } else if (hasPageAccess(RouteMap.contactLogs.viewPolicies)) {
      return RouteMap.contactLogs;
    } else {
      // Agent and all other roles should land on the first available route
      return routes[0];
    }
  }, [hasPageAccess, JSON.stringify(routes)]);

  // Sets all routes that the current user has access to view
  useEffect(() => {
    const routeList: Page[] = [];

    for (let key in Routes) {
      const k = key as any as keyof Routes;
      const route: Page = Routes[k];

      // If an extension reference is present we want to make sure to check if the extension is enabled or not
      // as well as if the user has permission to view it and its related routes.
      // Else we just want to check if the user has permission to view the specific route.
      if (route.extensionRef) {
        if (isExtensionEnabled(route.extensionRef) && hasPageAccess(route.viewPolicies)) {
          route.selected(location.pathname === route.path);
          routeList.push(route);
        }
      } else {
        if (hasPageAccess(route.viewPolicies)) {
          route.selected(location.pathname === route.path);
          routeList.push(route);
        }
      }
    }

    setRoutes(routeList);
  }, [appConfig.extensions, location.pathname, hasPageAccess]);

  const context = {
    defaultLoginPage,
    routes,
  };

  return <RouteContext.Provider value={context}>{children}</RouteContext.Provider>;
};

export default RouteProvider;
