import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  deleteMessageTemplate,
  getMessageTemplates,
  getMessageTemplatesByNextUrl,
  postMessageTemplate,
  putMessageTemplate,
} from './api';
import { MessageTemplate, MessageTemplateOmitId, MessageTemplatesResponse } from './domain';

const useMessageTemplate = (queue: string, search: string, filter: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: MessageTemplatesResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getMessageTemplatesByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setMessageTemplates((prev) => [...prev, ...resp!.list]);
      setCategories(resp.categories);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: MessageTemplatesResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getMessageTemplates(queue, search, filter, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setMessageTemplates(resp.list);
    setCategories(resp.categories);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, []);

  const removeMessageTemplate = useCallback(async (messageTemplateId: number) => {
    try {
      await deleteMessageTemplate(queue, messageTemplateId);
    } catch (e) {
      throw e;
    }

    reload();
  }, []);

  const createMessageTemplate = useCallback(async (messageTemplate: MessageTemplateOmitId) => {
    try {
      await postMessageTemplate(queue, messageTemplate);
    } catch (e) {
      throw e;
    }

    reload();
  }, []);

  const updateMessageTemplate = useCallback(
    async (messageTemplateId: number, messageTemplate: MessageTemplateOmitId) => {
      try {
        await putMessageTemplate(queue, messageTemplateId, messageTemplate);
      } catch (e) {
        throw e;
      }

      reload();
    },
    [],
  );

  useEffect(() => {
    setMessageTemplates([]);
  }, [search, filter]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(false);

      let resp: MessageTemplatesResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getMessageTemplates(queue, search, filter, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setMessageTemplates((prev) => [...prev, ...resp!.list]);
      setCategories(resp.categories);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, filter]);

  return {
    loading,
    error,
    messageTemplates,
    categories,
    hasMore,
    getNextPage,
    createMessageTemplate,
    updateMessageTemplate,
    removeMessageTemplate,
  };
};

export default useMessageTemplate;
