import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import { DiallerGroupSipQueue } from '~pages/CampaignManagement/domain';
import { getSipQueues } from '~pages/SystemManagement/api';
import { SipQueue } from '~pages/SystemManagement/domain';

interface Props {
  open: boolean;
  diallerGroupId: string;
  submitting: boolean;
  onAccept: (data: DiallerGroupSipQueue) => void;
  onClose: () => void;
  queuesPresent: { [key: string]: string };
}

interface Form {
  queue: SipQueue | null;
  priority: number;
  delay: number;
}

const AssignQueueModal = ({ open, diallerGroupId, submitting, onAccept, onClose, queuesPresent }: Props) => {
  const [search, setSearch] = useState<string>('');
  // todo: useQueueSearch()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [queues, setQueues] = useState<SipQueue[]>([]);

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<Form>({
    defaultValues: {
      queue: null,
      priority: 1,
      delay: 0,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const load = async () => {
    setIsLoading(true);
    try {
      const queues = await getSipQueues(search);
      setQueues(queues);
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [search]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: DiallerGroupSipQueue = {
      queueID: data.queue!.queueID,
      priority: data.priority,
      delay: data.delay,
    };

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearch('');
  };

  const noOptionsText = useMemo(() => {
    if (isLoading) {
      return <DotLoader align='center' />;
    }

    if (error) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load queues
        </Typography>
      );
    }

    return undefined;
  }, [isLoading, error]);

  // todo: remove queues already present on the group.
  const filteredList = queues.filter((q) => !queuesPresent[`${q.queueID}`]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Assign Queue'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='queue'
              control={control}
              rules={{
                required: 'Queue is required.',
              }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={filteredList}
                  noOptionsText={noOptionsText}
                  disabled={submitting}
                  isOptionEqualToValue={(option, value) => option.queueID == value.queueID}
                  getOptionLabel={(option) => option?.queueName || ''}
                  renderOption={(props, option) => (
                    <li {...props} key={option.queueID}>
                      {option.queueName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Queue'
                      variant='outlined'
                      required={true}
                      error={Boolean(errors.queue)}
                      helperText={errors.queue?.message}
                      onBlur={onSearchBlur}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledNumberField
              name='priority'
              control={control}
              rules={{
                required: 'Priority is required.',
                min: {
                  value: 1,
                  message: 'Priority must be greater than 0.',
                },
              }}
              label='Priority'
              disabled={submitting}
              required={true}
              error={Boolean(errors.priority)}
              helperText={errors.priority?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledNumberField
              name='delay'
              control={control}
              rules={{
                required: 'Delay is required.',
                min: {
                  value: 0,
                  message: 'Delay must be 0 or higher.',
                },
              }}
              label='Delay in seconds'
              disabled={submitting}
              required={true}
              error={Boolean(errors.delay)}
              helperText={errors.delay?.message}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Assign
          </LoadingButton>
        </>
      }
    />
  );
};

export default AssignQueueModal;
