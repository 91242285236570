import axios, { CancelTokenSource } from 'axios';

import { Agent } from '~pages/SystemManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { GetAgentResponseDecoder } from './domain';

export const getAgent = async (username: string, cancelToken?: CancelTokenSource): Promise<Agent | undefined> => {
  const path = `/api/agents/${username}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        return undefined;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
