import { Organisation } from '~organisations/domain';

import colesAdditionalFields from './additionalOutcomeCaptureFields/coles';
import comparePartnersAdditionalFields from './additionalOutcomeCaptureFields/comparePartners';
import jbhifiAdditionalFields from './additionalOutcomeCaptureFields/jbhifi';
import logo from './images/logo.png';

const telstraConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '3': comparePartnersAdditionalFields,
    '5': colesAdditionalFields,
    '9': jbhifiAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {},
};

export default telstraConfig;
