import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import blueGrey from '@mui/material/colors/blueGrey';
import React, { useEffect, useState } from 'react';

import { getIsPendingRoutingProfileChange } from '~components/AgentCard/RoutingProfileChangeBadge/api';

type Props = {
  timestamp: string;
  username: string;
};

export const RoutingProfileChangeBadge = ({ timestamp, username }: Props) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      let pending = false;
      try {
        pending = await getIsPendingRoutingProfileChange(username);
      } catch (e) {
        // Do nothing - if there is an error we cannot display anything about if there is a pending routing
        // profile change or not
        return;
      } finally {
        setLoading(false);
      }

      let interval: number;
      if (pending) {
        interval = window.setInterval(async () => {
          let pending = false;
          try {
            pending = await getIsPendingRoutingProfileChange(username);
          } catch (e) {
            // Do nothing - if there is an error we cannot display anything about if there is a pending routing
            // profile change or not
            return;
          }

          if (!pending) {
            window.clearInterval(interval);
          }

          setIsPending(pending);
        }, 10_000);
      }

      setIsPending(pending);

      return () => {
        window.clearInterval(interval);
      };
    })();
  }, [timestamp, username]);

  return (
    <>
      {!loading && isPending && (
        <Chip
          sx={{
            textTransform: 'uppercase',
            fontSize: 10,
            borderRadius: 1,
            height: 20,
            lineHeight: '21px',
            color: '#ffffff',
            fontWeight: 700,
            backgroundColor: blueGrey[500],
          }}
          label={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress sx={{ marginRight: 0.5 }} disableShrink size={12} color='inherit' /> Changing routing
              profile
            </Box>
          }
        />
      )}
    </>
  );
};

export default RoutingProfileChangeBadge;
