import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';

import { NewSipQueue, SipQueue } from '../../domain';

interface CreateEditAttributeModalProps {
  open: boolean;
  submitting: boolean;
  sipQueue: SipQueue | null;
  onAccept: (data: NewSipQueue) => void;
  onClose: () => void;
}

interface Form {
  name: string;
  description: string;
}

const QueueModal = ({ open, submitting, sipQueue, onAccept, onClose }: CreateEditAttributeModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(sipQueue);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'all',
    shouldUnregister: true,
  });

  // Handles all async based actions
  useEffect(() => {
    // If edit lets prefill all required fields
    if (open && sipQueue !== null) {
      setValue('name', sipQueue.queueName);
      setValue('description', sipQueue.description);
    }

    // Reset form on close
    return function cleanupCreateEditAttributeModal() {
      reset();
    };
  }, [open, sipQueue]);

  const onSubmit = handleSubmit(async (data: Form) => {
    const queue: NewSipQueue = {
      queueName: data.name,
      description: data.description,
    };
    try {
      await onAccept(queue);

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Queue`}
      subHeader={
        <Typography variant='caption' component='h1' color='textSecondary'>
          Create a queue for routing calls to groups of agents
        </Typography>
      }
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Name is required.',
                maxLength: 60,
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Name'
                  disabled={submitting || isEdit}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message || `Must be globally unique, max length is 60.`}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: false,
                maxLength: 500,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default QueueModal;
