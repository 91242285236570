import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import CircularProgressWithLabel from '~components/CircularProgressWithLabel';
import EmptyState from '~components/EmptyState';
import ControlledFileUpload from '~components/Form/ControlledFileUpload';
import InfoCard from '~components/InfoCard';
import OberonCard from '~components/OberonCard';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { applyImportList, discardImportList, getListImportDataById, uploadLeadsListFileForList } from '../../api';
import { LeadListUploadResponse, ListImportItem } from '../../domain';

interface Form {
  file: File | null;
}

// Used by upload step
interface Progress {
  isUploading: boolean;
  uploadProgress: number;
  isProcessing: boolean;
}

interface Actions {
  uploadProgress: Progress;
  processingCompleteAction: boolean;
  processingCancelAction: boolean;
  disableCompleteUpload: boolean;
  disableCancelUpload: boolean;
}

interface Error {
  text: string;
  subText: string;
}

const LeadListUpload = () => {
  const { pushNotification } = useNotification();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const [uploadResults, setUploadResults] = useState<LeadListUploadResponse | undefined>(undefined);
  const [importData, setImportData] = useState<ListImportItem[]>([]);
  const [uploadError, setUploadError] = useState<Error | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [actions, setActions] = useState<Actions>({
    uploadProgress: {
      isUploading: false,
      uploadProgress: 0,
      isProcessing: false,
    },
    processingCompleteAction: false,
    processingCancelAction: false,
    disableCompleteUpload: false,
    disableCancelUpload: false,
  });
  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm<Form>({
    defaultValues: {
      file: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const handleProgress = (progress: number) => {
    if (progress < 100) {
      setActions((prev) => ({
        ...prev,
        uploadProgress: { ...prev.uploadProgress, isUploading: true, uploadProgress: progress },
      }));
      return;
    }

    setActions((prev) => ({
      ...prev,
      uploadProgress: { ...prev.uploadProgress, isUploading: false, uploadProgress: 0, isProcessing: true },
    }));
  };

  const uploadFile = handleSubmit(async (data: Form) => {
    setSubmitting(true);
    // File should not be null at this point so we cast to to File type
    const file = data.file as File;
    let validationResults;

    setActions((prev) => ({
      ...prev,
      disableCompleteUpload: false,
      disableCancelUpload: false,
      uploadProgress: { ...prev.uploadProgress, isUploading: true },
    }));

    setUploadError(undefined);
    setUploadResults(undefined);

    try {
      validationResults = await uploadLeadsListFileForList(+campaignId, +listId, file!, handleProgress);
    } catch (e) {
      if (e instanceof APIError) {
        setUploadError({ text: 'Unable to request data from backend', subText: e.message });
      }

      if (e instanceof UnsupportedStructureError) {
        setUploadError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
      }
      return;
    } finally {
      setActions((prev) => ({
        ...prev,
        uploadProgress: { ...prev.uploadProgress, isUploading: false, uploadProgress: 0, isProcessing: false },
      }));
      setSubmitting(false);
    }

    setUploadResults(validationResults);
  });

  const applyListUpload = async () => {
    if (uploadResults === undefined) {
      console.error('! applyListUpload: uploadResults does not exist.');
      return;
    }

    setActions((prev) => ({
      ...prev,
      disableCancelUpload: true,
      disableCompleteUpload: true,
      processingCompleteAction: true,
    }));

    try {
      await applyImportList(+campaignId, +listId, uploadResults.importId);
    } catch (e) {
      setActions((prev) => ({ ...prev, disableCancelUpload: false, disableCompleteUpload: false }));
      pushNotification('error', 'Unable to completed list upload.');
      return;
    } finally {
      setActions((prev) => ({ ...prev, processingCompleteAction: false }));
    }

    pushNotification('success', 'Successfully completed list upload.');
  };

  const cancelListUpload = async () => {
    if (uploadResults === undefined) {
      console.error('! cancelListUpload: uploadResults does not exist.');
      return;
    }

    setActions((prev) => ({
      ...prev,
      disableCancelUpload: true,
      disableCompleteUpload: true,
      processingCancelAction: true,
    }));

    try {
      await discardImportList(+campaignId, +listId, uploadResults.importId);
    } catch (e) {
      setActions((prev) => ({ ...prev, disableCancelUpload: false, disableCompleteUpload: false }));
      pushNotification('error', 'Unable to discard list upload.');
      return;
    } finally {
      setActions((prev) => ({ ...prev, processingCancelAction: false }));
    }

    pushNotification('success', 'Successfully discarded list upload.');
  };

  useEffect(() => {
    (async () => {
      // Note: we dont care about loading view states here as this data isnt technically
      // necessary to complete the file upload. In the future this will be handled on an import page level and will have loading
      if (uploadResults !== undefined) {
        try {
          setImportData(await getListImportDataById(+campaignId, +listId, uploadResults.importId));
        } catch (e) {
          // Note: No-op for now. In the future this will be handled on an import page level etc
        }
      }
    })();
  }, [uploadResults]);

  const errorResultDisplay = importData
    .filter((item: ListImportItem) => item.importError || item.importWarning || item.parseError)
    .map((item: ListImportItem, index: number) => {
      const id = `${index}-${item.importRecordNumber}`;
      // Should always exist if we fall into here
      const content = item.originalData as string;
      let error = '';

      if (item.parseError) {
        error = item.parseError;
      } else if (item.importError) {
        error = item.importError;
      } else {
        error = item.importWarning as string;
      }

      return (
        <OberonCard
          key={id}
          reverseTitles
          titleFontWeight={700}
          title={error}
          subHeader={`Row ${item.importRecordNumber}`}
          footer={
            content && (
              <Typography variant='body1' color='textPrimary'>
                {content}
              </Typography>
            )
          }
        />
      );
    });

  return (
    <form noValidate onSubmit={uploadFile}>
      <Grid container spacing={1} justifyContent='center' alignContent='center'>
        <Grid item xs={12} md={6}>
          <ControlledFileUpload
            control={control}
            rules={{ required: 'File is required.' }}
            name='file'
            label='File'
            disabled={submitting}
            required={true}
            error={Boolean(errors.file)}
            helperText={errors.file?.message}
          />
        </Grid>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={6}>
          <Button
            fullWidth
            type='submit'
            variant='contained'
            disableElevation
            startIcon={<CloudUploadIcon />}
            disabled={!isValid || actions.uploadProgress.isProcessing || actions.uploadProgress.isUploading}
            color='primary'>
            Upload List
          </Button>
        </Grid>

        {uploadError && <EmptyState type='error' text={uploadError.text} subText={uploadError.subText} />}

        {!uploadError && (
          <>
            {actions.uploadProgress.isUploading && (
              <Grid style={{ marginTop: 40, textAlign: 'center' }} item xs={12}>
                <CircularProgressWithLabel value={actions.uploadProgress.uploadProgress} />
                <Typography variant='body1' component='p' color='textSecondary'>
                  Uploading List
                </Typography>
              </Grid>
            )}

            {actions.uploadProgress.isProcessing && (
              <Grid style={{ marginTop: 40, textAlign: 'center' }} item xs={12}>
                <CircularProgress />
                <Typography variant='body1' component='p' color='textSecondary'>
                  Processing List
                </Typography>
              </Grid>
            )}

            {!actions.uploadProgress.isProcessing && !actions.uploadProgress.isUploading && !uploadResults && (
              <Grid style={{ marginTop: 40 }} item xs={12}>
                <EmptyState type='no-items-2' />
              </Grid>
            )}

            {!actions.uploadProgress.isProcessing && !actions.uploadProgress.isUploading && uploadResults && (
              <>
                <Grid style={{ marginTop: 16 }} item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <InfoCard
                        variant='success'
                        icon={CheckCircleIcon}
                        text={uploadResults.validCount}
                        subText='Valid Rows'
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InfoCard
                        variant='warning'
                        icon={ErrorIcon}
                        text={uploadResults.importWarningCount}
                        subText='Rows with Import Warnings'
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InfoCard
                        variant='danger'
                        icon={CancelIcon}
                        text={uploadResults.importErrorCount}
                        subText='Rows with Import Errors'
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InfoCard
                        variant='danger'
                        icon={CancelIcon}
                        text={uploadResults.parseErrorCount}
                        subText='Rows with Parsing Errors'
                      />
                    </Grid>
                  </Grid>

                  <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                    <LoadingButton
                      sx={{ marginBottom: 1 }}
                      variant='contained'
                      fullWidth
                      disableElevation
                      color='success'
                      loading={actions.processingCompleteAction}
                      disabled={actions.processingCompleteAction || actions.disableCompleteUpload}
                      onClick={applyListUpload}>
                      Complete List Upload
                    </LoadingButton>

                    <LoadingButton
                      variant='contained'
                      fullWidth
                      disableElevation
                      color='error'
                      loading={actions.processingCancelAction}
                      disabled={actions.processingCancelAction || actions.disableCancelUpload}
                      onClick={cancelListUpload}>
                      Cancel List Upload
                    </LoadingButton>
                  </Box>

                  {errorResultDisplay.length > 0 && (
                    <Grid style={{ marginTop: 16 }} item xs={12}>
                      <Typography variant='h4' component='h1' gutterBottom>
                        Errors
                      </Typography>

                      <Typography variant='body1' component='p' paragraph>
                        The following errors have been found with the uploaded file.
                      </Typography>

                      {errorResultDisplay}
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </form>
  );
};

export default LeadListUpload;
