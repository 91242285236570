import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/styled-engine';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React from 'react';

const bounceAndColorShift = keyframes({
  '0%': {
    transform: 'initial',
    backgroundColor: '#292929',
  },
  '60%': {
    transform: 'initial',
    backgroundColor: '#292929',
  },
  '100%': {
    transform: 'initial',
    backgroundColor: '#292929',
  },
  '30%': {
    transform: 'translateY(-15px)',
    backgroundColor: '#e6e6e6',
  },
});

const colorShift = keyframes({
  '0%': {
    transform: 'initial',
    backgroundColor: '#292929',
  },
  '60%': {
    transform: 'initial',
    backgroundColor: '#292929',
  },
  '100%': {
    transform: 'initial',
    backgroundColor: '#292929',
  },
  '30%': {
    backgroundColor: '#e6e6e6',
  },
});

type BlinkingDotProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    bounce: boolean;
    animationDelay?: number;
    size: number;
  };

const BlinkingDot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bounce' && prop !== 'animationDelay' && prop !== 'size',
})<BlinkingDotProps>(({ size, bounce, animationDelay }) => ({
  width: size || 10,
  height: size || 10,
  borderRadius: 50,
  display: 'inline-block',
  margin: '0 1px',
  animation: `${bounce === true ? bounceAndColorShift : colorShift} 1.3s linear infinite`,
  animationDelay: `${animationDelay || 0}s`,
}));

interface DotLoaderProps {
  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  size?: number;
  bounce?: boolean;
}

export const DotLoader = ({ align = 'start', size = 10, bounce = false }: DotLoaderProps) => {
  return (
    <div style={{ textAlign: align }}>
      <BlinkingDot size={size} bounce={bounce} />
      <BlinkingDot size={size} bounce={bounce} animationDelay={-1.1} />
      <BlinkingDot size={size} bounce={bounce} animationDelay={-0.9} />
    </div>
  );
};
