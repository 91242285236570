import * as j from '@mojotech/json-type-validation';

import { CampaignListItem } from '~pages/CampaignManagement/domain';

export interface CampaignResponse {
  nextPageUrl: string | null;
  campaigns: CampaignListItem[];
}

const CampaignListItemResponseDecoder: j.Decoder<CampaignListItem> = j
  .object({
    id: j.number(),
    name: j.string(),
    num_lists: j.number(),
    is_active: j.boolean(),
    archived: j.union(j.string(), j.constant(null)),
  })
  .map((item: any) => ({
    id: item.id,
    name: item.name,
    numLists: item.num_lists,
    isActive: item.is_active,
    archived: item.archived,
  }));

export const CampaignsResponseDecoder: j.Decoder<CampaignResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    campaigns: j.array(CampaignListItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    campaigns: item.campaigns,
  }));
