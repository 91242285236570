import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

import { ChannelType } from '~services/AsyncStatsManager/domain';

export const channelIdToName: { [key in ChannelType]: string } = {
  [ChannelType.WebChat]: 'Webchat',
  [ChannelType.SMS]: 'SMS',
  [ChannelType.Email]: 'Email',
  [ChannelType.AndroidRCS]: 'Android RCS',
  [ChannelType.AppleBusinessChat]: 'Apple Business Chat',
};

export const channelIdToColor: { [key in ChannelType]: string } = {
  [ChannelType.WebChat]: '#2196f3',
  [ChannelType.SMS]: '#78909c',
  [ChannelType.Email]: '#ef5350',
  [ChannelType.AndroidRCS]: '#43a047',
  [ChannelType.AppleBusinessChat]: '#7d7d7d',
};

export const channelIdToIcon: { [key in ChannelType]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> } = {
  [ChannelType.WebChat]: ChatIcon,
  [ChannelType.SMS]: SmsIcon,
  [ChannelType.Email]: EmailIcon,
  [ChannelType.AndroidRCS]: AndroidIcon,
  [ChannelType.AppleBusinessChat]: AppleIcon,
};
