import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import React from 'react';

import { Lead } from '~hooks/useLeadList/domain';
import { leadStatusColor } from '~pages/CampaignManagement/domain';

type Props = {
  lead: Lead;
  onClear?: () => void;
};

export const SelectedLead = ({ lead, onClear }: Props) => {
  const baseColor = leadStatusColor[lead.leadStatus]?.baseColor || grey['600'];

  return (
    <Box
      sx={{
        width: '100%',
        border: `1px solid rgba(0, 0, 0, 0.12)`,
        borderRadius: 2,
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Box>
        {lead.name}

        <br />

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
          <Chip
            sx={{
              marginTop: 0.5,
              textTransform: 'uppercase',
              fontSize: 10,
              borderRadius: 1,
              height: 20,
              lineHeight: '21px',
              color: '#ffffff',
              fontWeight: 700,
              backgroundColor: baseColor,
            }}
            label={lead.leadStatus}
          />

          <Chip
            sx={{
              marginTop: 0.5,
              textTransform: 'uppercase',
              fontSize: 10,
              borderRadius: 1,
              height: 20,
              lineHeight: '21px',
              color: '#ffffff',
              fontWeight: 700,
            }}
            color='primary'
            label={`${lead.campaignName} - ${lead.listName}`}
          />
        </Box>
      </Box>
      {onClear && <Button onClick={onClear}>Clear</Button>}
    </Box>
  );
};

export default SelectedLead;
