import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React from 'react';

type ContentSpacerProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    spacing: number;
  };

const ContentSpacer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<ContentSpacerProps>(({ theme, spacing }) => ({
  'marginBottom': theme.spacing(spacing),
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export default ContentSpacer;
