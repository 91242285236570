import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AgentsResponse, GetAgentsResponseDecoder, UpdateAgentGroupAssignment } from './domain';

export const getGroupAssignedAgentsByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<AgentsResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getGroupAssignedAgents = async (
  diallerGroupId: number,
  search: string,
  cancelToken?: CancelTokenSource,
): Promise<AgentsResponse | undefined> => {
  const path = `/api/group/${diallerGroupId}/agents`;
  const params = {
    search: search.toLowerCase() || undefined,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const addAgentsToGroup = async (diallerGroupId: number, data: UpdateAgentGroupAssignment): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/agents`;

  let body: any = {
    agents: data.agents,
    routing_profile: data.routingProfile,
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const removeAgentFromGroupByUsername = async (diallerGroupId: number, username: string): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/agents/${username}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
