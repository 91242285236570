import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';

import { enableEndpointSequence, getEndpointSequence, putEndpointSequence } from '~pages/CampaignManagement/api';
import { EndpointSequence } from '~pages/CampaignManagement/domain';
import { useNotification } from '~providers/NotificationProvider';

import EditEndpointModal from './EditEndpointModal';
import EndpointTypeCard from './EndpointTypeCard';

interface EndpointOrderProps {
  campaignId: number;
}

const EndpointOrder = ({ campaignId }: EndpointOrderProps) => {
  const { pushNotification } = useNotification();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [orderSpecified, setOrderSpecified] = useState<boolean>(false);
  const [endpointSeq, setEndpointSeq] = useState<EndpointSequence[]>([]);
  const [editing, setEditing] = useState<EndpointSequence | null>(null);

  useEffect(() => {
    if (loaded) {
      return;
    }
    (async () => {
      var esr;
      try {
        esr = await getEndpointSequence(campaignId);
      } catch (error) {
        console.warn(error);
        pushNotification('error', 'Could not load endpoint sequence');
        return;
      }

      const endpointSequence = esr.endpointSequence.slice();
      const present = Object.fromEntries(endpointSequence.map((seq) => [seq.endpointType, true]));
      esr.endpointTypes.forEach((et) => {
        if (!present[et]) {
          endpointSequence.push({
            endpointType: et,
            start: 0,
            priority: 5,
          });
        }
      });

      endpointSequence.sort((x, y: EndpointSequence) => {
        if (x.start != y.start) {
          return x.start - y.start;
        }
        if (x.priority != y.priority) {
          return x.priority - y.priority;
        }
        if (x.endpointType < y.endpointType) {
          return -1;
        }
        if (x.endpointType > y.endpointType) {
          return 1;
        }
        return 0;
      });

      setOrderSpecified(esr.enabled);
      setEndpointSeq(endpointSequence);
      setLoaded(true);
    })();
  }, [loaded]);

  const unconfigureEndpointSequence = useCallback(async () => {
    setSubmitting(true);
    try {
      await enableEndpointSequence(campaignId, !orderSpecified);
    } catch (error) {
      pushNotification('error', 'Could not configure endpoint sequence');
    }
    setSubmitting(false);
    setLoaded(false);
  }, [orderSpecified, endpointSeq]);

  const submit = async (updated: EndpointSequence) => {
    setSubmitting(true);
    const newSequences = endpointSeq.map((seq) => {
      if (seq.endpointType == updated.endpointType) {
        return updated;
      }
      return seq;
    });
    try {
      await putEndpointSequence(campaignId, newSequences);
    } catch (error) {
      pushNotification('error', 'Could not save endpoint sequence');
    }
    setEditing(null);
    setSubmitting(false);
    setLoaded(false);
  };

  return (
    <>
      <Grid container spacing={2} alignContent='center'>
        <Grid item xs={12}>
          <Typography component='span' variant='body1' color='textPrimary'>
            Use custom endpoint sequence
          </Typography>
          <Switch
            checked={orderSpecified}
            disabled={!loaded || submitting}
            onClick={unconfigureEndpointSequence}
            name='orderSpecified'
          />
        </Grid>
        <Grid item xs={12}>
          {endpointSeq.map((seq, index) => (
            <EndpointTypeCard key={index} endpointSeq={seq} index={index} submitting={submitting} edit={setEditing} />
          ))}
        </Grid>
      </Grid>
      {editing !== null && (
        <EditEndpointModal
          submitting={submitting}
          editing={editing}
          onClose={() => setEditing(null)}
          onSubmit={submit}
        />
      )}
    </>
  );
};

export default EndpointOrder;
