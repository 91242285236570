import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createSipQueue, deleteSipQueue, getSipQueues, updateSipQueue } from '../api';
import { NewSipQueue, SipQueue } from '../domain';
import MessageCard from './MessageCard';
import QueueModal from './QueueModal';

interface Error {
  text: string;
  subText: string;
}

interface Query {
  search: string;
}

const SipQueuesList = () => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editingQueue, setEditingQueue] = useState<SipQueue | null>(null);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [queues, setQueues] = useState<SipQueue[]>([]);
  const [query, setQuery] = useState<Query>({ search: '' });

  const load = async () => {
    try {
      setQueues(await getSipQueues(query.search));
    } catch (e) {
      handleError(e);
    } finally {
      setPageLoaded(true);
    }
  };

  useEffect(() => {
    load();
  }, [query]);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const closeModal = () => {
    setCreateModalOpen(false);
    setEditingQueue(null);
  };

  const updateQueue = useCallback(
    async (data: NewSipQueue) => {
      setSubmittingData(true);
      try {
        if (editingQueue === null) {
          await createSipQueue(data);
        } else {
          await updateSipQueue({ queueID: editingQueue.queueID, ...data });
        }
        await load();
      } catch (e) {
        handleError(e);
      } finally {
        setSubmittingData(false);
        closeModal();
      }
    },
    [editingQueue],
  );

  const deleteQueue = async (queueID: number) => {
    await deleteSipQueue(queueID);
    await load();
  };

  const setSearch = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const filteredQueues = queues;

  const noSearchOrFilterResults = query.search && filteredQueues.length === 0;
  const noResults = queues.length === 0;
  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noResults && (
        <EmptyState
          type='no-items-3'
          text='No SIP Queues currently exist'
          subText='Create a SIP Queue by clicking the button below'
          action={() => setCreateModalOpen(true)}
          actionText='Create SIP Queue'
        />
      )}

      {!noResults && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={setSearch}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => setCreateModalOpen(true)}>
              Create Queue
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!noSearchOrFilterResults && (
              <List>
                {filteredQueues.map((queue) => (
                  <MessageCard
                    key={queue.queueID}
                    queue={queue}
                    onEdit={() => setEditingQueue(queue)}
                    onDelete={() => deleteQueue(queue.queueID)}
                  />
                ))}
              </List>
            )}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No queues found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}
      <QueueModal
        open={createModalOpen || Boolean(editingQueue)}
        submitting={submittingData}
        sipQueue={editingQueue}
        onClose={closeModal}
        onAccept={updateQueue}
      />
    </AsyncLoader>
  );
};

export default SipQueuesList;
