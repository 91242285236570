import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import EmptyState from '../../components/EmptyState';
import { AppConfiguration, getAppConfiguration } from './api';

interface AppConfigurationProviderProps {
  children: ReactNode;
}

const AppConfigurationContext = createContext<AppConfiguration | undefined>(undefined);

export const useAppConfiguration = (): AppConfiguration => {
  return useContext(AppConfigurationContext) as AppConfiguration;
};

const AppConfigurationProvider = ({ children }: AppConfigurationProviderProps) => {
  const [context, setContext] = useState<AppConfiguration | undefined>(undefined);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        setContext(await getAppConfiguration());
      } catch (e) {
        console.error(e);
        setError('Error fetching application configuration');
      }
    })();
  }, []);

  if (error) {
    return (
      <EmptyState
        fullHeight
        type='error'
        text='An Error occurred while fetching application configuration'
        subText={error}
      />
    );
  }

  if (context === undefined) return null;

  return <AppConfigurationContext.Provider value={context}>{children}</AppConfigurationContext.Provider>;
};

export default AppConfigurationProvider;
