import { DateTime } from 'luxon';
import React from 'react';

import { BasicLineChart, BasicLineChartData } from '~components/BasicLineChart';
import SectionCard from '~components/SectionCard';
import { ContactMetricItem } from '~pages/ContactLog/domain';
import { calculatePercentage, findIndexByProperty } from '~utils/Functions';

enum StreamType {
  AudioAgentToDialler = 'audio-agent-to-dialler',
  AudioDiallerToAgent = 'audio-dialler-to-agent',
}

interface ContactCallMetricsProps {
  metrics: ContactMetricItem[];
  canView: boolean;
}

const getChartXAxis = (metrics: ContactMetricItem[]): string[] => {
  const arr: string[] = [];

  for (let i = 0; i < metrics.length; i++) {
    arr.push(DateTime.fromISO(metrics[i].timestamp).toFormat('F'));
  }

  return [...new Set(arr)];
};

const getBasicChartData = (
  metrics: ContactMetricItem[],
  xKey: keyof ContactMetricItem,
  yKey: keyof ContactMetricItem,
): BasicLineChartData[] => {
  let data: BasicLineChartData[] = [
    {
      key: 'Agent -> Dialler',
      lineItems: [],
    },
    {
      key: 'Dialler -> Agent',
      lineItems: [],
    },
  ];

  for (let i = 0; i < metrics.length; i++) {
    let streamKey = '';

    if (metrics[i].streamType === StreamType.AudioAgentToDialler) {
      streamKey = 'Agent -> Dialler';
    }

    if (metrics[i].streamType === StreamType.AudioDiallerToAgent) {
      streamKey = 'Dialler -> Agent';
    }

    const keyIndex = findIndexByProperty(data, 'key', streamKey);

    if (keyIndex !== -1) {
      data[keyIndex] = {
        ...data[keyIndex],
        lineItems: [
          ...data[keyIndex].lineItems,
          {
            x: DateTime.fromISO(metrics[i][xKey] as string).toFormat('F'),
            y: metrics[i][yKey] as number,
          },
        ],
      };
    }
  }

  return data;
};

const getPacketsLostChartData = (metrics: ContactMetricItem[]): BasicLineChartData[] => {
  let data: BasicLineChartData[] = [
    {
      key: 'Agent -> Dialler',
      lineItems: [],
    },
    {
      key: 'Dialler -> Agent',
      lineItems: [],
    },
  ];

  for (let i = 0; i < metrics.length; i++) {
    let streamKey = '';

    if (metrics[i].streamType === StreamType.AudioAgentToDialler) {
      streamKey = 'Agent -> Dialler';
    }

    if (metrics[i].streamType === StreamType.AudioDiallerToAgent) {
      streamKey = 'Dialler -> Agent';
    }

    const keyIndex = findIndexByProperty(data, 'key', streamKey);

    if (keyIndex !== -1) {
      data[keyIndex] = {
        ...data[keyIndex],
        lineItems: [
          ...data[keyIndex].lineItems,
          {
            x: DateTime.fromISO(metrics[i].timestamp).toFormat('F'),
            y: calculatePercentage(metrics[i].packetsLost, metrics[i].packetsCount),
          },
        ],
      };
    }
  }

  return data;
};

const ContactCallMetrics = ({ metrics, canView }: ContactCallMetricsProps) => {
  // We want to skip fast, do none of the below logic, and not display anything
  if (!canView) return null;

  const xAxis = getChartXAxis(metrics);
  const packetsLostChartData = getPacketsLostChartData(metrics);
  const roundTripChartData = getBasicChartData(metrics, 'timestamp', 'roundTripTimeMillis');
  const jitterBufferChartData = getBasicChartData(metrics, 'timestamp', 'jitterBufferMillis');

  return (
    <>
      <SectionCard title='Packets Lost'>
        <BasicLineChart
          xAxis={xAxis}
          data={packetsLostChartData}
          yAxisSuffix='%'
          legend={true}
          tooltipValueFormat={(value, suffix) => {
            return `${Number(value).toFixed(2)}${suffix}`;
          }}
        />
      </SectionCard>

      <SectionCard title='Round Trip'>
        <BasicLineChart xAxis={xAxis} data={roundTripChartData} legend={true} yAxisSuffix='ms' />
      </SectionCard>

      <SectionCard title='Jitter Buffer'>
        <BasicLineChart xAxis={xAxis} data={jitterBufferChartData} legend={true} yAxisSuffix='ms' />
      </SectionCard>
    </>
  );
};

export default ContactCallMetrics;
