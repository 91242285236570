import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { isEmpty, parseBoolean, removePropertyFromObject } from '../../utils/Functions';

type ValidatorObject = ValidatorBasic | ValidatorRegex | ValidatorIf | ValidatorBetween | ValidatorCustom;

export enum ValidatorType {
  Required = 'required',
  Email = 'email',
  Regex = 'regex',
  ErrorIfSetWith = 'error-if-set-with',
  ValidIfSetWith = 'valid-if-set-with',
  OptionalRequireIf = 'optional-require-if',
  Custom = 'custom',
  Between = 'between',
}

export interface Error {
  [key: string]: string[];
}

export interface ValidatorBasic {
  type: ValidatorType;
  message: string | ((value: any | null) => string);
}

export interface ValidatorRegex extends ValidatorBasic {
  pattern: RegExp;
}

export interface ValidatorIf extends ValidatorBasic {
  fieldName: string;
}

export interface ValidatorBetween extends ValidatorBasic {
  min: number;
  max: number;
}

export interface ValidatorCustom extends ValidatorBasic {
  onValidation: (value: any | null) => boolean;
}

export interface SchemaItem {
  validators: ValidatorObject[];
  value: any;
}

export interface Schema {
  [key: string]: SchemaItem;
}

export interface FormItem {
  value: any;
  pristine: boolean;
}

export interface Form {
  [key: string]: FormItem;
}

export interface SubmitCallback {
  value: any;
}

export type TCallback<T> = (data: T) => void;

export interface UseForm {
  fields: Form;
  errors: Error;
  pristine: boolean;
  dirty: boolean;
  valid: boolean;
  handleSubmit: <T>(
    callback: TCallback<T>,
  ) => (e?: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => void;
  addSchemaProperties: (schema: Schema) => void;
  removeSchemaProperties: (keys: string[]) => void;
  addValidationTypeToField: (property: string, validation: ValidatorObject) => void;
  removeValidationTypeFromField: (property: string, type: ValidatorType) => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleUnconventionalInputChange: (fieldName: string, value: any) => Promise<void>;
  resetForm: () => void;
  setAsyncFields: (data: { [key: string]: any }) => void;
}

const regex = {
  email:
    /^[a-zA-Z0-9.!#$%&''*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

const generateFieldDefaults = (schema: Schema): Form => {
  return Object.keys(schema).reduce((state: any, key: string): Form => {
    state[key] = {
      value: schema[key].value,
      pristine: true,
    };

    return state;
  }, {});
};

const removePropertiesFromObject = (obj: any, keys: string[]): any => {
  return keys.reduce((state, key) => {
    return removePropertyFromObject(state, key);
  }, obj);
};

const removePropertyFromArray = <T extends Record<string, any>>(array: T[], property: string, value: string): any => {
  return array.filter((item: { [key: string]: any }) => item[property] !== value);
};

const isNumber = (value: string) => {
  return !Number.isNaN(Number(value));
};

// NOTE: submitting state should be handled by the handle submit callback function and should not be in here (as this is a generic hook).
// This is because the calling component can easily manage the state as the callback might make async api calls.
/** Deprecated - DO NOT USE AS WE ARE PHASING IT OUT */
const useForm = (schema: Schema): UseForm => {
  const [fieldsSchema, setFieldSchema] = useState<Schema>(schema);
  // Defines states
  const [fields, setFields] = useState<Form>(generateFieldDefaults(fieldsSchema));
  const [errors, setErrors] = useState<Error>({});
  const [pristine, setPristine] = useState<boolean>(true);
  const [dirty, setDirty] = useState<boolean>(false);
  // dynamically set at the end of this function
  const [valid, setValid] = useState<boolean>(false);

  // Validates field data
  const isValid = (value: string | boolean | number | Array<any>, validator: ValidatorObject): boolean => {
    const { type }: ValidatorObject = validator;
    const { pattern } = validator as ValidatorRegex;
    const { fieldName } = validator as ValidatorIf;
    const { min, max } = validator as ValidatorBetween;
    const { onValidation } = validator as ValidatorCustom;

    if (type === ValidatorType.Required) {
      // Validates if array is greater than length 0
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }

      // Validates if value is null, undefined, empty string
      if (value === null || value === undefined || value === '') {
        return false;
      }

      return true;
    }

    if (type === ValidatorType.Email) {
      return regex.email.test(value.toString());
    }

    if (type === ValidatorType.Regex) {
      // We only want to validate regex if a value is populated as we dont what this regex logic conflicting
      // with fields that are optional and not also conditionally marked as required
      if (value !== null && value !== undefined && value !== '') {
        return pattern.test(value.toString());
      }

      return true;
    }

    if (type === ValidatorType.ErrorIfSetWith) {
      const field = fields[fieldName];
      // Assume field being validated is valid if comparison field doesn't exist
      if (!field) return true;
      // If field is set at the same time as it's comparison field it's invalid
      if (field.value && value) return false;

      // If fields are not set at the same time this is valid
      if ((field.value && !value) || (!field.value && value)) return true;
    }

    if (type === ValidatorType.ValidIfSetWith) {
      const field = fields[fieldName];
      // Assume field being validated is valid if comparison field doesn't exist
      if (!field) return true;
      // if we have a value, we're good - assume the other fields will be invalid if they don't have a value.
      if (value) return true;
      // If both fields are not set, this is valid
      if (!value && (!field.value || (Array.isArray(field.value) && field.value.length === 0))) return true;
      // otherwise we fall through to invalid
    }

    if (type === ValidatorType.OptionalRequireIf) {
      const field = fields[fieldName];

      // Field being validated is required if comparison field does not exist or is not populated
      if (
        !field ||
        !(field.value != null && field.value !== '') ||
        (Array.isArray(field.value) && field.value.length !== 0)
      ) {
        // Validates if array is greater than length 0
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }

        // Validates if value is null, undefined, empty string
        if (value === null || value === undefined || value === '') {
          return false;
        }

        return true;
      }

      // Field being validated is optional and valid if comparison field is populated
      return true;
    }

    if (type === ValidatorType.Custom) {
      return onValidation(value);
    }

    if (type === ValidatorType.Between) {
      return value >= min && value <= max;
    }

    return false;
  };

  const validateFields = (overridePristineCheck: boolean = false): Error => {
    const errorsObj: Error = {};

    Object.keys(fieldsSchema).forEach((key) => {
      fieldsSchema[key].validators.forEach((validator) => {
        // We want to only generate error if field has been touched
        if (!fields[key].pristine || overridePristineCheck) {
          if (!isValid(fields[key].value, validator)) {
            if (!errorsObj[key]) {
              errorsObj[key] = [];
            }
            if (typeof validator.message === 'string') {
              errorsObj[key].push(validator.message);
            } else {
              errorsObj[key].push(validator.message(fields[key].value));
            }
          }
        }
      });
    });

    return errorsObj;
  };

  // Resets the form
  const resetForm = (): void => {
    const fieldState: Form = {};

    Object.keys(fieldsSchema).forEach((key: string) => {
      fieldState[key] = {
        value: fieldsSchema[key].value,
        pristine: true,
      };
    });

    setFields(fieldState);

    setPristine(true);
    setDirty(false);
    // dynamically set on useForm initialisation based on validations
    setValid(false);
  };

  const addSchemaProperties = (additionalSchemaFields: Schema): void => {
    setFieldSchema((prev) => ({ ...prev, ...additionalSchemaFields }));
    setFields((prev) => ({ ...prev, ...generateFieldDefaults(additionalSchemaFields) }));
  };

  const addValidationTypeToField = (property: string, validation: ValidatorObject): void => {
    setFieldSchema((prev) => ({
      ...prev,
      [property]: { ...prev[property], validators: [...prev[property].validators, validation] },
    }));
  };

  const removeValidationTypeFromField = (property: string, type: ValidatorType): void => {
    setFieldSchema((prev) => ({
      ...prev,
      [property]: { ...prev[property], validators: removePropertyFromArray(prev[property].validators, 'type', type) },
    }));
  };

  const removeSchemaProperties = (keys: string[]): void => {
    setFieldSchema((prev) => removePropertiesFromObject(prev, keys));
    setFields((prev) => removePropertiesFromObject(prev, keys));
  };

  // Updates existing form fields with asynchronous fetched data
  const setAsyncFields = (data: { [key: string]: any }): void => {
    const fieldState: Form = {};
    const dataKeys = Object.keys(data);

    Object.keys(fieldsSchema).forEach((key: string) => {
      if (dataKeys.includes(key)) {
        fieldState[key] = {
          value: data[key],
          pristine: true,
        };
      }
    });

    setFields((oldFieldState) => {
      return {
        ...oldFieldState,
        ...fieldState,
      };
    });
  };

  // Manages the form submition event
  // If for is invalid it blocks submition
  // If valid it triggers the custom callback function for form submition that it has been passed
  const handleSubmit =
    (callback: TCallback<any>) =>
    (e?: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void => {
      const fieldState: Form = {};

      // We want this to trigger only if this function is triggered by a form event
      if (e?.preventDefault) {
        e.preventDefault();
      }

      // Strip out unneeded form field data
      const data: SubmitCallback = Object.keys(fields).reduce((state: any, key: string) => {
        fieldState[key] = {
          value: fields[key].value,
          pristine: false,
        };

        state[key] = fields[key].value;
        return state;
      }, {});

      setFields(fieldState);

      // Double handling as setFields call above does trigger validateFields on change however we wont know if the form is valid or not
      // as its ivocation is asyncronous. Due to this we need to rerun validateFields here so that we can block form calback trigger if
      // any errors exist.
      if (!isEmpty(validateFields(true))) {
        return;
      }

      callback(data);
    };

  // Manages form field input changes as well as form state
  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (typeof e.persist == 'function') {
      e.persist();
    }

    let value: any;

    if (e.target.type === 'checkbox') {
      value = parseBoolean(e.target.checked);
    } else if (e.target.type === 'recaptcha') {
      value = e.target.value;
    } else if (e.target.type === 'file') {
      value = e.target.files;
      // Needed as an empty array is considered a number and triggers the number casting validation below i.e. [] = 0
    } else if (Array.isArray(e.target.value)) {
      value = e.target.value;
    }
    // Cast to type of number if not NaN
    else if (e.target.type === 'number' && isNumber(e.target.value)) {
      value = Number(e.target.value);
    } else {
      value = e.target.value;
    }

    setFields((fields) => ({
      ...fields,
      [e.target.name]: {
        value: value,
        pristine: false,
      },
    }));

    if (pristine) {
      setPristine(false);
      setDirty(true);
    }
  };

  // Handle input changes not passed via events (unconventional inputs)
  const handleUnconventionalInputChange = async (fieldName: string, value: any): Promise<void> => {
    setFields((fields) => ({
      ...fields,
      [fieldName]: {
        value: value,
        pristine: false,
      },
    }));

    if (pristine) {
      setPristine(false);
      setDirty(true);
    }
  };

  // Runs on fields object change validating inputs and generating error object as well as form valid state
  useEffect(() => {
    const schemaEmpty = isEmpty(fieldsSchema);
    const fieldsEmpty = isEmpty(fields);
    const lengthsMatch = Object.keys(fieldsSchema).length === Object.keys(fields).length;

    if (!schemaEmpty && !fieldsEmpty && lengthsMatch) {
      const errors = validateFields();
      setErrors(errors);
      isEmpty(errors) ? setValid(true) : setValid(false);
    }
  }, [fields]);

  return {
    resetForm,
    handleSubmit,
    addSchemaProperties,
    removeSchemaProperties,
    addValidationTypeToField,
    removeValidationTypeFromField,
    handleInputChange,
    handleUnconventionalInputChange,
    setAsyncFields,
    fields,
    pristine,
    dirty,
    valid,
    errors,
  };
};

export default useForm;
