import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React from 'react';

import OberonDialog from '~components/OberonDialog';
import { MessageTemplate } from '~pages/AsyncManagement/QueueDetails/MessageTemplates/useMessageTemplate/domain';

interface LeadDetailsModalProps {
  open: boolean;
  messageTemplate?: MessageTemplate;
  onClose: () => void;
}

const MessageTemplateDetailsModal = ({ open, messageTemplate, onClose }: LeadDetailsModalProps) => {
  const title = messageTemplate?.title || '';
  const category = messageTemplate?.category || '';
  const template = messageTemplate?.template || '';

  return (
    <OberonDialog
      open={open}
      closeOnBackdropClick
      onClose={onClose}
      title={title}
      subHeader={
        <Chip
          sx={{
            marginTop: 0.5,
            textTransform: 'uppercase',
            fontSize: 10,
            borderRadius: 1,
            height: 20,
            lineHeight: '21px',
            color: '#ffffff',
            fontWeight: 700,
          }}
          color='primary'
          label={category}
        />
      }
      content={
        <Box sx={{ backgroundColor: 'rgb(0 0 0 / 5%)', padding: 2, borderRadius: 2, whiteSpace: 'pre-wrap' }}>
          <code>{template}</code>
        </Box>
      }
    />
  );
};

export default MessageTemplateDetailsModal;
