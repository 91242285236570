import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledSwitch from '~components/Form/ControlledSwitch';
import ControlledTextField from '~components/Form/ControlledTextField';
import SectionCard from '~components/SectionCard';
import useSystemEndpointList, { DiallerType } from '~hooks/useSystemEndpointList';
import { Campaign, UpdateCampaignGeneralSettings } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';

interface GeneralSettingsFormProps {
  campaign: Campaign;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit?: () => void;
  onSubmit: (data: UpdateCampaignGeneralSettings) => Promise<void>;
}

interface Form {
  name: string;
  description: string;
  isPaused: boolean;
  defaultListId: number;
  trunk: string;
  sipSourcePhoneNumber: string;
}

const GeneralSettingsForm = ({ campaign, isEdit, submitting, toggleEdit, onSubmit }: GeneralSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultList = campaign.lists.find((list) => list.id === campaign.defaultListId);
  const appConfig = useAppConfiguration();
  const {
    list: endpoints,
    loading: fetchingEndpoints,
    intersectionObserverRef,
  } = useSystemEndpointList({
    shouldFetch: appConfig.extensions.predictive !== undefined,
    diallerType: DiallerType.SIP,
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      isPaused: false,
      defaultListId: 0,
      trunk: '',
      sipSourcePhoneNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    setValue('name', campaign.name);
    setValue('description', campaign.description);
    setValue('isPaused', !campaign.isActive);
    setValue('defaultListId', campaign.defaultListId);
    setValue('sipSourcePhoneNumber', campaign.sipSourcePhoneNumber);

    return () => {
      reset();
    };
  }, [isEdit]);

  const onSubmitFn = handleSubmit((data: Form) => {
    const submitData: UpdateCampaignGeneralSettings = {
      name: data.name,
      description: data.description || '',
      isActive: !data.isPaused,
      defaultListId: data.defaultListId,
      trunk: data.trunk,
      sipSourcePhoneNumber: data.sipSourcePhoneNumber,
    };

    onSubmit(submitData);
  });

  const leadListsDisplay = campaign.lists.map((item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  return (
    <SectionCard title='General' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem stacked={isExtraSmall} disableMargin title='Is paused' value={campaign.isActive ? 'No' : 'Yes'} />
          <DataItem stacked={isExtraSmall} title='Campaign name' value={campaign.name} />
          <DataItem stacked={isExtraSmall} title='Description' value={campaign.description || '-'} />
          <DataItem stacked={isExtraSmall} title='Default lead list' value={defaultList?.name || '-'} />
          {appConfig.extensions.predictive !== undefined && (
            <DataItem
              stacked={isExtraSmall}
              title='Campaign default trunk'
              tooltip='Which trunk should be used to place and receive SIP calls?'
              value={campaign.trunk || 'Default'}
            />
          )}
          {(endpoints.length > 0 || campaign.sipSourcePhoneNumber != '') && (
            <DataItem
              stacked={isExtraSmall}
              title='Outbound SIP CLI'
              value={campaign.sipSourcePhoneNumber || '-'}
              tooltip='The outbound CLI used for the campaign on the SIP dialler.'
            />
          )}
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledSwitch name='isPaused' label='Is paused?' control={control} disabled={submitting} />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Name is required.',
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Name'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Description'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='defaultListId'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    label='Default lead list'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.defaultListId)}
                    helperText={errors.defaultListId?.message}
                    {...field}>
                    {leadListsDisplay}
                  </TextField>
                )}
              />
            </Grid>

            {appConfig.extensions.predictive !== undefined && (
              <Grid item xs={12}>
                <ControlledTextField
                  name='trunk'
                  control={control}
                  label='Trunk'
                  disabled={submitting}
                  error={Boolean(errors.trunk)}
                  helperText={errors.trunk?.message}
                />
              </Grid>
            )}

            {(endpoints.length > 0 || campaign.sipSourcePhoneNumber) && (
              <Grid item xs={12}>
                <Controller
                  name='sipSourcePhoneNumber'
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      onChange={(e, data) => {
                        field.onChange(data);
                      }}
                      options={endpoints}
                      disabled={submitting || fetchingEndpoints}
                      disableClearable
                      getOptionLabel={(option) => option || ''}
                      renderOption={(props, option) => (
                        <li {...props} ref={intersectionObserverRef} key={option}>
                          <Typography variant='body1' color='textPrimary' component='p'>
                            {option}
                          </Typography>
                        </li>
                      )}
                      renderInput={({ inputProps, ...rest }) => (
                        <TextField
                          {...rest}
                          label='Outbound SIP CLI'
                          variant='outlined'
                          error={Boolean(errors.sipSourcePhoneNumber)}
                          helperText={errors.sipSourcePhoneNumber?.message}
                          inputProps={{ ...inputProps, readOnly: true }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default GeneralSettingsForm;
