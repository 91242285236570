export const isEmpty = (value: any): boolean => {
  return !(value && Object.keys(value).length > 0);
};

export const hasDuplicates = (array: Array<any>): boolean => {
  return [...new Set(array)].length !== array.length;
};

export const parseBoolean = (value: string | number | boolean): boolean => {
  const allowedValues = ['true', 'false', 1, 0, true, false];

  if (allowedValues.indexOf(value) === -1) {
    throw new Error(`Unable to parse value ${value} to type of boolean.`);
  }

  if (typeof value === 'number') {
    return value === 1;
  }

  if (typeof value === 'string') {
    return value === 'true';
  }

  return value;
};

export const findIndexByProperty = (array: Array<any>, property: string, value: any): number => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][property] === value) {
      return i;
    }
  }

  return -1;
};

export const pluraliseWord = (num: number, word: string): string => {
  return num > 1 ? `${word}s` : word;
};

export const getDurationFromSeconds = (seconds: number): string => {
  if (seconds <= 0 || !seconds) {
    return '00:00:00';
  }

  const duration = new Date(1000 * seconds).toISOString().substr(11, 8);
  const split = duration.split(':');

  if (split.length === 2) {
    return `00:${split[0]}:${split[1]}`;
  } else if (split.length === 3) {
    return `${split[0]}:${split[1]}:${split[2]}`;
  } else {
    return `00:00:${split[1]}`;
  }
};

export const removePropertyFromObject = <T extends Record<string, any>>(obj: T, property: string) => {
  return Object.keys(obj).reduce((accumulator: any, key: string) => {
    return key !== property ? { ...accumulator, [key]: obj[key] } : accumulator;
  }, {});
};

const getHashCode = (input: string | undefined | null): number => {
  if (!input) return 0;

  let hash = 0;

  for (let i = 0; i < input.length; i++) {
    hash += input.charCodeAt(i) * (i + 1);
  }

  return hash;
};

const convertToHSL = (input: number, lightness?: number | null, opacity?: number | null) =>
  `hsla(${input % 360}, 100%, ${lightness === undefined || lightness === null ? 30 : lightness}%, ${
    opacity === undefined || opacity === null ? 1 : opacity
  })`;

export const convertToColor = (input: string, lightness?: number | null, opacity?: number | null) =>
  convertToHSL(getHashCode(input), lightness, opacity);

export const toAvatarLetters = (input: string | null | undefined): string => {
  if (!input) {
    return '';
  }
  return input
    .trim()
    .split(/\s+/)
    .slice(0, 2)
    .map((word) => word.charAt(0))
    .join('')
    .toUpperCase();
};

/**
 * Returns true if s1 is strictly a subset of s2
 */
export const isSubSetOf = <T>(s1: Set<T>, s2: Set<T>): boolean => {
  if (s1.size > s2.size) {
    return false; // early bail out
  }

  for (let e of s1) {
    if (!s2.has(e)) {
      return false;
    }
  }

  return true;
};

export const calculatePercentage = (val1: number, val2: number): number => {
  if (val1 === 0 || val2 === 0) return 0;

  return (val1 / val2) * 100;
};

export const capitalizeString = (string: string) => {
  const trim = string.trim();
  let result = '';

  for (let i = 0; i < trim.length; i++) {
    if (i === 0 || trim[i - 1] === ' ' || trim[i - 1] === '_' || trim[i - 1] === '-') {
      result += trim[i].toUpperCase();
      continue;
    }

    result += trim[i];
  }

  return result;
};

export const assertUnreachable = (_x: never, message?: string): never => {
  throw new Error(message || "Didn't expect to get here");
};

export const deepEqual = (a: any, b: any) => {
  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (Object.keys(a).length != Object.keys(b).length) {
      return false;
    }

    for (var key in a) {
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  } else {
    return a === b;
  }
};

export const exponentialDelay = (retryCount: number, callback: () => void): number => {
  return window.setTimeout(() => {
    callback();
  }, Math.pow(2, retryCount) * 100);
};

export const isObject = (item: any) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

// Consider lodash if we need array merging at some point if we cannot write a decent working one ourselves
// Note: In JavaScript an array is also considered an object so this could break if we introduce arrays
// Note 2: In JavaScript a class is also considered an object so this could break if we introduce classes
export const mergeDeep = <T>(target: any, ...sources: any): T => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export const convertDictValuesToString = (dict: { [key: string]: any }): { [key: string]: string } => {
  const keys = Object.keys(dict);

  return (
    keys
      // Let's filter out any dodgy records that should never be there
      .filter(
        (key) =>
          !(!dict[key] || Array.isArray(dict[key]) || typeof dict[key] === 'object' || typeof dict[key] === 'function'),
      )
      // Let's rebuild the attributes object
      .reduce((state, currentKey) => {
        return {
          ...state,
          [currentKey]: dict[currentKey].toString(),
        };
      }, {})
  );
};

export const flattenObject = <T extends Record<string, any>>(obj: T): Record<string, string> => {
  const flattened: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

export const generatePathNoErrorThrow = (path: string, params: Record<string, string>): string => {
  return path
    .replace(/:(\w+)/g, (_, key) => {
      // rather than send null and undefined, lets send an empty string
      let value = params[key] ?? '';

      // If we get any encode error, assume invalid value and set to empty string
      try {
        value = encodeURIComponent(value);
      } catch (e) {
        value = '';
      }

      return value;
    })
    .replace(/\/*\*$/, (_) => (params['*'] == null ? '' : params['*'].replace(/^\/*/, '/')));
};

export const sortNumber = (sortOrder: 'asc' | 'desc') => (a: number, b: number) => {
  const safeA = a ?? (sortOrder === 'asc' ? Infinity : -Infinity);
  const safeB = b ?? (sortOrder === 'asc' ? Infinity : -Infinity);

  return sortOrder === 'asc' ? safeA - safeB : safeB - safeA;
};

export const sortAlphanumericString = (sortOrder: 'asc' | 'desc') => (a: string, b: string) => {
  const aString = a ?? '';
  const bString = b ?? '';

  return sortOrder === 'asc'
    ? aString.localeCompare(bString, undefined, { numeric: true, sensitivity: 'base' })
    : bString.localeCompare(aString, undefined, { numeric: true, sensitivity: 'base' });
};

export const sortObject =
  <T extends Record<string, any>>(sortBy: keyof T, sortOrder: 'asc' | 'desc') =>
  (a: T, b: T) => {
    const aVal = a[sortBy];
    const bVal = b[sortBy];

    if (aVal == null) return sortOrder === 'asc' ? -1 : 1;
    if (bVal == null) return sortOrder === 'asc' ? 1 : -1;

    const parsedAVal = Number(aVal);
    const parsedBVal = Number(bVal);

    if (!Number.isNaN(parsedAVal) && !Number.isNaN(parsedBVal)) {
      return sortNumber(sortOrder)(parsedAVal, parsedBVal);
    } else {
      return sortAlphanumericString(sortOrder)(aVal, bVal);
    }
  };
