import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  addDiallerGroupQueue,
  getDiallerGroupQueues,
  removeDiallerGroupQueue,
  updateDiallerGroupQueue,
} from '../../api';
import { DiallerGroupSipQueue } from '../../domain';
import AssignQueueModal from './AssignQueueModal';
import EditQueueModal from './EditQueueModal';
import QueueCard from './QueueCard';

interface Error {
  text: string;
  subText: string;
}

interface Query {
  search: string;
}

interface Props {
  diallerGroupId: string;
}

const DiallerGroupQueues = ({ diallerGroupId }: Props) => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editingQueue, setEditingQueue] = useState<DiallerGroupSipQueue | null>(null);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [groupQueues, setGroupQueues] = useState<DiallerGroupSipQueue[]>([]);
  const [query, setQuery] = useState<Query>({ search: '' });

  const load = async () => {
    try {
      setGroupQueues(await getDiallerGroupQueues(diallerGroupId, query.search));
    } catch (e) {
      handleError(e);
    } finally {
      setPageLoaded(true);
    }
  };

  useEffect(() => {
    load();
  }, [query]);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    } else if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    } else {
      console.log(e);
    }
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const onAddQueue = async (queue: DiallerGroupSipQueue) => {
    try {
      setSubmittingData(true);
      await addDiallerGroupQueue(diallerGroupId, queue);
    } catch (e) {
      handleError(e);
    } finally {
      setAddModalOpen(false);
      setSubmittingData(false);
      load();
    }
  };

  const onEditQueue = async (queue: DiallerGroupSipQueue) => {
    try {
      setSubmittingData(true);
      await updateDiallerGroupQueue(diallerGroupId, queue);
    } catch (e) {
      handleError(e);
    } finally {
      setEditingQueue(null);
      setSubmittingData(false);
      load();
    }
  };

  const onDelete = async (queue: DiallerGroupSipQueue) => {
    try {
      await removeDiallerGroupQueue(diallerGroupId, queue.queueID);
    } catch (e) {
      handleError(e);
    } finally {
      load();
    }
  };

  const queuesPresent = useMemo(() => {
    const present: { [key: string]: string } = {};
    groupQueues.forEach(({ queueID }: DiallerGroupSipQueue) => {
      present[`${queueID}`] = `${queueID}`;
    });
    return present;
  }, [groupQueues]);

  const filteredQueues = groupQueues;
  const noSearchOrFilterResults = filteredQueues.length === 0;
  const noResults = groupQueues.length === 0;
  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noResults && (
        <EmptyState
          type='no-items-3'
          text='No SIP Queues on this Dialler Group'
          subText='Add a SIP Queue by clicking the button below'
          action={() => setAddModalOpen(true)}
          actionText='Add SIP Queue'
        />
      )}

      {!noResults && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => setAddModalOpen(true)}>
              Assign Queue
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!noSearchOrFilterResults && (
              <List>
                {filteredQueues.map((queue) => (
                  <QueueCard
                    key={queue.queueID}
                    queue={queue}
                    onEdit={() => setEditingQueue(queue)}
                    onDelete={() => onDelete(queue)}
                  />
                ))}
              </List>
            )}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No queues found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <AssignQueueModal
        open={addModalOpen}
        diallerGroupId={diallerGroupId}
        submitting={submittingData}
        onAccept={onAddQueue}
        onClose={() => setAddModalOpen(false)}
        queuesPresent={queuesPresent}
      />
      <EditQueueModal
        open={editingQueue !== null}
        submitting={submittingData}
        queue={editingQueue}
        onAccept={onEditQueue}
        onClose={() => setEditingQueue(null)}
      />
    </AsyncLoader>
  );
};

export default DiallerGroupQueues;
