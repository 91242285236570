import { MoreHoriz } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { ForwardedRef, MouseEvent, forwardRef, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';
import { Transfer } from '~hooks/useTransfers/domain';

interface TransferCardProps {
  transfer: Transfer;
  onEdit: () => void;
  onRemove: () => void;
}

const TransferCard = forwardRef(
  ({ transfer, onEdit, onRemove }: TransferCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
    const [performingRemoveAction, setPerformingRemoveAction] = useState<boolean>(false);

    const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const triggerEdit = () => {
      setAnchorEl(null);
      onEdit();
    };

    const handleRemove = async () => {
      setPerformingRemoveAction(true);

      try {
        await onRemove();
      } catch (e) {
        return;
      } finally {
        setPerformingRemoveAction(false);
      }

      setRemoveConfirmOpen(false);
    };

    const handleDeleteConfirmationOpen = () => {
      setAnchorEl(null);
      setRemoveConfirmOpen(true);
    };

    const handleRemoveConfirmationClose = () => {
      setRemoveConfirmOpen(false);
    };

    return (
      <>
        <OberonCard
          ref={ref}
          titleFontWeight={700}
          title={transfer.name}
          subHeader={
            <>
              {transfer.endpoint}
              {transfer.description && (
                <Typography
                  sx={{ display: 'block' }}
                  fontSize={12}
                  fontWeight={400}
                  variant='body2'
                  component='span'
                  color='textPrimary'>
                  {transfer.description}
                </Typography>
              )}
            </>
          }
          action={
            <>
              <IconButton aria-controls='agent-menu' aria-haspopup='true' onClick={handleMenuOpen}>
                <MoreHoriz />
              </IconButton>

              <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={triggerEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDeleteConfirmationOpen}>Remove</MenuItem>
              </Menu>
            </>
          }
        />

        <ActionDialog
          open={removeConfirmOpen}
          title='Are you sure you want to do this?'
          content={`You are about to delete the transfer ${transfer.name}, once you have completed this action it cannot be undone.`}
          onClose={handleRemoveConfirmationClose}
          onAccept={handleRemove}
          loading={performingRemoveAction}
          primaryActionTitle='Remove'
        />
      </>
    );
  },
);

export default TransferCard;
