import grey from '@mui/material/colors/grey';
import lightBlue from '@mui/material/colors/lightBlue';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import Box from '@mui/system/Box';
import { DateTime } from 'luxon';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

enum MessageRoleType {
  Agent = 'AGENT',
  System = 'SYSTEM',
  Customer = 'CUSTOMER',
}

interface MessageItemProps {
  role: MessageRoleType;
  name: string;
  date: string;
  // isPreview was introduced to display <MessageItem/> components in canned
  // responses modal
  isPreview?: boolean;
  children: string | React.ReactNode;
}

const formatSystemName = (name: string) => {
  const stringReplaced = name.replace(/_/g, ' ');

  return Object.keys(stringReplaced).reduce((accumulator: string, currentVal: string) => {
    const index = Number(currentVal);

    accumulator +=
      index === 0 || stringReplaced[index - 1] === ' '
        ? stringReplaced[index].toUpperCase()
        : stringReplaced[index].toLowerCase();

    return accumulator;
  }, '');
};

type BubbleContainerProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    isInternal: boolean;
  };

const BubbleContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'isInternal' })<BubbleContainerProps>(
  ({ isInternal }) => ({
    'padding': 16,
    'borderRadius': 5,
    '&:last-of-type': {
      marginBottom: 0,
    },
    'color': grey[900],
    'backgroundColor': isInternal ? lightBlue[200] : grey[300],
  }),
);

const BubbleMessage = styled('div')({
  'fontSize': 14,
  'fontWeight': 400,
  'margin': 0,
  'wordBreak': 'break-word',
  'whiteSpace': 'pre-wrap',
  // NOTE(Jae): 2020-11-17
  // Added explicit styling to HTML content underneath so it's
  // consistent across chat window and canned responses preview
  '& ul': {
    listStyle: 'disc',
    margin: 0,
    marginTop: 8,
    padding: 0,
    paddingLeft: 16,
  },
  '& ul li': {
    margin: 0,
    marginTop: 2,
  },
});

const MessageItem = ({ role, name, date, isPreview, children }: MessageItemProps) => {
  let displayName = role === MessageRoleType.System ? formatSystemName(name) : name;

  const onPreviewClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // NOTE(Jae): 2020-11-17
    // This logic prevents a user from accidental clicking the <a> tag in the canned responses
    // modal
    if (e.target && e.target instanceof HTMLAnchorElement) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // Compute a pretty date
  //
  // ie. "Today at 14:49", "Yesterday at 10:22" or "7 days ago at 13:54"
  let prettyDate = '';
  let actualDate = '';
  if (date) {
    const dateTime = DateTime.fromISO(date);
    let relativeDay = dateTime.toRelativeCalendar();
    if (relativeDay && relativeDay.length > 0) {
      relativeDay = relativeDay.charAt(0).toUpperCase() + relativeDay.slice(1); // change 'today' to 'Today', etc.
    }
    prettyDate = relativeDay + ' at ' + dateTime.toFormat('t');
    // actualDate is the current date in a human-readable format
    // we use this so an agent can hover over the date with their mouse and see
    // the precise date.
    //
    // ie. 20 November 2020, 14:53 GMT+11
    //
    // note(jae): 2021-05-21
    // Datetime.DATETIME_FULL is valid in TS 3.8 but not in 4.2. For now lets
    // retain the same behaviour as that's been tested (even if its technically invalid)
    // This should be fixed next time this code is touched.
    actualDate = dateTime.toLocaleString(DateTime.DATETIME_FULL as any);
  }

  return (
    <Box
      sx={{ margin: isPreview === true ? undefined : '16px 0' }}
      onClick={isPreview === true ? onPreviewClick : undefined}>
      {(displayName || prettyDate) && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p
            style={{
              flex: '1 1 50%',
              fontSize: 14,
              fontWeight: 500,
              margin: '8px 0',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color: grey[900],
            }}>
            {displayName}
          </p>
          <p
            style={{ flex: 1, margin: '8px 0', color: grey[900], fontSize: 12, fontWeight: 500, textAlign: 'right' }}
            title={actualDate}>
            {prettyDate}
          </p>
        </Box>
      )}

      <BubbleContainer isInternal={role === MessageRoleType.Agent}>
        {role === MessageRoleType.Agent && (
          <BubbleMessage
            dangerouslySetInnerHTML={{
              __html:
                typeof children === 'string'
                  ? sanitizeHtml(children, {
                      allowedTags: ['a', 'strong', 'em', 'ul', 'ol', 'li'],
                      allowedAttributes: {
                        a: ['href', 'alt', 'target'],
                      },
                    })
                  : 'unexpected node used instead of plain text',
            }}
          />
        )}
        {role !== MessageRoleType.Agent && (
          // Don't parse HTML/format customers/system chat messages as they should
          // never be able to send HTML to an agent
          <BubbleMessage children={children} />
        )}
      </BubbleContainer>
    </Box>
  );
};

export default MessageItem;
