import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import UpdateAsyncSettingsModal from 'pages/SystemManagement/AgentList/UpdateAsyncSettingsModal';
import React, {
  ChangeEvent,
  ComponentProps,
  FocusEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { generatePath } from 'react-router-dom';

import ActionDialog from '~components/ActionDialog';
import AgentCard from '~components/AgentCard';
import AsyncLoader from '~components/AsyncLoader';
import ButtonMenu from '~components/ButtonMenu';
import CheckboxButton from '~components/CheckboxButton';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useAgentList from '~hooks/useAgentList';
import { ChangeGroup, UpdateAsyncSettings } from '~hooks/useAgentList/domain';
import useDebounce from '~hooks/useDebounce';
import useDiallerGroupSearch from '~pages/CampaignManagement/DiallerGroupList/useDiallerGroupSearch';
import { DiallerGroup } from '~pages/CampaignManagement/domain';
import UpdateGroupModal from '~pages/SystemManagement/AgentList/UpdateGroupModal';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createAgent, deleteAgentByUsername, updateAgentByUsername } from '../api';
import { AgentListItem, AgentUpdateRequest } from '../domain';
import CreateEditAgentModal from './CreateEditAgentModal';

interface Query {
  search: string;
  diallerGroup: DiallerGroup | null;
}

interface BulkManagement {
  agentsToInclude: AgentListItem[];
  agentsToExclude: AgentListItem[];
  diallerGroupId?: number;
  search?: string;
  totalAgents: number;
  selectAll: boolean;
}

const bulkManagementDefault: BulkManagement = Object.freeze({
  agentsToInclude: [],
  agentsToExclude: [],
  diallerGroupId: undefined,
  search: undefined,
  totalAgents: 0,
  selectAll: false,
});

const queryDefault: Query = Object.freeze({
  search: '',
  diallerGroup: null,
});

const calculateSelectionCount = (bulkManagement: BulkManagement) => {
  let total = bulkManagement.agentsToInclude.length;
  if (bulkManagement.selectAll) {
    total = bulkManagement.totalAgents + bulkManagement.agentsToInclude.length - bulkManagement.agentsToExclude.length;

    if (total < 0) {
      total = 0;
    }
  }

  return total;
};

// NOTE: This function needs to act the same as the backend DB query that is used to filter the list of agents that will
// be updated as a result of a bulk action.
const agentMatchesSearch = (search: string | undefined, agent: AgentListItem) => {
  // If no search term default true
  if (!search) return true;
  const searchArray = search.split(' ');
  const username = agent.username.toLowerCase();
  const fullname = agent.fullName.toLowerCase();

  for (let term of searchArray) {
    if (username.includes(term.toLowerCase())) {
      return true;
    }
    if (fullname.includes(term.toLowerCase())) {
      return true;
    }
  }

  return false;
};

const agentMatchesGroup = (groupId: number | undefined, agent: AgentListItem) => {
  return groupId ? agent.diallerGroupId === groupId : true;
};

const isAgentChecked = (bulkManagement: BulkManagement, agent: AgentListItem) => {
  let flag = false;
  const includeAgent = bulkManagement.agentsToInclude.find((a) => a.username === agent.username);
  const excludeAgent = bulkManagement.agentsToExclude.find((a) => a.username === agent.username);

  if (!bulkManagement.selectAll) {
    const includeAgent = bulkManagement.agentsToInclude.find((a) => a.username === agent.username);
    if (includeAgent) {
      flag = true;
    }
  } else {
    if (agentMatchesSearch(bulkManagement.search, agent) && agentMatchesGroup(bulkManagement.diallerGroupId, agent)) {
      if (!excludeAgent) {
        flag = true;
      }
    } else {
      if (includeAgent) {
        flag = true;
      }
    }
  }

  return flag;
};

const isSelectAllChecked = (bulkManagement: BulkManagement, totalAgents: number) => {
  let flag = false;
  const count = calculateSelectionCount(bulkManagement);

  if (count >= totalAgents) {
    flag = true;
  }

  return flag;
};

const isSelectAllIndeterminate = (bulkManagement: BulkManagement, totalAgents: number) => {
  let flag = false;
  const count = calculateSelectionCount(bulkManagement);

  if (bulkManagement.selectAll && count < totalAgents) {
    flag = true;
  } else if (!bulkManagement.selectAll && bulkManagement.agentsToInclude.length > 0 && count < totalAgents) {
    flag = true;
  }

  return flag;
};

const AgentList = () => {
  const { aws } = useAppConfiguration();
  const { accessFilter } = useUserPreferences();
  const { pushNotification } = useNotification();
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [bulkUpdateAsyncModalOpen, setBulkUpdateAsyncModalOpen] = useState<boolean>(false);
  const [bulkChangeGroupModalOpen, setBulkChangeGroupModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [editableAgentRef, setEditableAgentRef] = useState<string | undefined>(undefined);
  const [deletableAgentRef, setDeletableAgentRef] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState<Query>(queryDefault);
  const debouncedSearch = useDebounce(query.search, 500);
  const [searchGroup, setSearchGroup] = useState<string>('');
  const debouncedSearchGroup = useDebounce(searchGroup, 500);
  const [bulkManagement, setBulkManagement] = useState<BulkManagement>(bulkManagementDefault);
  const canBulkAction =
    bulkManagement.selectAll || bulkManagement.agentsToInclude.length > 0 || bulkManagement.agentsToExclude.length > 0;
  const {
    loading: groupFetching,
    list: groups,
    error: groupFetchError,
    intersectionObserverRef: lastGroupDataElement,
  } = useDiallerGroupSearch(debouncedSearchGroup, { accessFilterId: accessFilter?.id, archived: false });
  const {
    loading: agentFetching,
    agents,
    total: totalAgents,
    error: agentFetchError,
    hasMore: hasMoreAgents,
    reload: reloadAgentsList,
    intersectionObserverRef: lastAgentDataElement,
    bulkAsyncSettingsUpdate,
    bulkGroupChangeUpdate,
  } = useAgentList(debouncedSearch, {
    accessFilterId: accessFilter?.id,
    diallerGroupId: query.diallerGroup?.id || undefined,
    disabled: false,
  });
  const editableAgent = useMemo(() => {
    return agents.find((item) => item.username === editableAgentRef);
  }, [editableAgentRef]);
  const deletableAgent = useMemo(() => {
    return agents.find((item) => item.username === deletableAgentRef);
  }, [deletableAgentRef]);
  const noSearchOrFilterSet = query.search === '' && !query.diallerGroup;
  const selectionCount = useMemo(() => calculateSelectionCount(bulkManagement), [bulkManagement]);
  const selectAllChecked = isSelectAllChecked(bulkManagement, totalAgents);
  const selectAllIndeterminate = isSelectAllIndeterminate(bulkManagement, totalAgents);

  // Handle select all value reset on specific cases
  useEffect(() => {
    if (
      bulkManagement.selectAll &&
      bulkManagement.agentsToInclude.length === 0 &&
      bulkManagement.agentsToExclude.length >= bulkManagement.totalAgents
    ) {
      setBulkManagement(bulkManagementDefault);
    } else if (
      bulkManagement.selectAll &&
      bulkManagement.agentsToInclude.length > 0 &&
      bulkManagement.agentsToExclude.length >= bulkManagement.totalAgents
    ) {
      setBulkManagement((prev) => ({ ...bulkManagementDefault, agentsToInclude: prev.agentsToInclude }));
    }
  }, [bulkManagement, totalAgents]);

  // Reset bulk selection on access filter change
  useEffect(() => {
    setBulkManagement(bulkManagementDefault);
    // We reset query filters as some filter options may not exist under certain access filters
    setQuery(queryDefault);
  }, [accessFilter?.id]);

  const onSearchChange = async (e: ChangeEvent<any>) => {
    setQuery((prev) => ({ ...prev, search: e.target.value }));
  };

  const onFilterAutoCompleteChange = async (data: DiallerGroup | null) => {
    // setGroupListValue(data);
    setQuery((prev) => ({ ...prev, diallerGroup: data }));
  };

  const handleRemoveConfirmationOpen = (username: string) => () => {
    setDeletableAgentRef(username);
    setRemoveConfirmOpen(true);
  };

  const handleRemoveConfirmationClose = () => {
    setDeletableAgentRef(undefined);
    setRemoveConfirmOpen(false);
  };

  const toggleSelectAll = useCallback(
    (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
      setBulkManagement((prev) => {
        if (prev.selectAll) {
          return { ...bulkManagementDefault, selectAll: false };
        }

        return {
          ...bulkManagementDefault,
          diallerGroupId: query.diallerGroup?.id,
          search: debouncedSearch,
          totalAgents: totalAgents,
          selectAll: true,
        };
      });
    },
    [totalAgents, debouncedSearch, query.diallerGroup],
  );

  const onAgentSelection = (agent: AgentListItem) => () => {
    setBulkManagement((prev) => {
      let update: BulkManagement;
      if (prev.selectAll && agentMatchesSearch(prev.search, agent) && agentMatchesGroup(prev.diallerGroupId, agent)) {
        const index = prev.agentsToExclude.findIndex((item) => item.username === agent.username);
        if (index === -1) {
          update = {
            ...prev,
            agentsToExclude: [...prev.agentsToExclude, agent],
          };
        } else {
          update = {
            ...prev,
            agentsToExclude: prev.agentsToExclude.filter((item) => item.username !== agent.username),
          };
        }
      } else {
        const index = prev.agentsToInclude.findIndex((item) => item.username === agent.username);
        if (index === -1) {
          update = {
            ...prev,
            agentsToInclude: [...prev.agentsToInclude, agent],
          };
        } else {
          update = {
            ...prev,
            agentsToInclude: prev.agentsToInclude.filter((item) => item.username !== agent.username),
          };
        }
      }

      return update;
    });
  };

  const toggleCreateAgentModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const toggleBulkUpdateAsyncModal = () => {
    setBulkUpdateAsyncModalOpen((prev) => !prev);
  };

  const toggleBulkChangeGroupModal = () => {
    setBulkChangeGroupModalOpen((prev) => !prev);
  };

  const setEditAgentData = (username: string) => () => {
    setEditableAgentRef(username);
  };

  const removeEditAgentData = () => {
    setEditableAgentRef(undefined);
  };

  const addAgent = async (data: AgentUpdateRequest) => {
    setSubmittingData(true);

    try {
      await createAgent(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created agent ${data.firstName} ${data.lastName}`);
    setCreateModalOpen(false);
    setBulkManagement(bulkManagementDefault);
    reloadAgentsList();
  };

  const updateAgent = async (data: AgentUpdateRequest) => {
    setSubmittingData(true);

    try {
      await updateAgentByUsername(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Updated agent ${data.firstName} ${data.lastName}`);
    removeEditAgentData();
    reloadAgentsList();
  };

  const deleteAgent = (data: AgentListItem | undefined) => async () => {
    if (data != undefined) {
      try {
        await deleteAgentByUsername(data.username);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      }

      pushNotification('success', `Deleted agent ${data.firstName} ${data.lastName}`);
      handleRemoveConfirmationClose();
      reloadAgentsList();
    }
  };

  const onBulkUpdateAsyncConcurrency = async (data: Partial<UpdateAsyncSettings>) => {
    setSubmittingData(true);

    try {
      await bulkAsyncSettingsUpdate({
        queues: data.queues,
        maxConcurrency: data.maxConcurrency,
        desiredConcurrency: data.desiredConcurrency,
        agentsToInclude: bulkManagement.agentsToInclude.map((item) => item.username),
        agentsToExclude: bulkManagement.agentsToExclude.map((item) => item.username),
        diallerGroupId: bulkManagement.diallerGroupId,
        search: bulkManagement.search,
        selectAll: bulkManagement.selectAll,
      });
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'You have successfully updated the selected agents.');
    setBulkManagement(bulkManagementDefault);
    setBulkUpdateAsyncModalOpen(false);
  };

  const onBulkGroupChangeUpdate = async (data: ChangeGroup | null) => {
    setSubmittingData(true);

    try {
      await bulkGroupChangeUpdate({
        changeGroup: data,
        agentsToInclude: bulkManagement.agentsToInclude.map((item) => item.username),
        agentsToExclude: bulkManagement.agentsToExclude.map((item) => item.username),
        diallerGroupId: bulkManagement.diallerGroupId,
        search: bulkManagement.search,
        selectAll: bulkManagement.selectAll,
      });
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'You have successfully updated the selected agents.');
    setBulkManagement(bulkManagementDefault);
    setBulkChangeGroupModalOpen(false);
  };

  const displayList = useMemo(
    () =>
      agents.map((item, index: number) => {
        const viewLink = generatePath(Routes.agentDetails.path, { username: item.username });
        const checked = isAgentChecked(bulkManagement, item);
        let props: ComponentProps<typeof AgentCard> = {
          key: item.username,
          fullName: item.fullName,
          username: item.username,
          diallerGroupName: item.diallerGroupName || 'N/A',
          to: viewLink,
          checked: {
            value: checked,
            onClick: onAgentSelection(item),
          },
          menuItems: [{ name: 'Edit', action: setEditAgentData(item.username) }],
        };

        if (!aws.externallyManagedConnectInstance) {
          props.menuItems = [
            ...(props.menuItems ?? []),
            { name: 'Delete', action: handleRemoveConfirmationOpen(item.username) },
          ];
        }

        if (index === agents.length - 1) {
          props = { ...props, ref: lastAgentDataElement };
        }

        return <AgentCard key={item.username} {...props} />;
      }),
    [bulkManagement, agents, lastAgentDataElement],
  );

  const onGroupSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchGroup(e.target.value);
  };

  const onGroupSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchGroup('');
  };

  const groupNoOptionsText = useMemo(() => {
    if (groupFetching) {
      return <DotLoader align='center' />;
    }

    if (groupFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          'Failed to load groups'
        </Typography>
      );
    }

    return undefined;
  }, [groupFetching, groupFetchError]);

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            value={query.search}
            onChange={onSearchChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            id='filter'
            value={query.diallerGroup}
            onChange={(e, data) => {
              onFilterAutoCompleteChange(data);
            }}
            fullWidth
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={groups}
            noOptionsText={groupNoOptionsText}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(props, option) => (
              <li {...props} ref={lastGroupDataElement} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Assigned to Dialler Group'
                variant='outlined'
                onBlur={onGroupSearchBlur}
                onChange={onGroupSearchChange}
              />
            )}
          />
        </Grid>

        <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <ButtonMenu
            title='Actions'
            disabled={!canBulkAction}
            actions={[
              {
                title: 'Change Group',
                func: () => {
                  toggleBulkChangeGroupModal();
                },
              },
              {
                title: 'Update Messaging Settings',
                func: () => {
                  toggleBulkUpdateAsyncModal();
                },
              },
            ]}
          />
        </Grid>

        {!aws.externallyManagedConnectInstance && (
          <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateAgentModal}>
              Create Agent
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={1} alignContent='center'>
        <Grid item xs={12}>
          <Box sx={{ marginBottom: 2 }}>
            <CheckboxButton
              checked={selectAllChecked}
              indeterminate={selectAllIndeterminate}
              disabled={agentFetching || agents.length === 0}
              onClick={toggleSelectAll}
            />

            {(bulkManagement.selectAll || bulkManagement.agentsToInclude.length > 0) && (
              <Typography sx={{ marginLeft: 1 }} variant='body2' color='textSecondary' component='span'>
                {selectionCount.toLocaleString()} agents selected
              </Typography>
            )}
          </Box>

          <AsyncLoader isLoading={agentFetching && agents.length === 0}>
            {agents.length > 0 && (
              <>
                <List sx={{ marginTop: 1, marginBottom: 1, paddingTop: 0, paddingBottom: 0 }}>{displayList}</List>
                {agentFetching && agents.length > 0 && <DotLoader align='center' />}

                {!agentFetching && !hasMoreAgents && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {agentFetchError && agents.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load agents
                  </Typography>
                )}
              </>
            )}

            {agents.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No agents found matching your search criteria'
                subText='Try selecting a different campaign or entering a different search name.'
              />
            )}

            {agents.length === 0 && noSearchOrFilterSet && !accessFilter && (
              <EmptyState type='no-items-3' text='No agents currently exist' />
            )}

            {agents.length === 0 && noSearchOrFilterSet && accessFilter && (
              <EmptyState
                type='no-records-found'
                text={`${accessFilter.name} access filter does not have any agents assigned to it`}
              />
            )}
          </AsyncLoader>
        </Grid>
      </Grid>

      <CreateEditAgentModal
        open={createModalOpen || Boolean(editableAgent)}
        submitting={submittingData}
        agentUsername={editableAgent?.username || undefined}
        onClose={editableAgent !== undefined ? removeEditAgentData : toggleCreateAgentModal}
        onAccept={editableAgent !== undefined ? updateAgent : addAgent}
      />

      <UpdateAsyncSettingsModal
        open={bulkUpdateAsyncModalOpen}
        submitting={submittingData}
        onClose={toggleBulkUpdateAsyncModal}
        onAccept={onBulkUpdateAsyncConcurrency}
      />

      <UpdateGroupModal
        open={bulkChangeGroupModalOpen}
        submitting={submittingData}
        onClose={toggleBulkChangeGroupModal}
        onAccept={onBulkGroupChangeUpdate}
      />

      <ActionDialog
        open={removeConfirmOpen}
        title='Are you sure you want to do this?'
        content={`You are about to delete ${deletableAgent?.username}, once you have completed this action it cannot be undone.`}
        onClose={handleRemoveConfirmationClose}
        onAccept={deleteAgent(deletableAgent)}
        primaryActionTitle='Remove'
      />
    </>
  );
};

export default AgentList;
