import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getQueues } from './api';
import { ListQueuesResponse } from './domain';

const useQueues = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [dict, setDict] = useState<ListQueuesResponse>({ queues: {} });
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: ListQueuesResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getQueues(axiosCancelRef.current);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setDict(resp);
    setLoading(false);
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      setError(false);

      let resp: ListQueuesResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getQueues(axiosCancelRef.current);
      } catch (e) {
        console.log(e);
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setDict(resp);
      setLoading(false);
    };

    load();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, []);

  return {
    loading,
    error,
    queues: dict.queues,
    reload,
  };
};

export default useQueues;
