import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useState } from 'react';
import useMeasure from 'react-use-measure';

import TransferableAgentListItem from '~components/TransferableAgentListItem';
import { useAgentStates } from '~providers/AgentStatesProvider';

interface AgentTransferPanelProps {
  onTransferToAgent: (agent: string) => void;
}

const AgentTransferPanel = ({ onTransferToAgent }: AgentTransferPanelProps) => {
  const { agentAsyncList } = useAgentStates();
  const [search, setSearch] = useState<string>('');
  const [boxRef, { height: boxHeight }] = useMeasure();

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const searchTerm = search.split(/\s+/).filter((term) => term.length > 0);
  const searchResultList = agentAsyncList
    .filter(
      (item) =>
        searchTerm.every((term) => item.name.toLowerCase().includes(term.toLowerCase())) ||
        searchTerm.every((term) => item.username.toLowerCase().includes(term.toLowerCase())),
    )
    .sort((x, y) => {
      if (x.username < y.username) {
        return -1;
      } else if (y.username < x.username) {
        return 1;
      }
      return 0;
    });
  const transferItemList = searchResultList.map((item, index) => (
    <TransferableAgentListItem
      key={item.username}
      variant='messaging'
      agent={item}
      divider={index !== searchResultList.length - 1}
      onTransferToAgent={() => onTransferToAgent(item.username)}
    />
  ));

  return (
    <>
      <Box ref={boxRef}>
        <Grid sx={{ padding: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='search'
              name='search'
              label='Search'
              onChange={onSearchChange}
            />
          </Grid>
        </Grid>

        <Divider component='hr' />
      </Box>

      <Box sx={{ height: `calc(100% - ${boxHeight}px)`, overflow: 'auto' }}>
        {transferItemList.length === 0 && (
          <Typography marginTop={1} variant='body1' component='p' align='center'>
            {searchTerm.length === 0 ? "There are no agent's to transfer to." : 'No search results found.'}
          </Typography>
        )}

        {transferItemList.length > 0 && <List sx={{ padding: 0 }}>{transferItemList}</List>}
      </Box>
    </>
  );
};

export default AgentTransferPanel;
