import * as j from '@mojotech/json-type-validation';

import {
  AccessFilter,
  AccessFilterAgentEntry,
  AccessFilterAsyncQueueEntry,
  AccessFilterCampaignEntry,
  AccessFilterDiallerGroupEntry,
} from '~pages/SystemManagement/domain';

const AccessFilterAgentEntryDecoder: j.Decoder<AccessFilterAgentEntry> = j
  .object({
    id: j.string(),
    name: j.string(),
    disabled: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    id: item.id,
    name: item.name,
    disabled: item.disabled || undefined,
  }));

const AccessFilterGroupEntryDecoder: j.Decoder<AccessFilterDiallerGroupEntry> = j
  .object({
    id: j.number(),
    name: j.string(),
    archived: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    id: item.id,
    name: item.name,
    archived: item.archived || undefined,
  }));

const AccessFilterCampaignEntryDecoder: j.Decoder<AccessFilterCampaignEntry> = j
  .object({
    id: j.number(),
    name: j.string(),
    archived: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    id: item.id,
    name: item.name,
    archived: item.archived || undefined,
  }));

const AccessFilterAsyncQueueEntryDecoder: j.Decoder<AccessFilterAsyncQueueEntry> = j
  .object({
    id: j.string(),
    name: j.string(),
  })
  .map((item) => ({
    id: item.id,
    name: item.name,
  }));

export const AccessFilterResponseDecoder: j.Decoder<AccessFilter> = j
  .object({
    access_filter_id: j.number(),
    name: j.string(),
    description: j.string(),
    archived: j.optional(j.string()),
    agents: j.optional(j.array(AccessFilterAgentEntryDecoder)),
    campaigns: j.optional(j.array(AccessFilterGroupEntryDecoder)),
    groups: j.optional(j.array(AccessFilterCampaignEntryDecoder)),
    async_queues: j.optional(j.array(AccessFilterAsyncQueueEntryDecoder)),
  })
  .map((item) => ({
    id: item.access_filter_id,
    name: item.name,
    description: item.description,
    archived: item.archived || undefined,
    numAgents: undefined,
    numCampaigns: undefined,
    numGroups: undefined,
    numAsyncQueues: undefined,
    agents: item.agents || [],
    campaigns: item.campaigns || [],
    groups: item.groups || [],
    asyncQueues: item.async_queues || [],
  }));
