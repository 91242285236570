import React, { ReactNode, useEffect } from 'react';

import AsyncLoader from '~components/AsyncLoader';

import EmptyState from '../EmptyState';

interface DetectMicrophoneProps {
  children: ReactNode;
}

const DetectMicrophone = ({ children }: DetectMicrophoneProps) => {
  const [state, setState] = React.useState({
    checking: true,
    blocked: false,
  });

  useEffect(() => {
    (async () => {
      let timeout: number | undefined = undefined;

      try {
        // Since there is no way to test for the ask (default) and the following check of getUserMedia
        // can block function execution for this case, this is a sneaky hack to get a nice error page displayed to the user
        // in this case so they have a bit more info of what is going on etc
        timeout = window.setTimeout(() => {
          console.log('+ yes');
          setState((prev) => ({ checking: false, blocked: true }));
        }, 400);
        /**
         * If microphone permissions have not been set for the domain before (Ask (default)) you wil be prompted with the error page where you
         * have to either the option to allow or block access to the microphone. Once allowed refresh the page to access site
         *
         * If access is allowed this will success equalling microphone enabled for usage
         *
         * If access is blocked this will fall to the catch block equalling microphone disabled OR is not connected error message
         */
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setState((prev) => ({ checking: false, blocked: false }));
      } catch (e) {
        setState((prev) => ({ checking: false, blocked: true }));
      } finally {
        clearTimeout(timeout);
      }
    })();
  }, []);

  const content = !state.blocked ? (
    <>{children}</>
  ) : (
    <EmptyState
      type='error'
      text='Microphone Connectivity Error'
      subText='Please check that your microphone is connected and that you have allowed it in the browser.'
      action={() => window.location.reload()}
      actionText='Refresh'
    />
  );

  return <AsyncLoader isLoading={state.checking}>{content}</AsyncLoader>;
};

export default DetectMicrophone;
