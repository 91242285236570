import { Organisation } from '~organisations/domain';

import genericContactAssessmentForm from './contactAssessmentForms/generic';
import logo from './images/logo.svg';

const compareTheMarketConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {},
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {
    '1': genericContactAssessmentForm,
  },
};

export default compareTheMarketConfig;
