import axios from 'axios';

import { APIError } from '~services/Errors';

export const postScreenShare = async (sessionId: string, screenId: string, recordingId: string): Promise<void> => {
  const path = '/api/screen-recordings';

  const body = {
    session_id: sessionId,
    screen_id: screenId,
    recording_id: recordingId,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};
