import React, { Ref, forwardRef } from 'react';

interface ProgressItemProps {
  /**  Percentage value */
  progress: number;
  color?: string;
  striped?: boolean;
  gaps?: boolean;
}

const ProgressItem = forwardRef(
  ({ progress, color = '', striped = false, gaps = false, ...rest }: ProgressItemProps, ref: Ref<any>) => {
    let styles: { [key: string]: any } = {
      width: `${progress}%`,
      backgroundColor: color,
      height: '100%',
    };

    if (striped === true) {
      styles = {
        ...styles,
        backgroundImage: `linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)`,
        backgroundSize: `16px 16px`,
      };
    }

    if (gaps === true) {
      styles = {
        ...styles,
        marginLeft: 0.5,
        marginRight: 0.5,
      };
    }

    return <div {...rest} ref={ref} style={styles} />;
  },
);

export default ProgressItem;
