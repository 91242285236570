import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { MouseEvent, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';

import { PredictiveAgentStatus } from '../../domain';

interface PredictiveAgentStatusProps {
  isDragDisabled: boolean;
  agentStatus: PredictiveAgentStatus;
  draggableProvided: DraggableProvided;
  onDelete?: () => void;
}

// This does not make use of the OberonCard due to the draggable functionality
// If we end up with more of these in the future we can think of componentising it with the OberonCard somehow
const PredictiveStatusCard = ({
  isDragDisabled,
  agentStatus,
  draggableProvided,
  onDelete,
}: PredictiveAgentStatusProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const triggerDelete = () => {
    if (!onDelete) {
      console.error('triggerDelete: onDelete not defined');
      return;
    }
    onDelete();
    setDeleteConfirmModalOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setAnchorEl(null);
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmModalOpen(false);
  };

  return (
    <>
      <OberonCard
        titleFontWeight={700}
        title={agentStatus.agentStatus}
        subHeader={agentStatus.description}
        draggableProvided={draggableProvided}
        action={
          onDelete && (
            <>
              <IconButton aria-controls='agent-menu' aria-haspopup='true' onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>

              <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleDeleteConfirmationOpen}>Delete</MenuItem>
              </Menu>
            </>
          )
        }
      />

      <ActionDialog
        open={deleteConfirmModalOpen}
        title='Are you sure you want to do this?'
        content={`You are about to delete the status ${agentStatus.agentStatus}.`}
        onClose={handleDeleteConfirmationClose}
        onAccept={triggerDelete}
        primaryActionTitle='Delete'
      />
    </>
  );
};

export default PredictiveStatusCard;
