import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      brand_code: {
        value: 'A4NK',
        validators: [],
      },
      customer_full_name: {
        value: '',
        validators: [],
      },
    });

    return () => {
      form.removeSchemaProperties(['brand_code', 'customer_full_name']);
    };
  }, []);

  // Logic to hide show sales based fields
  useEffect(() => {
    // Should only fire if disposition code is sale OR if outcome type is callback AND if fields are not already set
    if (
      (form.fields.disposition.value?.code === 'sale' || form.fields.disposition.value?.outcome === 'callback') &&
      !form.fields.cidn &&
      !form.fields.sales_id
    ) {
      form.addSchemaProperties({
        cidn: {
          value: '',
          validators: [],
        },
        sales_id: {
          value: '',
          validators: [],
        },
      });
    }

    // Should only fire if disposition code is not sale AND disposition outcome is NOT callback AND if fields are already set
    if (
      form.fields.disposition.value?.code !== 'sale' &&
      form.fields.disposition.value?.outcome !== 'callback' &&
      form.fields.cidn &&
      form.fields.sales_id
    ) {
      form.removeSchemaProperties(['cidn', 'sales_id']);
    }
  }, [form.fields.disposition?.value]);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='brand_code'
          name='brand_code'
          label='Dealer Code'
          disabled={true}
          value={form.fields?.brand_code?.value || ''}
          error={Boolean(form.errors.brand_code)}
          helperText={form.errors.brand_code && form.errors.brand_code[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='customer_full_name'
          name='customer_full_name'
          label='Customer Full Name (first and last name)'
          disabled={formSubmitting}
          value={form.fields?.customer_full_name?.value || ''}
          error={Boolean(form.errors.customer_full_name)}
          helperText={form.errors.customer_full_name && form.errors.customer_full_name[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      {form.fields?.cidn && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant='outlined'
            id='cidn'
            name='cidn'
            label='CIDN (Telstra Customer ID)'
            disabled={formSubmitting}
            value={form.fields?.cidn?.value || ''}
            error={Boolean(form.errors.cidn)}
            helperText={form.errors.cidn && form.errors.cidn[0]!}
            onChange={form.handleInputChange}
          />
        </Grid>
      )}

      {form.fields?.sales_id && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant='outlined'
            id='sales_id'
            name='sales_id'
            label='Sales ID'
            disabled={formSubmitting}
            value={form.fields?.sales_id?.value || ''}
            error={Boolean(form.errors.sales_id)}
            helperText={form.errors.sales_id && form.errors.sales_id[0]!}
            onChange={form.handleInputChange}
          />
        </Grid>
      )}
    </>
  );
};

export default OutcomesForm;
