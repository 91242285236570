import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grow, MenuList } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import blueGrey from '@mui/material/colors/blueGrey';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React from 'react';

type Actions = {
  title: string;
  func: () => void;
};

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HSL = `hsl(${number}, ${number}, ${number})`;
type HSLA = `hsl(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HSL | HSLA | HEX;

type Props = {
  title: string;
  actions: Actions[];
  disabled?: boolean;
  color?: Color;
};

export const ButtonMenu = ({ title, actions = [], disabled, color }: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (action: () => void) => () => {
    action();
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        sx={(theme) => ({
          'color': theme.palette.getContrastText(color || blueGrey[900]),
          'backgroundColor': color || blueGrey[900],
          ':hover': {
            backgroundColor: color || blueGrey[800],
          },
        })}
        fullWidth
        disableElevation
        disabled={disabled}
        variant='contained'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}>
        <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>{title}</Box>

        <ArrowDropDownIcon sx={{ marginLeft: -3 }} />
      </Button>

      <Popper
        sx={{
          zIndex: 1,
        }}
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {actions.map(({ title, func }, index) => (
                    <MenuItem key={index} onClick={handleMenuItemClick(func)}>
                      {title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ButtonMenu;
