import SocketManagerBase from '~services/SocketManagerBase';

import { AsyncAllStats, AsyncAllStatsDecoder } from './domain';

interface Callbacks {
  open: () => void;
  message: (message: AsyncAllStats) => void;
  closed: (code: number) => void;
}

type CallbacksType = keyof Callbacks;

const noop = () => {};

export class AsyncStatsManager extends SocketManagerBase {
  private heartbeatIntervalRef: number | undefined;
  private callbacks: Callbacks = {
    open: noop,
    message: noop,
    closed: noop,
  };
  constructor(address: string) {
    super('AsyncStatsManager', address);
  }

  /** Event that is triggered on the successful connection of the websocket */
  protected onOpen = (): void => {
    if (this.ws === undefined) {
      console.error('onOpen: websocket missing.');
      return;
    }

    const socket = this.ws;
    // Post heartbeat to let the socket know we are still here
    this.heartbeatIntervalRef = window.setInterval(() => {
      const req = {
        heartbeat: '',
      };

      socket.send(JSON.stringify(req));
    }, 15_000);

    this.callbacks.open();
  };

  /** Event handler that manages all responses from the connected websocket */
  protected onMessage = (messageEvent: MessageEvent): void => {
    let msg: AsyncAllStats;

    try {
      const rawMessage = JSON.parse(messageEvent.data);

      if (Object.keys(rawMessage).length === 0) {
        // This is a heartbeat and we can ignore it
        return;
      }

      msg = AsyncAllStatsDecoder.runWithException(rawMessage);
    } catch (e) {
      console.error('onMessage: Unable to decode message payload: ', e);
      return;
    }

    this.callbacks.message(msg);
  };

  /** Event handler that is triggered if the websocket is closed/ ended */
  protected onClose = (closeEvent: CloseEvent): void => {
    console.log('AsyncStatsManager websocket closed with code: ', closeEvent.code);

    clearInterval(this.heartbeatIntervalRef);
    this.ws = undefined;
    this.callbacks.closed(closeEvent.code);
  };

  public on = <T extends CallbacksType>(listener: T, action: Callbacks[T]) => {
    // A bit dodgy, but we set all callbacks as a noop so this will cover the case where someone
    // makes a typo and ignores the typescript linter message, as typescript will still compile and run etc.
    if (this.callbacks[listener]) {
      this.callbacks[listener] = action;
    }
  };
}
