import React, { useEffect } from 'react';

import EmptyState from '~/components/EmptyState';
import { CampaignType, DiallerType } from '~/pages/CampaignManagement/domain';
import DetectMicrophone from '~components/DetectMicrophone';
import PopUpEnforcer from '~components/PopUpEnforcer';
import ConnectDialler from '~pages/Dialler/ConnectDialler';
import AgentStatesProvider from '~providers/AgentStatesProvider';
import { AssignedDiallerGroupProvider, useAssignedDiallerGroup } from '~providers/AssignedDiallerGroupProvider';
import { AsyncProvider } from '~providers/AsyncProvider';
import { AttemptProvider } from '~providers/AttemptProvider';
import { useAuth } from '~providers/AuthProvider';
import ConnectProvider from '~providers/ConnectProvider';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import ScreenShareProvider from '~providers/ScreenShareProvider';

import AsteriskDialler from './AsteriskDialler';

const NoCampaignAssigned = () => (
  <EmptyState type='puzzle' text='User Configuration Error' subText='You are not assigned to a dialling campaign.' />
);

const DiallerSwitch = () => {
  const { group } = useAssignedDiallerGroup();
  const { username: loggedInUserUsername } = useAuth();

  if (group.diallerType === DiallerType.Connect || group.diallerType === DiallerType.SIP) {
    return (
      <DetectMicrophone>
        <PopUpEnforcer>
          <ScreenShareProvider>
            <ConnectProvider>
              <AsyncProvider enabled={group.hasAsyncQueues && group.campaignType !== CampaignType.Predictive}>
                <AgentStatesProvider>
                  <AttemptProvider loggedInUserUsername={loggedInUserUsername}>
                    {group.diallerType === DiallerType.Connect ? <ConnectDialler /> : <AsteriskDialler />}
                  </AttemptProvider>
                </AgentStatesProvider>
              </AsyncProvider>
            </ConnectProvider>
          </ScreenShareProvider>
        </PopUpEnforcer>
      </DetectMicrophone>
    );
  } else {
    return <NoCampaignAssigned />;
  }
};

const Dialler = () => {
  const setPageTitleProps = useSetPageTitleProps();

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Dialler' });
  }, []);

  return (
    <AssignedDiallerGroupProvider>
      <DiallerSwitch />
    </AssignedDiallerGroupProvider>
  );
};

export default Dialler;
