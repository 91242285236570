import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabPanel } from '~components/TabPanel';
import AsyncDashboard from '~pages/AsyncManagement/AsyncDashboard';
import QueueList from '~pages/AsyncManagement/QueueList';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';

// Used to manage visible tab selection
enum ViewTabsType {
  Dashboard,
  Queues,
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const AsyncQueueManagement = () => {
  const setPageTitleProps = useSetPageTitleProps();
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[0],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Async Configuration' });
  }, []);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        Messaging Configuration
      </Typography>

      <Tabs
        orientation='horizontal'
        variant='scrollable'
        onChange={onTabChange}
        value={tabIndex}
        indicatorColor='primary'
        aria-label='async tabs'>
        <Tab label='Dashboard' {...a11yProps(ViewTabsType.Dashboard)} value={ViewTabsType.Dashboard} />
        <Tab label='Queues' {...a11yProps(ViewTabsType.Queues)} value={ViewTabsType.Queues} />
      </Tabs>
      <Divider variant='fullWidth' component='hr' />

      <TabPanel value={tabIndex} index={ViewTabsType.Dashboard}>
        <AsyncDashboard />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.Queues}>
        <QueueList />
      </TabPanel>
    </>
  );
};

export default AsyncQueueManagement;
