import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { DiallingHourProfile, DiallingHourProfileResponseDecoder } from './domain';

export const getDiallingHourProfiles = async (cancelToken?: CancelTokenSource): Promise<DiallingHourProfile[]> => {
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: '/api/dialling-hour-profiles',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallingHourProfileResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    throw new UnsupportedStructureError(decoded.error.message);
  }

  return decoded.result;
};
