import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { createTransfer, getTransfers, getTransfersByNextUrl, removeTransfer, updateTransfer } from './api';
import { CreateTransfer, Transfer, TransferResponse, UpdateTransfer } from './domain';

type Options = {
  search?: string;

  shouldFetch?: boolean;
};

const useTransfers = (
  options: Options = {
    search: undefined,
    shouldFetch: true,
  },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<Transfer[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const search = options.search;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: TransferResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getTransfersByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.transfers]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: TransferResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getTransfers(search, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.transfers);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [search]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const create = useCallback(
    async (data: CreateTransfer) => {
      try {
        await createTransfer(data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  const update = useCallback(
    async (transferId: number, data: UpdateTransfer) => {
      try {
        await updateTransfer(transferId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  const remove = useCallback(
    async (transferId: number) => {
      try {
        await removeTransfer(transferId);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [reload],
  );

  useEffect(() => {
    setList([]);
  }, [search, shouldFetch]);

  useEffect(() => {
    const load = async (search: string | undefined) => {
      setLoading(true);
      setError(false);

      let resp: TransferResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getTransfers(search, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.transfers]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(search);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, shouldFetch]);

  return { loading, error, list, hasMore, create, update, remove, intersectionObserverRef };
};

export default useTransfers;
