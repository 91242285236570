import React from 'react';

import ScreenRecordingCard from '~components/ScreenRecordingCard';
import { ScreenRecording } from '~pages/ContactLog/domain';

interface ContactScreenRecordingProps {
  contactId: string;
  recordingInfoList: ScreenRecording[];
  canDownload: boolean;
  canView: boolean;
}

const ContactScreenRecording = ({
  contactId,
  recordingInfoList,
  canDownload,
  canView,
}: ContactScreenRecordingProps) => {
  // We want to skip fast, do none of the below logic, and not display anything
  if (!canView) return null;

  // const classes = useStyles(useTheme());
  const recordingRelativePath = `/api/contacts/details/${contactId}/screen-recording`;

  return (
    <ScreenRecordingCard
      recordingRelativePath={recordingRelativePath}
      recordingInfoList={recordingInfoList}
      canDownload={canDownload}
    />
  );
};

export default ContactScreenRecording;
