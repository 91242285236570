import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';

import { MessageTemplate, MessageTemplateOmitId } from '../useMessageTemplate/domain';

const enum CategoryType {
  Existing,
  New,
}

interface CreateEditMessageTemplateModalProps {
  open: boolean;
  submitting: boolean;
  messageTemplate?: MessageTemplate;
  categories: string[];
  onAccept: (data: MessageTemplateOmitId) => Promise<void>;
  onClose: () => void;
}

interface Form {
  title: string;
  categoryType: CategoryType;
  category: string;
  template: string;
}

const categoryTypeList = [
  {
    label: 'Existing',
    value: CategoryType.Existing,
  },
  {
    label: 'New',
    value: CategoryType.New,
  },
];

const CreateEditMessageTemplateModal = ({
  open,
  submitting,
  messageTemplate,
  categories,
  onAccept,
  onClose,
}: CreateEditMessageTemplateModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(messageTemplate);
  const noCategories = categories.length === 0;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      title: '',
      categoryType: noCategories ? CategoryType.New : CategoryType.Existing,
      category: '',
      template: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const categoryTypeWatch = watch('categoryType');

  const categoryListDisplay = categories.map((val, index) => (
    <MenuItem key={index} value={val}>
      {val}
    </MenuItem>
  ));

  const categoryTypeListDisplay = categoryTypeList
    // Let's filter the existing option out IF we do not have any to begin with
    .filter((item) => {
      if (noCategories && item.value !== CategoryType.Existing) {
        return false;
      }

      return true;
    })
    .map((item, index) => {
      return (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      );
    });

  // Handles reset and editable display
  useEffect(() => {
    if (open) {
      setValue('categoryType', noCategories ? CategoryType.New : CategoryType.Existing);

      if (messageTemplate !== undefined) {
        setValue('category', messageTemplate.category);
        setValue('title', messageTemplate.title);
        setValue('template', messageTemplate.template);
      }
    }

    // Reset form on close
    return () => {
      reset();
    };
  }, [open, messageTemplate]);

  const onCategoryTypeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: ControllerRenderProps<Form, 'categoryType'>) => {
      field.onChange(e);

      // Let's either empty out the field on this state change or prefill it with the existing category if it exists
      // and the category is exists
      if ((e.target.value as unknown as CategoryType) === CategoryType.Existing && messageTemplate !== undefined) {
        setValue('category', messageTemplate.category);
      } else {
        setValue('category', '');
      }
    },
    [messageTemplate],
  );

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept({
        category: data.category,
        title: data.title,
        template: data.template,
      });
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const templateHelperText =
    errors.template?.message ||
    `
      Note: You can use {{ variable_name }} to indicate to agents that this section of the template should be filled with
      customer specific data. It also prevents them from accidentally sending a template before its ready!
    `;

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Message template`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='title'
              control={control}
              rules={{ required: 'Title is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Title'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.title)}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {!noCategories && (
            <Grid item xs={12}>
              <Controller
                name='categoryType'
                rules={{ required: 'Category type is required.' }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    variant='outlined'
                    label='Category Type'
                    disabled={isLoading || noCategories}
                    required={true}
                    error={Boolean(errors.categoryType)}
                    helperText={errors.categoryType?.message}
                    onChange={(e) => onCategoryTypeChange(e, field)}>
                    {categoryTypeListDisplay}
                  </TextField>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <>
              {categoryTypeWatch === CategoryType.Existing && (
                <Controller
                  name='category'
                  rules={{ required: 'Category is required.' }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      select
                      variant='outlined'
                      label='Category'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(errors.category)}
                      helperText={errors.category?.message}
                      {...field}>
                      {categoryListDisplay}
                    </TextField>
                  )}
                />
              )}

              {categoryTypeWatch === CategoryType.New && (
                <Controller
                  name='category'
                  control={control}
                  rules={{ required: 'Category is required.' }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Category'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(errors.category)}
                      helperText={errors.category?.message}
                      {...field}
                    />
                  )}
                />
              )}
            </>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='template'
              control={control}
              rules={{ required: 'Template is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Template'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.template)}
                  helperText={templateHelperText}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditMessageTemplateModal;
