import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { BasicTable, ColumnData, RowData } from '~components/BasicTable';
import ContentSpacer from '~components/ContentSpacer';
import EmptyState from '~components/EmptyState';
import useDiallerGroupsByAccessFilter from '~pages/CampaignManagement/GroupsDashboard/useDiallerGroupsByAccessFilter';
import { DiallerGroupAccessFilterDict } from '~pages/CampaignManagement/GroupsDashboard/useDiallerGroupsByAccessFilter/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { AsteriskStatsManager } from '~services/AsteriskStatsManager';
import { AsteriskStats } from '~services/AsteriskStatsManager/domain';
import { exponentialDelay, sortObject } from '~utils/Functions';

interface ListItem {
  label: string;
  value: string | number;
}

interface TableFilter {
  groups: ListItem[];
  statuses?: ListItem[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

const getAccessFilterGroups = (
  accessFilterId: number | undefined,
  data: DiallerGroupAccessFilterDict | undefined,
  state: AsteriskStats[],
) => {
  if (!data || !accessFilterId) {
    return state;
  }

  const accessFilterGroups = data[accessFilterId];

  return state.filter((item) => accessFilterGroups.includes(item.groupId));
};

const GroupsDashboard = () => {
  const appConfig = useAppConfiguration();
  const { accessFilter } = useUserPreferences();
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [state, setState] = useState<AsteriskStats[]>([]);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  const { data: groupsByAccessFilterId } = useDiallerGroupsByAccessFilter();
  const predictiveConfig = appConfig.extensions.predictive ?? {
    diallerURL: '',
    sipWebsocket: '',
    sipHost: '',
  };
  const [agentsInStatusPerGroupFilter, setAgentsInStatusPerGroupFilter] = useState<TableFilter>({
    groups: [],
    sortBy: 'groupName',
    sortOrder: 'desc',
  });
  const [metricsPerGroupFilter, setMetricsPerGroupFilter] = useState<TableFilter>({
    groups: [],
    sortBy: 'groupName',
    sortOrder: 'desc',
  });
  const [globalAgentsInStatusFilter, setGlobalAgentsInStatusFilter] = useState<TableFilter>({
    groups: [],
    statuses: [],
    sortBy: 'username',
    sortOrder: 'desc',
  });
  const asteriskStatsManager = useMemo(() => {
    const socketUrl = `${predictiveConfig.diallerURL}/stats-ws`;

    const manager = new AsteriskStatsManager(socketUrl, null, {
      onConnected: () => {
        clearTimeout(retryTimeoutRef.current);
        setSocketConnected(true);
        retryCount.current = 0;
      },
      onMessage: (asteriskStats) => {
        setInitialLoad(false);

        if (Array.isArray(asteriskStats)) {
          setState(asteriskStats);
        }
      },
      onDisconnected: (reconnect) => {
        clearTimeout(retryTimeoutRef.current);
        setInitialLoad(false);
        setSocketConnected(false);

        retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
          retryCount.current += 1;
          reconnect();
        });
      },
    });

    return manager;
  }, []);
  const filteredState = getAccessFilterGroups(accessFilter?.id, groupsByAccessFilterId, state);

  // Handle the connection and disconnection of the socket
  useEffect(() => {
    asteriskStatsManager.connect();

    return () => {
      clearTimeout(retryTimeoutRef.current);
      asteriskStatsManager.disconnect();
    };
  }, []);

  // Logic to attempt a resend of any potential messages not send due to a network error
  useEffect(() => {
    const ref = setInterval(() => {
      asteriskStatsManager.sendQueuedMessages();
    }, 1_000);

    return () => {
      clearInterval(ref);
    };
  }, []);

  const onTableRowClick = useCallback((data: RowData) => {
    navigate(`${generatePath(Routes.viewDiallerGroup.path, { diallerGroupId: data.groupId })}?show=LiveDash`);
  }, []);

  const onFilterChange = useCallback(
    (key: string, setState: Dispatch<SetStateAction<TableFilter>>) => (e: ChangeEvent<{}>, value: ListItem[]) => {
      setState((prev) => ({ ...prev, [key]: value }));
    },
    [],
  );

  const handleColumnSort = useCallback(
    (setState: Dispatch<SetStateAction<TableFilter>>, column: string) => () => {
      setState((prev) => {
        if (column === prev.sortBy) {
          return { ...prev, sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc' };
        }

        return { ...prev, sortBy: column, sortOrder: 'asc' };
      });
    },
    [],
  );

  const groupsList = filteredState.map((asteriskStats) => ({
    label: asteriskStats.groupName,
    value: asteriskStats.groupId,
  }));

  const statusList =
    filteredState.length > 0
      ? filteredState[0].agentStatusCounts.map((agentStatusCount) => ({
          label: agentStatusCount.status,
          value: agentStatusCount.status,
        }))
      : [];

  const agentsInStatusPerGroupColumns: ColumnData[] = useMemo(() => {
    if (filteredState === undefined || filteredState.length === 0) {
      return [];
    }

    // NOTE: statuses should be the same for all objects, so we grab from the very first array object if it exists
    let columns: ColumnData[] = filteredState[0].agentStatusCounts.map((item) => ({
      label: item.status,
      dataKey: item.status,
      align: 'center',
      sortDirection: agentsInStatusPerGroupFilter.sortOrder,
      sortActive: agentsInStatusPerGroupFilter.sortBy === item.status,
      onColumnSort: handleColumnSort(setAgentsInStatusPerGroupFilter, item.status),
    }));

    return [
      {
        wrapText: true,
        width: 200,
        label: 'Group Name',
        dataKey: 'groupName',
        align: 'left',
        sortDirection: agentsInStatusPerGroupFilter.sortOrder,
        sortActive: agentsInStatusPerGroupFilter.sortBy === 'groupName',
        onColumnSort: handleColumnSort(setAgentsInStatusPerGroupFilter, 'groupName'),
      },
      {
        wrapText: true,
        label: 'Total Agents',
        dataKey: 'totalAgents',
        align: 'center',
        sortDirection: agentsInStatusPerGroupFilter.sortOrder,
        sortActive: agentsInStatusPerGroupFilter.sortBy === 'totalAgents',
        onColumnSort: handleColumnSort(setAgentsInStatusPerGroupFilter, 'totalAgents'),
      },
      ...columns,
    ];
  }, [filteredState, agentsInStatusPerGroupFilter.sortBy, agentsInStatusPerGroupFilter.sortOrder]);

  // Should only revalidate if the dataset changes
  const agentsInStatusPerGroupRows = useMemo(() => {
    if (filteredState === undefined || filteredState.length === 0) {
      return [];
    }

    const filteredByGroup = filteredState.filter((asteriskStats) => {
      if (agentsInStatusPerGroupFilter.groups.length === 0) return true;

      return agentsInStatusPerGroupFilter.groups.find((item) => item.value === asteriskStats.groupId) !== undefined;
    });

    return filteredByGroup
      .map((asteriskStats) => {
        const totalAgents = asteriskStats.agentStatusCounts.reduce((state, currentVal) => state + currentVal.count, 0);
        const row = asteriskStats.agentStatusCounts.reduce(
          (state, currentVal) => ({ ...state, [currentVal.status]: currentVal.count }),
          {},
        );

        return {
          groupId: asteriskStats.groupId,
          groupName: asteriskStats.groupName,
          totalAgents: totalAgents,
          ...row,
        };
      })
      .sort(sortObject(agentsInStatusPerGroupFilter.sortBy as any, agentsInStatusPerGroupFilter.sortOrder));
  }, [filteredState, agentsInStatusPerGroupFilter]);

  const metricsPerGroupColumns: ColumnData[] = useMemo(() => {
    const columns: ColumnData[] = [
      {
        wrapText: true,
        width: 200,
        label: 'Group Name',
        dataKey: 'groupName',
        align: 'left',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'groupName',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'groupName'),
      },
      {
        label: 'AWT',
        dataKey: 'Average Agent Wait Time',
        align: 'center',
        titleTooltip: 'Average Wait Time',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Average Agent Wait Time',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Average Agent Wait Time'),
      },
      {
        label: 'AHT',
        dataKey: 'Average Handling Time',
        align: 'center',
        titleTooltip: 'Average Handling Time ',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Average Handling Time',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Average Handling Time'),
      },
      {
        label: 'Drop %',
        dataKey: 'Drop Rate',
        align: 'center',
        titleTooltip: 'Drop Rate',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Drop Rate',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Drop Rate'),
      },
      {
        label: 'Drop #',
        dataKey: 'Drop Call Count',
        align: 'center',
        titleTooltip: 'Drop Call Count',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Drop Call Count',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Drop Call Count'),
      },
      {
        label: 'Answered Calls',
        dataKey: 'Answered Calls',
        align: 'center',
        titleTooltip: 'Answered Calls',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Answered Calls',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Answered Calls'),
      },
      {
        label: 'Total Agents',
        dataKey: 'totalAgents',
        align: 'center',
        titleTooltip: 'Total Agents',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'totalAgents',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'totalAgents'),
      },
      {
        label: 'Available Agents',
        dataKey: 'Available Agents',
        align: 'center',
        titleTooltip: 'Available Agents',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Available Agents',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Available Agents'),
      },
      {
        label: 'IAR',
        dataKey: 'Inv Answer Rate',
        align: 'center',
        titleTooltip: 'Inverse Answer Rate',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Inv Answer Rate',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Inv Answer Rate'),
      },
      {
        label: 'Line',
        dataKey: 'maxLinesPerAgent',
        align: 'center',
        titleTooltip: 'Max Lines per Agent',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'maxLinesPerAgent',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'maxLinesPerAgent'),
      },
      {
        label: 'Calls in Queue',
        dataKey: 'Call Queue Depth',
        align: 'center',
        titleTooltip: 'Calls in Queue',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Call Queue Depth',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Call Queue Depth'),
      },
      {
        label: 'Inbound Queue Depth',
        dataKey: 'Inbound Call Queue Depth',
        align: 'center',
        titleTooltip: 'Inbound Queue Depth',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Inbound Call Queue Depth',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Inbound Call Queue Depth'),
      },
      {
        label: 'Oldest in Queue',
        dataKey: 'Oldest In Queue',
        align: 'center',
        titleTooltip: 'Oldest in Queue',
        sortDirection: metricsPerGroupFilter.sortOrder,
        sortActive: metricsPerGroupFilter.sortBy === 'Oldest In Queue',
        onColumnSort: handleColumnSort(setMetricsPerGroupFilter, 'Oldest In Queue'),
      },
    ];

    const filteredColumns = columns.filter((item) => {
      return !appConfig.web.liveDashTableBlacklist.includes(item.dataKey as string);
    });

    return filteredColumns;
  }, [
    metricsPerGroupFilter.sortBy,
    metricsPerGroupFilter.sortOrder,
    JSON.stringify(appConfig.web.liveDashTableBlacklist),
  ]);

  const metricsPerGroupRows = useMemo(() => {
    if (filteredState === undefined || filteredState.length === 0) {
      return [];
    }

    const filteredByGroup = filteredState.filter((asteriskStats) => {
      if (metricsPerGroupFilter.groups.length === 0) return true;

      return metricsPerGroupFilter.groups.find((item) => item.value === asteriskStats.groupId) !== undefined;
    });

    return filteredByGroup
      .map((asteriskStats) => {
        const totalAgents = asteriskStats.agentStatusCounts.reduce((state, currentVal) => state + currentVal.count, 0);
        return {
          ...asteriskStats.vars,
          groupId: asteriskStats.groupId,
          groupName: asteriskStats.groupName,
          maxLinesPerAgent: asteriskStats.systemConfig.maxLinesPerAgent,
          totalAgents: totalAgents,
        };
      })
      .sort(sortObject(metricsPerGroupFilter.sortBy as any, metricsPerGroupFilter.sortOrder));
  }, [filteredState, metricsPerGroupFilter]);

  const globalAgentInStatusColumns: ColumnData[] = useMemo(() => {
    return [
      {
        wrapText: false,
        label: 'ID',
        dataKey: 'id',
        align: 'left',
        copyToClipboardIcon: true,
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'id',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'id'),
      },
      {
        wrapText: false,
        label: 'Username',
        dataKey: 'username',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'username',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'username'),
      },
      {
        wrapText: true,
        label: 'Group Name',
        dataKey: 'groupName',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'groupName',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'groupName'),
      },
      {
        wrapText: true,
        label: 'Status',
        dataKey: 'status',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'status',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'status'),
      },
      {
        wrapText: false,
        label: 'Time In Status',
        dataKey: 'timeInStatus',
        align: 'left',
        sortDirection: globalAgentsInStatusFilter.sortOrder,
        sortActive: globalAgentsInStatusFilter.sortBy === 'timeInStatus',
        onColumnSort: handleColumnSort(setGlobalAgentsInStatusFilter, 'timeInStatus'),
      },
    ];
  }, [globalAgentsInStatusFilter.sortBy, globalAgentsInStatusFilter.sortOrder]);

  const globalAgentInStatusRows = useMemo(() => {
    if (filteredState === undefined || filteredState.length === 0) {
      return [];
    }

    const filteredByGroup = filteredState.filter((asteriskStats) => {
      if (globalAgentsInStatusFilter.groups.length === 0) return true;

      return globalAgentsInStatusFilter.groups.find((item) => item.value === asteriskStats.groupId) !== undefined;
    });

    // TODO: can we improve performance of this reduce to avoid O(n2)
    return filteredByGroup
      .reduce((state, asteriskStats) => {
        const agents = asteriskStats.agents
          // Filter out agents whose statuses do not exist in the status filter list
          .filter((agent) => {
            if (globalAgentsInStatusFilter.statuses === undefined || globalAgentsInStatusFilter.statuses.length === 0) {
              return true;
            }

            return globalAgentsInStatusFilter.statuses.find((item) => item.value === agent.status) !== undefined;
          })
          .map((agent) => ({
            groupId: asteriskStats.groupId,
            groupName: asteriskStats.groupName,
            key: agent.key,
            username: agent.username,
            status: agent.status,
            timeInStatus: agent.timeInStatus,
          }));

        return [...state, ...agents];
      }, [] as Array<any>)
      .sort(sortObject(globalAgentsInStatusFilter.sortBy, globalAgentsInStatusFilter.sortOrder));
  }, [filteredState, globalAgentsInStatusFilter]);

  return (
    <AsyncLoader isLoading={initialLoad}>
      {!socketConnected && (
        <EmptyState
          type='error'
          text='Not Connected'
          subText={`Unable to connect to server: ${predictiveConfig.diallerURL}/stats-ws`}
        />
      )}

      {socketConnected && filteredState.length === 0 && !accessFilter && (
        <EmptyState type='no-items-2' text='No active predictive groups currently exist' />
      )}

      {socketConnected && filteredState.length === 0 && accessFilter && (
        <EmptyState
          type='no-records-found'
          text={`${accessFilter.name} access filter does not have any predictive groups assigned to it`}
        />
      )}

      {socketConnected && filteredState.length > 0 && (
        <>
          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Agents In Status Per Group
            </Typography>

            {filteredState.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No agents connected to system.</i>
              </Typography>
            )}

            {filteredState.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete<ListItem, true, undefined, undefined>
                    multiple
                    fullWidth
                    onChange={onFilterChange('groups', setAgentsInStatusPerGroupFilter)}
                    value={agentsInStatusPerGroupFilter.groups}
                    options={groupsList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='groups' name='groups' label='Groups' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BasicTable
                    height={400}
                    columns={agentsInStatusPerGroupColumns}
                    rows={agentsInStatusPerGroupRows}
                    onRowClick={onTableRowClick}
                  />
                </Grid>
              </Grid>
            )}
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Metrics Per Group
            </Typography>

            {filteredState.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No active groups to show metrics for.</i>
              </Typography>
            )}

            {filteredState.length > 0 && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete<ListItem, true, undefined, undefined>
                      multiple
                      fullWidth
                      onChange={onFilterChange('groups', setMetricsPerGroupFilter)}
                      value={metricsPerGroupFilter.groups}
                      options={groupsList}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            deleteIcon={<CancelIcon />}
                            label={option.label}
                            {...getTagProps({ index })}
                            variant='filled'
                            color='primary'
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} id='groups' name='groups' label='Groups' variant='outlined' />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <BasicTable
                      height={400}
                      columns={metricsPerGroupColumns}
                      rows={metricsPerGroupRows}
                      onRowClick={onTableRowClick}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Global Agents In Status
            </Typography>

            {filteredState.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No agents connected to system.</i>
              </Typography>
            )}

            {filteredState.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete<ListItem, true, undefined, undefined>
                    multiple
                    fullWidth
                    onChange={onFilterChange('groups', setGlobalAgentsInStatusFilter)}
                    value={globalAgentsInStatusFilter.groups}
                    options={groupsList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='groups' name='groups' label='Groups' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete<ListItem, true, undefined, undefined>
                    multiple
                    fullWidth
                    onChange={onFilterChange('statuses', setGlobalAgentsInStatusFilter)}
                    value={globalAgentsInStatusFilter.statuses || []}
                    options={statusList}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          variant='filled'
                          color='primary'
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} id='statuses' name='statuses' label='Statuses' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BasicTable
                    height={400}
                    columns={globalAgentInStatusColumns}
                    rows={globalAgentInStatusRows}
                    onRowClick={onTableRowClick}
                    noResultsMessage='No agents data available.'
                  />
                </Grid>
              </Grid>
            )}
          </ContentSpacer>
        </>
      )}
    </AsyncLoader>
  );
};

export default GroupsDashboard;
