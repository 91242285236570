import React, { useState } from 'react';

import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';

import GlobalSettings from './GlobalSettings';

const enum EditType {
  Global = 'global',
}

const DiallerControls = () => {
  const { hasScope } = useAuth();
  const [edit, setEdit] = useState<EditType | undefined>(undefined);

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  return (
    <GlobalSettings
      isEdit={edit === EditType.Global}
      toggleEdit={hasScope(AccessScope.CanUpdateCampaignGeneralSettings) ? toggleEdit(EditType.Global) : undefined}
    />
  );
};

export default DiallerControls;
