import React, { CSSProperties, ReactNode } from 'react';

interface TabPanelProps {
  style?: CSSProperties;
  children?: ReactNode;
  disablePadding?: boolean;
  index: number;
  value: number;
}

export const TabPanel = ({ style, children, disablePadding, index, value }: TabPanelProps): JSX.Element | null => {
  return value === index ? (
    <div style={{ width: '100%', padding: disablePadding ? undefined : 16, ...style }}>{children}</div>
  ) : null;
};
