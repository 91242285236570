import * as j from '@mojotech/json-type-validation';

export const enum AgentStateTypeWS {
  Routable = 'routable',
  NotRoutable = 'not_routable',
  Offline = 'offline',
}

export const enum ContactStateTypeWS {
  Connecting = 'connecting',
  Connected = 'connected',
  Ended = 'ended',
  Missed = 'missed',
  Error = 'error',
}

export interface AgentStateUpdate {
  state: string;
  stateType: AgentStateTypeWS;
  contactStateType?: ContactStateTypeWS;
  routingProfile: string;
  hasVoice: boolean;
  hasAsync: boolean;
  inVoiceCall: boolean;
  messagingConcurrency: number;
}

export interface AgentState {
  name: string;
  username: string;
  lastSeenTimestamp: Date;
  stateUpdatedTimestamp: Date;
  state: string;
  stateType: AgentStateTypeWS;
  contactStateType?: ContactStateTypeWS;
  routingProfile: string;
  hasVoice: boolean;
  hasAsync: boolean;
  inVoiceCall: boolean;
  messagingConcurrency: number;
}

export interface AmazonConnectMetrics {
  snapshotTime: string;
  contactsInQueue: number;
  oldestContactAge: number;
}

export interface ServerMessage {
  agents: AgentState[] | undefined;
  agentUpdate: AgentState | undefined;
  agentRemoved: string | undefined;
  metrics: AmazonConnectMetrics | undefined;
}

const AgentStateResponseDecoder: j.Decoder<AgentState> = j
  .object({
    name: j.string(),
    username: j.string(),
    last_seen_timestamp: j.string(),
    agent_state_updated_timestamp: j.string(),
    agent_state: j.string(),
    agent_state_type: j.oneOf(
      j.constant(AgentStateTypeWS.Routable),
      j.constant(AgentStateTypeWS.NotRoutable),
      j.constant(AgentStateTypeWS.Offline),
    ),
    contact_state_type: j.oneOf(
      j.constant(ContactStateTypeWS.Connecting),
      j.constant(ContactStateTypeWS.Connected),
      j.constant(ContactStateTypeWS.Ended),
      j.constant(ContactStateTypeWS.Missed),
      j.constant(ContactStateTypeWS.Error),
      j.constant(''),
    ),
    routing_profile: j.string(),
    has_voice: j.boolean(),
    has_async: j.boolean(),
    in_voice_call: j.boolean(),
    messaging_concurrency: j.number(),
  })
  .map((s) => ({
    name: s.name,
    username: s.username,
    lastSeenTimestamp: new Date(s.last_seen_timestamp),
    stateUpdatedTimestamp: new Date(s.agent_state_updated_timestamp),
    state: s.agent_state,
    stateType: s.agent_state_type as AgentStateTypeWS,
    contactStateType: s.contact_state_type !== '' ? (s.contact_state_type as ContactStateTypeWS) : undefined,
    routingProfile: s.routing_profile,
    hasVoice: s.has_voice,
    hasAsync: s.has_async,
    inVoiceCall: s.in_voice_call,
    messagingConcurrency: s.messaging_concurrency,
  }));

const AmazonConnectMetricsResponseDecoder: j.Decoder<AmazonConnectMetrics> = j
  .object({
    snapshot_time: j.string(),
    contacts_in_queue: j.number(),
    oldest_contact_age: j.number(),
  })
  .map((s) => ({
    snapshotTime: s.snapshot_time,
    contactsInQueue: s.contacts_in_queue,
    oldestContactAge: s.oldest_contact_age,
  }));

export const ServerMessageResponseDecoder: j.Decoder<ServerMessage> = j
  .object({
    agents: j.union(j.array(AgentStateResponseDecoder), j.constant(null)),
    agent_update: j.union(AgentStateResponseDecoder, j.constant(null)),
    agent_removed: j.union(j.string(), j.constant(null)),
    metrics: j.union(AmazonConnectMetricsResponseDecoder, j.constant(null)),
  })
  .map((s) => ({
    agents: s.agents || undefined,
    agentUpdate: s.agent_update || undefined,
    agentRemoved: s.agent_removed || undefined,
    metrics: s.metrics || undefined,
  }));
