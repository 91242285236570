import axios, { CancelTokenSource } from 'axios';

import { CreateAccessFilter } from '~pages/SystemManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AccessFilterResponse, AccessFiltersResponseDecoder } from './domain';

export const getAccessFiltersByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<AccessFilterResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = AccessFiltersResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getAccessFilters = async (
  search: string,
  archived: boolean | undefined,
  cancelToken?: CancelTokenSource,
): Promise<AccessFilterResponse | undefined> => {
  const path = '/api/access-filter/';
  const params = {
    search: search.toLowerCase() || undefined,
    archived: archived !== undefined ? archived.toString() : undefined,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = AccessFiltersResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createAccessFilter = async (data: CreateAccessFilter): Promise<void> => {
  const path = '/api/access-filter/';

  let body: any = {
    name: data.name,
    description: data.description,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
