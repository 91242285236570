import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import { DiallerGroupSipQueue } from '~pages/CampaignManagement/domain';

interface Props {
  open: boolean;
  submitting: boolean;
  queue: DiallerGroupSipQueue | null;
  onAccept: (data: DiallerGroupSipQueue) => void;
  onClose: () => void;
}

interface Form {
  priority: number;
  delay: number;
}

const EditQueueModal = ({ open, submitting, queue, onAccept, onClose }: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<Form>({
    defaultValues: {
      priority: 1,
      delay: 0,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (open && queue !== null) {
      setValue('priority', queue.priority);
      setValue('delay', queue.delay);
    }
  }, [open, queue]);

  const onSubmit = handleSubmit(async (data: Form) => {
    if (queue === null) {
      return;
    }

    let submitData: DiallerGroupSipQueue = {
      ...queue,
      priority: data.priority,
      delay: data.delay,
    };

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={queue === null ? 'Edit Queue' : `Edit ${queue.queueName}`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledNumberField
              name='priority'
              control={control}
              rules={{
                required: 'Priority is required.',
                min: {
                  value: 1,
                  message: 'Priority must be greater than 0.',
                },
              }}
              label='Priority'
              disabled={submitting}
              required={true}
              error={Boolean(errors.priority)}
              helperText={errors.priority?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledNumberField
              name='delay'
              control={control}
              rules={{
                required: 'Delay is required.',
                min: {
                  value: 0,
                  message: 'Delay must be 0 or higher.',
                },
              }}
              label='Delay in seconds'
              disabled={submitting}
              required={true}
              error={Boolean(errors.delay)}
              helperText={errors.delay?.message}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' onClick={onClose}>
            Close
          </Button>

          <Button type='submit' variant='contained' disableElevation color='primary'>
            Save
          </Button>
        </>
      }
    />
  );
};

export default EditQueueModal;
