import * as j from '@mojotech/json-type-validation';

export enum SentimentType {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
  Mixed = 'MIXED',
}

export enum ParticipantRoleType {
  System = 'SYSTEM',
  Customer = 'CUSTOMER',
  Agent = 'AGENT',
}

export interface TranscriptRecord {
  id: string;
  content: string;
  sentiment: SentimentType;
  participantId: string;
  participantRole: ParticipantRoleType;
  beginOffsetMillis: number;
  endOffsetMillis: number;
}

export interface Rule {
  category: string;
  phrases: string[];
  bgColor: string;
  textColor: string;
  content: string;
}

const RealtimeTranscriptRecordResponseDecoder: j.Decoder<TranscriptRecord> = j
  .object({
    id: j.string(),
    content: j.string(),
    sentiment: j.oneOf(
      j.constant(SentimentType.Positive),
      j.constant(SentimentType.Neutral),
      j.constant(SentimentType.Negative),
      j.constant(SentimentType.Mixed),
    ),
    participant_id: j.string(),
    participant_role: j.oneOf(
      j.constant(ParticipantRoleType.System),
      j.constant(ParticipantRoleType.Agent),
      j.constant(ParticipantRoleType.Customer),
    ),
    begin_offset_millis: j.number(),
    end_offset_millis: j.number(),
  })
  .map((item) => ({
    id: item.id,
    content: item.content,
    sentiment: item.sentiment as SentimentType,
    participantId: item.participant_id,
    participantRole: item.participant_role,
    beginOffsetMillis: item.begin_offset_millis,
    endOffsetMillis: item.end_offset_millis,
  }));

export const RealtimeTranscriptResponseDecoder: j.Decoder<TranscriptRecord[]> = j.array(
  RealtimeTranscriptRecordResponseDecoder,
);

const RuleRecordResponseDecoder: j.Decoder<Rule> = j
  .object({
    category: j.string(),
    phrases: j.array(j.string()),
    bg_color: j.string(),
    text_color: j.string(),
    content: j.string(),
  })
  .map((item) => ({
    category: item.category,
    phrases: item.phrases,
    bgColor: item.bg_color,
    textColor: item.text_color,
    content: item.content,
  }));

export const RuleResponseDecoder: j.Decoder<Rule[]> = j.array(RuleRecordResponseDecoder);
