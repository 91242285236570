import PhoneIcon from '@mui/icons-material/Phone';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useState } from 'react';

import OberonCard from '~components/OberonCard';
import { Lead, leadStatusColor } from '~pages/CampaignManagement/domain';
import LeadDetailsModal from '~pages/CampaignManagement/LeadListDetails/LeadListLeads/LeadDetailsModal';

type Checked = {
  value: boolean;
  onClick: () => void;
};

interface LeadCardProps {
  lead: Lead;
  checked: Checked;
  canRemoveLeads: boolean;
}

const LeadCard = forwardRef(({ lead, checked, canRemoveLeads }: LeadCardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const statusColor = leadStatusColor[lead.leadStatus];

  // If backgroundColor unknown default to grey
  const baseColor = statusColor?.baseColor || grey['600'];
  // If lightColor unknown default to light grey variant
  const lightColor = statusColor?.lightColor || grey['100'];
  const statusName = lead.leadStatus.replace(/-/gi, ' ');

  const onModalOpen = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  const handleCheckboxClick =
    (onClick: () => void) => (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick();
    };

  return (
    <>
      <OberonCard
        ref={ref}
        onClick={onModalOpen}
        titleFontWeight={400}
        title={lead.name}
        backgroundColor={checked ? grey[50] : undefined}
        subHeader={
          <Chip
            sx={{
              marginTop: 0.5,
              textTransform: 'uppercase',
              fontSize: 10,
              borderRadius: 1,
              height: 20,
              lineHeight: '21px',
              color: '#ffffff',
              fontWeight: 700,
              backgroundColor: baseColor,
            }}
            label={statusName}
          />
        }
        avatar={
          <>
            {canRemoveLeads && (
              <Box sx={{ position: 'absolute', left: -1, zIndex: 1, top: -1 }}>
                <Checkbox
                  onClick={handleCheckboxClick(checked.onClick)}
                  onKeyPress={handleCheckboxClick(checked.onClick)}
                  checked={checked.value}
                />
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    position: 'absolute',
                    left: 12,
                    top: 12,
                    width: 18,
                    height: 18,
                    zIndex: -1,
                    borderRadius: 0.5,
                  }}
                />
              </Box>
            )}

            <Avatar style={{ backgroundColor: lightColor }}>
              <PhoneIcon style={{ color: baseColor, display: 'inline-block', marginTop: 4, fontSize: 27 }} />
            </Avatar>
          </>
        }
        footer={
          <>
            <Typography fontSize={12} fontWeight={400} variant='body1' component='span' color='textPrimary'>
              Endpoints:
            </Typography>{' '}
            <Typography fontSize={12} fontWeight={400} variant='body2' component='span' color='textSecondary'>
              {lead.endpoints.join(', ')}
            </Typography>
          </>
        }
        footerBorderColor={baseColor}
      />

      <LeadDetailsModal open={openModal} listId={lead.listId} leadId={lead.id} onClose={onModalClose} />
    </>
  );
});

export default LeadCard;
