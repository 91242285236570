import Chart, { ChartData, ChartDataSets, ChartTooltipItem } from 'chart.js';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';

import { convertToColor } from '~utils/Functions';

// export type BasicChartData = { [key: string]: ChartLineItem[] };
export type TooltipValueFormat = (value: string, suffix?: string) => string;

export interface ChartLineItem {
  x: string;
  y: number;
}

export interface BasicLineChartData {
  key: string;
  lineColor?: string;
  lineItems: ChartLineItem[];
}

interface BasicLineChartProps {
  xAxis: string[];
  data: BasicLineChartData[];
  yAxisSuffix?: string;
  yAxisPosition?: 'left' | 'right';
  legend?: boolean;
  fillSpace?: boolean;
  tooltipValueFormat?: TooltipValueFormat;
}

const createDataSetItem = (chartData: BasicLineChartData, xAxis: string[], fillSpace: boolean): ChartDataSets => {
  const data = [];

  for (let i = 0; i < xAxis.length; i++) {
    let value = Number.NaN;

    for (let j = 0; j < chartData.lineItems.length; j++) {
      if (
        xAxis[i] === chartData.lineItems[j].x &&
        (chartData.lineItems[j].y !== undefined || chartData.lineItems[j].y !== null)
      ) {
        value = chartData.lineItems[j].y;
        break;
      }
    }

    data.push(value);
  }

  return {
    label: chartData.key,
    data: data,
    borderColor: chartData.lineColor ?? convertToColor(chartData.key),
    backgroundColor: chartData.lineColor ?? convertToColor(chartData.key),
    fill: fillSpace,
  };
};

const generateDataSetItems = (
  chartDataArray: BasicLineChartData[],
  xAxis: string[],
  fillSpace: boolean,
): ChartDataSets[] => {
  const dataSets = [];

  for (let data of chartDataArray) {
    dataSets.push(createDataSetItem(data, xAxis, fillSpace));
  }

  return dataSets;
};

export const BasicLineChart = ({
  xAxis,
  data,
  yAxisSuffix,
  yAxisPosition,
  legend,
  fillSpace,
  tooltipValueFormat,
}: BasicLineChartProps) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartRef.current !== null) {
      const myChartRef = chartRef.current.getContext('2d') as CanvasRenderingContext2D;

      const dataset = generateDataSetItems(data, xAxis, Boolean(fillSpace));

      const chart = new Chart(myChartRef, {
        type: 'line',
        data: {
          labels: xAxis,
          datasets: dataset,
        },
        options: {
          legend: {
            display: legend ?? false,
          },
          tooltips: {
            enabled: true,
            position: 'nearest',
            callbacks: {
              title: (tooltipItems: ChartTooltipItem[], data: ChartData) => {
                if (tooltipItems[0] === undefined) return '';

                // If the label is a string we want to do some more checks to see if its an ISO string date
                if (typeof tooltipItems[0].label === 'string') {
                  const date = DateTime.fromISO(tooltipItems[0]?.label as string);

                  // If valid date we want to return a formatted date
                  if (date.isValid === true) {
                    return date.toFormat('F');
                  }
                }

                return tooltipItems[0].label || '';
              },
              label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
                const datasetIndex = tooltipItem.datasetIndex;

                // Should never be undefined, but thats what the typing says even if wrong
                if (datasetIndex === undefined) {
                  return '';
                }

                const value = tooltipItem?.value || '';

                // Again should never be null in this case, but typing says it can be
                if (data.datasets === undefined) {
                  return '';
                }

                const label = data.datasets[datasetIndex].label || '';
                const valueAfterSuffixCheck = yAxisSuffix ? `${value}${yAxisSuffix}` : value;
                return `${label} - ${
                  tooltipValueFormat ? tooltipValueFormat(value, yAxisSuffix) : valueAfterSuffixCheck
                }`;
              },
            },
          },
          animation: {
            // general animation time
            duration: 0,
          },
          hover: {
            // duration of animations when hovering an item
            animationDuration: 0,
          },
          // animation duration after a resize
          responsiveAnimationDuration: 0,
          scales: {
            xAxes: [
              {
                display: true,
              },
            ],
            yAxes: [
              {
                position: yAxisPosition || 'right',
                ticks: {
                  callback: (value, index, values) => {
                    return yAxisSuffix ? `${value}${yAxisSuffix}` : value;
                  },
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 15,
              left: 15,
              right: 15,
              bottom: 15,
            },
          },
          spanGaps: false,
        },
      });

      return function BasicChartCleanup() {
        chart.destroy();
      };
    }
  }, [xAxis, data, yAxisSuffix, legend, tooltipValueFormat]);

  return (
    <div style={{ position: 'relative', width: '100%', height: 300 }}>
      <canvas ref={chartRef} />
    </div>
  );
};
