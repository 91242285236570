import axios, { CancelTokenSource } from 'axios';

import { DiallerType } from '~pages/CampaignManagement/domain';

import { APIError, UnsupportedStructureError } from '../../services/Errors';
import { GetUserMetricsResponseDecoder, MetricsResponse } from './domain';

export const getMetrics = async (
  dateFrom: string,
  dateTo: string,
  diallerType: DiallerType,
  agents: string[],
  accessFilterId: number | undefined,
  cancelToken?: CancelTokenSource,
): Promise<MetricsResponse> => {
  const path = '/api/webrtc-metrics/';
  let resp;

  const query = {
    date_from: dateFrom,
    date_to: dateTo,
    dialler_type: diallerType,
    agent: agents.length > 0 ? agents : undefined,
    access_filter_id: accessFilterId,
  };

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      params: query,
      paramsSerializer: {
        indexes: null,
      },
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetUserMetricsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
