import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import { DataItem } from '~components/DataItem';
import OberonDialog from '~components/OberonDialog';
import { Disposition, DispositionAttributeItem } from '~pages/CampaignManagement/domain';

interface DispositionDetailsModalProps {
  open: boolean;
  disposition?: Disposition;
  onClose: () => void;
}

const DispositionDetailsModal = ({ open, disposition, onClose }: DispositionDetailsModalProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const title = disposition?.title || '';

  const attributeItems =
    disposition?.attributes.map((attribute: DispositionAttributeItem, index: number) => (
      <Grid key={index} item xs={6}>
        <DataItem
          stacked={true}
          disableMargin={!isExtraSmall && index < 2}
          title={attribute.attribute}
          value={attribute.value}
        />
      </Grid>
    )) || [];

  return (
    <OberonDialog
      open={open}
      closeOnBackdropClick
      onClose={onClose}
      title={title}
      content={
        <>
          <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
            <Grid item xs={12} sm={6}>
              <DataItem stacked disableMargin={!isExtraSmall} title='Code' value={disposition?.code} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked disableMargin={!isExtraSmall} title='Sub Code' value={disposition?.subCode} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DataItem stacked title='Outcome' value={disposition?.outcome} />
            </Grid>

            <Grid item xs={12}>
              <DataItem stacked title='Description' value={disposition?.description} />
            </Grid>
          </Grid>

          <Typography variant='h6' component='h1' gutterBottom>
            <b>Attributes</b>
          </Typography>

          {attributeItems.length === 0 && (
            <Typography variant='body2' component='h1' gutterBottom color='textSecondary'>
              There have been no attribute's selected for this disposition.
            </Typography>
          )}

          {attributeItems.length > 0 && (
            <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
              {attributeItems}
            </Grid>
          )}
        </>
      }
    />
  );
};

export default DispositionDetailsModal;
