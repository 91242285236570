import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import React from 'react';

interface DirectoryCardProps {
  name: string;
  extension?: number;
  divider: boolean;
  onDial: () => Promise<void>;
}

const CustomizedListItem = styled(ListItem)(({ theme }) => ({
  'padding': 16,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const CustomizedListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    display: 'block',
    fontSize: 16,
    paddingRight: 50,
    minWidth: 0,
    textOverflow: 'ellipsis',

    /* Required for text-overflow to do anything */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

// TODO: display phone number and extension
const DirectoryCard = ({ name, extension, divider, onDial }: DirectoryCardProps) => {
  return (
    <CustomizedListItem divider={divider}>
      <CustomizedListItemText primary={name} secondary={`Extension: ${extension || 'N/A'}`} />
      <ListItemSecondaryAction>
        <IconButton onClick={onDial} size='medium' aria-label={`Dial ${name}`}>
          <PhoneForwardedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </CustomizedListItem>
  );
};

export default DirectoryCard;
