import { Organisation } from '~organisations/domain';

import genericAdditionalFields from './additionalOutcomeCaptureFields/generic';
import genericAsyncAdditionalFields from './asyncAdditionalOutcomeCaptureFields/generic';
import genericContactAssessmentForm from './contactAssessmentForms/generic';
import logo from './images/logo.png';

const tsaConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '1': genericAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {
    'general-enquiries': genericAsyncAdditionalFields,
  },
  contactAssessmentForms: {
    '27': genericContactAssessmentForm,
  },
};

export default tsaConfig;
