import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import React, { ReactNode, SyntheticEvent, createContext, useContext, useRef, useState } from 'react';

interface NotificationProviderProps {
  children: ReactNode;
}

type MessageSeverityType = 'error' | 'warning' | 'info' | 'success';

interface SnackbarMessage {
  referenceId?: string;
  message: string;
  severity: MessageSeverityType;
  key: number;
}

interface NotificationProviderContext {
  pushNotification: (severity: MessageSeverityType, message: string) => void;
  pushNotificationWithReferenceId: (severity: MessageSeverityType, message: string, referenceId: string) => void;
}

export const NotificationContext = createContext<NotificationProviderContext | undefined>(undefined);

// This function should only be used by child components wrapped in the NotificationProvider component
export const useNotification = (): NotificationProviderContext => {
  /**
   *
   * This context is always available at the time of this functions usage, so we do this illegal cast to
   * avoid needing redundant null checks elsewhere.
   */
  return useContext(NotificationContext) as NotificationProviderContext;
};

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const queueRef = useRef<SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift());
      setOpen(true);
    }
  };

  const processNewMessage = () => {
    if (open) {
      // immediately begin dismissing current message
      // to start showing new one
      setOpen(false);
      processQueue();
    } else {
      processQueue();
    }
  };

  const pushNotification = (severity: MessageSeverityType, message: string) => {
    queueRef.current.push({
      severity,
      message,
      key: new Date().getTime(),
    });

    processNewMessage();
  };

  const pushNotificationWithReferenceId = (severity: MessageSeverityType, referenceId: string, message: string) => {
    queueRef.current.push({
      severity,
      referenceId,
      message,
      key: new Date().getTime(),
    });

    processNewMessage();
  };

  const handleClose = (event: SyntheticEvent<Element, Event> | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Slide}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert
          sx={{ boxShadow: 1 }}
          variant='filled'
          severity={(messageInfo && messageInfo.severity) || 'info'}
          onClose={handleClose}>
          {messageInfo && messageInfo.referenceId && (
            <Typography variant='caption' display='block'>
              Reference Id: {messageInfo.referenceId}
            </Typography>
          )}
          {messageInfo && messageInfo.message}
        </Alert>
      </Snackbar>

      <NotificationContext.Provider value={{ pushNotification, pushNotificationWithReferenceId }}>
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export default NotificationProvider;
