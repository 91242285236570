import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  CallbackFilterValues,
  CallbackResponse,
  CallbacksResponseDecoder,
  CreateCallback,
  SortColumnValues,
  SortOrder,
  UpdateCallback,
} from './domain';

export const getCallbacksByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<CallbackResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = CallbacksResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getCallbacks = async (
  search: string | undefined,
  filter: CallbackFilterValues | undefined,
  accessFilterId: number | undefined,
  listId: number[] | undefined,
  campaignId: number[] | undefined,
  agentUsername: string[] | undefined,
  dateFrom: string | undefined,
  dateTo: string | undefined,
  sortColumn: SortColumnValues | undefined,
  sortOrder: SortOrder | undefined,
  assignedToGroup: boolean | undefined,
  cancelToken?: CancelTokenSource,
): Promise<CallbackResponse | undefined> => {
  const path = `/api/callbacks/`;
  const params = {
    search: search ? search.toLowerCase() : undefined,
    filter: filter,
    access_filter_id: accessFilterId,
    list_id: listId,
    campaign_id: campaignId,
    agent_username: agentUsername,
    date_from: dateFrom,
    date_to: dateTo,
    sort_column: sortColumn,
    sort_order: sortOrder,
    assigned_to_group: assignedToGroup ?? undefined,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      paramsSerializer: {
        indexes: null,
      },
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = CallbacksResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export async function createCallback(data: CreateCallback) {
  const path = `/api/callbacks/`;
  const body = {
    lead_id: data.leadId,
    scheduled: data.scheduled,
    for_agent: data.forAgent,
    endpoint: data.endpoint,
    notes: data.notes,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
}

export async function updateCallback(callbackId: number, data: UpdateCallback) {
  const path = `/api/callbacks/${callbackId}`;
  const body = {
    scheduled: data.scheduled,
    for_agent: data.forAgent,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
}

export async function removeCallback(callbackId: number) {
  const path = `/api/callbacks/${callbackId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
}
