import CancelIcon from '@mui/icons-material/Cancel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import ButtonMenu from '~components/ButtonMenu';
import CheckboxButton from '~components/CheckboxButton';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import BulkRemoveConfirmModal from '~pages/CampaignManagement/LeadListDetails/LeadListLeads/BulkRemoveConfirmModal';
import useCampaignListLeadSearch from '~pages/CampaignManagement/LeadListDetails/useCampaignListLeadSearch';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { sortObject } from '~utils/Functions';

import { Lead, LeadStatusType } from '../../domain';
import LeadCard from './LeadCard';

interface Query {
  search?: string;
  leadStatus?: { label: string; value: LeadStatusType }[];
}

interface BulkManagement {
  leadsToInclude: Lead[];
  leadsToExclude: Lead[];
  search?: string;
  leadStatus: LeadStatusType[];
  totalLeads: number;
  selectAll: boolean;
}

const bulkManagementDefault: BulkManagement = Object.freeze({
  leadsToInclude: [],
  leadsToExclude: [],
  search: undefined,
  leadStatus: [],
  totalLeads: 0,
  selectAll: false,
});

const leadStatusList = [
  {
    label: 'Contacted',
    value: LeadStatusType.Contacted,
  },
  {
    label: 'Washed',
    value: LeadStatusType.Washed,
  },
  {
    label: 'Filtered',
    value: LeadStatusType.Filtered,
  },
  {
    label: 'Opt-out',
    value: LeadStatusType.OptOut,
  },
  {
    label: 'No Endpoints',
    value: LeadStatusType.NoEndpoints,
  },
  {
    label: 'Excluded',
    value: LeadStatusType.Excluded,
  },
  {
    label: 'Duplicate',
    value: LeadStatusType.Duplicate,
  },
  {
    label: 'Building',
    value: LeadStatusType.Building,
  },
  {
    label: 'Awaiting Retry',
    value: LeadStatusType.AwaitingRetry,
  },
  {
    label: 'Awaiting Start',
    value: LeadStatusType.AwaitingStart,
  },
  {
    label: 'Ready',
    value: LeadStatusType.Ready,
  },
  {
    label: 'Out of Hours',
    value: LeadStatusType.OutOfHours,
  },
  {
    label: 'Finished',
    value: LeadStatusType.Finished,
  },
  {
    label: 'Finished Today',
    value: LeadStatusType.FinishedToday,
  },
  {
    label: 'Callback',
    value: LeadStatusType.Callback,
  },
  {
    label: 'No Skilled Agents',
    value: LeadStatusType.NoSkilledAgents,
  },
  {
    label: 'Assigned',
    value: LeadStatusType.Assigned,
  },
  {
    label: 'Initiated',
    value: LeadStatusType.Initiated,
  },
  {
    label: 'Connected',
    value: LeadStatusType.Connected,
  },
  {
    label: 'Invalid Endpoint',
    value: LeadStatusType.InvalidEndpoint,
  },
  {
    label: 'Disconnected',
    value: LeadStatusType.Disconnected,
  },
  {
    label: 'Removed',
    value: LeadStatusType.Removed,
  },
  {
    label: 'Expired',
    value: LeadStatusType.Expired,
  },
  {
    label: 'Awaiting SMS',
    value: LeadStatusType.AwaitingSMS,
  },
  {
    label: 'Inactive List',
    value: LeadStatusType.InactiveList,
  },
  {
    label: 'Missed Callback',
    value: LeadStatusType.MissedCallback,
  },
  {
    label: 'In Queue',
    value: LeadStatusType.InQueue,
  },
  {
    label: 'Awaiting Callback',
    value: LeadStatusType.AwaitingCallback,
  },
  {
    label: 'Replaced',
    value: LeadStatusType.Replaced,
  },
  {
    label: 'Snoozed',
    value: LeadStatusType.Snoozed,
  },
].sort(sortObject('label', 'asc'));

const calculateSelectionCount = (bulkManagement: BulkManagement) => {
  let total = bulkManagement.leadsToInclude.length;
  if (bulkManagement.selectAll) {
    total = bulkManagement.totalLeads + bulkManagement.leadsToInclude.length - bulkManagement.leadsToExclude.length;

    if (total < 0) {
      total = 0;
    }
  }

  return total;
};

// NOTE: This function needs to act the same as the backend DB query that is used to filter the list of leads that will
// be updated as a result of a bulk action.
const leadMatchesSearch = (search: string | undefined, lead: Lead) => {
  // If no search term default true
  if (!search) return true;
  const searchArray = search.split(' ');
  const externalId = lead.externalId.toLowerCase();
  const name = lead.name.toLowerCase();
  const endpoints = lead.endpoints.map((item) => item.toLowerCase());

  for (let term of searchArray) {
    if (externalId.includes(term.toLowerCase())) {
      return true;
    }
    if (name.includes(term.toLowerCase())) {
      return true;
    }
    if (endpoints.some((e) => e.includes(term.toLowerCase()))) {
      return true;
    }
  }

  return false;
};

const leadMatchesStatus = (leadStatus: LeadStatusType[], lead: Lead) => {
  return leadStatus.length > 0 ? leadStatus.some((item) => item === lead.leadStatus) : true;
};

const isLeadChecked = (bulkManagement: BulkManagement, lead: Lead) => {
  let flag = false;
  const includeAgent = bulkManagement.leadsToInclude.find((l) => l.id === lead.id);
  const excludeAgent = bulkManagement.leadsToExclude.find((l) => l.id === lead.id);

  if (!bulkManagement.selectAll) {
    const includeAgent = bulkManagement.leadsToInclude.find((l) => l.id === lead.id);
    if (includeAgent) {
      flag = true;
    }
  } else {
    if (leadMatchesSearch(bulkManagement.search, lead) && leadMatchesStatus(bulkManagement.leadStatus, lead)) {
      if (!excludeAgent) {
        flag = true;
      }
    } else {
      if (includeAgent) {
        flag = true;
      }
    }
  }

  return flag;
};

const isSelectAllChecked = (bulkManagement: BulkManagement, totalLeads: number) => {
  let flag = false;
  const count = calculateSelectionCount(bulkManagement);

  if (count >= totalLeads) {
    flag = true;
  }

  return flag;
};

const isSelectAllIndeterminate = (bulkManagement: BulkManagement, totalLeads: number) => {
  let flag = false;
  const count = calculateSelectionCount(bulkManagement);

  if (bulkManagement.selectAll && count < totalLeads) {
    flag = true;
  } else if (!bulkManagement.selectAll && bulkManagement.leadsToInclude.length > 0 && count < totalLeads) {
    flag = true;
  }

  return flag;
};

const LeadListLeads = () => {
  const { hasScope } = useAuth();
  const { pushNotification } = useNotification();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const [query, setQuery] = useState<Query>({
    search: undefined,
    leadStatus: undefined,
  });
  const debouncedSearch = useDebounce(query.search, 500);
  const {
    loading,
    error,
    leads,
    total: totalLeads,
    hasMore,
    intersectionObserverRef: lastDataElement,
    downloadLink,
    bulkRemove: bulkRemoveLeads,
  } = useCampaignListLeadSearch(+campaignId, +listId, {
    search: debouncedSearch,
    leadStatus: query.leadStatus?.map((item) => item.value),
  });
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState<boolean>(false);
  const [bulkManagement, setBulkManagement] = useState<BulkManagement>(bulkManagementDefault);
  const canBulkAction =
    bulkManagement.selectAll || bulkManagement.leadsToInclude.length > 0 || bulkManagement.leadsToExclude.length > 0;
  const canRemoveLeads = hasScope(AccessScope.CanRemoveLeads);
  const noSearchOrFilterSet = !query.search && !query.leadStatus;
  const selectionCount = useMemo(() => calculateSelectionCount(bulkManagement), [bulkManagement]);
  const selectAllChecked = isSelectAllChecked(bulkManagement, totalLeads);
  const selectAllIndeterminate = isSelectAllIndeterminate(bulkManagement, totalLeads);

  // Handle select all value reset on specific cases
  useEffect(() => {
    if (
      bulkManagement.selectAll &&
      bulkManagement.leadsToInclude.length === 0 &&
      bulkManagement.leadsToExclude.length >= bulkManagement.totalLeads
    ) {
      setBulkManagement(bulkManagementDefault);
    } else if (
      bulkManagement.selectAll &&
      bulkManagement.leadsToInclude.length > 0 &&
      bulkManagement.leadsToExclude.length >= bulkManagement.totalLeads
    ) {
      setBulkManagement((prev) => ({ ...bulkManagementDefault, leadsToInclude: prev.leadsToInclude }));
    }
  }, [bulkManagement, totalLeads]);

  const toggleBulkDeleteOpen = () => {
    setOpenBulkDeleteModal((prev) => !prev);
  };

  const onSearchChange = async (e: ChangeEvent<any>) => {
    const { value } = e.target;
    setQuery((prev) => ({ ...prev, search: value }));
  };

  const onLeadStatusChange = (e: ChangeEvent<{}>, value: any) => {
    setQuery((prev) => ({ ...prev, leadStatus: value }));
  };

  const toggleSelectAll = useCallback(
    (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
      setBulkManagement((prev) => {
        if (prev.selectAll) {
          return { ...bulkManagementDefault, selectAll: false };
        }

        return {
          ...bulkManagementDefault,
          leadStatus: query.leadStatus?.map((item) => item.value) || [],
          search: debouncedSearch,
          totalLeads: totalLeads,
          selectAll: true,
        };
      });
    },
    [totalLeads, debouncedSearch, query.leadStatus],
  );

  const onLeadSelection = (lead: Lead) => () => {
    setBulkManagement((prev) => {
      let update: BulkManagement;
      if (prev.selectAll && leadMatchesSearch(prev.search, lead) && leadMatchesStatus(prev.leadStatus, lead)) {
        const index = prev.leadsToExclude.findIndex((item) => item.id === lead.id);
        if (index === -1) {
          update = {
            ...prev,
            leadsToExclude: [...prev.leadsToExclude, lead],
          };
        } else {
          update = {
            ...prev,
            leadsToExclude: prev.leadsToExclude.filter((item) => item.id !== lead.id),
          };
        }
      } else {
        const index = prev.leadsToInclude.findIndex((item) => item.id === lead.id);
        if (index === -1) {
          update = {
            ...prev,
            leadsToInclude: [...prev.leadsToInclude, lead],
          };
        } else {
          update = {
            ...prev,
            leadsToInclude: prev.leadsToInclude.filter((item) => item.id !== lead.id),
          };
        }
      }

      return update;
    });
  };

  const onBulkRemoveLeads = async () => {
    try {
      await bulkRemoveLeads({
        selectAll: bulkManagement.selectAll,
        leadIds: bulkManagement.leadsToInclude.map((item) => item.id),
        excludedLeadIds: bulkManagement.leadsToExclude.map((item) => item.id),
        search: bulkManagement.search,
        leadStatus: bulkManagement.leadStatus,
      });
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', 'You have successfully updated the list.');
    setBulkManagement(bulkManagementDefault);
    setOpenBulkDeleteModal(false);
  };

  const displayList = useMemo(
    () =>
      leads.map((item, index) => {
        const checked = isLeadChecked(bulkManagement, item);
        const props = {
          ref: index === leads.length - 1 ? lastDataElement : undefined,
          key: item.id,
          lead: item,
          canRemoveLeads: canRemoveLeads,
          checked: {
            value: checked,
            onClick: onLeadSelection(item),
          },
        };

        return <LeadCard {...props} />;
      }),
    [bulkManagement, leads, lastDataElement, canRemoveLeads],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField fullWidth variant='outlined' label='Search' id='search' name='search' onChange={onSearchChange} />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            multiple
            fullWidth
            onChange={onLeadStatusChange}
            value={query.leadStatus || []}
            options={leadStatusList}
            filterSelectedOptions
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  deleteIcon={<CancelIcon />}
                  label={option.label}
                  {...getTagProps({ index })}
                  variant='filled'
                  color='primary'
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} id='leadStatus' name='leadStatus' label='Lead Status' variant='outlined' />
            )}
          />
        </Grid>

        {!canRemoveLeads && (
          <Hidden smDown>
            <Grid item md={3}></Grid>
          </Hidden>
        )}

        {canRemoveLeads && (
          <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonMenu
              title='Actions'
              disabled={!canBulkAction}
              actions={[
                {
                  title: 'Remove Leads',
                  func: () => {
                    toggleBulkDeleteOpen();
                  },
                },
              ]}
            />
          </Grid>
        )}

        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            fullWidth
            variant='contained'
            href={downloadLink}
            disableElevation
            startIcon={<CloudDownloadIcon />}
            color='primary'
            title='download list'>
            Download
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignContent='center'>
        <Grid item xs={12}>
          <Box sx={{ marginBottom: 2 }}>
            <CheckboxButton
              checked={selectAllChecked}
              indeterminate={selectAllIndeterminate}
              disabled={loading || leads.length === 0}
              onClick={toggleSelectAll}
            />

            {(bulkManagement.selectAll || bulkManagement.leadsToInclude.length > 0) && (
              <Typography sx={{ marginLeft: 1 }} variant='body2' color='textSecondary' component='span'>
                {selectionCount.toLocaleString()} leads selected
              </Typography>
            )}
          </Box>

          <AsyncLoader isLoading={loading && leads.length === 0}>
            <Grid item xs={12}>
              {leads.length > 0 && (
                <>
                  <List>{displayList}</List>
                  {loading && leads.length > 0 && <DotLoader align='center' />}
                  {!loading && !hasMore && (
                    <Typography variant='body2' align='center' color='textSecondary'>
                      No more results to display
                    </Typography>
                  )}
                  {error && leads.length > 0 && (
                    <Typography variant='body2' align='center' color='textSecondary'>
                      Failed to load leads
                    </Typography>
                  )}
                </>
              )}

              {leads.length === 0 && !noSearchOrFilterSet && (
                <EmptyState
                  type='no-records-found'
                  text='No leads found matching your search criteria'
                  subText='Try alternate words or selections.'
                />
              )}

              {leads.length === 0 && noSearchOrFilterSet && <EmptyState type='no-items-2' text='No leads available' />}
            </Grid>
          </AsyncLoader>
        </Grid>
      </Grid>

      <BulkRemoveConfirmModal
        open={openBulkDeleteModal}
        count={selectionCount}
        onClose={toggleBulkDeleteOpen}
        onAccept={onBulkRemoveLeads}
      />
    </>
  );
};

export default LeadListLeads;
