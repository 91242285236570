import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import SectionCard from '~components/SectionCard';

interface Props {
  archived: string | undefined;
  archiveAccessFilter: () => Promise<void>;
}

const AccessFilterArchiveForm = ({ archived, archiveAccessFilter }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <SectionCard title='Archive Access Filter'>
        {!archived && (
          <>
            <Typography variant='body1' component='p' paragraph>
              Click below to archive this access filter.
            </Typography>

            <Button variant='contained' disableElevation onClick={toggleOpen} color='secondary'>
              Archive
            </Button>
          </>
        )}

        {archived && (
          <Typography variant='body1' component='p' paragraph>
            This access filter has been archived as of {DateTime.fromISO(archived).toFormat('FFF')}.
          </Typography>
        )}
      </SectionCard>

      <ActionDialog
        open={open}
        title='Are you sure you want to do this?'
        content='You are about to archive this acess filter, once you have completed this action it cannot be undone.'
        onClose={toggleOpen}
        onAccept={archiveAccessFilter}
        primaryActionTitle='Archive'
      />
    </>
  );
};

export default AccessFilterArchiveForm;
