import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { ValidatorType } from '~hooks/useForm';
import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting, channelType }: OutcomesFormProps) => {
  useEffect(() => {
    if (channelType === 'chat') {
      form.addSchemaProperties({
        external_id: {
          value: '',
          validators: [
            {
              type: ValidatorType.Required,
              message: 'External ID is required',
            },
          ],
        },
      });

      return () => {
        form.removeSchemaProperties(['external_id']);
      };
    }
  }, [form.fields]);

  return (
    <>
      {channelType === 'chat' && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant='outlined'
            id='external_id'
            name='external_id'
            label='Lead ID'
            disabled={formSubmitting}
            value={form.fields?.external_id?.value || ''}
            error={Boolean(form.errors.external_id)}
            helperText={form.errors.external_id && form.errors.external_id[0]!}
            onChange={form.handleInputChange}
          />
        </Grid>
      )}
    </>
  );
};

export default OutcomesForm;
