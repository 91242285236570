import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { ValidatorType } from '~hooks/useForm';
import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      store_id: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'Store ID is Required.',
          },
          {
            type: ValidatorType.Regex,
            pattern: /^\d+$/,
            message: 'Store ID must be numeric.',
          },
        ],
      },
    });

    return () => {
      form.removeSchemaProperties(['store_id']);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='store_id'
          name='store_id'
          label='Store ID'
          disabled={formSubmitting}
          value={form.fields?.store_id?.value || ''}
          error={Boolean(form.errors.store_id)}
          helperText={form.errors.store_id && form.errors.store_id[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;
