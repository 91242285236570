import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import ReplyIcon from '@mui/icons-material/Reply';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import { AgentState, AgentStateTypeWS } from '~services/AgentStatesManager/domain';

interface TransferableAgentListItem {
  variant?: 'voice' | 'messaging';
  divider: boolean;
  agent: AgentState;
  disabled?: boolean;
  onTransferToAgent: () => void;
}

const CustomizedListItem = styled(ListItem)(() => ({
  padding: 16,
}));

const CustomizedListItemText = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    userSelect: 'none',
    display: 'block',
    fontSize: 16,
    paddingRight: 50,
    minWidth: 0,
    textOverflow: 'ellipsis',

    /* Required for text-overflow to do anything */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

interface Status {
  name: string;
  color: string;
}

const stateTypeData: { [key in AgentStateTypeWS]: Status } = {
  [AgentStateTypeWS.Routable]: {
    name: 'Available',
    color: green[600],
  },
  [AgentStateTypeWS.NotRoutable]: {
    name: 'Not Available',
    color: red[800],
  },
  [AgentStateTypeWS.Offline]: {
    name: 'Offline',
    color: blueGrey[800],
  },
};

const TransferableAgentListItem = ({
  variant = 'messaging',
  divider,
  agent,
  disabled,
  onTransferToAgent,
}: TransferableAgentListItem) => {
  const isTransferable = agent.stateType === AgentStateTypeWS.Routable;
  const statusInfo: Status = stateTypeData[agent.stateType];
  // If backgroundColor unknown default to grey
  const statusName = statusInfo?.name || 'Unknown';
  // If lightColor unknown default to light grey variant
  const statusColor = statusInfo?.color || blueGrey['800'];
  const buttonIconStyles = variant === 'voice' ? {} : { transform: 'scaleX(-1)' };
  const ButtonIcon = variant === 'voice' ? PhoneForwardedIcon : ReplyIcon;
  const inVoiceCallColor = agent.inVoiceCall ? red[800] : blue[500];

  return (
    <CustomizedListItem divider={divider}>
      <CustomizedListItemText
        primary={
          <Typography fontSize={14} fontWeight={700}>
            {agent.name}
          </Typography>
        }
        secondary={
          <>
            <Typography sx={{ marginBottom: 0.5 }} fontSize={14}>
              {agent.username}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
              <Chip
                sx={{ fontSize: 12, fontWeight: 500, backgroundColor: statusColor, color: '#ffffff' }}
                size='small'
                color='primary'
                label={
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <PersonIcon sx={{ fontSize: 16, marginRight: 0.25 }} /> {statusName}
                  </Box>
                }
              />

              <Chip
                sx={{ fontSize: 12, fontWeight: 500, backgroundColor: inVoiceCallColor, color: '#ffffff' }}
                size='small'
                label={
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <PhoneIcon sx={{ fontSize: 16, marginRight: 0.5 }} /> {agent.inVoiceCall ? 'In Call' : 'N/A'}
                  </Box>
                }
              />

              <Chip
                sx={{ fontSize: 12, fontWeight: 500 }}
                size='small'
                color='primary'
                label={
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <ChatBubbleIcon sx={{ fontSize: 16, marginRight: 0.75 }} /> {agent.messagingConcurrency}
                  </Box>
                }
              />
            </Box>
          </>
        }
      />

      <ListItemSecondaryAction>
        <IconButton
          onClick={() => onTransferToAgent()}
          size='medium'
          disabled={!isTransferable || Boolean(disabled)}
          aria-label={`Transfer to ${agent.name}`}>
          <ButtonIcon sx={buttonIconStyles} />
        </IconButton>
      </ListItemSecondaryAction>
    </CustomizedListItem>
  );
};

export default TransferableAgentListItem;
