import { Add } from '@mui/icons-material';
import { Button, Grid, Hidden, List, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import useTransfers from '~hooks/useTransfers';
import { CreateTransfer, UpdateTransfer } from '~hooks/useTransfers/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import CreateTransferModal from './CreateTransferModal';
import EditTransferModal from './EditTransferModal';
import TransferCard from './TransferCard';

function TransferList() {
  const { pushNotification } = useNotification();
  const [editableRef, setEditableRef] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const {
    list: transfers,
    intersectionObserverRef: lastTransferElementRef,
    loading,
    error,
    create,
    update,
    remove,
    hasMore,
  } = useTransfers({
    search: debouncedSearch,
  });
  const editableTransfer = useMemo(() => transfers.find((item) => item.id === editableRef), [editableRef]);
  const filtersEnabled = debouncedSearch !== undefined && debouncedSearch != '';

  const toggleCreateTransferModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onTransferCreate = async (data: CreateTransfer) => {
    try {
      await create(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    pushNotification('success', `You have successfully create transfer ${data.name}`);
    setCreateModalOpen(false);
  };

  const onTransferUpdate = async (transferId: number, data: UpdateTransfer) => {
    try {
      await update(transferId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    pushNotification('success', `You have successfully updated transfer ${data.name}.`);
    setEditableRef(undefined);
  };

  const onTransferRemove = (transferId: number) => async () => {
    try {
      await remove(transferId);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    pushNotification('success', 'You have successfully removed the transfer.');
  };

  const displayList = useMemo(() => {
    return transfers.map((item, index) => (
      <TransferCard
        key={item.id}
        ref={index === transfers.length - 1 ? lastTransferElementRef : undefined}
        transfer={item}
        onEdit={() => setEditableRef(item.id)}
        onRemove={onTransferRemove(item.id)}
      />
    ));
  }, [transfers]);

  return (
    <>
      <Grid container spacing={1} alignContent='center'>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Grid>

        <Hidden smDown>
          <Grid item md={5}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<Add />}
            onClick={toggleCreateTransferModal}>
            Create Transfer
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && transfers.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {displayList.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {!loading && error && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load transfer destinations
                  </Typography>
                )}
              </>
            )}

            {!loading && transfers.length === 0 && filtersEnabled && (
              <EmptyState
                type='no-records-found'
                text='No transfer destinations found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {!loading && transfers.length === 0 && !filtersEnabled && (
              <EmptyState type='no-items-3' text='No transfer destinations currently exist' />
            )}
          </Grid>
        </Grid>
      </AsyncLoader>

      <CreateTransferModal open={createModalOpen} onAccept={onTransferCreate} onClose={toggleCreateTransferModal} />

      <EditTransferModal
        open={Boolean(editableTransfer)}
        transfer={editableTransfer}
        onAccept={onTransferUpdate}
        onClose={() => {
          setEditableRef(undefined);
        }}
      />
    </>
  );
}

export default TransferList;
