import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { triggerDiallerGroupReload } from '~pages/CampaignManagement/api';
import {
  deletePredictiveAgentStatus,
  getPredictiveAgentStatuses,
  postPredictiveAgentStatus,
  putPredictiveAgentStatuses,
} from '~pages/SystemManagement/api';
import { PredictiveAgentStatus } from '~pages/SystemManagement/domain';
import CreateEditStatusModal from '~pages/SystemManagement/PredictiveAgentStatuses/CreateEditStatusModal';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import PredictiveStatusCard from './PredictiveStatusCard';

const noResults = false;

interface Error {
  text: string;
  subText: string;
}

const PredictiveAgentStatuses = () => {
  const { pushNotification } = useNotification();
  const appConfig = useAppConfiguration();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editableStatus, setEditableStatus] = useState<PredictiveAgentStatus | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [agentStatuses, setAgentStatuses] = useState<PredictiveAgentStatus[]>([]);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setAgentStatuses(await getPredictiveAgentStatuses());
      } catch (e) {
        handleError(e);
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeModal = () => {
    setCreateModalOpen(false);
    setEditableStatus(undefined);
  };

  const reloadPredictiveCampaigns = async () => {
    if (appConfig.extensions.predictive !== undefined) {
      // ask the predictive dialler to reload its copy of the dialler groups after this was successfully created,
      // if the predictive extension is enabled.
      await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
    }
  };

  const deleteStatus = (data: string) => async () => {
    setSubmittingData(true);

    try {
      await deletePredictiveAgentStatus(data);
      await reloadPredictiveCampaigns();
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Deleted agent status ${data}`);

    try {
      setAgentStatuses(await getPredictiveAgentStatuses());
    } catch (e) {
      handleError(e);
    }
  };

  const createStatus = async (data: PredictiveAgentStatus) => {
    setSubmittingData(true);

    try {
      await postPredictiveAgentStatus(data);
      await reloadPredictiveCampaigns();
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created status ${data.agentStatus}`);

    try {
      setAgentStatuses(await getPredictiveAgentStatuses());
    } catch (e) {
      handleError(e);
    } finally {
      closeModal();
    }
  };

  const reorderList = async (e: DropResult) => {
    if (!e.destination) {
      return;
    }

    const fromIndex = e.source.index;
    const toIndex = e.destination?.index;

    if (toIndex == fromIndex) {
      return;
    }

    setSubmittingData(true);

    let element = agentStatuses[fromIndex];
    agentStatuses.splice(fromIndex, 1);
    agentStatuses.splice(toIndex, 0, element);

    for (let i = 0; i < agentStatuses.length; i++) {
      agentStatuses[i].position = i + 1;
    }

    try {
      await putPredictiveAgentStatuses(agentStatuses);
      await reloadPredictiveCampaigns();
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Updated agent status order`);

    try {
      setAgentStatuses(await getPredictiveAgentStatuses());
    } catch (e) {
      handleError(e);
    }
  };

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : undefined;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {/*There should always be the online/offline states, here just in case*/}
      {noResults && (
        <EmptyState
          type='no-items-3'
          text='No predictive agent states found'
          subText='Create a predictive agent state by clicking the button below'
          action={openCreateModal}
          actionText='Create predictive agent state'
        />
      )}
      {!noResults && (
        <Grid container spacing={1} alignContent='center'>
          <Hidden smDown>
            <Grid item md={9}></Grid>
          </Hidden>

          <Grid item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={openCreateModal}>
              Create Agent Status
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={reorderList}>
              <Droppable droppableId='droppable'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {agentStatuses.map((item, index) => (
                      <Draggable key={item.agentStatus} draggableId={item.agentStatus} index={index}>
                        {(provided) => {
                          const deleteFunc =
                            item.agentStatus == 'Not Ready' || item.agentStatus == 'Available'
                              ? undefined
                              : deleteStatus(item.agentStatus);
                          return (
                            <PredictiveStatusCard
                              isDragDisabled={submittingData}
                              draggableProvided={provided}
                              agentStatus={item}
                              onDelete={deleteFunc}
                            />
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      )}

      <CreateEditStatusModal
        open={createModalOpen || Boolean(editableStatus)}
        submitting={submittingData}
        predictiveStatus={editableStatus}
        onClose={closeModal}
        onAccept={createStatus}
      />
    </AsyncLoader>
  );
};

export default PredictiveAgentStatuses;
