import * as j from '@mojotech/json-type-validation';

import { DispositionAttributeItem } from '~pages/CampaignManagement/domain';

export interface AsyncQueue {
  queue: string;
  title: string;
  description: string;
  channelType: string;
  isPublic: boolean;
  systemEndpoint: string;
}

export type CreateEditAsyncQueue = Omit<AsyncQueue, 'queue' | 'channelType' | 'systemEndpoint'> & {
  queue: string | undefined;
  channelType: string | undefined;
  systemEndpoint: string | undefined;
  accessFilterIds: number[] | undefined;
};

export interface QueueDisposition {
  code: string;
  subCode: string;
  outcome: string;
  title: string;
  description: string;
  attributes: DispositionAttributeItem[];
}

export interface UpdateQueueDisposition {
  originalCode: string;
  originalSubCode: string;
  disposition: QueueDisposition;
}

const QueueDispositionAttributeDecoder: j.Decoder<DispositionAttributeItem> = j
  .object({
    attribute: j.string(),
    value: j.string(),
  })
  .map((item) => ({
    attribute: item.attribute,
    value: item.value,
  }));

const QueueDispositionDecoder: j.Decoder<QueueDisposition> = j
  .object({
    code: j.string(),
    sub_code: j.string(),
    outcome: j.string(),
    title: j.string(),
    description: j.string(),
    attributes: j.array(QueueDispositionAttributeDecoder),
  })
  .map((item) => ({
    code: item.code,
    subCode: item.sub_code,
    outcome: item.outcome,
    title: item.title,
    description: item.description,
    attributes: item.attributes || [],
  }));

export const AsyncQueueDecoder: j.Decoder<AsyncQueue> = j
  .object({
    queue: j.string(),
    title: j.string(),
    description: j.string(),
    channel_type: j.string(),
    is_public: j.boolean(),
    system_endpoint: j.string(),
  })
  .map((item) => ({
    queue: item.queue,
    title: item.title,
    description: item.description,
    channelType: item.channel_type,
    isPublic: item.is_public,
    systemEndpoint: item.system_endpoint,
  }));

export const GetAsyncQueueDispositionsDecoder: j.Decoder<QueueDisposition[]> = j.array(QueueDispositionDecoder);
