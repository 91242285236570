import { Buffer } from 'buffer';

import { deflate } from 'pako';
// @ts-ignore
import { v4 as uuid } from 'uuid';

export const getDeobfucatedValue = async (
  authEndpoint: string, // Shall be provided by environment.
  deobfuscateEndpoint: string, // Shall be provided by environment.
  contactId: string,
  token: string,
): Promise<string> => {
  let jwt = '';

  try {
    const res = await fetch(authEndpoint, {
      credentials: 'include',
      method: 'GET',
      headers: {
        RequestVerb: 'GET',
        ProxyName: 'Customers_V2',
      },
    });

    if (res.status !== 200) {
      throw new Error(`Unexpected status code ${res.status} ${res.statusText} from ${authEndpoint}`);
    }

    jwt = await res.text();
  } catch (e) {
    throw e;
  }

  const compressedJwt = 'compressedToBase64' + Buffer.from(deflate(Buffer.from(jwt))).toString('base64');

  let result: { [key: string]: string } = {};
  try {
    const res = await fetch(deobfuscateEndpoint, {
      method: 'POST',
      headers: {
        'BNZAPI-Request-Id': uuid(),
        'Authorization': compressedJwt,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      cache: 'no-cache',
      body: JSON.stringify({ contactId, token }),
    });

    if (res.status !== 200) {
      throw new Error(`Unexpected status code ${res.status} ${res.statusText} from ${deobfuscateEndpoint}`);
    }

    result = await res.json();
  } catch (e) {
    throw e;
  }

  return result['deobfuscatedValue'];
};
