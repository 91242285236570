import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef, useState } from 'react';

import { getGroupRoutingProfile, getGroupsRoutingProfile } from '~hooks/useDiallerGroupRoutingProfiles/api';

import { DiallerGroupRoutingProfile } from './domain';

type DataType = DiallerGroupRoutingProfile[] | DiallerGroupRoutingProfile | undefined;

export const isDiallerGroupRoutingProfileList = (
  data: DiallerGroupRoutingProfile[] | DiallerGroupRoutingProfile,
): data is DiallerGroupRoutingProfile[] => {
  return (data as DiallerGroupRoutingProfile[]).length !== undefined;
};

type Options = {
  diallerGroupId?: number;
  /** Will be ignored if diallerGroupId option is set */
  accessFilterId?: number;
  shouldFetch: boolean;
};

const useDiallerGroupRoutingProfiles = (
  options: Options = { diallerGroupId: undefined, accessFilterId: undefined, shouldFetch: true },
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<DataType>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const diallerGroupId = options.diallerGroupId;
  const accessFilterId = options.accessFilterId;
  const shouldFetch = options.shouldFetch ?? true;

  useEffect(() => {
    setData(undefined);
  }, [diallerGroupId, accessFilterId, shouldFetch]);

  useEffect(() => {
    const load = async (diallerGroupId: number | undefined, accessFilterId: number | undefined) => {
      setLoading(true);
      setError(false);

      let resp: DataType | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();

        if (diallerGroupId) {
          resp = await getGroupRoutingProfile(diallerGroupId, axiosCancelRef.current);
        } else {
          resp = await getGroupsRoutingProfile(accessFilterId, axiosCancelRef.current);
        }
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setData(resp);
      setLoading(false);
    };

    if (shouldFetch) {
      load(diallerGroupId, accessFilterId);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [diallerGroupId, accessFilterId, shouldFetch]);

  return { loading, error, data };
};

export default useDiallerGroupRoutingProfiles;
