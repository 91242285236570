import Button, { ButtonProps } from '@mui/material/Button';
import lightBlue from '@mui/material/colors/lightBlue';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React from 'react';

import keys from './keys';

interface Keypad {
  disabled?: boolean;
  translucentButtons?: boolean;
  onKeyClick: (digit: string) => void;
}

const KeypadGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  width: '100%',
  gap: '5px',
  justifyItems: 'stretch',
  alignItems: 'stretch',
});

type KeyProps = MUIStyledCommonProps<Theme> & ButtonProps & { translucentButtons: boolean };

const Key = styled(Button, { shouldForwardProp: (prop) => prop !== 'translucentButtons' })<KeyProps>(
  ({ translucentButtons }) => ({
    'display': 'block',
    'backgroundColor': translucentButtons ? 'rgba(255, 255, 255, .10)' : lightBlue[900],
    ':hover': {
      backgroundColor: translucentButtons ? 'rgba(255, 255, 255, .5)' : lightBlue[800],
    },
    ':disabled': {
      color: '#ffffff80',
      backgroundColor: translucentButtons ? 'rgba(255, 255, 255, .20)' : `${lightBlue[900]}80`,
    },
  }),
);

const Digit = styled('div')({
  fontSize: 16,
  fontWeight: 700,
});

type AlphaNumericProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    hasPadding: boolean;
  };

const AlphaNumeric = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasPadding',
})<AlphaNumericProps>(({ hasPadding }) => ({
  fontSize: 12,
  fontWeight: 400,
  height: hasPadding ? 21 : undefined,
}));

const Keypad = ({ disabled, translucentButtons, onKeyClick }: Keypad) => {
  const keyList = keys.map((key, index) => (
    <Key
      key={index}
      variant='contained'
      color='primary'
      onClick={() => onKeyClick(key.digit)}
      disabled={disabled}
      translucentButtons={Boolean(translucentButtons)}
      disableElevation>
      <Digit>{key.digit}</Digit>
      <AlphaNumeric hasPadding={!key.alphaNumeric}>{key.alphaNumeric}</AlphaNumeric>
    </Key>
  ));

  return <KeypadGrid>{keyList}</KeypadGrid>;
};

export default Keypad;
