import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getLeads, getLeadsByNextUrl } from './api';
import { Lead, LeadsResponse } from './domain';

type Options = {
  leadStatus?: string[];
  accessFilterId?: number;
  shouldFetch?: boolean;
};

const useLeadList = (
  search: string,
  options: Options = { leadStatus: undefined, accessFilterId: undefined, shouldFetch: true },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<Lead[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const leadStatus = options.leadStatus;
  const accessFilterId = options.accessFilterId;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: LeadsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLeadsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.list]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: LeadsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getLeads(search, leadStatus, accessFilterId, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.list);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [search, JSON.stringify(leadStatus), accessFilterId]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  useEffect(() => {
    setList([]);
  }, [search, JSON.stringify(leadStatus), accessFilterId, shouldFetch]);

  useEffect(() => {
    const load = async (search: string, leadStatus?: string[], accessFilterId?: number) => {
      setLoading(true);
      setError(false);

      let resp: LeadsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLeads(search, leadStatus, accessFilterId, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.list]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(search, leadStatus, accessFilterId);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [search, JSON.stringify(leadStatus), accessFilterId, shouldFetch]);

  return { loading, error, list, hasMore, reload, intersectionObserverRef };
};

export default useLeadList;
