import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import Routes from '~providers/RouteProvider/Routes';

import { ContactDetails } from '../../domain';

interface ContactSummaryProps {
  contactDetails: ContactDetails;
}

const DATE_FORMAT = 'FFF';

const formatDate = (date: string, formatString: string) => {
  if (date) {
    return DateTime.fromISO(date).toFormat(formatString);
  }
  return '-';
};

const ContactSummary = ({ contactDetails }: ContactSummaryProps) => {
  const {
    web: { additionalContactDetailAttributes },
  } = useAppConfiguration();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const navigateToContactDetails = (contactId: string) =>
    generatePath(Routes.contactLogDetails.path, { contactId: contactId });

  const attributeKeys = Object.keys(contactDetails.attributes);
  let additionalDisplayAttributes = null;

  // We only want to do this logic if the backend returns at least one attribute
  // and if we have additional attributes we want to display
  if (attributeKeys.length > 0 && additionalContactDetailAttributes.length > 0) {
    additionalDisplayAttributes = additionalContactDetailAttributes.map((item, index) => (
      <DataItem
        key={index}
        stacked={isExtraSmall}
        title={item.label}
        value={contactDetails.attributes[item.key] ?? undefined}
      />
    ));
  }

  const originalContactIdDisplay = contactDetails.originalContactId ? (
    <Link
      underline='hover'
      fontSize={14}
      component={RouterLink}
      to={navigateToContactDetails(contactDetails.originalContactId)}>
      {contactDetails.originalContactId}
    </Link>
  ) : null;

  const previousContactIdDisplay = contactDetails.previousContactId ? (
    <Link
      underline='hover'
      fontSize={14}
      component={RouterLink}
      to={navigateToContactDetails(contactDetails.previousContactId)}>
      {contactDetails.previousContactId}
    </Link>
  ) : null;

  const nextContactIdDisplay = contactDetails.nextContactId ? (
    <Link
      underline='hover'
      fontSize={14}
      component={RouterLink}
      to={navigateToContactDetails(contactDetails.nextContactId)}>
      {contactDetails.nextContactId}
    </Link>
  ) : null;

  return (
    <SectionCard title='Contact Summary'>
      <DataItem
        stacked={isExtraSmall}
        disableMargin
        copyToClipboardIcon
        title='Contact ID'
        value={contactDetails.contactId}
      />
      <DataItem stacked={isExtraSmall} title='Original contact ID' value={originalContactIdDisplay} />
      <DataItem stacked={isExtraSmall} title='Previous contact ID' value={previousContactIdDisplay} />
      <DataItem stacked={isExtraSmall} title='Next contact ID' value={nextContactIdDisplay} />
      <DataItem stacked={isExtraSmall} title='External ID' value={contactDetails.externalId} />
      <DataItem stacked={isExtraSmall} title='Endpoint' value={contactDetails.customerEndpointNumber} />
      <DataItem stacked={isExtraSmall} title='Campaign ID' value={contactDetails.campaignId} />
      <DataItem stacked={isExtraSmall} title='Campaign name' value={contactDetails.campaignName} />
      <DataItem stacked={isExtraSmall} title='Agent' value={contactDetails.agent} />
      <DataItem stacked={isExtraSmall} title='Channel' value={contactDetails.channel} />
      <DataItem stacked={isExtraSmall} title='Initiation method' value={contactDetails.initiationMethod} />
      <DataItem
        stacked={isExtraSmall}
        title='Initiation timestamp'
        value={formatDate(contactDetails.initiationTimestamp, DATE_FORMAT)}
      />
      <DataItem
        stacked={isExtraSmall}
        title='Disconnect timestamp'
        value={formatDate(contactDetails.disconnectedTimestamp, DATE_FORMAT)}
      />
      <DataItem stacked={isExtraSmall} title='Disconnect reason' value={contactDetails.disconnectReason} />
      <DataItem stacked={isExtraSmall} title='Agent connection attempts' value={contactDetails.connectionAttempts} />
      <DataItem stacked={isExtraSmall} title='Disposition code' value={contactDetails.dispositionCode} />
      <DataItem stacked={isExtraSmall} title='Disposition sub code' value={contactDetails.dispositionSubCode} />
      <DataItem stacked={isExtraSmall} title='Disposition title' value={contactDetails.dispositionTitle} />
      <DataItem
        stacked={isExtraSmall}
        title='Last updated'
        value={formatDate(contactDetails.lastUpdatedTimestamp, DATE_FORMAT)}
      />
      <DataItem stacked={isExtraSmall} title='System issue' value={contactDetails.hasSystemIssue ? 'Yes' : 'No'} />
      <DataItem stacked={isExtraSmall} title='System issue description' value={contactDetails.systemIssueDescription} />

      {additionalDisplayAttributes}
    </SectionCard>
  );
};

export default ContactSummary;
