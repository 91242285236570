import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabPanel } from '~components/TabPanel';
import DiallerGroupList from '~pages/CampaignManagement/DiallerGroupList';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';

import CampaignList from './CampaignList';
import DiallerControls from './DiallerControls';
import GroupsDashboard from './GroupsDashboard';

// Used to manage visible tab selection
enum ViewTabsType {
  DiallerGroups,
  Campaigns,
  GroupsDashboard,
  DiallerControls,
}

// default tab, fallback to this tab when no permission on specified tab
const defaultViewTab = ViewTabsType.DiallerGroups;

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const CampaignManagement = () => {
  const { hasScope } = useAuth();
  const setPageTitleProps = useSetPageTitleProps();
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[defaultViewTab],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'Dialler Configuration' });
  }, []);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const canViewDiallerControls = hasScope(AccessScope.CanViewDiallerControls);

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        Dialler Configuration
      </Typography>

      <Tabs
        orientation='horizontal'
        variant='scrollable'
        onChange={onTabChange}
        value={tabIndex}
        indicatorColor='primary'
        aria-label='Vertical tabs example'>
        <Tab label='Groups' {...a11yProps(ViewTabsType.DiallerGroups)} value={ViewTabsType.DiallerGroups} />
        <Tab label='Campaigns' {...a11yProps(ViewTabsType.Campaigns)} value={ViewTabsType.Campaigns} />
        <Tab
          label='Groups Dashboard'
          {...a11yProps(ViewTabsType.GroupsDashboard)}
          value={ViewTabsType.GroupsDashboard}
        />

        {canViewDiallerControls && (
          <Tab
            label='Dialler Controls'
            {...a11yProps(ViewTabsType.DiallerControls)}
            value={ViewTabsType.DiallerControls}
          />
        )}
      </Tabs>
      <Divider variant='fullWidth' component='hr' />

      <TabPanel value={tabIndex} index={ViewTabsType.DiallerGroups}>
        <DiallerGroupList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.Campaigns}>
        <CampaignList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.GroupsDashboard}>
        <GroupsDashboard />
      </TabPanel>

      {canViewDiallerControls && (
        <TabPanel value={tabIndex} index={ViewTabsType.DiallerControls}>
          <DiallerControls />
        </TabPanel>
      )}
    </>
  );
};

export default CampaignManagement;
