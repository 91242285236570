import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import SiteLayout from '~components/SiteLayout';
import { useRoutes } from '~providers/RouteProvider';
import Page from '~providers/RouteProvider/Page';

const Pages = () => {
  const { defaultLoginPage, routes } = useRoutes();
  const navigate = useNavigate();
  const location = useLocation();

  // Handles initial login redirect to the first dynamic route
  // Is there a better way to do this???
  useEffect(() => {
    if (location.pathname === '/' && defaultLoginPage !== undefined) {
      navigate(defaultLoginPage.path, { replace: true });
    }
  }, [location.pathname, defaultLoginPage]);

  const routeDisplay = routes.map(({ path, component: Component }: Page, index: number) =>
    Component ? <Route key={index} path={path} element={<Component />} /> : null,
  );

  return (
    <SiteLayout>
      <Routes>{routeDisplay}</Routes>
    </SiteLayout>
  );
};

export default Pages;
