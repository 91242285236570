import CallIcon from '@mui/icons-material/Call';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import Timeline from '@mui/lab/Timeline/Timeline';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import React from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';

import { DataItem } from '~components/DataItem';
import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useLead from '~pages/CampaignManagement/LeadListDetails/useLead';
import { AttemptCreationContext } from '~providers/AttemptProvider/domain';
import Routes from '~providers/RouteProvider/Routes';

import { leadEndpointStatusColor, leadStatusColor } from '../../../domain';

interface LeadDetailsModalProps {
  open: boolean;
  listId: number;
  leadId: number;
  onClose: () => void;
}

interface AttributeItem {
  key: string;
  value: string;
}

const attemptCreationContextToName: { [key in AttemptCreationContext]: string } = {
  [AttemptCreationContext.Standard]: 'Outbound',
  [AttemptCreationContext.ManualPrepared]: 'Manual Outbound',
  [AttemptCreationContext.ReceivedTransfer]: 'Transfer',
  [AttemptCreationContext.Callback]: 'Callback',
  [AttemptCreationContext.Inbound]: 'Inbound',
  [AttemptCreationContext.UnknownInbound]: 'Inbound',
  [AttemptCreationContext.ManagedInboundQueue]: 'Inbound',
};

const LeadDetailsModal = ({ open, listId, leadId, onClose }: LeadDetailsModalProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading, data: lead, error } = useLead(listId, open ? leadId : undefined);
  const name = lead?.name || '';
  const id = lead?.id;
  const externalId = lead?.externalId;
  const priority = lead?.priority;
  const addedTimestamp = lead?.addedTimestamp ? DateTime.fromISO(lead?.addedTimestamp).toFormat('FFF') : null;
  const startTimestamp = lead?.startTimestamp ? DateTime.fromISO(lead?.startTimestamp).toFormat('FFF') : null;
  const expirationTimestamp = lead?.expirationTimestamp
    ? DateTime.fromISO(lead?.expirationTimestamp).toFormat('FFF')
    : null;
  const nextLeadRecheck = lead?.recheckTimestamp ? DateTime.fromISO(lead?.recheckTimestamp).toFormat('FFF') : null;
  const timezone = lead?.timezone;
  const endpoints = lead?.endpoints || [];
  const attributes = lead?.attributes
    ? Object.keys(lead.attributes).map((key: string): AttributeItem => ({ key: key, value: lead.attributes[key] }))
    : [];
  const endpointAttributes = lead?.endpointAttributes || {};
  const requiredSkills = lead?.requiredSkills || [];
  const attempts = lead?.attempts || [];
  // If color unknown default to grey
  const statusBackgroundColor = lead?.leadStatus ? leadStatusColor[lead.leadStatus]?.baseColor : grey['600'];
  const statusName = lead?.leadStatus?.replace(/-/gi, ' ');
  const leadStatusDetail = lead?.leadStatusDetail;

  let dialogTitle = name;
  if (loading) {
    dialogTitle = 'Loading...';
  } else if (error) {
    dialogTitle = 'Lead Fetch Error';
  }

  const navigateToContactDetails = (contactId: string) =>
    generatePath(Routes.contactLogDetails.path, { contactId: contactId });

  const endpointItems = endpoints.map((endpoint, index) => (
    <Grid key={index} item xs={12} sm={12}>
      {index > 0 && <Divider component='hr' />}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '8px 0' }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
            <Box>
              <Chip
                style={{
                  backgroundColor:
                    leadEndpointStatusColor[endpointAttributes[endpoint]?.status]?.baseColor || grey['600'],
                }}
                sx={{
                  textTransform: 'uppercase',
                  borderRadius: 0.5,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: 'white',
                  fontSize: 10,
                  fontWeight: theme.typography.fontWeightBold,
                  height: 20,
                  lineHeight: '21px',
                }}
                label={endpointAttributes[endpoint].status.replace(/-/gi, ' ')}
              />
            </Box>

            <Typography variant='body2' component='h1' color='textSecondary'>
              {endpoint}
            </Typography>
          </Box>

          <Box>
            <Typography variant='body2' component='h1' color='textSecondary' fontSize={12}>
              <b>Type:</b> {endpointAttributes[endpoint].type || '-'}
            </Typography>
          </Box>
        </Box>

        <Typography sx={{ flex: 1 }} variant='body2' component='h1' color='textSecondary'>
          <Box>
            <b>Next Recheck:</b>
          </Box>
          {endpointAttributes[endpoint].recheckTimestamp
            ? DateTime.fromISO(endpointAttributes[endpoint].recheckTimestamp).toFormat('FFF')
            : '-'}
        </Typography>
      </Box>
    </Grid>
  ));

  const attributeItems = attributes.map((attribute: AttributeItem, index: number) => (
    <Grid key={index} item xs={12} sm={6}>
      <DataItem
        stacked={true}
        disableMargin={!isExtraSmall && index < 2}
        title={attribute.key}
        value={attribute.value}
      />
    </Grid>
  ));

  const requiredSkillItems = requiredSkills.map((skill: string, index: number) => (
    <Grid key={index} item xs={12} sm={6}>
      <Typography variant='body2' component='h1' color='textSecondary'>
        {skill}
      </Typography>
    </Grid>
  ));

  const attemptItems = attempts.map((attempt, index: number) => (
    <TimelineItem
      key={index}
      sx={{
        ':before': {
          content: '""',
          flex: '0',
          padding: 0,
        },
      }}>
      <TimelineSeparator>
        <TimelineDot>
          <CallIcon />
        </TimelineDot>
        {index !== attempts.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} sx={{ padding: '6px 16px' }}>
          <Typography marginRight={2} fontSize={16} fontWeight='bold' align='left' color='textPrimary'>
            {DateTime.fromISO(attempt.timestamp).toFormat('FFF')}
          </Typography>

          <Typography fontSize={14} align='left' color='textSecondary'>
            <b>Context:</b> {attemptCreationContextToName[attempt.creationContext] || attempt.creationContext}
          </Typography>

          <Typography fontSize={14} align='left' color='textSecondary'>
            <b>Endpoint:</b> {attempt.endpoint}
          </Typography>

          <Typography fontSize={14} align='left' color='textSecondary'>
            <b>Contact ID:</b>{' '}
            {attempt.contactId ? (
              <Link
                underline='hover'
                fontSize={14}
                component={RouterLink}
                to={navigateToContactDetails(attempt.contactId)}>
                {attempt.contactId}
              </Link>
            ) : (
              'No Call interaction'
            )}
          </Typography>

          <Typography fontSize={14} align='left' color='textSecondary'>
            <b>Outcome:</b> {attempt.outcome || '-'}
          </Typography>

          <Typography fontSize={14} align='left' color='textSecondary'>
            <b>Disposition Code:</b> {attempt.dispositionCode || '-'}
          </Typography>

          <Typography fontSize={14} align='left' color='textSecondary'>
            <b>Disposition Sub Code:</b> {attempt.dispositionSubCode || '-'}
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  ));

  return (
    <OberonDialog
      open={open}
      closeOnBackdropClick
      onClose={onClose}
      title={dialogTitle}
      subHeader={
        !loading &&
        !error &&
        lead && (
          <>
            <Chip
              style={{ backgroundColor: statusBackgroundColor }}
              sx={{
                textTransform: 'uppercase',
                borderRadius: 0.5,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'white',
                fontSize: 10,
                fontWeight: theme.typography.fontWeightBold,
                height: 20,
                lineHeight: '21px',
              }}
              label={statusName}
            />
            {leadStatusDetail && (
              <Typography variant='caption' component='h1' color='textSecondary'>
                * {leadStatusDetail}
              </Typography>
            )}
          </>
        )
      }
      content={
        <>
          {loading && <DotLoader />}

          {!loading && error && !lead && (
            <Typography variant='body2' component='h1' color='textSecondary'>
              {error}
            </Typography>
          )}

          {!loading && !error && lead && (
            <>
              <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
                <Grid item xs={12} sm={6}>
                  <DataItem stacked disableMargin={!isExtraSmall} title='Lead ID' value={id} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked disableMargin={!isExtraSmall} title='Lead External ID' value={externalId} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked title='Added Timestamp' value={addedTimestamp} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked title='Start Timestamp' value={startTimestamp} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked title='Expiration Timestamp' value={expirationTimestamp} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked title='Next Recheck' value={nextLeadRecheck} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked title='Timezone' value={timezone} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DataItem stacked title='Lead Priority' value={priority} />
                </Grid>
              </Grid>

              <Typography variant='h6' component='h1' gutterBottom>
                <b>Endpoints</b>
              </Typography>

              <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
                {endpointItems}
              </Grid>

              <Typography variant='h6' component='h1' gutterBottom>
                <b>Attributes</b>
              </Typography>

              <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
                {attributeItems}
              </Grid>

              <Typography variant='h6' component='h1' gutterBottom>
                <b>Required Skills</b>
              </Typography>

              {requiredSkillItems.length === 0 && (
                <Typography variant='body2' component='h1' gutterBottom color='textSecondary'>
                  There are no required agent skills needed for this lead.
                </Typography>
              )}

              {requiredSkillItems.length > 0 && (
                <Grid container sx={{ marginBottom: `calc(32px - ${theme.spacing(2)})` }} spacing={0}>
                  {requiredSkillItems}
                </Grid>
              )}

              <Typography variant='h6' component='h1' gutterBottom>
                <b>Attempt Timeline</b>
              </Typography>

              {attemptItems.length === 0 && (
                <Typography variant='body2' component='h1' gutterBottom color='textSecondary'>
                  There have been no attempts to contact this lead.
                </Typography>
              )}

              {attemptItems.length > 0 && <Timeline>{attemptItems}</Timeline>}
            </>
          )}
        </>
      }
    />
  );
};

export default LeadDetailsModal;
