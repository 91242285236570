import React from 'react';

interface AudioProps {
  id: string;
  src?: string;
  canDownload?: boolean;
  style?: any;
}

const Audio = ({ id, src, canDownload, style }: AudioProps) => {
  const audioProps = {
    id: id,
    src: src,
    controls: true,
    controlsList: canDownload ? undefined : 'nodownload',
  };

  return (
    <div style={style}>
      <audio {...audioProps} />
    </div>
  );
};

export default Audio;
