import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { Fragment, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';

import { DispositionAttribute } from '../../domain';

interface CreateEditAttributeModalProps {
  open: boolean;
  submitting: boolean;
  dispositionAttribute?: DispositionAttribute;
  onAccept: (isEdit: boolean, data: DispositionAttribute) => void;
  onClose: () => void;
}

interface Form {
  attribute: string;
  values: { value: string }[];
}

const CreateEditAttributeModal = ({
  open,
  submitting,
  dispositionAttribute,
  onAccept,
  onClose,
}: CreateEditAttributeModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(dispositionAttribute);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      attribute: '',
      values: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'values',
    shouldUnregister: true,
  });

  // Handles all async based actions
  useEffect(() => {
    // If create attribute, lets enforce that we must have at least one value set to submit the form
    if (open && dispositionAttribute === undefined) {
      append({ value: '' });
    }

    // If edit lets prefill all required fields
    if (open && dispositionAttribute !== undefined) {
      setValue('attribute', dispositionAttribute.attribute);
      dispositionAttribute.values.forEach((item) => append({ value: item }));
    }

    // Reset form on close
    return function cleanupCreateEditAttributeModal() {
      reset();
      remove();
    };
  }, [open, dispositionAttribute]);

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept(isEdit, {
        attribute: data.attribute,
        values: data.values.map((item) => item.value),
      });

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  const attributeItems = fields.map((field: any, index: number) => (
    <Fragment key={field.id}>
      <Grid sx={{ display: 'flex' }} item xs={2}>
        <LoadingButton
          sx={{
            flex: 1,
            // Height of default sized input field
            maxHeight: 56,
          }}
          variant='contained'
          disableElevation
          color='secondary'
          disabled={submitting || fields.length === 1}
          loading={submitting}
          onClick={() => remove(index)}>
          <DeleteIcon />
        </LoadingButton>
      </Grid>
      <Grid item xs={10}>
        <Controller
          name={`values.${index}.value` as const}
          control={control}
          rules={{ required: 'Value is required.' }}
          defaultValue={field.value}
          render={({ field }) => (
            <TextField
              fullWidth
              variant='outlined'
              label='Value'
              disabled={submitting}
              required={true}
              error={Boolean(errors?.values?.[index]?.value)}
              helperText={errors?.values?.[index]?.value?.message}
              {...field}
            />
          )}
        />
      </Grid>
    </Fragment>
  ));

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Attribute`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='attribute'
              control={control}
              rules={{
                required: 'Attribute is required.',
                pattern: {
                  value: /^[0-9a-z_]+$/,
                  message: 'Attribute can only be lowercase, alphanumeric and contain underscores.',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Attribute'
                  disabled={submitting || isEdit}
                  required={true}
                  error={Boolean(errors.attribute)}
                  helperText={errors.attribute?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {attributeItems}

          <Grid item xs={12}>
            <Button
              variant='contained'
              disableElevation
              fullWidth
              color='primary'
              onClick={() => append({ value: '' })}>
              Add A Value
            </Button>
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditAttributeModal;
