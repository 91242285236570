import SocketManagerBase from '~services/SocketManagerBase';
import { assertUnreachable } from '~utils/Functions';

import { ServerMessage, ServerMessageResponseDecoder, TransferTarget } from './domain';

interface AsyncCallbacks {
  onConnected: () => void;
  onMessage: (socketMessage: ServerMessage) => void;
  onDisconnected: (closeCode: number) => void;
}

interface VoiceCallData {
  connectedTimestamp?: string;
  contactId: string;
  initialContactId: string;
  initiationMethod: string;
  queue: string;
  queueARN: string;
  queueTimestamp: string;
  state: string;
  stateTimestamp: string;
}

export class AsyncManager extends SocketManagerBase {
  private heartbeatIntervalRef: number | undefined;
  private callbacks: AsyncCallbacks = {
    onConnected: () => {},
    onMessage: () => {},
    onDisconnected: () => {},
  };

  constructor(address: string, callbacks: Partial<AsyncCallbacks>) {
    super('AsyncManager', address);

    // Assign optional functions else default to empty default values
    this.callbacks.onConnected = callbacks.onConnected || this.callbacks.onConnected;
    this.callbacks.onMessage = callbacks.onMessage || this.callbacks.onMessage;
    this.callbacks.onDisconnected = callbacks.onDisconnected || this.callbacks.onDisconnected;
  }

  /** marks if an agent is on a voice call or not */
  public setAgentVoiceCall = (voiceCallData?: VoiceCallData): void => {
    console.log('AsyncManager action set agent on voice call triggered.');

    if (this.ws === undefined) {
      console.error('setAgentOnVoiceCall: websocket missing.');
      return;
    }

    let req = {
      agent_voice_call: {},
    };

    if (voiceCallData != undefined) {
      req = {
        ...req,
        agent_voice_call: {
          current_voice_call: {
            connected_timestamp: voiceCallData.connectedTimestamp,
            contact_id: voiceCallData.contactId,
            initial_contact_id: voiceCallData.initialContactId,
            initiation_method: voiceCallData.initiationMethod,
            queue: voiceCallData.queue,
            queue_arn: voiceCallData.queueARN,
            queue_timestamp: voiceCallData.queueTimestamp,
            state: voiceCallData.state,
            state_timestamp: voiceCallData.stateTimestamp,
          },
        },
      };
    }

    this.send(JSON.stringify(req));
  };

  /** sets the agent to a specific state */
  public setAgentState = (state: string): void => {
    console.log('AsyncManager action set state triggered.');

    if (this.ws === undefined) {
      console.error('setAgentState: websocket missing.');
      return;
    }

    const req = {
      agent_state_change: {
        next_state: state,
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Sends new message */
  public sendMessage = (conversationId: number, message: string): void => {
    console.log('AsyncManager action send message triggered.');

    if (this.ws === undefined) {
      console.error('sendMessage: websocket missing.');
      return;
    }

    const req = {
      message: {
        conversation_id: conversationId,
        message: {
          text: message,
        },
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Loads past conversations from conversation specified */
  public loadMoreConversationsFrom = (conversationId: number): void => {
    console.log('AsyncManager action load more messages triggered.');

    if (this.ws === undefined) {
      console.error('loadMoreConversationsFrom: websocket missing.');
      return;
    }

    const req = {
      load_previous_conversations_from: conversationId,
    };

    this.send(JSON.stringify(req));
  };

  /** Marks specified messages as read by id */
  public markMessagesAsRead = (messageIds: number[]): void => {
    console.log('AsyncManager mark messages as read triggered.');

    if (this.ws === undefined) {
      console.error('markMessagesAsRead: websocket missing.');
      return;
    }

    const req = {
      messages_read: messageIds,
    };

    this.send(JSON.stringify(req));
  };

  /** Marks specified messages as delivered by id */
  public markMessagesAsDelivered = (messageIds: number[]): void => {
    console.log('AsyncManager mark messages as delivered triggered.');

    if (this.ws === undefined) {
      console.error('markMessagesAsDelivered: websocket missing.');
      return;
    }

    const req = {
      messages_delivered: messageIds,
    };

    this.send(JSON.stringify(req));
  };

  /** Marks specified conversation as disposed */
  public markConversationAsDisposed = (
    conversationId: number,
    dispositionCode: string,
    dispositionSubCode: string,
    attributes: { [key: string]: string },
  ): void => {
    console.log('AsyncManager mark conversation as disposed triggered.');

    if (this.ws === undefined) {
      console.error('markMessagesAsDelivered: websocket missing.');
      return;
    }

    const req = {
      conversation_disposition: {
        conversation_id: conversationId,
        disposition_code: dispositionCode,
        disposition_sub_code: dispositionSubCode,
        attributes: attributes,
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Transfers customer conversation to a specified queue or agent */
  public transferTo = (transferTarget: TransferTarget, conversationId: number, value: string): void => {
    console.log('AsyncManager transfer conversation to queue triggered.');

    if (this.ws === undefined) {
      console.error('transferTo: websocket missing.');
      return;
    }

    let transferTo: { [key: string]: any } = {
      conversation_id: conversationId,
    };

    switch (transferTarget) {
      case TransferTarget.Queue: {
        transferTo = { ...transferTo, queue: value };
        break;
      }
      case TransferTarget.Agent: {
        transferTo = { ...transferTo, agent: value };
        break;
      }
      default: {
        assertUnreachable(
          transferTarget,
          `Transfer target '${transferTarget}' is not one of the expected values, how did we get here?`,
        );
      }
    }

    const req = {
      transfer_to: transferTo,
    };

    this.send(JSON.stringify(req));
  };

  /** Event that is triggered on the successful connection of the websocket */
  protected onOpen = (): void => {
    if (this.ws === undefined) {
      console.error('onOpen: websocket missing.');
      return;
    }

    const socket = this.ws;
    // Post heartbeat to let the socket know we are still here
    this.heartbeatIntervalRef = window.setInterval(() => {
      const req = {
        heartbeat: '',
      };

      socket.send(JSON.stringify(req));
    }, 15_000);

    this.callbacks.onConnected();
  };

  /** Event handler that manages all responses from the connected websocket */
  protected onMessage = (messageEvent: MessageEvent): void => {
    let msg: ServerMessage;

    try {
      const rawMessage = JSON.parse(messageEvent.data);
      msg = ServerMessageResponseDecoder.runWithException(rawMessage);
    } catch (e) {
      console.error('onMessage: Unable to decode message payload: ', e);
      return;
    }

    this.callbacks.onMessage(msg);
  };

  /** Event handler that is triggered if the websocket is closed/ ended */
  protected onClose = (closeEvent: CloseEvent): void => {
    console.log('AsyncManager websocket closed with code: ', closeEvent.code);

    clearInterval(this.heartbeatIntervalRef);
    this.ws = undefined;
    this.callbacks.onDisconnected(closeEvent.code);
  };
}
