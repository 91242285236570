import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';
import Button from '@mui/material/Button';
import React, { MouseEvent } from 'react';

type Props = {
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const CheckboxButton = ({ checked, indeterminate, disabled = false, onClick }: Props) => {
  let Icon = CheckBoxOutlineBlank;
  if (indeterminate) {
    Icon = IndeterminateCheckBox;
  } else if (checked) {
    Icon = CheckBox;
  }

  return (
    <Button variant='contained' onClick={onClick} disableElevation disabled={disabled} startIcon={<Icon />}>
      Select All
    </Button>
  );
};

export default CheckboxButton;
