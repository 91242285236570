import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import grey from '@mui/material/colors/grey';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useEffect, useState } from 'react';

import ContentSpacer from '~components/ContentSpacer';
import { DataItem } from '~components/DataItem';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getContactContext } from './api';
import { ContactContext as ContactContextInterface } from './domain';

interface ContactContextProps {
  endpoint: string;
}

const isUrl = (value: string): boolean => {
  try {
    new URL(value);
  } catch (e) {
    return false;
  }

  return true;
};

const ContactContext = ({ endpoint }: ContactContextProps) => {
  const {
    web: { additionalContactContextAttributes },
  } = useAppConfiguration();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [contactContext, setContactContext] = useState<ContactContextInterface[]>([]);
  // Array of open panels keyed on their array index
  const [openPanels, setOpenPanels] = useState<number[]>([0]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const hasContactContext = contactContext.length > 0;

  useEffect(() => {
    if (endpoint) {
      fetchContactContext();
    }

    return () => {
      setContactContext([]);
      setOpenPanels([0]);
      setLoading(true);
      setError('');
    };
  }, [endpoint]);

  const fetchContactContext = async () => {
    setOpenPanels([0]);
    setLoading(true);
    setError('');

    // Amazon passes the string anonymous when a private number calls in.
    // Due to this we do not try and fetch call context at all as it would get the
    // last set of anonymous contacts that may or not be currently calling contact
    if (endpoint === 'anonymous') {
      setError('Unable to fetch contact context as this call is from an anonymous number.');
      setLoading(false);
      return;
    }

    try {
      setContactContext(await getContactContext(endpoint));
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError('Unable to fetch contact context.');
    }

    if (e instanceof UnsupportedStructureError) {
      setError('Unable to decode contact context data.');
    }
  };

  const onPanelChange = (value: number) => (event: ChangeEvent<{}>, expanded: boolean) => {
    setOpenPanels((prev) => {
      const index = openPanels.indexOf(value);
      const newArray = [...prev];

      if (index === -1) {
        newArray.push(value);
      } else {
        newArray.splice(index, 1);
      }

      return newArray;
    });
  };

  if (loading) return <CircularProgress size={16} />;

  if (!loading && error) {
    return (
      <ContentSpacer spacing={2}>
        <Typography fontWeight={theme.typography.fontWeightBold} variant='h6' gutterBottom>
          Contact Context
        </Typography>

        <Typography variant='body2' color='textSecondary'>
          {error}
        </Typography>
      </ContentSpacer>
    );
  }

  return (
    <ContentSpacer spacing={2}>
      <Typography fontSize={19} fontWeight={theme.typography.fontWeightBold} variant='h5' paragraph>
        Contact Context
      </Typography>

      {!hasContactContext && (
        <Typography variant='body2' color='textSecondary'>
          No contact context found.
        </Typography>
      )}

      {hasContactContext &&
        contactContext.map((item: ContactContextInterface, index: number) => {
          const attributeKeys = Object.keys(item.attributes);
          let additionalDisplayAttributes = null;

          // We only want to do this logic if the backend returns at least one attribute
          // and if we have additional attributes we want to display
          if (attributeKeys.length > 0 && additionalContactContextAttributes.length > 0) {
            additionalDisplayAttributes = additionalContactContextAttributes.map((a, index) => {
              const value = item.attributes[a.key];
              const displayValue = isUrl(value) ? (
                <Link href={value} target='_blank'>
                  {value}
                </Link>
              ) : (
                value
              );
              return <DataItem key={index} stacked={isExtraSmall} title={a.label} value={displayValue} />;
            });
          }

          return (
            <Accordion
              key={index}
              sx={{
                ':first-of-type': {
                  'borderRadius': '4px 4px 0 0',
                  ':before': {
                    content: '""',
                    opacity: 0,
                  },
                },
              }}
              expanded={openPanels.indexOf(index) !== -1}
              onChange={onPanelChange(index)}>
              <AccordionSummary
                sx={{
                  'backgroundColor': grey[100],
                  ':before': {
                    content: '""',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                }}
                expandIcon={<ExpandMoreIcon />}>
                <b>{DateTime.fromISO(item.connectedToSystem).toRelative()}</b>
              </AccordionSummary>

              <AccordionDetails sx={{ display: 'block', padding: 3 }}>
                <DataItem
                  stacked={isExtraSmall}
                  title='Initiation Method'
                  disableMargin
                  value={item.initiationMethod}
                />
                <DataItem stacked={isExtraSmall} title='Agent Username' value={item.agentUsername} />
                <DataItem stacked={isExtraSmall} title='Disposition Code' value={item.dispositionCode} />
                <DataItem stacked={isExtraSmall} title='Disposition Sub Code' value={item.dispositionSubCode} />
                <DataItem stacked={isExtraSmall} title='Disposition Title' value={item.dispositionTitle} />
                <DataItem stacked={isExtraSmall} title='Callback Notes' value={item.callbackNotes || '-'} />

                {additionalDisplayAttributes}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </ContentSpacer>
  );
};

export default ContactContext;
