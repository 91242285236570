import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import ViewListIcon from '@mui/icons-material/ViewList';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';
import { Callback } from '~pages/Callbacks/useCallbacks/domain';

type StatusType = 'pending' | 'attempted' | 'removed';

interface Status {
  baseColor: string;
  lightColor: string;
  title: string;
  showMenu: boolean;
}

interface CallbackCardProps {
  callback: Callback;
  onClick: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

const statusInfo: { [key in StatusType]: Status } = {
  pending: {
    baseColor: blue['600'],
    lightColor: blue['100'],
    title: 'Pending',
    showMenu: true,
  },
  attempted: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    title: 'Attempted',
    showMenu: false,
  },
  removed: {
    baseColor: grey['600'],
    lightColor: grey['100'],
    title: 'Removed',
    showMenu: false,
  },
};

const CallbackCard = forwardRef(
  ({ callback, onClick, onEdit, onRemove }: CallbackCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
    const [performingRemoveAction, setPerformingRemoveAction] = useState<boolean>(false);
    let statusData = statusInfo['pending'];

    if (callback.attemptId !== null) {
      statusData = statusInfo['attempted'];
    } else if (callback.disabled !== null) {
      statusData = statusInfo['removed'];
    }

    const handleMenuOpen = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(null);
    };

    const handleRemoveConfirmationOpen = (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      setRemoveConfirmOpen(true);
    };

    const handleRemoveConfirmationClose = () => {
      setRemoveConfirmOpen(false);
    };

    const handleRemove = async () => {
      setPerformingRemoveAction(true);

      try {
        await onRemove();
      } catch (e) {
        return;
      } finally {
        setPerformingRemoveAction(false);
      }

      setRemoveConfirmOpen(false);
    };

    const handleEdit = (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      onEdit();
    };

    return (
      <>
        <OberonCard
          ref={ref}
          onClick={onClick}
          titleFontWeight={400}
          avatar={
            <Avatar style={{ backgroundColor: statusData.lightColor }}>
              <AccessTimeIcon style={{ color: statusData.baseColor, display: 'inline-block', fontSize: 27 }} />
            </Avatar>
          }
          title={callback.leadName}
          subHeader={
            <>
              <Chip
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  borderRadius: 1,
                  height: 20,
                  lineHeight: '21px',
                  color: '#ffffff',
                  fontWeight: 700,
                  backgroundColor: statusData.baseColor,
                }}
                label={statusData.title}
              />

              <div>{DateTime.fromISO(callback.scheduled, { zone: callback.timezone }).toFormat('FFF')}</div>

              <Typography variant='caption' component='p' color='textSecondary'>
                <i>(local time in {callback.timezone})</i>
              </Typography>
            </>
          }
          action={
            statusData.showMenu && (
              <>
                <IconButton
                  aria-controls='callback-menu'
                  aria-haspopup='true'
                  onKeyPress={handleMenuOpen}
                  onClick={handleMenuOpen}>
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id='callback-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}>
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleRemoveConfirmationOpen}>Remove</MenuItem>
                </Menu>
              </>
            )
          }
          footer={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography
                  marginRight={1}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  <SettingsPhoneIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                  {callback.campaignName}
                </Typography>

                <Typography
                  marginRight={1}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  variant='caption'
                  color='textSecondary'>
                  <ViewListIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                  {callback.listName}
                </Typography>
              </Box>
            </Box>
          }
          footerBorderColor={statusData.baseColor}
        />

        <ActionDialog
          open={removeConfirmOpen}
          title='Are you sure you want to do this?'
          content={`You are about to remove the callback for ${callback.leadName}, once you have completed this action it cannot be undone.`}
          onClose={handleRemoveConfirmationClose}
          onAccept={handleRemove}
          loading={performingRemoveAction}
          primaryActionTitle='Remove'
        />
      </>
    );
  },
);

export default CallbackCard;
