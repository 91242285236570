import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DateTime } from 'luxon';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useCallback, useMemo, useState } from 'react';

import RoutingProfileChangeBadge from '~components/AgentCard/RoutingProfileChangeBadge';
import AgentStatsBar from '~components/AgentStatsBar';
import OberonCard from '~components/OberonCard';
import { convertToColor, toAvatarLetters } from '~utils/Functions';

type Checked = {
  value: boolean;
  onClick: () => void;
};

type Props = {
  fullName: string;
  username: string;
  diallerGroupName?: string;
  to?: string;
  checked?: Checked;
  menuItems?: { action: () => void; name: string }[];
};

// Formats dates to 2021-10-10
const SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';

const AgentCard = forwardRef(
  ({ fullName, username, diallerGroupName, to, checked, menuItems }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
    }, []);

    const handleMenuClose = useCallback((e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(null);
    }, []);

    const handleActionClick = useCallback((e: MouseEvent<HTMLLIElement>, callback: () => void) => {
      e.stopPropagation();
      setAnchorEl(null);
      callback();
    }, []);

    const handleCheckboxClick =
      (onClick: () => void) => (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick();
      };

    const menuList = useMemo(() => {
      if (!menuItems) {
        return [];
      }
      return menuItems.map((item, index) => (
        <MenuItem key={index} onClick={(e) => handleActionClick(e, item.action)}>
          {item.name}
        </MenuItem>
      ));
    }, [menuItems]);

    return (
      <OberonCard
        ref={ref}
        titleFontWeight={700}
        title={fullName}
        alignItems='flex-start'
        backgroundColor={checked && checked.value ? grey[50] : undefined}
        subHeader={
          <>
            <div>{username}</div>
            <Box sx={{ marginTop: 1, display: 'flex', gap: 1 }}>
              {diallerGroupName && (
                <Chip
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: 10,
                    borderRadius: 1,
                    height: 20,
                    lineHeight: '21px',
                    color: '#ffffff',
                    fontWeight: 700,
                  }}
                  color='primary'
                  label={`Group: ${diallerGroupName}`}
                />
              )}

              <RoutingProfileChangeBadge timestamp={new Date().toString()} username={username} />
            </Box>
          </>
        }
        avatar={
          <>
            {checked && (
              <Box sx={{ position: 'absolute', left: -1, zIndex: 1, top: -1 }}>
                <Checkbox
                  onClick={handleCheckboxClick(checked.onClick)}
                  onKeyPress={handleCheckboxClick(checked.onClick)}
                  checked={checked.value}
                />
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    position: 'absolute',
                    left: 12,
                    top: 12,
                    width: 18,
                    height: 18,
                    zIndex: -1,
                    borderRadius: 0.5,
                  }}
                />
              </Box>
            )}

            <Avatar alt={fullName} style={{ background: convertToColor(username) }}>
              {toAvatarLetters(fullName)}
            </Avatar>
          </>
        }
        to={to}
        action={
          menuList.length > 0 ? (
            <>
              <IconButton
                aria-controls='agent-menu'
                aria-haspopup='true'
                onKeyPress={handleMenuOpen}
                onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {menuList}
              </Menu>
            </>
          ) : undefined
        }
        footer={
          <AgentStatsBar
            date={DateTime.fromJSDate(new Date()).toFormat(SIMPLE_DATE_FORMAT)}
            username={username}
            spinnerType='threeDot'
          />
        }
      />
    );
  },
);

export default AgentCard;
