import * as j from '@mojotech/json-type-validation';

interface RoutingProfile {
  id: string;
  arn: string;
  name: string;
}

export interface DiallerGroupRoutingProfile {
  id: number;
  name: string;
  routingProfiles: RoutingProfile[];
}

const RoutingProfileResponseDecoder: j.Decoder<RoutingProfile> = j
  .object({
    id: j.string(),
    arn: j.string(),
    name: j.string(),
  })
  .map((item) => ({
    id: item.id,
    arn: item.arn,
    name: item.name,
  }));

export const DiallerGroupRoutingProfileResponseDecoder: j.Decoder<DiallerGroupRoutingProfile> = j
  .object({
    id: j.number(),
    name: j.string(),
    routing_profiles: j.array(RoutingProfileResponseDecoder),
  })
  .map((item: any) => ({
    id: item.id,
    name: item.name,
    routingProfiles: item.routing_profiles,
  }));

export const DiallerGroupRoutingProfilesListResponseDecoder: j.Decoder<DiallerGroupRoutingProfile[]> = j
  .object({
    groups: j.array(DiallerGroupRoutingProfileResponseDecoder),
  })
  .map((item: any) => item.groups);
