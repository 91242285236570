import CardContent, { CardContentProps } from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';

type CardFooterProps = MUIStyledCommonProps<Theme> &
  CardContentProps & {
    spacing: number;
  };

const CardFooter = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<CardFooterProps>(({ theme, spacing }) => ({
  'padding': theme.spacing(spacing),
  '&:last-child': {
    paddingBottom: theme.spacing(spacing),
  },
}));

export default CardFooter;
