import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PhoneIcon from '@mui/icons-material/Phone';
import Grid from '@mui/material/Grid';
import React from 'react';

import InfoCard from '~components/InfoCard';
import { pluraliseWord } from '~utils/Functions';

import { Campaign } from '../../domain';

interface CampaignSummaryProps {
  campaign: Campaign;
  supersetUrl: String;
}

const CampaignSummary = ({ campaign }: CampaignSummaryProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <InfoCard variant='info' icon={DonutLargeIcon} text={campaign.progress.toFixed(2)} subText='% progress' />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard
          variant='info'
          icon={ListAltIcon}
          text={campaign.numLists.toLocaleString()}
          subText={`${pluraliseWord(campaign.numLists, 'list')}`}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <InfoCard
          variant='info'
          icon={PhoneIcon}
          text={campaign.numCompleted.toLocaleString()}
          subText={`active ${pluraliseWord(campaign.numCompleted, 'call')}`}
        />
      </Grid>
    </Grid>
  );
};

const CampaignSummarySuperset = ({ campaign, supersetUrl }: CampaignSummaryProps) => {
  const style = {
    color: 'white',
    width: '100%',
    border: '0',
    height: '1250px',
  };
  const url =
    supersetUrl +
    '/?standalone=2&expand_filters=0&native_filters=' +
    '(NATIVE_FILTER-DjkiSl_wx%3A(extraFormData%3A(filters%3A!((col%3Acampaign_id%2Cop%3AIN%2Cval%3A!(' +
    campaign.id +
    "))))%2CfilterState%3A(label%3A'" +
    campaign.id +
    "'%2CvalidateMessage%3A!f%2CvalidateStatus%3A!f%2Cvalue%3A!(" +
    campaign.id +
    '))%2Cid%3ANATIVE_FILTER-DjkiSl_wx))';

  return <iframe style={style} src={url} />;
};

export { CampaignSummary, CampaignSummarySuperset };
