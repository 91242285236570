import axios from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AssignedGroupConfig, AssignedGroupConfigDecoder } from './domains';

export const getAssignedGroupConfig = async (): Promise<AssignedGroupConfig> => {
  // TODO: rename when endpoint path is updated
  const path = 'api/group-config/my-config';
  let resp;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    resp = await axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      params: { timezone: timezone },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = AssignedGroupConfigDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    throw err;
  }

  return decoded.result;
};
