import ArticleIcon from '@mui/icons-material/Article';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';

import EmptyState from '~components/EmptyState';
import { AuthorRole, Customer, TransferTarget } from '~services/AsyncManager/domain';
import {
  getOrderedMessageKeysByMessageIdAndCreatedTimestamp,
  getOrderedNumberKeys,
} from '~services/AsyncManager/helpers';

import Conversation from './Conversation';
import MessageTemplateModal from './MessageTemplateModal';
import MessageTextarea from './MessageTextarea';
import TransferModal from './TransferModal';

interface MessageContainerProps {
  orgReference: string;
  attributeConfiguration: { key: string; label: string }[];
  contactPopLinks: { [key: string]: string };
  customer: Customer | undefined;
  markAsRead: (messageIds: number[]) => void;
  sendMessage: (conversationId: number, message: string) => void;
  loadMoreFrom: (conversationId: number) => void;
  transferTo: (transferTarget: TransferTarget, conversationId: number, value: string) => void;
  markConversationAsDisposed: (
    conversationId: number,
    dispositionCode: string,
    dispositionSubCode: string,
    attributes: { [key: string]: string },
  ) => void;
}

const getUnreadCustomerMessageIds = (customer: Customer): number[] => {
  let unreadMessageIds: number[] = [];

  // for each conversation
  for (const conversationId in customer.conversations) {
    const conversation = customer.conversations[conversationId];
    // for each message
    for (const messageId in conversation.messages) {
      // mark the message as read if it came from the customer
      const message = conversation.messages[messageId];
      if (message.readTimestamp === undefined && message.authorRole === AuthorRole.Customer) {
        unreadMessageIds = [...unreadMessageIds, message.id];
      }
    }
  }

  return unreadMessageIds;
};

const getMessageCountAcrossConversations = (customer: Customer | undefined): number => {
  let count = 0;

  if (customer === undefined) return count;

  for (const conversationId in customer.conversations) {
    const conversation = customer.conversations[conversationId];
    for (const _ in conversation.messages) {
      count += 1;
    }
  }

  return count;
};

const getCanDisposeForCurrentlyActiveConversations = (customer: Customer | undefined): boolean => {
  if (customer === undefined) return false;

  const orderedConversationKeys = getOrderedNumberKeys(customer.conversations);

  if (orderedConversationKeys.length === 0) return false;

  return customer.conversations[orderedConversationKeys[orderedConversationKeys.length - 1]].canDispose;
};

const geQueueForCurrentlyActiveConversation = (customer: Customer | undefined): string => {
  if (customer === undefined) return '';

  const orderedConversationKeys = getOrderedNumberKeys(customer.conversations);

  if (orderedConversationKeys.length === 0) return '';

  return customer.conversations[orderedConversationKeys[orderedConversationKeys.length - 1]].queue;
};

const getMessageCountForCurrentlyActiveConversations = (customer: Customer | undefined): number => {
  let count = 0;

  if (customer === undefined) return count;

  const orderedConversationKeys = getOrderedNumberKeys(customer.conversations);
  const currentConversation = customer.conversations[orderedConversationKeys[orderedConversationKeys.length - 1]];
  for (const _ in currentConversation.messages) {
    count += 1;
  }

  return count;
};

const getConversationIdToLoadFrom = (customer: Customer): number | undefined => {
  const orderedConversationKeys = getOrderedNumberKeys(customer.conversations);
  const selectedOldestConversationId = orderedConversationKeys[0];

  // Make sure the orderedConversationKeys was not an empty array and that the index value was not undefined
  if (!selectedOldestConversationId) {
    return undefined;
  }

  const oldestConversation = customer.conversations[selectedOldestConversationId];

  // Make sure that a conversation actually exists for the value selected
  if (!oldestConversation) {
    return undefined;
  }

  return oldestConversation.previousConversationId ? oldestConversation.id : undefined;
};

const getNewestUnreadCustomerMessageKey = (customer: Customer): string | undefined => {
  const orderedConversationKeys = getOrderedNumberKeys(customer.conversations);
  const newestConversationId = orderedConversationKeys[orderedConversationKeys.length - 1];
  const messages = customer.conversations[newestConversationId].messages;
  const orderedMessageKeys = getOrderedMessageKeysByMessageIdAndCreatedTimestamp(messages);
  const filteredKeys = orderedMessageKeys.filter(
    (key) => messages[key].authorRole === AuthorRole.Customer && messages[key].readTimestamp === undefined,
  );

  if (filteredKeys.length === 0) {
    return undefined;
  }

  return filteredKeys[filteredKeys.length - 1];
};

const MessageContainer = ({
  orgReference,
  attributeConfiguration,
  contactPopLinks,
  customer,
  markAsRead,
  sendMessage,
  loadMoreFrom,
  transferTo,
  markConversationAsDisposed,
}: MessageContainerProps) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const [newMessagePos, setNewMessagePos] = useState<string | undefined>(undefined);
  const [messageTemplateModalOpen, setMessageTemplateModalOpen] = useState<boolean>(false);
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>('');
  const orderedConversationKeys = customer !== undefined ? getOrderedNumberKeys(customer.conversations) : [];
  const loadConversationsFromId = customer ? getConversationIdToLoadFrom(customer) : undefined;
  const activeConversationQueue = geQueueForCurrentlyActiveConversation(customer);

  const loadMoreConversationsFrom = () => {
    if (loadConversationsFromId !== undefined) {
      loadMoreFrom(loadConversationsFromId);
    }
  };

  const sendConversationMessage = (messageContent: string) => {
    if (orderedConversationKeys.length > 0) {
      const conversationId = orderedConversationKeys[orderedConversationKeys.length - 1];
      sendMessage(conversationId, messageContent);
      setNewMessagePos(undefined);
      setMessageContent('');
    }
  };

  const transferConversation = (transferTarget: TransferTarget, value: string) => {
    if (orderedConversationKeys.length > 0) {
      const conversationId = orderedConversationKeys[orderedConversationKeys.length - 1];
      transferTo(transferTarget, conversationId, value);
      toggleTransferModal();
    }
  };

  const disposeConversation =
    (conversationId: number) =>
    (dispositionCode: string, dispositionSubCode: string, attributes: { [key: string]: string }) => {
      markConversationAsDisposed(conversationId, dispositionCode, dispositionSubCode, attributes);
    };

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessageContent(value);
  };

  const onSelectMessageTemplate = (message: string) => {
    setMessageContent(message);
    toggleMessageTemplateModal();
  };

  const toggleMessageTemplateModal = () => {
    setMessageTemplateModalOpen((prev) => !prev);
  };

  const toggleTransferModal = () => {
    setTransferModalOpen((prev) => !prev);
  };

  // Mark messages as read and set new message position when applicable
  const totalMessageCount = getMessageCountAcrossConversations(customer);
  useEffect(() => {
    if (customer !== undefined) {
      if (newMessagePos === undefined) {
        const newestUnreadMessageKey = getNewestUnreadCustomerMessageKey(customer);
        if (newestUnreadMessageKey) {
          setNewMessagePos(newestUnreadMessageKey);
        }
      }

      const unreadMessageIds = getUnreadCustomerMessageIds(customer);
      if (unreadMessageIds.length > 0) {
        markAsRead(unreadMessageIds);
      }

      return () => {
        setNewMessagePos(undefined);
      };
    }
  }, [totalMessageCount]);

  // Resets message content when we switch customers
  // TODO: When we support draft messages this logic should take applying the draft over resetting the field if available
  useEffect(() => {
    setMessageContent('');
  }, [customer]);

  // Scroll to bottom of the container if:
  // - A new message is presented
  // - Agent can now dispose of a conversation
  // - The new message element exists in the dom or not
  const currentConversationTotalMessageCount = getMessageCountForCurrentlyActiveConversations(customer);
  const currentConversationCanDispose = getCanDisposeForCurrentlyActiveConversations(customer);
  useLayoutEffect(() => {
    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTo(0, contentContainerRef.current.scrollHeight);
    }
  }, [currentConversationTotalMessageCount, currentConversationCanDispose, newMessagePos]);

  const conversations =
    customer !== undefined
      ? orderedConversationKeys.map((key) => {
          const isLastItem = key === orderedConversationKeys[orderedConversationKeys.length - 1];
          let isExpanded = false;

          if (
            key === orderedConversationKeys[orderedConversationKeys.length - 1] ||
            (orderedConversationKeys.length > 1 && key === orderedConversationKeys[orderedConversationKeys.length - 2])
          ) {
            isExpanded = true;
          }

          return (
            <Conversation
              key={`conversation-${key}`}
              conversation={customer.conversations[key]}
              orgReference={orgReference}
              attributeConfiguration={attributeConfiguration}
              contactPopLinks={contactPopLinks}
              expanded={isExpanded}
              disabled={isLastItem}
              newMessagePos={isLastItem ? newMessagePos : undefined}
              onDispose={disposeConversation(key)}
            />
          );
        })
      : [];

  return (
    <>
      {customer === undefined && (
        <div
          style={{
            position: 'relative',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            background: '#ffffff',
            height: '100%',
            border: '1px solid #e6e6e6',
            boxShadow: '0 2px 4px rgba(0, 0, 0, .05)',
            borderRadius: 10,
            padding: 16,
            overflow: 'hidden',
          }}>
          <EmptyState
            type='empty-chat-view'
            text='No active conversations'
            subText='Active conversations appear in the contact bar on the left.'
          />
        </div>
      )}

      {customer !== undefined && (
        <>
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              background: '#ffffff',
              height: '100%',
              border: '1px solid #e6e6e6',
              boxShadow: '0 2px 4px rgba(0, 0, 0, .05)',
              borderRadius: '10px',
              overflow: 'hidden',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title='Transfer' arrow placement='bottom-start'>
                  <IconButton
                    sx={{
                      'display': 'block',
                      'padding': '7px',
                      'borderRadius': 0,
                      '&.Mui-focusVisible': {
                        borderRadius: 'inherit',
                      },
                      '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: 'inherit',
                      },
                    }}
                    onClick={toggleTransferModal}>
                    <SwapHorizIcon sx={{ display: 'block' }} />
                  </IconButton>
                </Tooltip>
                <Divider orientation='vertical' />
              </Box>

              <Divider />

              <Box ref={contentContainerRef} sx={{ flex: 1, padding: 2, height: '100%', overflow: 'auto' }}>
                {loadConversationsFromId !== undefined && (
                  <Button
                    sx={{
                      display: 'block',
                      marginBottom: 1,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      borderRadius: '50px',
                      padding: '4px 16px',
                      fontSize: 12,
                      textTransform: 'none',
                    }}
                    variant='contained'
                    color='primary'
                    disableElevation
                    size='small'
                    onClick={loadMoreConversationsFrom}>
                    Load Previous Conversations
                  </Button>
                )}
                {conversations}
              </Box>

              <MessageTextarea
                toolbar={[
                  {
                    icon: ArticleIcon,
                    tooltip: 'Message Templates',
                    action: toggleMessageTemplateModal,
                  },
                ]}
                value={messageContent}
                onChange={onMessageChange}
                sendMessage={sendConversationMessage}
              />
            </Box>
          </Box>

          <MessageTemplateModal
            open={messageTemplateModalOpen}
            queue={activeConversationQueue}
            onSelect={onSelectMessageTemplate}
            onClose={toggleMessageTemplateModal}
          />

          <TransferModal open={transferModalOpen} onTransferTo={transferConversation} onClose={toggleTransferModal} />
        </>
      )}
    </>
  );
};

export default MessageContainer;
