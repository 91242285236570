import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import SectionCard from '~components/SectionCard';
import { CampaignPreviewSettings } from '~pages/CampaignManagement/domain';

const getHourFromSeconds = (seconds: number) => {
  return Math.floor(seconds / 3600);
};

interface PreviewSettingsFormProps {
  previewSettings: CampaignPreviewSettings;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit?: () => void;
  onSubmit: (data: CampaignPreviewSettings) => Promise<void>;
}

interface Form {
  agentAssignedTimeoutSeconds: number;
}

const PreviewSettingsForm = ({
  previewSettings,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
}: PreviewSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
  } = useForm<Form>({
    defaultValues: {
      agentAssignedTimeoutSeconds: 0,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isEdit === true) {
      setValue('agentAssignedTimeoutSeconds', previewSettings.agentAssignedTimeoutSeconds);
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  const onSubmitFn = handleSubmit(async (data: Form) => {
    const submitData: CampaignPreviewSettings = {
      agentAssignedTimeoutSeconds: data.agentAssignedTimeoutSeconds,
    };

    try {
      await onSubmit(submitData);
    } catch (e) {
      // Just return on error as we only want to reset the form on a successful submission
      return;
    }
  });

  let agentAssignedTimeoutSecondsHelperText =
    getHourFromSeconds(getValues('agentAssignedTimeoutSeconds')) < 2
      ? 'Note: Reducing this time period below 2 hours may cause unintended consequences, including simultaneous calls to a customer, and agent assignment confusion. It is recommended to keep this value above 2 hours to ensure the agent has no chance of dialling this lead after its assignment has lapsed.'
      : '';

  if (errors.agentAssignedTimeoutSeconds) {
    agentAssignedTimeoutSecondsHelperText = errors.agentAssignedTimeoutSeconds?.message || '';
  }

  return (
    <SectionCard title='Preview Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            title='Agent assigned timeout seconds'
            tooltip='The amount of time the system will allow a lead to be allocated to an agent before it’s assigned to another agent.'
            value={previewSettings.agentAssignedTimeoutSeconds}
          />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledNumberField
                name='agentAssignedTimeoutSeconds'
                control={control}
                rules={{
                  required: 'agent assigned timeout seconds is required.',
                }}
                label='Agent assigned timeout seconds'
                disabled={submitting}
                required={true}
                error={Boolean(errors.agentAssignedTimeoutSeconds)}
                helperText={agentAssignedTimeoutSecondsHelperText}
              />
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default PreviewSettingsForm;
