import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { TabPanel } from '~components/TabPanel';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getLeadsListById } from '../api';
import { LeadList } from '../domain';
import LeadListLeads from './LeadListLeads';
import LeadListSettings from './LeadListSettings';
import LeadListSummary from './LeadListSummary';
import LeadListUpload from './LeadListUpload';

// Used to manage visible tab selection
enum ViewTabsType {
  Summary,
  Leads,
  UploadLeads,
  Settings,
}

interface Error {
  text: string;
  subText: string;
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const defaultViewTab = ViewTabsType.Summary; // default tab, fallback to this tab when no permission on specified tab

const LeadListDetails = () => {
  const { hasScope } = useAuth();

  // Map for restricted tabs(key: ViewTabsType, value: boolean(true means can access))
  const restrictedViewTabs = useMemo(
    () =>
      new Map([
        [ViewTabsType.Leads, hasScope(AccessScope.CanViewLeads)],
        [ViewTabsType.UploadLeads, hasScope(AccessScope.CanUploadLeads)],
        [ViewTabsType.Settings, hasScope(AccessScope.CanViewLeadListSettings)],
      ]),
    [hasScope],
  );

  // check whether we have permission on specified ViewTabsType
  const hasViewTabPermission = useCallback(
    (viewTabsType: ViewTabsType) => {
      const allowed = restrictedViewTabs.get(viewTabsType);
      return allowed === undefined || allowed;
    },
    [restrictedViewTabs],
  );

  const setPageTitleProps = useSetPageTitleProps();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [leadList, setLeadList] = useState<LeadList | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[defaultViewTab],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  useEffect(() => {
    // fallback to default one when no permission(can happen when show parameter in the URL is manually updated)
    if (!hasViewTabPermission(tabIndex)) {
      setSearchParams({
        show: ViewTabsType[defaultViewTab],
      });
    }
    (async () => {
      if (
        initialLoad ||
        (searchParams.get('show') !== ViewTabsType[ViewTabsType.Leads] &&
          searchParams.get('show') !== ViewTabsType[ViewTabsType.UploadLeads])
      ) {
        await fetchPageData();
      }
    })();
  }, [searchParams.get('show')]);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number) => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const fetchPageData = async (): Promise<void> => {
    let list;

    try {
      [list] = await Promise.all([getLeadsListById(+campaignId, +listId)]);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setInitialLoad(false);
    }

    setLeadList(list);
    // Set page title
    setPageTitleProps({ pageName: list?.name });
  };

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const errorAction = () => {
    navigate(generatePath(Routes.viewCampaign.path, { campaignId: campaignId }));
  };

  const errorDisplay = error ? (
    <EmptyState
      type='error'
      text={error.text}
      subText={`${error.subText} \n Click the button below to return to campaign details.`}
      action={errorAction}
      actionText='Campaigns Details'
    />
  ) : null;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.diallerConfig.path}>
          Dialler Configuration
        </Link>

        <Link
          underline='hover'
          color='inherit'
          component={RouterLink}
          to={generatePath(Routes.viewCampaign.path, { campaignId })}>
          Campaign Details
        </Link>

        <Typography color='textPrimary'>List Details</Typography>
      </Breadcrumbs>

      {leadList === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to campaign details.'
          action={errorAction}
          actionText='Campaigns Details'
        />
      )}

      {leadList !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {leadList.name}
          </Typography>

          <Tabs
            orientation='horizontal'
            variant='scrollable'
            onChange={onTabChange}
            value={tabIndex}
            indicatorColor='primary'
            aria-label='Vertical tabs example'>
            <Tab label='Summary' {...a11yProps(ViewTabsType.Summary)} value={ViewTabsType.Summary} />

            {hasViewTabPermission(ViewTabsType.Leads) && (
              <Tab label='Leads' {...a11yProps(ViewTabsType.Leads)} value={ViewTabsType.Leads} />
            )}

            {hasViewTabPermission(ViewTabsType.UploadLeads) && (
              <Tab label='Upload Leads' {...a11yProps(ViewTabsType.UploadLeads)} value={ViewTabsType.UploadLeads} />
            )}

            {hasViewTabPermission(ViewTabsType.Settings) && (
              <Tab label='Settings' {...a11yProps(ViewTabsType.Settings)} value={ViewTabsType.Settings} />
            )}
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel value={tabIndex} index={ViewTabsType.Summary}>
            <LeadListSummary leadList={leadList} />
          </TabPanel>
          {hasViewTabPermission(ViewTabsType.Leads) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Leads}>
              <LeadListLeads />
            </TabPanel>
          )}
          {hasViewTabPermission(ViewTabsType.UploadLeads) && (
            <TabPanel value={tabIndex} index={ViewTabsType.UploadLeads}>
              <LeadListUpload />
            </TabPanel>
          )}

          {hasViewTabPermission(ViewTabsType.Settings) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Settings}>
              <LeadListSettings leadList={leadList} triggerLeadListRefresh={fetchPageData} />
            </TabPanel>
          )}
        </>
      )}
    </AsyncLoader>
  );
};

export default LeadListDetails;
