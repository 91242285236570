import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import SectionCard from '~components/SectionCard';
import {
  DiallerGroupPreviewSettings,
  PushPreviewSeconds,
  RingOutSeconds,
  UpdateDiallerGroup,
} from '~pages/CampaignManagement/domain';

interface PreviewSettingsFormProps {
  previewSettings: DiallerGroupPreviewSettings;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit?: () => void;
  update: (data: Partial<UpdateDiallerGroup>) => Promise<void>;
}

interface Form {
  enableEndpointSelection: boolean;
  allowPushPreviewSeconds: boolean;
  pushPreviewSeconds: number;
  allowRingOutSeconds: boolean;
  ringOutSeconds: number;
}

const PreviewSettingsForm = ({ previewSettings, isEdit, submitting, toggleEdit, update }: PreviewSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      enableEndpointSelection: false,
      allowPushPreviewSeconds: false,
      pushPreviewSeconds: PushPreviewSeconds.Min,
      allowRingOutSeconds: false,
      ringOutSeconds: RingOutSeconds.Min,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const watchAllowPushPreviewSeconds = watch('allowPushPreviewSeconds');
  const watchAllowRingOutSeconds = watch('allowRingOutSeconds');

  useEffect(() => {
    if (isEdit === true) {
      setValue('enableEndpointSelection', previewSettings.enableEndpointSelection);
      setValue('allowPushPreviewSeconds', previewSettings.pushPreviewSeconds > 0);
      setValue('pushPreviewSeconds', previewSettings.pushPreviewSeconds);
      setValue('allowRingOutSeconds', previewSettings.ringOutSeconds > 0);
      setValue('ringOutSeconds', previewSettings.ringOutSeconds);
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  // Update push preview seconds with the min default if allowed else of not allowed force to Infinite (0)
  // which indicates infinite preview time
  useEffect(() => {
    if (watchAllowPushPreviewSeconds === true) {
      setValue('pushPreviewSeconds', previewSettings.pushPreviewSeconds || PushPreviewSeconds.Min);
    } else {
      setValue('pushPreviewSeconds', PushPreviewSeconds.Infinite);
    }
  }, [watchAllowPushPreviewSeconds]);

  // Update ring out seconds with the min default if allowed else of not allowed force to Infinite (0)
  // which indicates that we do not auto ring out and leave it up to the contact service provider
  useEffect(() => {
    if (watchAllowRingOutSeconds === true) {
      setValue('ringOutSeconds', previewSettings.ringOutSeconds || RingOutSeconds.Min);
    } else {
      setValue('ringOutSeconds', RingOutSeconds.Infinite);
    }
  }, [watchAllowRingOutSeconds]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: Partial<UpdateDiallerGroup> = {
      previewSettings: {
        enableEndpointSelection: data.enableEndpointSelection,
        pushPreviewSeconds: data.pushPreviewSeconds,
        ringOutSeconds: data.ringOutSeconds,
      },
    };

    update(submitData);
  });

  return (
    <SectionCard title='Preview Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            disableMargin
            title='Enable endpoint selection'
            tooltip='Allows the agent the ability to dial any of the endpoints attached to a lead.'
            value={previewSettings.enableEndpointSelection ? 'Yes' : 'No'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Push preview seconds'
            tooltip='(Optional) The amount of time an agent has to preview a lead before its auto dialed.'
            value={previewSettings.pushPreviewSeconds || 'N/A'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Ring out seconds'
            tooltip='(Optional) The amount of time the preview dialler should ring before hanging up on a lead.'
            value={previewSettings.ringOutSeconds || 'N/A'}
          />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledCheckbox
                name='enableEndpointSelection'
                label='Enable endpoint selection?'
                control={control}
                disabled={submitting}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledCheckbox
                name='allowPushPreviewSeconds'
                label='Allow push preview seconds?'
                control={control}
                disabled={submitting}
              />
            </Grid>

            {watchAllowPushPreviewSeconds && (
              <Grid item xs={12}>
                <ControlledNumberField
                  name='pushPreviewSeconds'
                  control={control}
                  rules={{
                    required: 'push preview seconds is required.',
                    min: {
                      value: PushPreviewSeconds.Min,
                      message: `push preview seconds cannot be lower than ${PushPreviewSeconds.Min}.`,
                    },
                    max: {
                      value: PushPreviewSeconds.Max,
                      message: `push preview seconds cannot be greater than ${PushPreviewSeconds.Max}.`,
                    },
                  }}
                  label='Push preview seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.pushPreviewSeconds)}
                  helperText={errors.pushPreviewSeconds?.message}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <ControlledCheckbox
                name='allowRingOutSeconds'
                label='Allow ring out seconds?'
                control={control}
                disabled={submitting}
              />
            </Grid>

            {watchAllowRingOutSeconds && (
              <Grid item xs={12}>
                <ControlledNumberField
                  name='ringOutSeconds'
                  control={control}
                  rules={{
                    required: 'ring out seconds is required.',
                    min: {
                      value: RingOutSeconds.Min,
                      message: `ring out seconds cannot be lower than ${RingOutSeconds.Min}.`,
                    },
                    max: {
                      value: RingOutSeconds.Max,
                      message: `ring out seconds cannot be greater than ${RingOutSeconds.Max}.`,
                    },
                  }}
                  label='Ring out seconds'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.ringOutSeconds)}
                  helperText={errors.ringOutSeconds?.message}
                />
              </Grid>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default PreviewSettingsForm;
