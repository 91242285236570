import { Typography } from '@mui/material';
import React from 'react';

enum MessageContentType {
  TextPlain = 'text/plain',
  ConnectionAcknowledged = 'application/vnd.amazonaws.connect.event.connection.acknowledged',
  Typing = 'application/vnd.amazonaws.connect.event.typing',
  ParticipantJoined = 'application/vnd.amazonaws.connect.event.participant.joined',
  ParticipantLeft = 'application/vnd.amazonaws.connect.event.participant.left',
  TransferSucceeded = 'application/vnd.amazonaws.connect.event.transfer.succeeded',
  TransferFailed = 'application/vnd.amazonaws.connect.event.transfer.failed',
  ChatEnded = 'application/vnd.amazonaws.connect.event.chat.ended',
}

interface MessageEventItemProps {
  contentType: string;
  name: string;
}

const MessageEventItem = ({ contentType, name }: MessageEventItemProps) => {
  let eventMessage = null;

  if (contentType === MessageContentType.ParticipantJoined) {
    eventMessage = (
      <Typography margin='8px 0' color='#8c8c8c' fontSize={12} fontWeight={400} textAlign='center' component='p'>
        {name} joined chat.
      </Typography>
    );
  }

  if (contentType === MessageContentType.ParticipantLeft) {
    eventMessage = (
      <Typography margin='8px 0' color='#8c8c8c' fontSize={12} fontWeight={400} textAlign='center' component='p'>
        {name} left the chat.
      </Typography>
    );
  }

  if (contentType === MessageContentType.ChatEnded) {
    eventMessage = (
      <Typography margin='8px 0' color='#8c8c8c' fontSize={12} fontWeight={400} textAlign='center' component='p'>
        Chat has ended.
      </Typography>
    );
  }

  return eventMessage;
};

export default MessageEventItem;
