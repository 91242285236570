import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DiallingHourProfile } from '~hooks/useDiallingHourProfiles/domain';

import { getDiallingHourProfiles } from './api';

type Options = {
  shouldFetch?: boolean;
};

const useDiallingHourProfiles = (
  options: Options = {
    shouldFetch: true,
  },
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<DiallingHourProfile[]>([]);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const shouldFetch = options.shouldFetch ?? true;

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: DiallingHourProfile[] | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getDiallingHourProfiles(axiosCancelRef.current);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp);
    setLoading(false);
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      setError(false);

      let resp: DiallingHourProfile[] | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallingHourProfiles(axiosCancelRef.current);
      } catch (e) {
        console.log(e);
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList(resp);
      setLoading(false);
    };

    if (shouldFetch) {
      load();
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [shouldFetch]);

  return {
    loading,
    error,
    list,
    reload,
  };
};

export default useDiallingHourProfiles;
