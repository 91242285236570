import * as j from '@mojotech/json-type-validation';

import { AsyncQueue } from '~pages/AsyncManagement/domain';

export interface AsyncQueueResponse {
  nextPageUrl: string | null;
  queues: AsyncQueue[];
}

export const AsyncQueueDecoder: j.Decoder<AsyncQueue> = j
  .object({
    queue: j.string(),
    title: j.string(),
    description: j.string(),
    channel_type: j.string(),
    is_public: j.boolean(),
    system_endpoint: j.string(),
  })
  .map((item) => ({
    queue: item.queue,
    title: item.title,
    description: item.description,
    channelType: item.channel_type,
    isPublic: item.is_public,
    systemEndpoint: item.system_endpoint,
  }));

export const AsyncQueuesResponseDecoder: j.Decoder<AsyncQueueResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    queues: j.array(AsyncQueueDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    queues: item.queues,
  }));
