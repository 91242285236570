import { PhoneDisabled } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import { Agent, Customer } from '~services/AsteriskStatsManager/domain';

interface CallStatusCardProps {
  color: string;
  id: string;
  address: string;
  callTime: string;
  agentChannels: Agent[];
  customerChannels: Customer[];
  disconnect: (id: string) => void;
}

const CallStatusCard = ({
  color,
  id,
  address,
  callTime,
  agentChannels,
  customerChannels,
  disconnect,
}: CallStatusCardProps) => {
  const [open, setOpen] = useState<string>('');

  const resetOpen = () => setOpen('');
  const confirmDisconnect = () => {
    console.log(open);
    disconnect(open);
    resetOpen();
  };

  return (
    <Card>
      <CardContent style={{ borderTop: `8px solid ${color}` }}>
        <Typography
          fontWeight={700}
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          overflow='hidden'
          variant='body2'
          align='center'>
          {address}
        </Typography>

        <Typography variant='body2' align='center' color='textSecondary'>
          {id}
        </Typography>
      </CardContent>

      {customerChannels.map((cChannel, index) => (
        <CardContent key={index} style={{ borderTop: `8px solid ${cChannel.color}` }}>
          <Typography
            fontWeight={700}
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            variant='body2'
            align='center'>
            {cChannel.address}
          </Typography>

          <Typography variant='body2' align='center' color='textSecondary'>
            {cChannel.key}
          </Typography>

          <Typography variant='body2' align='center'>
            {cChannel.status} - {cChannel.timeInStatus}
          </Typography>

          <Typography align='center'>
            <IconButton size={'small'} color={'warning'} onClick={setOpen.bind(this, cChannel.key)}>
              <PhoneDisabled />
            </IconButton>
          </Typography>
        </CardContent>
      ))}

      {agentChannels.map((aChannel, index) => (
        <CardContent key={index} style={{ borderTop: `8px solid ${aChannel.color}` }}>
          <Typography
            fontWeight={700}
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            variant='body2'
            align='center'>
            {aChannel.address}
          </Typography>

          <Typography variant='body2' align='center' color='textSecondary'>
            {aChannel.key}
          </Typography>

          <Typography variant='body2' align='center'>
            {aChannel.status} - {aChannel.timeInStatus}
          </Typography>
        </CardContent>
      ))}

      <Divider />

      <CardActions sx={{ padding: 1, backgroundColor: '#f5f5f5' }}>
        <div>Total Interaction Time</div>
        <div style={{ marginLeft: 'auto' }}>{callTime}</div>
      </CardActions>

      <ActionDialog
        open={open !== ''}
        title='Are you sure you want to do this?'
        content={'You are about to disconnect a customer from the system.'}
        onClose={resetOpen}
        onAccept={confirmDisconnect}
        primaryActionTitle='Disconnect'
      />
    </Card>
  );
};

export default CallStatusCard;
