import axios from 'axios';

import { APIError, UnsupportedStructureError } from '../../services/Errors';
import {
  ContactDetails,
  ContactLog,
  ContactLogFilter,
  ContactLogFilterOptions,
  GetChatMessagesResponseDecoder,
  GetContactDetailsResponseDecoder,
  GetContactLogFilterOptionsResponseDecoder,
  GetContactLogsResponseDecoder,
  Message,
} from './domain';

export const getContactLogs = async (
  filter: ContactLogFilter,
  accessFilterId: number | undefined,
): Promise<ContactLog[]> => {
  const path: string = '/api/contacts/';
  let resp;

  const query = {
    endpoint: filter.endpoint || undefined,
    external_id: filter.externalId || undefined,
    contact_id: filter.contactId || undefined,
    campaign_name: filter.campaign || undefined,
    channel: filter.channel || undefined,
    disposition_code: filter.disposition || undefined,
    disposition_sub_code: filter.dispositionSubCode || undefined,
    agent_username: filter.agent || undefined,
    date_from: filter.dateFrom || undefined,
    date_to: filter.dateTo || undefined,
    sort_by: filter.sortBy || undefined,
    sort_order: filter.sortOrder || undefined,
    disconnect_reason: filter.disconnectReason || undefined,
    initiation_method: filter.initiationMethod || undefined,
    attribute_name: filter.attributeName && filter.attributeValue ? filter.attributeName : undefined,
    attribute_value: filter.attributeName && filter.attributeValue ? filter.attributeValue : undefined,
    access_filter_id: accessFilterId,
  };

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: query,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetContactLogsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getContactLogFilterOptions = async (
  accessFilterId: number | undefined,
): Promise<ContactLogFilterOptions> => {
  const path: string = '/api/contacts/filter-options';
  const params = {
    access_filter_id: accessFilterId,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetContactLogFilterOptionsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getContactDetails = async (contactId: string): Promise<ContactDetails> => {
  const path: string = `/api/contacts/details/${contactId}`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetContactDetailsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getChatMessagesFromUrl = async (url: string): Promise<Message[]> => {
  try {
    const res = await axios({
      method: 'GET',
      url: url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    return GetChatMessagesResponseDecoder.runWithException(res.data['Transcript']);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const postContactAssessment = async (contactId: string, data: { [key: string]: any }) => {
  const path: string = `/api/contacts/assessment/${contactId}`;
  const body = data;

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};
