import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import organisations from '~organisations';
import { postContactAssessment } from '~pages/ContactLog/api';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { capitalizeString, isEmpty } from '~utils/Functions';

interface ContactAssessmentProps {
  contactId: string;
  campaignId: number;
  canView: boolean;
  assessmentDetails: { [key: string]: any };
  triggerContactLogRefresh: () => Promise<void>;
}

const ContactAssessment = ({
  canView,
  contactId,
  campaignId,
  assessmentDetails,
  triggerContactLogRefresh,
}: ContactAssessmentProps) => {
  // We want to skip fast, do none of the below logic, and not display anything
  if (!canView) return null;

  const appConfig = useAppConfiguration();
  const ContactAssessmentForm = organisations[appConfig.orgReference]?.contactAssessmentForms[campaignId];

  // We want to skip fast if the form does not exist for specified organisation and not display anything
  if (!ContactAssessmentForm) return null;

  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { pushNotification } = useNotification();
  const [edit, setEdit] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  // If empty or null we have no details
  const hasDetails = !isEmpty(assessmentDetails);

  const toggleEdit = () => {
    setEdit((prev) => !prev);
  };

  const onSubmit = async (data: { [key: string]: any }) => {
    setFormSubmitting(true);

    try {
      await postContactAssessment(contactId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject(e);
    } finally {
      setFormSubmitting(false);
    }

    pushNotification('success', 'Contact interaction assessed');
    await triggerContactLogRefresh();
    // Close form
    setEdit(false);
  };

  const detailsDisplay = hasDetails
    ? Object.keys(assessmentDetails).map((key: string, index: number) => (
        <DataItem
          key={index}
          stacked={isExtraSmall}
          disableMargin={index === 0}
          title={capitalizeString(key.replace(/\_/g, ' '))}
          value={assessmentDetails[key]}
        />
      ))
    : [];

  return (
    <SectionCard title='Contact Assessment'>
      {!hasDetails && !edit && (
        <Button
          fullWidth
          variant='contained'
          startIcon={<AssignmentIcon />}
          disableElevation
          onClick={toggleEdit}
          color='primary'>
          Make Assessment
        </Button>
      )}

      {hasDetails && !edit && <>{detailsDisplay}</>}

      {edit && <ContactAssessmentForm formSubmitting={formSubmitting} onClose={toggleEdit} onSubmit={onSubmit} />}
    </SectionCard>
  );
};

export default ContactAssessment;
