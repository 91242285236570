import { Organisation } from '~organisations/domain';

import genericAdditionalFields from './additionalOutcomeCaptureFields/generic';
import genericAsyncAdditionalFields from './asyncAdditionalOutcomeCaptureFields/generic';
import logo from './images/logo.png';

const bonzaConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '1': genericAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {
    'new-booking': genericAsyncAdditionalFields,
    'existing-booking': genericAsyncAdditionalFields,
    'general-enquiry': genericAsyncAdditionalFields,
    'escalations': genericAsyncAdditionalFields,
  },
  contactAssessmentForms: {},
};

export default bonzaConfig;
