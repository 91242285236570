import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import ContentSpacer from '~components/ContentSpacer';

interface SectionCardProps {
  title: string;
  subTitle?: string;
  children: ReactNode;
  disableEdit?: boolean;
  disablePadding?: boolean;
  onEdit?: () => void;
}

const CustomizedHeader = styled(CardHeader)(({ theme }) => ({
  'padding': theme.spacing(3),
  'backgroundColor': grey[100],
  '& .MuiCardHeader-title': {
    fontWeight: theme.typography.fontWeightBold,
  },
  '.MuiCardHeader-subheader': {
    marginTop: 4,
    color: theme.palette.text.primary,
  },
}));

const SectionCard = ({ title, subTitle, children, disableEdit, disablePadding, onEdit }: SectionCardProps) => {
  let cardHeaderProps: {} = {
    title: title,
  };

  if (subTitle) {
    cardHeaderProps = {
      ...cardHeaderProps,
      subheader: subTitle,
    };
  }

  if (onEdit) {
    const onEditAction = (
      <Button onClick={onEdit} size='small' disabled={disableEdit} color='primary'>
        Edit
      </Button>
    );

    cardHeaderProps = {
      ...cardHeaderProps,
      action: onEditAction,
    };
  }

  return (
    <ContentSpacer spacing={3}>
      <Card style={{ overflow: 'visible' }}>
        <CustomizedHeader {...cardHeaderProps} />
        <Divider variant='fullWidth' component='hr' />
        <CardContent style={{ padding: disablePadding ? 0 : 24 }}>{children}</CardContent>
      </Card>
    </ContentSpacer>
  );
};

export default SectionCard;
