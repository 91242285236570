interface Key {
  digit: string;
  alphaNumeric: string;
}

const keys: Key[] = [
  { digit: '1', alphaNumeric: '' },
  { digit: '2', alphaNumeric: 'ABC' },
  { digit: '3', alphaNumeric: 'DEF' },
  { digit: '4', alphaNumeric: 'GHI' },
  { digit: '5', alphaNumeric: 'JKL' },
  { digit: '6', alphaNumeric: 'MNO' },
  { digit: '7', alphaNumeric: 'PQRS' },
  { digit: '8', alphaNumeric: 'TUV' },
  { digit: '9', alphaNumeric: 'WXYZ' },
  { digit: '*', alphaNumeric: '' },
  { digit: '0', alphaNumeric: '+' },
  { digit: '#', alphaNumeric: '' },
];

export default keys;
