import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  DiallerGroupRoutingProfile,
  DiallerGroupRoutingProfileResponseDecoder,
  DiallerGroupRoutingProfilesListResponseDecoder,
} from './domain';

export const getGroupsRoutingProfile = async (
  accessFilterId: number | undefined,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroupRoutingProfile[] | undefined> => {
  const path = '/api/group-config/routing-profiles';
  const params = {
    access_filter_id: accessFilterId,
  };
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      params: params,
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupRoutingProfilesListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);
    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getGroupRoutingProfile = async (
  diallerGroupId: number,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroupRoutingProfile | undefined> => {
  const path = `/api/group/${diallerGroupId}/routing-profiles`;
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupRoutingProfileResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);
    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
