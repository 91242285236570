import { useCallback, useLayoutEffect, useRef, useState } from 'react';

interface useDebounceActionReturn {
  counter: number | undefined;
  running: boolean;
  setCounter: (num: number | undefined) => void;
  setAction: (action: (() => void) | undefined) => void;
  start: () => void;
  clear: () => void;
}

export const useDebounceAction = (
  action: (() => void) | undefined,
  counter: number | undefined,
  useOverrideProps?: boolean,
): useDebounceActionReturn => {
  const [asyncUpdateCounter, setAsyncUpdateCounter] = useState<number | undefined>(undefined);
  const [asyncUpdateAction, setAsyncUpdateAction] = useState<(() => void) | undefined>(undefined);
  const [localCounter, setLocalCounter] = useState<number | undefined>(counter);
  const localCounterRef = useRef<number | undefined>(localCounter);
  const [localAction, setLocalAction] = useState<(() => void) | undefined>(() => action);
  const localActionRef = useRef<(() => void) | undefined>(localAction);
  const [running, setRunning] = useState<boolean>(false);
  const runningRef = useRef<boolean>(running);

  const start = () => {
    if (localActionRef.current === undefined) {
      throw new Error('useDebounceAction: Cannot start counter debounce if action is not defined.');
    }

    if (localCounterRef.current === undefined) {
      throw new Error('useDebounceAction: Cannot start counter if a counter value has not been set.');
    }

    setRunning(true);
    runningRef.current = true;
  };

  const clear = useCallback(() => {
    setRunning(false);
    runningRef.current = false;

    // Will attempt to reuse override properties if specified too. If these properties were never set we default to
    // passed in hook defaults
    if (useOverrideProps === true) {
      // Reset to async set counter or default if not set
      setLocalCounter(asyncUpdateCounter ?? counter);
      localCounterRef.current = asyncUpdateCounter ?? counter;

      // Reset to async set action or default if not set
      setLocalAction(() => asyncUpdateAction ?? action);
      localActionRef.current = asyncUpdateAction ?? action;
      return;
    }

    // Reset local counter to passed in default
    setLocalCounter(counter);
    localCounterRef.current = counter;
    // Reset local action to passed in default
    setLocalAction(() => action);
    localActionRef.current = action;
  }, [asyncUpdateCounter, asyncUpdateAction, counter, action]);

  // For dynamically setting the counter and storying the new state
  const setCounter = (num: number | undefined) => {
    if (runningRef.current === true) {
      throw new Error(
        'useDebounceAction: Counter can only be set when action is not running. Consider clearing previous run before setting this value.',
      );
    }

    setAsyncUpdateCounter(num);
    setLocalCounter(num);
    localCounterRef.current = num;
  };

  const setAction = (action: (() => void) | undefined) => {
    if (runningRef.current === true) {
      throw new Error(
        'useDebounceAction: Action can only be set when action is not running. Consider clearing previous run before setting this value.',
      );
    }

    setLocalAction(() => action);
    setAsyncUpdateAction(() => action);
    localActionRef.current = action;
  };

  // Manages the event loop and triggering of action
  useLayoutEffect(() => {
    if (localCounter !== undefined && running === true) {
      if (localCounter > 0) {
        const currentLocalTimeoutRef = window.setTimeout(() => {
          setLocalCounter((prev) => {
            // Handles the case were autoDialTimer is cleared but this set interval event started before it could be cleared
            if (prev === undefined) {
              return undefined;
            }

            return prev! - 1;
          });
        }, 1_000);

        return function useDebounceActionCleanup() {
          clearTimeout(currentLocalTimeoutRef);
        };
      } else {
        clear();
        // Should never be undefined as the start function makes a check to see if this is undefined or not before starting
        // but we still have to do it anyway
        if (localAction !== undefined) {
          localAction();
        }
      }
    }
  }, [running, clear, localCounter, localAction]);

  return {
    counter: localCounter,
    running,
    setCounter,
    setAction,
    start,
    clear,
  };
};
