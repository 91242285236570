import React, { useContext } from 'react';
import { ReactNode, createContext, useEffect, useState } from 'react';

import EmptyState from '~components/EmptyState';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';
import { ScreenShareManager } from '~services/ScreenShareManager';
import { pluraliseWord } from '~utils/Functions';

import { postScreenShare } from './api';

interface ScreenShareProviderProps {
  children: ReactNode;
}

interface ScreenShareContextProps {
  serviceEnabled: boolean;
  recordingNames: string[];
  reconnect: () => void;
  connectionLost: boolean;
}

const ScreenShareContext = createContext<ScreenShareContextProps | undefined>(undefined);

export const useScreenShare = (): ScreenShareContextProps => {
  return useContext(ScreenShareContext) as ScreenShareContextProps;
};

// TODO: to come from backend, possibly stored in user table via AD
// This will be the amount of screens that need to be shared by the agent user
// before they get access to the dialler
const screenCount = 1;

const ScreenShareProvider = ({ children }: ScreenShareProviderProps) => {
  const { extensions } = useAppConfiguration();
  const { username } = useAuth();
  const [manager, setManager] = useState<ScreenShareManager | undefined>(undefined);
  const [screensShared, setScreensShared] = useState<number>(0);
  const [recordingNames, setRecordingNames] = useState<string[]>([]);
  const [connectionLost, setConnectionLost] = useState<boolean>(false);
  const extensionEnabled = Boolean(extensions.screenRecordings);
  const context: ScreenShareContextProps = {
    serviceEnabled: extensionEnabled,
    recordingNames,
    // Triggers a rerun of both the manager setup and add screen use effects by resetting view based
    // state properties
    reconnect: () => {
      setManager(undefined);
      setConnectionLost(false);
    },
    connectionLost,
  };

  // If the extension is not enabled we want to skip fast
  if (!extensionEnabled) {
    return <ScreenShareContext.Provider value={context}>{children}</ScreenShareContext.Provider>;
  }

  // used to cleanup the manager connection on user navigate away from page
  useEffect(() => {
    return () => {
      if (manager !== undefined) {
        console.log('ScreenShareProvider: ScreenShareManager Class Cleanup');
        // Cleanup everything
        manager.destroy();
      }
    };
  }, [manager]);

  useEffect(() => {
    if (manager !== undefined) {
      if (!connectionLost && screensShared < screenCount) {
        manager.addScreen();
        return;
      }
    }
  }, [manager, screensShared]);

  const shareScreenAction = () => {
    if (manager === undefined && extensions.screenRecordings) {
      const mgr = new ScreenShareManager(extensions.screenRecordings.janusUrl, username, {
        onShareSuccess: async (screenInfoItem) => {
          setRecordingNames((prev) => [...prev, screenInfoItem.recordingName]);
          setScreensShared((prev) => prev + 1);

          try {
            await postScreenShare(
              screenInfoItem.sessionId.toString(),
              screenInfoItem.screenStreamId,
              screenInfoItem.recordingId.toString(),
            );
          } catch (e) {
            console.error('! Unable to post agent screen share details due to error: ', e);
            return;
          }

          console.log('+ successfully posted screen share details');
        },
        onSelectedScreenError: (errorMessage) => {
          alert(errorMessage);
          mgr.addScreen();
        },
        onDisplayMediaRejection: (errorMessage) => {
          mgr.addScreen();
        },
        onConnectionError: (errorMessage) => {
          alert(errorMessage);
        },
        onConnectionLost: (errorMessage) => {
          alert(errorMessage);
          setConnectionLost(true);
          setScreensShared(0);
          setRecordingNames([]);
        },
      });

      setManager(mgr);
    }
  };

  const pluralisedScreenText = pluraliseWord(screenCount, 'screen');
  // If any error occurs do not redraw this empty state until the reconnect function has been triggered
  if (!connectionLost && screensShared < screenCount) {
    return (
      <EmptyState
        type='share-screen'
        text={`You must share your ${pluralisedScreenText} to continue`}
        subText='Click the button below to proceed.'
        action={shareScreenAction}
        actionText={`Share ${pluralisedScreenText}`}
      />
    );
  }

  return <ScreenShareContext.Provider value={context}>{children}</ScreenShareContext.Provider>;
};

export default ScreenShareProvider;
