import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';

import AgentFilter from '~pages/Callbacks/FilterDrawer/AgentFilter';
import CampaignFilter from '~pages/Callbacks/FilterDrawer/CampaignFilter';
import ListFilter from '~pages/Callbacks/FilterDrawer/ListFilter';
import SortOptions from '~pages/Callbacks/FilterDrawer/SortOptions';
import { CallbackFilter, FilterOptions } from '~pages/Callbacks/useCallbacks/domain';

type Props = {
  open: boolean;
  filter: FilterOptions;
  onReset: () => void;
  onSearch: () => void;
  onUpdate: <T extends keyof FilterOptions>(key: keyof FilterOptions) => (value: FilterOptions[T]) => void;
};

const filters = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Pending',
    value: CallbackFilter.Pending,
  },
  {
    label: 'Attempted',
    value: CallbackFilter.Attempted,
  },
  {
    label: 'Removed',
    value: CallbackFilter.Removed,
  },
];

const filterList = filters.map((val, index) => (
  <MenuItem key={index} value={val.value}>
    {val.label}
  </MenuItem>
));

export const FilterDrawer = ({ open, filter, onReset, onSearch, onUpdate }: Props) => {
  const [dateFromValidationError, setDateFromValidationError] = useState<string | undefined>(undefined);
  const [dateToValidationError, setDateToValidationError] = useState<string | undefined>(undefined);
  const filterDateToMinDate = useMemo(
    () => (filter.dateFrom ? DateTime.fromISO(filter.dateFrom) : undefined),
    [filter.dateFrom],
  );
  const filterDateToMaxDate = useMemo(
    () => (filter.dateTo ? DateTime.fromISO(filter.dateTo) : undefined),
    [filter.dateTo],
  );

  const handleDatepickerChange = (fieldName: 'dateFrom' | 'dateTo') => (dateObj: DateTime | null) => {
    let date = dateObj;

    // makes sure dateTo is set to end of date, not start of day
    if (fieldName === 'dateTo' && date !== null) {
      date = date.endOf('day');
    }

    onUpdate(fieldName)(date === null ? undefined : date.toISO());
  };

  return (
    <Collapse in={open} unmountOnExit>
      <Paper sx={{ padding: 2, marginBottom: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <DatePicker
              disableMaskedInput
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              maxDate={filterDateToMaxDate}
              onError={(reason) => {
                if (reason === 'maxDate') {
                  setDateFromValidationError('Date From should not be after Date From.');
                  return;
                }

                setDateFromValidationError(undefined);
              }}
              disableFuture
              inputFormat='DD'
              label='Date From'
              value={filter.dateFrom || null}
              onChange={handleDatepickerChange('dateFrom')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant='outlined'
                  error={Boolean(dateFromValidationError)}
                  helperText={dateFromValidationError}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              disableMaskedInput
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              minDate={filterDateToMinDate}
              onError={(reason) => {
                if (reason === 'minDate') {
                  setDateToValidationError('Date To should not be before Date From.');
                  return;
                }

                setDateToValidationError(undefined);
              }}
              inputFormat='DD'
              disableFuture
              label='Date To'
              value={filter.dateTo || null}
              onChange={handleDatepickerChange('dateTo')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant='outlined'
                  error={Boolean(dateToValidationError)}
                  helperText={dateToValidationError}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name='search'
              onChange={(e) => onUpdate('search')(e.target.value)}
              variant='outlined'
              label='Lead name or endpoint'
              value={filter.search || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name='filter'
              onChange={(e) => onUpdate('filter')(e.target.value)}
              select
              variant='outlined'
              label='Filter'
              value={filter.filter || ''}>
              {filterList}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider variant='fullWidth' component='hr' />
            <CampaignFilter selectedCampaigns={filter.campaignId} update={onUpdate('campaignId')} />
            <Divider variant='fullWidth' component='hr' />

            <ListFilter selectedLists={filter.listId} update={onUpdate('listId')} />
            <Divider variant='fullWidth' component='hr' />

            <AgentFilter
              selectedAgents={filter.agentUsername}
              assignedToGroup={filter.assignedToGroup || false}
              updateAgentList={onUpdate('agentUsername')}
              updateAssignedToGroup={onUpdate('assignedToGroup')}
            />
            <Divider variant='fullWidth' component='hr' />

            <SortOptions
              sortColumn={filter.sortColumn}
              sortOrder={filter.sortOrder}
              updateSortColumn={onUpdate('sortColumn')}
              updateSortOrder={onUpdate('sortOrder')}
            />
            <Divider variant='fullWidth' component='hr' />
          </Grid>

          <Hidden xsDown>
            <Grid item md={8}></Grid>
          </Hidden>

          <Grid item xs={12} md={2}>
            <Button fullWidth variant='text' onClick={onReset}>
              Reset
            </Button>
          </Grid>

          <Grid item xs={12} md={2}>
            <Button fullWidth disableElevation variant='contained' color='primary' onClick={onSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
};

export default FilterDrawer;
