import * as j from '@mojotech/json-type-validation';

import { LeadStatusType } from '~pages/CampaignManagement/domain';

export interface Lead {
  id: number;
  name: string;
  campaignId: number;
  campaignName: string;
  listName: string;
  externalId: string;
  leadStatus: LeadStatusType;
  endpoints: string[];
  timezone: string;
}

export interface LeadsResponse {
  nextPageUrl: string | null;
  list: Lead[];
}

export const GetLeadResponseDecoder: j.Decoder<Lead> = j
  .object({
    lead_id: j.number(),
    campaign_id: j.number(),
    campaign_name: j.string(),
    list_name: j.string(),
    external_id: j.string(),
    lead_name: j.string(),
    lead_status: j.union(j.string(), j.constant(null)),
    endpoints: j.array(j.string()),
    timezone: j.string(),
  })
  .map((item) => ({
    id: item.lead_id,
    name: item.lead_name,
    campaignId: item.campaign_id,
    campaignName: item.campaign_name,
    listName: item.list_name,
    externalId: item.external_id,
    // Less restrictive than all the other areas we use this, but still typed. This however can result in a value typed as LeadStatusType
    // without it actually being one of the values, so should be less noisy than the original method as the decoder just expects the string value to be there
    leadStatus: item.lead_status as unknown as LeadStatusType,
    endpoints: item.endpoints,
    timezone: item.timezone,
  }));

export const GetLeadsResponseDecoder: j.Decoder<LeadsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    list: j.array(GetLeadResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    list: item.list,
  }));
