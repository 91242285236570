import grey from '@mui/material/colors/grey';
import lightBlue from '@mui/material/colors/lightBlue';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import Box from '@mui/system/Box';
import linkifyHtml from 'linkify-html';
import { DateTime } from 'luxon';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import ContentSpacer from '~components/ContentSpacer';

interface MessageBubbleProps {
  role: 'agent' | 'customer';
  name: string;
  date?: string;
  content: string;
}

type BubbleContainerProps = MUIStyledCommonProps<Theme> &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    authorRole: 'agent' | 'customer';
  };

const BubbleContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'authorRole' })<BubbleContainerProps>(
  ({ authorRole }) => ({
    'padding': 16,
    'borderRadius': 5,
    '&:last-of-type': {
      marginBottom: 0,
    },
    'color': grey[900],
    'backgroundColor': authorRole === 'agent' ? lightBlue[200] : grey[300],
  }),
);

const BubbleMessage = styled('div')({
  'fontSize': 14,
  'fontWeight': 400,
  'margin': 0,
  'wordBreak': 'break-word',
  'whiteSpace': 'pre-wrap',
  '& ul': {
    listStyle: 'disc',
    margin: 0,
    marginTop: 8,
    padding: 0,
    paddingLeft: 16,
  },
  '& ul li': {
    margin: 0,
    marginTop: 2,
  },
});

const formatDate = (timestamp: string | undefined) => {
  if (timestamp === undefined) return '';

  const dateTime = new Date(timestamp);

  // Anything not a date timestamp will be caught here
  if (dateTime.toString() === 'Invalid Date') return '';

  const now = new Date();
  const time = DateTime.fromISO(timestamp).toFormat('h:mm a');
  const isToday =
    now.getDate() == dateTime.getDate() &&
    now.getMonth() == dateTime.getMonth() &&
    now.getFullYear() == dateTime.getFullYear();

  return isToday ? `Today at ${time}` : `${DateTime.fromISO(timestamp).toFormat('yyyy/MM/dd')} at ${time}`;
};

const parsePlainTextWithLinks = (text: string) => {
  const textWithLinks = linkifyHtml(text, { target: '_blank' });
  return sanitizeHtml(textWithLinks, {
    allowedTags: ['a'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });
};

const MessageBubble = ({ role, name, date, content }: MessageBubbleProps) => {
  let formattedDate = formatDate(date);

  return (
    <ContentSpacer spacing={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          fontSize={14}
          fontWeight={500}
          sx={{
            flex: 1,
            margin: '8px 0',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: grey[900],
          }}>
          {name}
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={400}
          title={date}
          sx={{ flex: 1, margin: '8px 0', color: grey[600], textAlign: 'right' }}>
          {formattedDate}
        </Typography>
      </Box>

      <BubbleContainer authorRole={role}>
        {role === 'agent' && (
          <BubbleMessage
            // TODO (christian): Extend this block to do different parsing based on the messageContentType.
            //                   Might be worth making this a switch statement of sorts within an overall
            //                   function.
            dangerouslySetInnerHTML={{
              __html:
                typeof content === 'string'
                  ? parsePlainTextWithLinks(content)
                  : 'unexpected node used instead of plain text',
            }}
          />
        )}
        {role !== 'agent' && (
          // Don't parse HTML/format customers/system chat messages as they should
          // never be able to send HTML to an agent
          <BubbleMessage children={content} />
        )}
      </BubbleContainer>
    </ContentSpacer>
  );
};

export default MessageBubble;
