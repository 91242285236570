import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { CSSProperties, ChangeEvent, KeyboardEvent, useState } from 'react';

import { APIError } from '~services/Errors';

import { normalisePhoneNumber } from './api';

interface DialPhoneNumberInputProps {
  style?: CSSProperties;
  label: string;
  id: string;
  name: string;
  disabled?: boolean;
  onClick: (phoneNumber: string) => Promise<void>;
}

const DialPhoneNumberInput = ({ style, label, id, name, disabled, onClick }: DialPhoneNumberInputProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const disableInput = disabled || loading;
  const disableAction = phoneNumber === '' || disabled || loading;

  const onClickFn = async () => {
    setLoading(true);
    setError('');

    try {
      let ep;

      try {
        ep = await normalisePhoneNumber(phoneNumber);
      } catch (e) {
        setError((e as Error | APIError).message);
        return;
      }

      // If number is successfully formatted we pass down the formatted number to custom click action and perform it
      try {
        await onClick(ep);
      } catch (e) {
        setError((e as Error).message);
        return;
      }

      // Reset phone number to empty on success
      setPhoneNumber('');
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  // If the use clicks enter we want to trigger the onClick function
  const onEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && phoneNumber !== '') {
      onClickFn();
    }
  };

  return (
    <TextField
      fullWidth
      variant='outlined'
      id={id}
      style={style}
      value={phoneNumber}
      onChange={onChange}
      onKeyDown={onEnter}
      name={name}
      label={label}
      disabled={disableInput}
      error={Boolean(error)}
      helperText={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <LoadingButton
              sx={{
                borderRadius: '0 4px 4px 0',
                marginRight: '-14px',
                marginLeft: '4px',
                padding: '16px 16px',
              }}
              type='submit'
              variant='contained'
              disableElevation
              color='primary'
              disabled={disableAction}
              loading={loading}
              onClick={onClickFn}>
              <PhoneForwardedIcon />
            </LoadingButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DialPhoneNumberInput;
