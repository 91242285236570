import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import OberonCard from '~components/OberonCard';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createSkillType, getSkillTypes } from '../api';
import { SkillType } from '../domain';
import CreateSkillTypeModal from './CreateSkillTypeModal';

interface Query {
  search: string;
}

interface Error {
  text: string;
  subText: string;
}

const SkillTypeList = () => {
  const { pushNotification } = useNotification();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [skillTypes, setSkillTypes] = useState<SkillType[]>([]);
  const [query, setQuery] = useState<Query>({
    search: '',
  });

  useEffect(() => {
    (async () => {
      try {
        setSkillTypes(await getSkillTypes());
      } catch (e) {
        handleError(e);
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const toggleCreateSkillTypeModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const addSkillType = async (data: SkillType) => {
    setSubmittingData(true);

    try {
      await createSkillType(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created skill type ${data.skillType}`);

    try {
      setSkillTypes(await getSkillTypes());
    } catch (e) {
      handleError(e);
    } finally {
      setCreateModalOpen(false);
    }
  };

  const displayList = skillTypes
    // Search Fuzzy matching
    .filter((item: SkillType) => {
      if (query.search) {
        return (
          item.skillType.toLowerCase().includes(query.search.toLowerCase()) ||
          item.description.toLowerCase().includes(query.search.toLowerCase())
        );
      }

      return true;
    })
    // Component generation
    .map((item: SkillType, index: number) => (
      <OberonCard key={index} titleFontWeight={700} title={item.skillType} subHeader={item.description} />
    ));

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = query.search && displayList.length === 0;
  const noSkillTypes = skillTypes.length === 0;

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noSkillTypes && (
        <EmptyState
          type='no-items-3'
          text='No skill types currently exist'
          subText='Create a skill type by clicking the button below'
          action={toggleCreateSkillTypeModal}
          actionText='Create Skill Type'
        />
      )}

      {!noSkillTypes && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateSkillTypeModal}>
              Create Skill Type
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No skill types found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CreateSkillTypeModal
        open={createModalOpen}
        submitting={submittingData}
        onClose={toggleCreateSkillTypeModal}
        onAccept={addSkillType}
      />
    </AsyncLoader>
  );
};

export default SkillTypeList;
