import { Organisation } from '~organisations/domain';

import genericAdditionalFields from './additionalOutcomeCaptureFields/generic';
import logo from './images/logo.png';

const g8Config: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '5': genericAdditionalFields,
    '7': genericAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {},
};

export default g8Config;
