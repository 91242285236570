import List from '@mui/material/List';
import Box from '@mui/system/Box';
import React, { useMemo } from 'react';

import EmptyState from '~/components/EmptyState';
import { AuthorRole, Customer } from '~services/AsyncManager/domain';
import {
  getKeysSortedByNewestConversationCreatedDate,
  getOrderedMessageKeysByMessageIdAndCreatedTimestamp,
  getOrderedNumberKeys,
} from '~services/AsyncManager/helpers';

import CustomerItem from './CustomerItem';

interface ConversationContainerProps {
  selectedCustomerKey: string;
  customers: { [key: string]: Customer };
  setActiveCustomer: (customerId: string) => void;
}

const ConversationContainer = ({ selectedCustomerKey, customers, setActiveCustomer }: ConversationContainerProps) => {
  const sortedCustomerKeys = useMemo(() => {
    return getKeysSortedByNewestConversationCreatedDate(customers);
  }, [Object.keys(customers).length]);

  const hasContacts = sortedCustomerKeys.length > 0;
  const contactsDisplay = sortedCustomerKeys.map((key: string) => {
    const isSelected = key === selectedCustomerKey;
    const orderedConversationKeys = getOrderedNumberKeys(customers[key].conversations);
    const convKey = orderedConversationKeys[orderedConversationKeys.length - 1];
    const conv = customers[key].conversations[convKey];
    const sortedMessageKeys = getOrderedMessageKeysByMessageIdAndCreatedTimestamp(conv.messages);
    const unseenMessageCount = sortedMessageKeys.filter((key) => {
      return conv.messages[key].readTimestamp === undefined && conv.messages[key].authorRole === AuthorRole.Customer;
    }).length;
    const hasAgentOrCustomerMessages =
      sortedMessageKeys.filter((key) => {
        return conv.messages[key].authorRole !== AuthorRole.System;
      }).length > 0;

    let latestMessage = '';
    let latestTimestamp = conv.createdTimestamp;
    if (sortedMessageKeys.length > 1) {
      const msgKey = sortedMessageKeys[sortedMessageKeys.length - 1];
      const msg = conv.messages[msgKey];

      if (msg !== undefined) {
        latestMessage = msg.text;

        if (msg.sentTimestamp !== undefined) {
          latestTimestamp = msg.sentTimestamp;
        }
      }
    }

    return (
      <CustomerItem
        key={key}
        name={conv.customerName}
        latestMessage={latestMessage}
        latestTimestamp={latestTimestamp}
        unseenMessageCount={unseenMessageCount}
        important={!hasAgentOrCustomerMessages}
        channelType={conv.channelType}
        autoDisposesAtTimestamp={conv.autoDisposesAtTimestamp}
        selected={isSelected}
        onSelect={() => setActiveCustomer(key)}
      />
    );
  });

  return (
    <Box
      sx={{
        minWidth: 380,
        maxWidth: 380,
        display: 'flex',
        justifyContent: 'center',
        background: '#ffffff',
        height: '100%',
        border: '1px solid #e6e6e6',
        boxShadow: '0 2px 4px rgba(0, 0, 0, .05)',
        borderRadius: '10px',
        marginRight: 2,
        overflow: 'hidden',
      }}>
      {!hasContacts && <EmptyState type='message' subText='No conversations...' />}
      {hasContacts && <List sx={{ width: '100%', margin: 0, padding: 0, overflow: 'auto' }}>{contactsDisplay}</List>}
    </Box>
  );
};

export default ConversationContainer;
