import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  addEndpointToExclusionList,
  bulkAddEndpointsToExclusionList,
  checkEndpointExistsInExclusionList,
  createExclusionList,
  getExclusionLists,
  removeEndpointFromExclusionList,
} from '../api';
import { CreateExclusionList, ExclusionEntry, ExclusionList, ExclusionListUploadResponse } from '../domain';
import BulkAddEndpointsModal from './BulkAddEndpointsModal';
import CreateExclusionListModal from './CreateExclusionListModal';
import EndpointActionModal, { EndpointActionType } from './EndpointActionModal';
import ExclusionListCard from './ExclusionListCard';

interface Query {
  search: string;
}

interface Error {
  text: string;
  subText: string;
}

const ExclusionListList = () => {
  const { pushNotification } = useNotification();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [bulkAddModalOpen, setBulkAddModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [exclusionLists, setExclusionLists] = useState<ExclusionList[]>([]);
  const [selectedExclusionListName, setSelectedExclusionListName] = useState<string | undefined>(undefined);
  const [endpointActionType, setEndpointActionType] = useState<EndpointActionType | undefined>(undefined);
  const [query, setQuery] = useState<Query>({
    search: '',
  });

  useEffect(() => {
    (async () => {
      try {
        setExclusionLists(await getExclusionLists());
      } catch (e) {
        handleError(e);
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const toggleCreateExclusionListModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const triggerBulkAddModalOpen = (listName: string) => () => {
    setSelectedExclusionListName(listName);
    setBulkAddModalOpen(true);
  };

  const triggerBulkAddModalClose = () => {
    setSelectedExclusionListName(undefined);
    setBulkAddModalOpen(false);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const openEndpointActionsModal = (listName: string, actionType: EndpointActionType) => () => {
    setSelectedExclusionListName(listName);
    setEndpointActionType(actionType);
  };

  const closeEndpointActionsModal = () => {
    setSelectedExclusionListName(undefined);
    setEndpointActionType(undefined);
  };

  const addExclusionList = async (data: CreateExclusionList) => {
    setSubmittingData(true);

    try {
      await createExclusionList(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Create exclusionList ${data.name}`);

    try {
      setExclusionLists(await getExclusionLists());
    } catch (e) {
      handleError(e);
    } finally {
      setCreateModalOpen(false);
    }
  };

  const addEndpoint = (listName?: string) => async (data: ExclusionEntry) => {
    setSubmittingData(true);

    if (listName === undefined) {
      console.error('List name not specified');
      return;
    }

    try {
      await addEndpointToExclusionList(listName, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Added endpoint ${data.number} to ${listName}`);
    closeEndpointActionsModal();
  };

  const bulkAddEndpoints =
    (listName?: string) =>
    async (
      file: File,
      onUploadProgress: (progress: number) => void,
      expiryTimestamp?: string,
    ): Promise<ExclusionListUploadResponse | undefined> => {
      setSubmittingData(true);

      if (listName === undefined) {
        console.error('List name not specified');
        return Promise.reject();
      }

      try {
        return await bulkAddEndpointsToExclusionList(listName, file, onUploadProgress, expiryTimestamp);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);

        // Modal catches error to prevent form reset on create failure
        return Promise.reject();
      } finally {
        setSubmittingData(false);
      }
    };

  const checkEndpointExists = (listName?: string) => async (endpoint: string) => {
    setSubmittingData(true);

    if (listName === undefined) {
      console.error('List name not specified');
      return;
    }

    try {
      await checkEndpointExistsInExclusionList(listName, endpoint);
    } catch (e) {
      if (e instanceof APIError && e.status === 404) {
        pushNotification('error', `Endpoint ${endpoint} does not exist in list ${listName}`);
      } else {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      }

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Endpoint ${endpoint} exists in list ${listName}`);
  };

  const removeEndpoint = (listName?: string) => async (endpoint: string) => {
    setSubmittingData(true);

    if (listName === undefined) {
      console.error('List name not specified');
      return;
    }

    try {
      await removeEndpointFromExclusionList(listName, endpoint);
    } catch (e) {
      if (e instanceof APIError && e.status === 404) {
        pushNotification('error', `Unable to remove endpoint ${endpoint} as it does not exist in list ${listName}`);
      } else {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      }

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Removed endpoint ${endpoint} from ${listName}`);
    closeEndpointActionsModal();
  };

  const displayList = exclusionLists
    // Search Fuzzy matching
    .filter((item: ExclusionList) => {
      if (query.search) {
        return item.name.toLowerCase().includes(query.search.toLowerCase());
      }

      return true;
    })
    // Component generation
    .map((item: ExclusionList, index: number) => (
      <ExclusionListCard
        key={index}
        exclusionList={item}
        onAddEndpoint={openEndpointActionsModal(item.name, EndpointActionType.Add)}
        onBulkAddEndpoints={triggerBulkAddModalOpen(item.name)}
        onCheckEndpointExists={openEndpointActionsModal(item.name, EndpointActionType.Check)}
        onRemoveEndpoint={openEndpointActionsModal(item.name, EndpointActionType.Remove)}
      />
    ));

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = query.search && displayList.length === 0;
  const noExclusionLists = exclusionLists.length === 0;

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noExclusionLists && (
        <EmptyState
          type='no-items-3'
          text='No exclusion lists currently exist'
          subText='Create an exclusion list by clicking the button below'
          action={toggleCreateExclusionListModal}
          actionText='Create Exclusion List'
        />
      )}

      {!noExclusionLists && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleCreateExclusionListModal}>
              Create Exclusion List
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No exclusion lists found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CreateExclusionListModal
        open={createModalOpen}
        submitting={submittingData}
        onClose={toggleCreateExclusionListModal}
        onAccept={addExclusionList}
      />

      <BulkAddEndpointsModal
        open={bulkAddModalOpen}
        submitting={submittingData}
        onClose={triggerBulkAddModalClose}
        onAccept={bulkAddEndpoints(selectedExclusionListName)}
      />

      <EndpointActionModal
        open={Boolean(selectedExclusionListName) && Boolean(endpointActionType)}
        endpointActionType={endpointActionType}
        submitting={submittingData}
        onClose={closeEndpointActionsModal}
        onAddEndpoint={addEndpoint(selectedExclusionListName)}
        onCheckEndpoint={checkEndpointExists(selectedExclusionListName)}
        onRemoveEndpoint={removeEndpoint(selectedExclusionListName)}
      />
    </AsyncLoader>
  );
};

export default ExclusionListList;
