import Box from '@mui/system/Box';
import React, { ReactElement } from 'react';

import ProgressItem from './ProgressItem';

interface SplitProgressBarProps {
  height?: number;
  children?: ReactElement<typeof ProgressItem>[];
}

const SplitProgressBar = ({ height = 16, children }: SplitProgressBarProps) => {
  return (
    <Box
      sx={{
        height: height,
        borderRadius: height,
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#e6e6e6',
        overflow: 'hidden',
      }}>
      <>{children}</>
    </Box>
  );
};

export default SplitProgressBar;
