import * as j from '@mojotech/json-type-validation';
import axios from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

export interface AppConfiguration {
  orgReference: string;
  defaultTimezone: string;
  web: WebConfiguration;
  aws: AwsConfiguration;
  validations: ValidationConfiguration;
  extensions: ExtensionsConfiguration;
}

interface WebField {
  key: string;
  label: string;
}

export interface ContactPopLink {
  label: string;
  contactType: string;
  value: string;
}

interface WebConfiguration {
  name: string;
  logrocketAppId: string;
  additionalContactDetailAttributes: WebField[];
  additionalContactContextAttributes: WebField[];
  additionalConversationAttributes: WebField[];
  contactPopLinks: ContactPopLink[];
  searchableContactLogAttributes: WebField[];
  timezonePrefixes: string[];
  supersetUrl: string;
  disableUnknownInboundLeadCreation: boolean;
  leadCreationExclusionList: string[];
  hideQueueDepth: boolean;
  liveDashTableBlacklist: string[];
  displayAllRecordings: boolean;
}

export interface AwsConfiguration {
  amazonConnectInstanceId: string;
  amazonConnectApiBase: string;
  amazonConnectCcpUrl: string;
  amazonConnectRegion: string;
  amazonConnectLoginUrl: string;
  externallyManagedRoutingProfile: boolean;
  externallyManagedConnectInstance: boolean;
}

export interface ValidationConfiguration {
  username?: ValidationItem;
}

export interface ValidationItem {
  pattern: string;
  error: string;
}

export interface InternalTransferConfiguration {
  quickConnectARN: string;
}

export interface ScreenRecordingsConfiguration {
  janusUrl: string;
}

export interface PaymentGatewayConfiguration {
  quickConnectARN: string;
}

export interface CallDirectoryItem {
  name: string;
  phoneNumber: string;
  extension?: number;
}

export interface CallDirectoryConfiguration {
  list: CallDirectoryItem[];
}

export interface Deobfuscation {
  properties: string[];
}

// TODO: Remove when BNZ stops using our softphone
export interface BNZDeobfuscation {
  authEndpoint: string;
  deobfuscateEndpoint: string;
  properties: string[];
}

// Add as we go
export interface ExtensionsConfiguration {
  internalTransfer?: InternalTransferConfiguration;
  predictive?: PredictiveConfiguration;
  screenRecordings?: ScreenRecordingsConfiguration;
  paymentGateway?: PaymentGatewayConfiguration;
  pauseRecordings?: {};
  routingProfileSelection?: {};
  callDirectory?: CallDirectoryConfiguration;
  connectOutboundTimezoneARNs?: { [key: string]: string };
  deobfuscation?: Deobfuscation;
  BNZDeobfuscation?: BNZDeobfuscation;
  amplifonSipFormFields?: {};
  HCFSipFormFields?: {};
}

export interface PredictiveConfiguration {
  defaultTrunk: string;
  diallerURL: string;
  sipWebsocket: string;
  sipHost: string;
}

const AppConfigurationDecoder: j.Decoder<AppConfiguration> = j
  .object({
    org_reference: j.string(),
    default_timezone: j.string(),
    web: j.object({
      name: j.string(),
      logrocket_app_id: j.string(),
      additional_contact_detail_attributes: j.union(
        j.array(
          j.object({
            key: j.string(),
            label: j.string(),
          }),
        ),
        j.constant(null),
      ),
      additional_contact_context_attributes: j.union(
        j.array(
          j.object({
            key: j.string(),
            label: j.string(),
          }),
        ),
        j.constant(null),
      ),
      additional_conversation_attributes: j.union(
        j.array(
          j.object({
            key: j.string(),
            label: j.string(),
          }),
        ),
        j.constant(null),
      ),
      contact_pop_links: j.union(
        j.array(
          j.object({
            label: j.string(),
            contact_type: j.string(),
            value: j.string(),
          }),
        ),
        j.constant(null),
      ),
      searchable_contact_log_attributes: j.union(
        j.array(
          j.object({
            key: j.string(),
            label: j.string(),
          }),
        ),
        j.constant(null),
      ),
      timezone_prefixes: j.union(j.array(j.string()), j.constant(null)),
      superset_url: j.string(),
      disable_unknown_inbound_lead_creation: j.union(j.boolean(), j.constant(null)),
      lead_creation_exclusion_list: j.union(j.array(j.string()), j.constant(null)),
      hide_queue_depth: j.union(j.boolean(), j.constant(null)),
      live_dash_table_blacklist: j.optional(j.union(j.array(j.string()), j.constant(null))),
      display_all_recordings: j.union(j.boolean(), j.constant(null), j.constant(undefined)),
    }),
    aws: j.object({
      amazon_connect_instance_id: j.string(),
      amazon_connect_api_base: j.string(),
      amazon_connect_ccp_url: j.string(),
      amazon_connect_region: j.string(),
      amazon_connect_login_url: j.string(),
      externally_managed_routing_profile: j.boolean(),
      externally_managed_connect_instance: j.boolean(),
    }),
    validations: j.object({
      username: j.optional(
        j.object({
          pattern: j.string(),
          error: j.string(),
        }),
      ),
    }),
    extensions: j.optional(
      j.object({
        // This will always be an empty object as the external transfer list is never returned to the frontend
        // via config (it is sent via the /api/external-transfers endpoint). If the property is an empty object
        // we assume this extension is enabled
        // TODO: might be worth not considering this an extension and just display the view or hiding it if the backend API
        // returns 0 records?????
        internal_transfer: j.optional(
          j.object({
            quick_connect_arn: j.string(),
          }),
        ),
        predictive: j.optional(
          j.object({
            default_trunk: j.string(),
            dialler_url: j.string(),
            sip_websocket: j.string(),
            sip_host: j.string(),
          }),
        ),
        screen_recordings: j.optional(
          j.object({
            janus_url: j.string(),
          }),
        ),
        payment_gateway: j.optional(
          j.object({
            quick_connect_arn: j.string(),
          }),
        ),
        pause_recordings: j.optional(j.object()),
        routing_profile_selection: j.optional(j.object()),
        call_directory: j.optional(
          j.object({
            call_directory_list: j.union(
              j.array(
                j.object({
                  name: j.string(),
                  phone_number: j.string(),
                  extension: j.union(j.number(), j.constant(null)),
                }),
              ),
              j.constant(null),
            ),
          }),
        ),
        connect_outbound_timezone_arns: j.optional(
          j.array(
            j.object({
              timezone: j.string(),
              arn: j.string(),
            }),
          ),
        ),
        deobfuscation: j.optional(
          j.object({
            properties: j.array(j.string()),
          }),
        ),
        bnz_deobfuscation: j.optional(
          j.object({
            auth_endpoint: j.string(),
            deobfuscate_endpoint: j.string(),
            properties: j.array(j.string()),
          }),
        ),
        amplifon_sip_form_fields: j.optional(j.object()),
        hcf_sip_form_fields: j.optional(j.object()),
      }),
    ),
  })
  .map((item: any) => ({
    orgReference: item.org_reference,
    defaultTimezone: item.default_timezone,
    web: {
      name: item.web.name,
      logrocketAppId: item.web.logrocket_app_id,
      additionalContactDetailAttributes: item.web.additional_contact_detail_attributes || [],
      additionalContactContextAttributes: item.web.additional_contact_context_attributes || [],
      additionalConversationAttributes: item.web.additional_conversation_attributes || [],
      contactPopLinks: item.web.contact_pop_links
        ? item.web.contact_pop_links.map((popLink: any) => ({
            label: popLink.label,
            contactType: popLink.contact_type,
            value: popLink.value,
          }))
        : [],
      searchableContactLogAttributes: item.web.searchable_contact_log_attributes || [],
      timezonePrefixes: item.web.timezone_prefixes || [],
      supersetUrl: item.web.superset_url || '',
      disableUnknownInboundLeadCreation: item.web.disable_unknown_inbound_lead_creation || false,
      leadCreationExclusionList: item.web.lead_creation_exclusion_list || [],
      hideQueueDepth: item.web.hide_queue_depth || false,
      liveDashTableBlacklist: item.web.live_dash_table_blacklist || [],
      displayAllRecordings: item.web.display_all_recordings || false,
    },
    aws: {
      amazonConnectInstanceId: item.aws.amazon_connect_instance_id,
      amazonConnectApiBase: item.aws.amazon_connect_api_base,
      amazonConnectCcpUrl: item.aws.amazon_connect_ccp_url,
      amazonConnectRegion: item.aws.amazon_connect_region,
      amazonConnectLoginUrl: item.aws.amazon_connect_login_url,
      externallyManagedRoutingProfile: item.aws.externally_managed_routing_profile,
      externallyManagedConnectInstance: item.aws.externally_managed_connect_instance,
    },
    validations: {
      username: item.validations.username
        ? {
            pattern: item.validations.username.pattern,
            error: item.validations.username.error,
          }
        : undefined,
    },
    extensions: item.extensions
      ? {
          // TODO: could we also fold this into the internal-transfers endpoint and
          // then not expose to the frontend?
          internalTransfer: item.extensions.internal_transfer
            ? {
                quickConnectARN: item.extensions.internal_transfer.quick_connect_arn,
              }
            : undefined,
          predictive: item.extensions.predictive
            ? {
                defaultTrunk: item.extensions.predictive.default_trunk,
                diallerURL: item.extensions.predictive.dialler_url,
                sipWebsocket: item.extensions.predictive.sip_websocket,
                sipHost: item.extensions.predictive.sip_host,
              }
            : undefined,
          screenRecordings: item.extensions.screen_recordings
            ? {
                janusUrl: item.extensions.screen_recordings.janus_url,
              }
            : undefined,
          paymentGateway: item.extensions.payment_gateway
            ? {
                quickConnectARN: item.extensions.payment_gateway.quick_connect_arn,
              }
            : undefined,
          pauseRecordings: item.extensions.pause_recordings ? {} : undefined,
          routingProfileSelection: item.extensions.routing_profile_selection ? {} : undefined,
          callDirectory: item.extensions.call_directory
            ? {
                list: item.extensions.call_directory.call_directory_list
                  ? item.extensions.call_directory.call_directory_list.map((cd: any) => ({
                      name: cd.name,
                      phoneNumber: cd.phone_number,
                      extension: cd.extension || undefined,
                    }))
                  : [],
              }
            : undefined,
          connectOutboundTimezoneARNs: item.extensions.connect_outbound_timezone_arns
            ? item.extensions.connect_outbound_timezone_arns.reduce(
                (state: any, currentValue: any) => ({
                  ...state,
                  [currentValue.timezone]: currentValue.arn,
                }),
                {},
              )
            : undefined,
          deobfuscation: item.extensions.deobfuscation
            ? {
                properties: item.extensions.deobfuscation.properties,
              }
            : undefined,
          BNZDeobfuscation: item.extensions.bnz_deobfuscation
            ? {
                authEndpoint: item.extensions.bnz_deobfuscation.auth_endpoint,
                deobfuscateEndpoint: item.extensions.bnz_deobfuscation.deobfuscate_endpoint,
                properties: item.extensions.bnz_deobfuscation.properties,
              }
            : undefined,
          amplifonSipFormFields: item.extensions.amplifon_sip_form_fields ? {} : undefined,
          HCFSipFormFields: item.extensions.hcf_sip_form_fields ? {} : undefined,
        }
      : {},
  }));

export const getAppConfiguration = async (): Promise<AppConfiguration> => {
  const path = '/api/configuration/';

  let resp;
  try {
    resp = await axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = AppConfigurationDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
