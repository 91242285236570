import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SmsIcon from '@mui/icons-material/Sms';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import red from '@mui/material/colors/red';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import useCountdown from '~hooks/useCountdown';
import { ChannelType } from '~services/AsyncManager/domain';

interface ContactItemProps {
  name: string;
  latestMessage: string;
  latestTimestamp: string;
  unseenMessageCount: number;
  important: boolean;
  channelType: ChannelType;
  autoDisposesAtTimestamp: string | undefined;
  selected: boolean;
  onSelect: () => void;
}

interface AutoDisposePillProps {
  timestamp: string;
}

const iconBackgroundColorMap: { [key in ChannelType]: string } = {
  [ChannelType.WebChat]: '#2196f3',
  [ChannelType.SMS]: '#78909c',
  [ChannelType.Email]: '#ef5350',
  [ChannelType.AndroidRCS]: '#43a047',
  [ChannelType.AppleBusinessChat]: '#7d7d7d',
};

const iconMap: { [key in ChannelType]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> } = {
  [ChannelType.WebChat]: ChatIcon,
  [ChannelType.SMS]: SmsIcon,
  [ChannelType.Email]: EmailIcon,
  [ChannelType.AndroidRCS]: AndroidIcon,
  [ChannelType.AppleBusinessChat]: AppleIcon,
};

const formatDate = (timestamp: string | undefined) => {
  if (timestamp === undefined) return '';

  const dateTime = new Date(timestamp);

  // Anything not a date timestamp will be caught here
  if (dateTime.toString() === 'Invalid Date') return '';

  const now = new Date();
  const time = DateTime.fromISO(timestamp).toFormat('h:mm a');
  const isToday =
    now.getDate() == dateTime.getDate() &&
    now.getMonth() == dateTime.getMonth() &&
    now.getFullYear() == dateTime.getFullYear();

  return isToday ? `Today at ${time}` : `${DateTime.fromISO(timestamp).toFormat('yyyy/MM/dd')} at ${time}`;
};

const zeroPad = (value: number | undefined): string => {
  if (value === undefined) return '00';

  if (value >= 0 && value <= 9) {
    return `0${value}`;
  }

  return `${value}`;
};

const AutoDisposePill = ({ timestamp }: AutoDisposePillProps) => {
  const { hours, minutes, seconds } = useCountdown(timestamp);
  const label = `auto dispose in ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;

  return (
    <Tooltip arrow title='Dictates how long until the customer conversation is auto disposed of as abandoned.'>
      <Chip
        sx={{ fontSize: 10, height: 22 }}
        component='span'
        size='small'
        variant='filled'
        color='error'
        label={label}
      />
    </Tooltip>
  );
};

const ContactItem = ({
  name,
  latestMessage,
  latestTimestamp,
  unseenMessageCount,
  important,
  channelType,
  autoDisposesAtTimestamp,
  selected,
  onSelect,
}: ContactItemProps) => {
  const Icon = iconMap[channelType];
  const iconBackgroundColor = iconBackgroundColorMap[channelType];
  const hasUnseenMessages = unseenMessageCount > 0;
  const showIndicator = hasUnseenMessages || important;
  let indicatorValue: any = <PriorityHighIcon sx={{ width: 12, height: 12 }} />;

  if (hasUnseenMessages) {
    indicatorValue = unseenMessageCount;
  }

  const lastMessageTimestamp = formatDate(latestTimestamp);

  return (
    <ListItem
      sx={{
        background: 'white',
      }}
      button
      alignItems='center'
      selected={selected}
      onClick={onSelect}>
      <ListItemAvatar>
        <Avatar alt={name} sx={{ backgroundColor: iconBackgroundColor }}>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          '& .MuiListItemText-secondary': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        primary={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
            <Box>
              <Typography fontSize={14} fontWeight='700'>
                {name}
              </Typography>
              <Typography fontSize={14}>{lastMessageTimestamp}</Typography>
            </Box>

            {showIndicator && (
              <Box>
                <Box
                  style={{
                    backgroundColor: red[600],
                    color: 'white',
                    borderRadius: 10,
                    fontWeight: 500,
                    minWidth: 20,
                    height: 20,
                    fontSize: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: hasUnseenMessages ? 6 : undefined,
                  }}>
                  {indicatorValue}
                </Box>
              </Box>
            )}
          </Box>
        }
        secondary={
          // "secondary" wraps the following in a <p> tag, so do not use <div>'s here
          // or React will give console errors about invalid HTML in development mode
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box component='span' sx={{ fontSize: 14, maxWidth: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {sanitizeHtml(latestMessage, {
                allowedTags: [],
              })}
            </Box>

            {autoDisposesAtTimestamp && <AutoDisposePill timestamp={autoDisposesAtTimestamp} />}
          </span>
        }
      />
    </ListItem>
  );
};

export default ContactItem;
