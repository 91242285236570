import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { ForwardedRef, KeyboardEvent, MouseEvent, forwardRef, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';
import MessageTemplateDetailsModal from '~pages/AsyncManagement/QueueDetails/MessageTemplates/MessageTemplateDetailsModal';
import { MessageTemplate } from '~pages/AsyncManagement/QueueDetails/MessageTemplates/useMessageTemplate/domain';

interface MessageTemplateCardProps {
  messageTemplate: MessageTemplate;
  onEdit: () => void;
  onRemove: () => void;
}

const MessageTemplateCard = forwardRef(
  ({ messageTemplate, onEdit, onRemove }: MessageTemplateCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
    const [performingRemoveAction, setPerformingRemoveAction] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleMenuOpen = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(null);
    };

    const handleRemoveConfirmationOpen = (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      setRemoveConfirmOpen(true);
    };

    const handleEdit = (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      onEdit();
    };

    const handleRemoveConfirmationClose = () => {
      setRemoveConfirmOpen(false);
    };

    const handleRemove = async () => {
      setPerformingRemoveAction(true);

      try {
        await onRemove();
      } catch (e) {
        return;
      } finally {
        setPerformingRemoveAction(false);
      }

      setRemoveConfirmOpen(false);
    };

    const onModalOpen = () => {
      setOpenModal(true);
    };

    const onModalClose = () => {
      setOpenModal(false);
    };

    return (
      <>
        <OberonCard
          ref={ref}
          onClick={onModalOpen}
          titleFontWeight={400}
          title={messageTemplate.title}
          subHeader={
            <Chip
              sx={{
                marginTop: 0.5,
                textTransform: 'uppercase',
                fontSize: 10,
                borderRadius: 1,
                height: 20,
                lineHeight: '21px',
                color: '#ffffff',
                fontWeight: 700,
              }}
              color='primary'
              label={messageTemplate.category}
            />
          }
          action={
            <>
              <IconButton
                aria-controls='message-template-menu'
                aria-haspopup='true'
                onKeyPress={handleMenuOpen}
                onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id='lead-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleRemoveConfirmationOpen}>Remove</MenuItem>
              </Menu>
            </>
          }
        />

        <MessageTemplateDetailsModal open={openModal} messageTemplate={messageTemplate} onClose={onModalClose} />

        <ActionDialog
          open={removeConfirmOpen}
          title='Are you sure you want to do this?'
          content={`You are about to remove the message template ${messageTemplate.title} from the dialling list, once you have completed this action it cannot be undone.`}
          onClose={handleRemoveConfirmationClose}
          onAccept={handleRemove}
          loading={performingRemoveAction}
          primaryActionTitle='Remove'
        />
      </>
    );
  },
);

export default MessageTemplateCard;
