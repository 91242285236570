import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

import achievementImg from './images/achievement.svg';
import callWaitingImg from './images/callWaiting.svg';
import comingSoonImg from './images/comingSoon.svg';
import documentImg from './images/document.svg';
import emptyChatView from './images/emptyChatView.svg';
import errorImg from './images/error.svg';
import messageImg from './images/message.svg';
import noItems from './images/noItems.svg';
import noItems2 from './images/noItems2.svg';
import noItems3 from './images/noItems3.svg';
import noRecordsFoundImg from './images/noRecordsFound.svg';
import notFoundImg from './images/notFound.svg';
import offlineImg from './images/offline.svg';
import puzzleImg from './images/puzzle.svg';
import shareScreenImg from './images/shareScreen.svg';
import videoImg from './images/video.svg';

export type StateType =
  | 'call-waiting'
  | 'document'
  | 'message'
  | 'offline'
  | 'error'
  | 'not-found'
  | 'no-records-found'
  | 'no-items-1'
  | 'no-items-2'
  | 'no-items-3'
  | 'coming-soon'
  | 'empty-chat-view'
  | 'puzzle'
  | 'achievement'
  | 'video'
  | 'share-screen';

interface EmptyStateProps {
  type?: StateType;
  text?: string;
  subText?: string;
  action?: () => void;
  actionText?: string;
  fullHeight?: boolean;
  fixedHeightContainer?: boolean;
}

const stateImage: { [key in StateType]: string } = {
  'call-waiting': callWaitingImg,
  'document': documentImg,
  'message': messageImg,
  'offline': offlineImg,
  'error': errorImg,
  'not-found': notFoundImg,
  'no-records-found': noRecordsFoundImg,
  'no-items-1': noItems,
  'no-items-2': noItems2,
  'no-items-3': noItems3,
  'coming-soon': comingSoonImg,
  'empty-chat-view': emptyChatView,
  'puzzle': puzzleImg,
  'achievement': achievementImg,
  'video': videoImg,
  'share-screen': shareScreenImg,
};

const EmptyState = ({ type, text, subText, action, actionText, fullHeight, fixedHeightContainer }: EmptyStateProps) => {
  const src = type ? stateImage[type] : errorImg;

  return (
    <div
      style={{
        height: fullHeight ? '100vh' : '100%',
        width: '100%',
        minWidth: 320,
        maxWidth: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <div
        style={{
          flex: fixedHeightContainer ? 1 : 'inital',
          width: '100%',
          minWidth: 320,
          maxWidth: 500,
          minHeight: 0,
        }}>
        <img style={{ objectFit: 'fill', width: '100%', height: '100%' }} src={src} />
      </div>
      {text && (
        <Typography variant='h4' component='p' align='center' color='textPrimary' gutterBottom>
          {text}
        </Typography>
      )}

      {subText && (
        <Typography
          style={{ whiteSpace: 'break-spaces' }}
          variant='body1'
          component='p'
          align='center'
          color='textSecondary'
          paragraph>
          {subText}
        </Typography>
      )}

      {action && (
        <Button variant='contained' disableElevation color='primary' onClick={action}>
          {actionText || 'Action'}
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
