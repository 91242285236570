import { detect } from 'detect-browser';
import React, { ReactNode } from 'react';

import EmptyState from '../EmptyState';

interface BrowserSupportProps {
  children: ReactNode;
}

const BrowserSupport = ({ children }: BrowserSupportProps) => {
  const browser = detect();

  switch (browser && browser.name) {
    case 'chrome': {
      return <>{children}</>;
    }
    case 'firefox': {
      return <>{children}</>;
    }
    case 'edge-chromium': {
      return <>{children}</>;
    }
    default: {
      return (
        <div style={{ height: '100vh' }}>
          <EmptyState text={`The browser ${browser?.name} is not supported.`} />
        </div>
      );
    }
  }
};

export default BrowserSupport;
