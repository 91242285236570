import { FC } from 'react';

import { PolicyTypeValues } from '~providers/AuthProvider/domain';

import { ExtensionsConfiguration } from '../AppConfigurationProvider/api';

interface PageOptions {
  // Reference to the extension name from the /configuration endpoint i.e. we may have some route level extensions
  // Used to identify if the page is navigable dependent on if the extension config is present or not
  extensionRef?: keyof ExtensionsConfiguration | undefined;
}

class Page {
  path: string;
  viewPolicies: PolicyTypeValues[];
  extensionRef: keyof ExtensionsConfiguration | undefined;
  title: string;
  icon?: FC | null;
  component?: FC;
  isTopLevel: boolean;
  options?: PageOptions;
  // Indicates whether the menu item is selected or not
  isSelected: boolean = false;

  constructor(
    path: string,
    viewRoles: PolicyTypeValues[],
    title: string,
    icon?: FC | null,
    component?: FC,
    options?: PageOptions,
  ) {
    this.path = path;
    this.viewPolicies = viewRoles;
    this.extensionRef = options?.extensionRef;
    this.title = title;
    this.icon = icon;
    this.component = component;
    // We only want to display top level routes and no wildcard variations
    this.isTopLevel = path.split('/').length <= 2 && path !== '*';
  }

  selected(value: boolean) {
    this.isSelected = value;
  }
}

export default Page;
