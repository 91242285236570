import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import { createAsyncQueue, updateAsyncQueue } from '~pages/AsyncManagement/api';
import CreateEditQueueModal from '~pages/AsyncManagement/QueueList/CreateEditQueueModal';
import QueueCard from '~pages/AsyncManagement/QueueList/QueueCard';
import useAsyncQueueSearch from '~pages/AsyncManagement/useAsyncQueueSearch';
import Routes from '~providers/RouteProvider/Routes';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

import { CreateEditAsyncQueue } from '../domain';

const QueueList = () => {
  const { accessFilter } = useUserPreferences();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editableRef, setEditableRef] = useState<string | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const {
    loading,
    error,
    list,
    hasMore,
    reload,
    intersectionObserverRef: lastDataElement,
  } = useAsyncQueueSearch(debouncedSearch, {
    accessFilterId: accessFilter?.id,
  });
  const editableQueue = useMemo(() => list.find((item) => item.queue === editableRef), [editableRef]);
  const noSearchOrFilterSet = search === '';
  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateEditModal = () => {
    setCreateModalOpen(false);
    setEditableRef(undefined);
  };

  const onQueryChange = async (e: ChangeEvent<any>) => {
    setSearch(e.target.value);
  };

  const onAccept = async (queue: CreateEditAsyncQueue) => {
    setSubmittingData(true);
    try {
      if (editableQueue) {
        await updateAsyncQueue(editableQueue.queue, queue);
      } else {
        await createAsyncQueue(queue);
      }
    } catch (e) {
      setSubmittingData(false);
      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    setSubmittingData(false);
    closeCreateEditModal();
    reload();
  };

  const displayList = useMemo(
    () =>
      list.map((item, index) => {
        const props = {
          ref: index === list.length - 1 ? lastDataElement : null,
          key: item.queue,
          to: generatePath(Routes.asyncQueueDetails.path, { queue: item.queue }),
          queue: item,
          onEdit: () => setEditableRef(item.queue),
        };

        return <QueueCard {...props} />;
      }),
    [list, lastDataElement],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={search}
            onChange={onQueryChange}
          />
        </Grid>
        <Hidden smDown>
          <Grid item sm={5}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={openCreateModal}>
            Create Queue
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && list.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load queues
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No queues found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {list.length === 0 && noSearchOrFilterSet && !accessFilter && (
              <EmptyState type='no-items-3' text='No queues currently exist' />
            )}

            {list.length === 0 && noSearchOrFilterSet && accessFilter && (
              <EmptyState
                type='no-records-found'
                text={`${accessFilter.name} access filter does not have any queues assigned to it`}
              />
            )}
          </Grid>
        </Grid>
      </AsyncLoader>

      <CreateEditQueueModal
        open={createModalOpen || Boolean(editableQueue)}
        queue={editableQueue}
        submitting={submittingData}
        onAccept={onAccept}
        onClose={closeCreateEditModal}
      />
    </>
  );
};

export default QueueList;
