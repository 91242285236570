import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ControlledFileUpload from '~components/Form/ControlledFileUpload';
import OberonDialog from '~components/OberonDialog';

import { PredictiveIvrMessage } from '../../domain';

interface CreateEditAttributeModalProps {
  open: boolean;
  submitting: boolean;
  ivrMessage?: PredictiveIvrMessage;
  onAccept: (isEdit: boolean, data: PredictiveIvrMessage) => void;
  onClose: () => void;
}

interface Form {
  key: string;
  description: string;
  uploadType: string;
  content?: string;
  file?: File;
}

const uploadTypeFile = 'File';
const uploadTypePolly = 'Polly';
const uploadTypes = [
  {
    label: 'SSML',
    value: uploadTypePolly,
  },
  {
    label: 'Audio File',
    value: uploadTypeFile,
  },
];

const SSMLTags = '(speak|break|lang|mark|p|phoneme|prosody|s|say-as|sub|w|amazon:effect)';
const SSMLOpen = new RegExp('^<\\s*' + SSMLTags + '(\\s+[-\\w:]+="[^"]*")*\\s*>');
const SSMLSelfClose = new RegExp('^<\\s*' + SSMLTags + '(\\s+[-\\w:]+=("[^"]*")*)*\\s*/>');
const SSMLClose = new RegExp('^</\\s*' + SSMLTags + '\\s*>');

const ssmlValidate = (value: string): string | undefined => {
  var state = [];
  while (value) {
    value = value.replace(/^\s+/, '');
    var isOpen = SSMLOpen.exec(value);
    if (isOpen !== null) {
      state.push(isOpen[1]);
      value = value.slice(isOpen[0].length);
      continue;
    }

    var isSelfClose = SSMLSelfClose.exec(value);
    if (isSelfClose !== null) {
      value = value.slice(isSelfClose[0].length);
      continue;
    }

    var isClose = SSMLClose.exec(value);
    if (isClose) {
      if (state.length === 0 || state[state.length - 1] != isClose[1]) {
        return `Unmatched closing tag: ${isClose[1]} found, state [${state.join('>')}]`;
      }
      state.pop();
      value = value.slice(isClose[0].length);
      continue;
    }

    if (/^[<>]/.test(value)) {
      return `Could not read at ${value.slice(0, 10)}`;
    }
    value = value.replace(/^[^<>]+/, '');
  }
  if (state.length > 0) {
    return `Unclosed tags: [${state.join('>')}]`;
  }

  return undefined;
};

const CreateEditIVRMessageModal = ({
  open,
  submitting,
  ivrMessage,
  onAccept,
  onClose,
}: CreateEditAttributeModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(ivrMessage);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      key: '',
      description: '',
      uploadType: uploadTypePolly,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const uploadType = watch('uploadType');

  const uploadTypeDisplay = uploadTypes.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  // Handles all async based actions
  useEffect(() => {
    // If edit lets prefill all required fields
    if (open && ivrMessage !== undefined) {
      setValue('key', ivrMessage.key);
      setValue('description', ivrMessage.description);
      setValue('content', ivrMessage.content);
      setValue('file', undefined);
    }

    // Reset form on close
    return function cleanupCreateEditAttributeModal() {
      reset();
    };
  }, [open, ivrMessage]);

  const validateContent = (content?: string): string | undefined => {
    const maxLength = 6000; // max characters polly supports
    if (content) {
      if (content.length > maxLength) {
        return `Length exceeds max value ${maxLength}`;
      }
    } else {
      return 'SSML content is required';
    }
    return ssmlValidate(content);
  };

  const validateFile = (file?: File): string | undefined => {
    const maxSize = 10 << 20; // 10mb
    if (!file) {
      return 'Audio file is required';
    }
    if (file) {
      let fn = file.name.toLowerCase();
      if (!fn.endsWith('.wav') && !fn.endsWith('.sln16')) {
        return 'Only wav or sln16 file is supported';
      }
      if (file.size > maxSize) {
        return 'File size exceeds 10MB';
      }
    }
    return undefined;
  };

  const onSubmit = handleSubmit(async (data: Form) => {
    const ivrMsg: PredictiveIvrMessage = {
      key: data.key,
      description: data.description,
      content: data.uploadType === uploadTypePolly ? data.content : undefined,
      file: data.uploadType === uploadTypeFile ? data.file : undefined,
    };
    try {
      await onAccept(isEdit, ivrMsg);

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} IVR Message`}
      subHeader={
        <Typography variant='caption' component='h1' color='textSecondary'>
          Either generate audio from SSML or upload an audio file
        </Typography>
      }
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='key'
              control={control}
              rules={{
                required: 'Key is required.',
                maxLength: 60,
                pattern: {
                  value: /^[0-9a-z_-]+$/,
                  message: 'Key can only be lowercase, alphanumeric and contain hyphens/underscores',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Key'
                  disabled={submitting || isEdit}
                  required={true}
                  error={Boolean(errors.key)}
                  helperText={errors.key?.message || `Must be globally unique, max length is 60.`}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: false,
                maxLength: 500,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='uploadType'
              control={control}
              rules={{
                required: 'Upload Type is required.',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  variant='outlined'
                  label='Upload Type'
                  required={true}
                  error={Boolean(errors.uploadType)}
                  helperText={errors.uploadType?.message}
                  value={field.value || ''}>
                  {uploadTypeDisplay}
                </TextField>
              )}
            />
          </Grid>

          {uploadType !== uploadTypeFile && (
            <Grid item xs={12}>
              <Controller
                name='content'
                control={control}
                rules={{
                  validate: validateContent,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Content'
                    disabled={isLoading}
                    error={Boolean(errors.content)}
                    helperText={
                      errors.content?.message || (
                        <>
                          Content must be valid{' '}
                          <Link
                            target={'_blank'}
                            href={'https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html'}>
                            SSML
                          </Link>
                        </>
                      )
                    }
                  />
                )}
              />
            </Grid>
          )}

          {uploadType === uploadTypeFile && (
            <Grid item xs={12}>
              <ControlledFileUpload
                control={control}
                rules={{
                  validate: validateFile,
                }}
                name='file'
                label='File'
                disabled={submitting}
                error={Boolean(errors.file)}
                helperText={errors.file?.message || `Max size: 10MB, supported format: wav`}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditIVRMessageModal;
