import { Organisation } from '~organisations/domain';

import genericAdditionalFields from './additionalOutcomeCaptureFields/generic';
import logo from './images/logo.svg';

const globalExpressConfig: Organisation = {
  logo: logo,
  additionalOutcomeCaptureFields: {
    '3': genericAdditionalFields,
  },
  asyncAdditionalOutcomeCaptureFields: {},
  contactAssessmentForms: {},
};

export default globalExpressConfig;
