import axios, { CancelTokenSource } from 'axios';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Lead, LeadStatusType } from '~pages/CampaignManagement/domain';

import { bulkRemoveLeads, getLeads, getLeadsByNextUrl, removeLead } from './api';
import { FilterOptions, LeadBulkActionOptions } from './domain';
import { LeadsResponse } from './domain';

type Options = FilterOptions & {
  shouldFetch?: boolean;
};

const useCampaignListLeadSearch = (
  campaignId: number,
  listId: number,
  options: Options = {
    search: undefined,
    leadStatus: undefined,
    shouldFetch: true,
  },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const search = options.search;
  const leadStatus = options.leadStatus;
  const shouldFetch = options.shouldFetch ?? true;

  const downloadLink = useMemo(() => {
    // We use searchParams to generate this link as we want the download to be an
    // exact representation of what is currently shown on the screen
    const searchQuery = queryString.stringify({
      search: search || undefined,
      lead_status: leadStatus || undefined,
    });

    return `/api/campaign/${campaignId}/list/${listId}/download?${searchQuery}`;
  }, [campaignId, listId, search, JSON.stringify(leadStatus)]);

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: LeadsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLeadsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setLeads((prev) => [...prev, ...resp!.list]);
      setTotal(resp.totalLeads);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: LeadsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getLeads(campaignId, listId, search, leadStatus, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setLeads(resp.list);
    setTotal(resp.totalLeads);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [campaignId, listId, search, JSON.stringify(leadStatus)]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const remove = useCallback(
    async (leadId: number) => {
      try {
        await removeLead(campaignId, listId, leadId);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      // We only update the local state as we do not want to trigger a full page refresh for one record only, especially if we
      // are a few pages deep in the list
      const index = leads.findIndex((item) => item.id === leadId);
      if (index !== -1) {
        setLeads((prev) => {
          let newList = [...prev];
          let leadForUpdate = { ...newList[index] };
          leadForUpdate.leadStatus = LeadStatusType.Removed;
          newList[index] = leadForUpdate;

          return newList;
        });
      }
    },
    [campaignId, listId, leads],
  );

  const bulkRemove = useCallback(
    async (data: LeadBulkActionOptions) => {
      try {
        await bulkRemoveLeads(campaignId, listId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [campaignId, listId, reload],
  );

  useEffect(() => {
    setLeads([]);
  }, [campaignId, listId, search, JSON.stringify(leadStatus), shouldFetch]);

  useEffect(() => {
    const load = async (campaignId: number, listId: number, search?: string, leadStatus?: string[]) => {
      setLoading(true);
      setError(false);

      let resp: LeadsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLeads(campaignId, listId, search, leadStatus, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setLeads((prev) => [...prev, ...resp!.list]);
      setTotal(resp.totalLeads);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(campaignId, listId, search, leadStatus);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [campaignId, listId, search, JSON.stringify(leadStatus), shouldFetch]);

  return { loading, error, leads, total, hasMore, intersectionObserverRef, downloadLink, remove, bulkRemove };
};

export default useCampaignListLeadSearch;
