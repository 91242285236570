import { Customer, Message } from '~services/AsyncManager/domain';

export const getOrderedNumberKeys = (obj: { [key: number]: any }): number[] => {
  return Object.keys(obj)
    .sort((a, b) => Number(a) - Number(b))
    .map((n) => Number(n));
};

export const getOrderedMessageKeysByMessageIdAndCreatedTimestamp = (messages: { [key: string]: Message }) => {
  return Object.keys(messages)
    .sort((a, b) => messages[a].id - messages[b].id)
    .sort(
      (a, b) => new Date(messages[a].createdTimestamp).getTime() - new Date(messages[b].createdTimestamp).getTime(),
    );
};

export const getKeysSortedByNewestConversationCreatedDate = (customers: { [key: string]: Customer }): string[] => {
  return Object.keys(customers).sort((first: string, second: string) => {
    const orderedConversationsFirst = getOrderedNumberKeys(customers[first].conversations);
    const convFirstKey = orderedConversationsFirst[orderedConversationsFirst.length - 1];

    const orderedConversationsSecond = getOrderedNumberKeys(customers[second].conversations);
    const convSecondKey = orderedConversationsSecond[orderedConversationsSecond.length - 1];

    return (
      new Date(customers[second].conversations[convSecondKey].createdTimestamp).getTime() -
      new Date(customers[first].conversations[convFirstKey].createdTimestamp).getTime()
    );
  });
};
