import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FocusEvent, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import useDebounce from '~hooks/useDebounce';
import useCampaignSearch from '~pages/CampaignManagement/CampaignList/useCampaignSearch';
import { AddCampaignGroupAssignment } from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/useDiallerGroupCampaignSearch/domain';
import { CampaignListItem } from '~pages/CampaignManagement/domain';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

interface Props {
  open: boolean;
  submitting: boolean;
  onAccept: (data: AddCampaignGroupAssignment) => void;
  onClose: () => void;
}

interface Form {
  campaign: CampaignListItem | null;
  priority: number;
  setStartOrEndTime: boolean;
  startTime: DateTime | null;
  endTime: DateTime | null;
}

const AssignCampaignModal = ({ open, submitting, onAccept, onClose }: Props) => {
  const { accessFilter } = useUserPreferences();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const {
    loading,
    error,
    list,
    intersectionObserverRef: lastDataElement,
  } = useCampaignSearch(debouncedSearch, {
    archived: false,
    shouldFetch: open,
    accessFilterId: accessFilter?.id,
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      campaign: null,
      priority: 1,
      setStartOrEndTime: false,
      startTime: null,
      endTime: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const campaignWatch = watch('campaign');
  const setStartOrEndTimeWatch = watch('setStartOrEndTime');
  const isLoading = loading || submitting;

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: AddCampaignGroupAssignment = {
      // Rule dictates that campaign cannot be null on submit, so telling typescript to ignore error
      campaignId: data.campaign!.id,
      priority: data.priority,
      startTime: null,
      endTime: null,
    };

    if (data.setStartOrEndTime) {
      submitData = {
        ...submitData,
        startTime: data.startTime?.toFormat('HH:mm') || null,
        endTime: data.endTime?.toFormat('HH:mm') || null,
      };
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearch('');
  };

  const noOptionsText = useMemo(() => {
    if (loading) {
      return <DotLoader align='center' />;
    }

    if (error) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load campaigns
        </Typography>
      );
    }

    return undefined;
  }, [loading, error]);

  const filteredList = useMemo(() => {
    if (!campaignWatch) {
      return list;
    }

    return list.filter((listItem) => {
      return listItem.id !== campaignWatch.id;
    });
  }, [list, campaignWatch]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Assign Campaigns'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='campaign'
              control={control}
              rules={{
                required: 'Campaign is required.',
              }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={filteredList}
                  noOptionsText={noOptionsText}
                  disabled={submitting}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastDataElement} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Campaign'
                      variant='outlined'
                      required={true}
                      error={Boolean(errors.campaign)}
                      helperText={errors.campaign?.message}
                      onBlur={onSearchBlur}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledNumberField
              name='priority'
              control={control}
              rules={{
                required: 'Priority is required.',
                min: {
                  value: 1,
                  message: 'Priority must be greater than 0.',
                },
              }}
              label='Priority'
              disabled={submitting}
              required={true}
              error={Boolean(errors.priority)}
              helperText={errors.priority?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledCheckbox
              name='setStartOrEndTime'
              label='Set Start or End Time?'
              control={control}
              disabled={submitting}
            />
          </Grid>

          {setStartOrEndTimeWatch && (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name='startTime'
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TimePicker
                      label='Start Time'
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.startTime)}
                          helperText={errors.startTime?.message}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name='endTime'
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TimePicker
                      label='End Time'
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.endTime)}
                          helperText={errors.endTime?.message}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Assign
          </LoadingButton>
        </>
      }
    />
  );
};

export default AssignCampaignModal;
