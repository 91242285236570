import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';

import { SkillType } from '../../domain';

interface CreateSkillTypeModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: SkillType) => void;
  onClose: () => void;
}

interface Form {
  skillType: string;
  description: string;
}

const CreateSkillTypeModal = ({ open, submitting, onAccept, onClose }: CreateSkillTypeModalProps) => {
  const isLoading = submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<Form>({
    defaultValues: {
      skillType: '',
      description: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept({
        skillType: data.skillType,
        description: data.description,
      });

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Skill Type'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='skillType'
              control={control}
              rules={{ required: 'Skill type is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  label='Skill Type'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.skillType)}
                  helperText={errors.skillType?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{ required: 'Description is required.' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateSkillTypeModal;
