import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { ValidatorType } from '~hooks/useForm';
import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      is_transfer: {
        value: false,
        validators: [],
      },
      phone_number: {
        value: '',
        validators: [
          {
            type: ValidatorType.Regex,
            pattern: /^[0]\d{9}$/,
            message: 'Must be 10 digits numbers starting with 0 with no spaces. eg 0498765437',
          },
        ],
      },
      member_id: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'Member ID is required',
          },
        ],
      },
      insurance_offered: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'Insurance offered is required',
          },
        ],
      },
      insurance_outcome: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'Outcome is required',
          },
        ],
      },
    });

    return () => {
      form.removeSchemaProperties([
        'is_transfer',
        'phone_number',
        'member_id',
        'insurance_offered',
        'insurance_outcome',
      ]);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        Did you transfer your member to another department?<br></br>
        <FormControlLabel
          control={
            <Checkbox
              id='is_transfer'
              name='is_transfer'
              disabled={formSubmitting}
              checked={form.fields?.is_transfer?.value || false}
              onChange={form.handleInputChange}
            />
          }
          label='Yes I transferred the member to another department'
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='phone_number'
          name='phone_number'
          label='Phone Number'
          disabled={formSubmitting}
          value={form.fields?.phone_number?.value || ''}
          error={Boolean(form.errors.phone_number)}
          helperText={form.errors.phone_number && form.errors.phone_number[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='member_id'
          name='member_id'
          label='Member ID'
          disabled={formSubmitting}
          value={form.fields?.member_id?.value || ''}
          error={Boolean(form.errors.member_id)}
          helperText={form.errors.member_id && form.errors.member_id[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          select
          variant='outlined'
          id='insurance_offered'
          name='insurance_offered'
          label='Life Insurance Introduced to Member'
          disabled={formSubmitting}
          required={true}
          helperText={form.errors.insurance_offered && form.errors.insurance_offered[0]!}
          error={Boolean(form.errors.insurance_offered)}
          value={form.fields?.insurance_offered?.value || ''}
          onChange={form.handleInputChange}>
          <MenuItem value='no'>No</MenuItem>
          <MenuItem value='yes'>Yes</MenuItem>
          <MenuItem value='auth_not_provided'>Authorisation Not Provided</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          select
          variant='outlined'
          id='insurance_outcome'
          name='insurance_outcome'
          label='Outcome'
          disabled={formSubmitting}
          required={true}
          helperText={form.errors.insurance_outcome && form.errors.insurance_outcome[0]!}
          error={Boolean(form.errors.insurance_outcome)}
          value={form.fields?.insurance_outcome?.value || ''}
          onChange={form.handleInputChange}>
          <MenuItem value='not_applicable'>Not Applicable</MenuItem>
          <MenuItem value='sale'>Sale</MenuItem>
          <MenuItem value='declined'>Declined</MenuItem>
          <MenuItem value='information_sent'>Information Sent</MenuItem>
          <MenuItem value='callback'>Callback</MenuItem>
        </TextField>
      </Grid>
    </>
  );
};

export default OutcomesForm;
