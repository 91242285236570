import sevenEleven from './7eleven';
import alinta from './alinta';
import amelco from './amelco';
import amplifon from './amplifon';
import auspost from './auspost';
import belong from './belong';
import bnz from './bnz';
import bonza from './bonza';
import compareTheMarket from './compareTheMarket';
import conduet from './conduet';
import { Organisation } from './domain';
import doordash from './doordash';
import draftKings from './draftkings';
import g8 from './g8';
import globalExpress from './globalExpress';
import hcf from './hcf';
import hood from './hood';
import mycar from './mycar';
import nine from './nine';
import pioneer from './pioneer';
import saracen from './saracen';
import soaringEagle from './soaringeagle';
import telstra from './telstra';
import telstraBusiness from './telstraBusiness';
import tsa from './tsa';
import vga from './vga';
import wilson from './wilson';

const exports: { [key: string]: Organisation } = {
  amelco,
  amplifon,
  auspost,
  belong,
  bnz,
  hood,
  telstra,
  tsa,
  'compare_the_market': compareTheMarket,
  g8,
  'global_express': globalExpress,
  doordash,
  conduet,
  pioneer,
  saracen,
  '7eleven': sevenEleven,
  wilson,
  'soaring_eagle': soaringEagle,
  'draft_kings': draftKings,
  nine,
  hcf,
  alinta,
  vga,
  bonza,
  'telstra_business': telstraBusiness,
  mycar,
};

export default exports;
