import axios from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { GetIsPendingRoutingProfileChangeDecoder } from './domain';

export const getIsPendingRoutingProfileChange = async (username: string): Promise<boolean> => {
  const path = `/api/agents/${username}/is-pending-routing-profile-change`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetIsPendingRoutingProfileChangeDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
