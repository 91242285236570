import React, { ReactNode, useEffect, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';

interface PopUpEnforcerProps {
  children: ReactNode;
}

const PopUpEnforcer = ({ children }: PopUpEnforcerProps) => {
  const [initialRun, setInitialRun] = useState<boolean>(true);
  const [state, setState] = useState({
    checking: true,
    blocked: false,
  });

  useEffect(() => {
    // Gross but fixes the issue of the wrong tab being focused on popup closed as we only get ONE popup opening
    // rather than two at once.
    if (initialRun) {
      setInitialRun(false);
      return;
    }

    const popup = window.open('/');

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      console.log('pop ups are blocked!');
      setState({ checking: false, blocked: true });
      return;
    }

    popup.close();
    setState({ checking: false, blocked: false });
  }, [initialRun]);

  const contents = !state.blocked ? (
    <>{children}</>
  ) : (
    <EmptyState
      type='puzzle'
      text='Pop-ups are blocked'
      subText='Pop-ups must be enabled in order to continue. Enable pop-ups and refresh the page to continue.'
      action={() => window.location.reload()}
      actionText='Refresh'
    />
  );

  return <AsyncLoader isLoading={state.checking}>{contents}</AsyncLoader>;
};

export default PopUpEnforcer;
