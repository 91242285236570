import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { ValidatorType } from '~hooks/useForm';
import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      brand_code: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'Dealer Code must be set.',
          },
        ],
      },
      compare_partner_p_number: {
        value: '',
        validators: [
          {
            type: ValidatorType.OptionalRequireIf,
            fieldName: 'compare_partner_reference_number',
            message: 'Compare Partner P Number is required',
          },
          {
            type: ValidatorType.Regex,
            pattern: /^P\d{6}$/,
            message: 'Compare Partner Team Member P Number must contain a capital P and six numbers.',
          },
        ],
      },
      compare_partner_reference_number: {
        value: '',
        validators: [
          {
            type: ValidatorType.OptionalRequireIf,
            fieldName: 'compare_partner_p_number',
            message: 'Compare Partner Reference Number is required',
          },
          {
            type: ValidatorType.Regex,
            pattern: /^\d{7}$/,
            message: 'Compare Partner Reference Number must be numeric and seven characters long.',
          },
        ],
      },
      customer_full_name: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'Customer Full Name is required.',
          },
        ],
      },
    });

    return () => {
      form.removeSchemaProperties([
        'brand_code',
        'compare_partner_p_number',
        'compare_partner_reference_number',
        'customer_full_name',
      ]);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='brand_code'
          name='brand_code'
          label='Dealer Code'
          disabled={formSubmitting}
          required={true}
          value={form.fields?.brand_code?.value || ''}
          error={Boolean(form.errors.brand_code)}
          helperText={form.errors.brand_code && form.errors.brand_code[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='compare_partner_p_number'
          name='compare_partner_p_number'
          label='Compare Partner P Number'
          disabled={formSubmitting}
          required={true}
          value={form.fields?.compare_partner_p_number?.value || ''}
          error={Boolean(form.errors.compare_partner_p_number)}
          helperText={form.errors.compare_partner_p_number && form.errors.compare_partner_p_number[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='compare_partner_reference_number'
          name='compare_partner_reference_number'
          label='Compare Partner Reference Number'
          disabled={formSubmitting}
          required={true}
          value={form.fields?.compare_partner_reference_number?.value || ''}
          error={Boolean(form.errors.compare_partner_reference_number)}
          helperText={form.errors.compare_partner_reference_number && form.errors.compare_partner_reference_number[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='customer_full_name'
          name='customer_full_name'
          label='Customer Full Name (first and last name)'
          disabled={formSubmitting}
          required={true}
          value={form.fields?.customer_full_name?.value || ''}
          error={Boolean(form.errors.customer_full_name)}
          helperText={form.errors.customer_full_name && form.errors.customer_full_name[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;
