import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AgentsResponse, BatchAsyncSettingsUpdate, BatchGroupChangeUpdate, GetAgentsResponseDecoder } from './domain';

export const getAgentsByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<AgentsResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getAgents = async (
  search: string,
  diallerGroupId: number | undefined,
  allocated: boolean | undefined,
  disabled: boolean | undefined,
  accessFilterId: number | undefined,
  cancelToken?: CancelTokenSource,
): Promise<AgentsResponse | undefined> => {
  const path = `/api/agents/`;
  const params = {
    search: search.toLowerCase() || undefined,
    dialler_group_id: diallerGroupId || undefined,
    allocated: allocated !== undefined ? allocated.toString() : undefined,
    disabled: disabled !== undefined ? disabled.toString() : undefined,
    access_filter_ids: accessFilterId ? [accessFilterId] : undefined,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      paramsSerializer: {
        indexes: null,
      },
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const batchAsyncSettingsUpdate = async (
  accessFilterId: number | undefined,
  data: BatchAsyncSettingsUpdate,
): Promise<void> => {
  const path = '/api/agent-settings/bulk/async-settings';
  let body: any = {
    queues: data.queues || undefined,
    max_concurrency: data.maxConcurrency || undefined,
    desired_concurrency: data.desiredConcurrency || undefined,
    select_all: data.selectAll,
    agents_to_include: data.agentsToInclude,
    agents_to_exclude: data.agentsToExclude,
    search: data.search || undefined,
    dialler_group_id: data.diallerGroupId ?? undefined,
    access_filter_ids: accessFilterId ? [accessFilterId] : undefined,
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const batchGroupChangeUpdate = async (
  accessFilterId: number | undefined,
  data: BatchGroupChangeUpdate,
): Promise<void> => {
  const path = '/api/agent-settings/bulk/group';
  let body: any = {
    change_group: data.changeGroup
      ? { group_id: data.changeGroup.groupId, routing_profile_id: data.changeGroup.routingProfileId }
      : null,
    select_all: data.selectAll,
    agents_to_include: data.agentsToInclude,
    agents_to_exclude: data.agentsToExclude,
    search: data.search || undefined,
    dialler_group_id: data.diallerGroupId ?? undefined,
    access_filter_ids: accessFilterId ? [accessFilterId] : undefined,
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
