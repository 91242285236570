import * as j from '@mojotech/json-type-validation';

export interface Stats {
  eventStartTime: string;
  duration: number;
  status: string;
}

export interface AgentStatsDay {
  startTime: string;
  endTime: string;
  stats: Stats[];
}

export const GetAgentStatsDayDecoder: j.Decoder<AgentStatsDay> = j
  .object({
    start_time: j.string(),
    end_time: j.string(),
    stats: j.array(
      j.object({
        event_start: j.string(),
        duration: j.number(),
        status: j.string(),
      }),
    ),
  })
  .map((item) => ({
    startTime: item.start_time,
    endTime: item.end_time,
    stats: item.stats.map((item) => ({
      eventStartTime: item.event_start,
      duration: item.duration,
      status: item.status,
    })),
  }));
