import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import OberonDialog from '~components/OberonDialog';
import useDebounce from '~hooks/useDebounce';
import { AsyncQueue, CreateEditAsyncQueue } from '~pages/AsyncManagement/domain';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { ChannelType } from '~services/AsyncManager/domain';

interface CreateEditQueueModalProps {
  open: boolean;
  submitting: boolean;
  queue?: AsyncQueue;
  onAccept: (data: CreateEditAsyncQueue) => Promise<void>;
  onClose: () => void;
}

interface ListItem {
  id: number;
  name: string;
}

interface Form {
  queue: string;
  title: string;
  channelType: string;
  description: string;
  isPublic: boolean;
  systemEndpoint: string;
  accessFilters: ListItem[] | undefined;
}

const channelTypeList = [
  // TODO: uncomment as each channel is added to async
  // TODO: For each channel that supports system endpoint, we should have some really loose validation
  //       i.e. if channel type email, have some email validation etc
  // {
  //   label: 'Android RCS',
  //   value: ChannelType.AndroidRCS,
  // },
  // {
  //   label: 'Apple Business Chat',
  //   value: ChannelType.AppleBusinessChat,
  // },
  // {
  //   label: 'Email',
  //   value: ChannelType.Email,
  // },
  // {
  //   label: 'SMS',
  //   value: ChannelType.SMS,
  // },
  {
    label: 'Webchat',
    value: ChannelType.WebChat,
  },
];

const CreateEditQueueModal = ({ open, submitting, queue, onAccept, onClose }: CreateEditQueueModalProps) => {
  const { accessFilter } = useUserPreferences();
  const isEdit = Boolean(queue);
  const [searchAccessFilter, setSearchAccessFilter] = useState<string>('');
  const debouncedSearchAccessFilter = useDebounce(searchAccessFilter, 500);
  const {
    loading: accessFilterFetching,
    error: accessFilterFetchError,
    list: accessFilters,
    intersectionObserverRef: lastAccessFilterDataElement,
  } = useAccessFilterSearch(debouncedSearchAccessFilter, { archived: false, shouldFetch: open && !isEdit });
  const isLoading = submitting || accessFilterFetching;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      queue: '',
      title: '',
      channelType: '',
      description: '',
      isPublic: false,
      systemEndpoint: '',
      accessFilters: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const accessFiltersWatch = watch('accessFilters');
  const channelTypeWatch = watch('channelType');
  const systemEndpointVisible =
    (queue && queue.channelType !== ChannelType.WebChat) ||
    (channelTypeWatch && channelTypeWatch !== ChannelType.WebChat);

  const channelTypeListDisplay = channelTypeList.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  // Handles reset and editable display
  useEffect(() => {
    if (open) {
      if (queue !== undefined) {
        setValue('title', queue.title);
        setValue('description', queue.description);
        setValue('isPublic', queue.isPublic);

        if (queue.channelType !== ChannelType.WebChat) {
          setValue('systemEndpoint', queue.systemEndpoint);
        }
      } else {
        if (accessFilter) {
          setValue('accessFilters', [{ id: accessFilter.id, name: accessFilter.name }]);
        }
      }
    }

    // Reset form on close
    return () => {
      reset();
    };
  }, [open, queue]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: CreateEditAsyncQueue = {
      queue: data.queue || undefined,
      title: data.title,
      description: data.description,
      channelType: data.channelType || undefined,
      isPublic: data.isPublic,
      systemEndpoint: data.systemEndpoint || undefined,
      accessFilterIds: undefined,
    };

    if (data.accessFilters) {
      submitData = {
        ...submitData,
        accessFilterIds: data.accessFilters.length > 0 ? data.accessFilters.map((item) => item.id) : undefined,
      };
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchAccessFilter(e.target.value);
  };

  const onSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchAccessFilter('');
  };

  const accessFiltersNoOptionsText = useMemo(() => {
    if (accessFilterFetching) {
      return <DotLoader align='center' />;
    }

    if (accessFilterFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load access filters
        </Typography>
      );
    }

    return undefined;
  }, [accessFilterFetching, accessFilterFetchError]);

  const accessFiltersFilteredList: ListItem[] = useMemo(() => {
    let list: ListItem[] = accessFilters.map((item) => ({ id: item.id, name: item.name }));
    if (accessFilter) {
      list = [...list, { id: accessFilter.id, name: accessFilter.name }];
    }
    if (!accessFiltersWatch) {
      return list;
    }

    return list.filter((listItem) => {
      return !accessFiltersWatch.find((watchItem) => listItem.id === watchItem.id);
    });
  }, [accessFilters, accessFiltersWatch]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Queue`}
      content={
        <Grid container spacing={2}>
          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='queue'
                control={control}
                rules={{
                  required: 'Queue is required.',
                  pattern: {
                    value: /^[0-9a-z-]+$/,
                    message: 'Queue can only be lowercase, alphanumeric and contain hyphens.',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Queue'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.queue)}
                    helperText={errors.queue?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name='title'
              control={control}
              rules={{ required: 'Title is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Title'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.title)}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='channelType'
                rules={{ required: 'Channel type is required.' }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    variant='outlined'
                    label='Channel Type'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.channelType)}
                    helperText={errors.channelType?.message}>
                    {channelTypeListDisplay}
                  </TextField>
                )}
              />
            </Grid>
          )}

          {systemEndpointVisible && (
            <Grid item xs={12}>
              <Controller
                name='systemEndpoint'
                control={control}
                rules={{ required: 'System endpoint is required.' }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='System Endpoint'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(errors.systemEndpoint)}
                    helperText={errors.systemEndpoint?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <ControlledCheckbox name='isPublic' control={control} label='Visible to customer?' disabled={isLoading} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{ required: 'Description is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {!isEdit && (
            <Grid item xs={12}>
              <Controller
                name='accessFilters'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    multiple
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    options={accessFiltersFilteredList}
                    noOptionsText={accessFiltersNoOptionsText}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={isLoading}
                    getOptionLabel={(option) => option.name || ''}
                    renderOption={(props, option) => (
                      <li {...props} ref={lastAccessFilterDataElement} key={option.id}>
                        <Typography variant='body1' color='textPrimary' component='p'>
                          {option.name}
                        </Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Access Filters'
                        variant='outlined'
                        onBlur={onSearchBlur}
                        onChange={onSearchChange}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditQueueModal;
