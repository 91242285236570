import CompressIcon from '@mui/icons-material/Compress';
import ExpandIcon from '@mui/icons-material/Expand';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import blueGrey from '@mui/material/colors/blueGrey';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { CopyToClipboard } from '~components/CopyToClipboard';
import { usePrevious } from '~hooks/usePrevious';

interface ResizableHeightProps {
  defaultHeight: number;
}

type ResizableHeightPropsWithChildren = PropsWithChildren<ResizableHeightProps>;

interface ResizableHeightState {
  height: number;
  // Mirrors the height property and is used to handle horizontal scrollbar scenarios.
  maxHeight: number;
  dragging: boolean;
  // Position relative to the cursor
  relCursorHeight: number | null;
  // Dictates whether the expand or compress button is visible
  isExpandable: boolean;
}

const isMouseEvent = function (e: Event): e is MouseEvent {
  return (e as MouseEvent).initMouseEvent !== undefined;
};

const ResizableHeight = ({ defaultHeight, children }: ResizableHeightPropsWithChildren) => {
  const [state, setState] = useState<ResizableHeightState>({
    height: defaultHeight,
    maxHeight: defaultHeight,
    dragging: false,
    relCursorHeight: null,
    isExpandable: true,
  });
  const prevDraggingSate = usePrevious<boolean>(state.dragging);
  const elemRef = useRef<HTMLDivElement | null>(null);

  const onMouseUp = (e: Event) => {
    setState((prev) => ({ ...prev, dragging: false }));

    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseMove = (e: Event) => {
    if (!state.dragging) return;
    if (elemRef.current === null) return;
    if (state.relCursorHeight === null) return;
    if (!isMouseEvent(e)) return;

    const hasVerticalScrollbar = elemRef.current.children[0].scrollHeight >= elemRef.current.clientHeight;
    const newHeight = state.height + (e.pageY - state.relCursorHeight);

    if (
      (hasVerticalScrollbar && newHeight > defaultHeight) ||
      (!hasVerticalScrollbar && e.pageY < state.relCursorHeight)
    ) {
      setState((prev) => ({ ...prev, height: newHeight, maxHeight: newHeight, isExpandable: true }));
    } else if (!hasVerticalScrollbar) {
      setState((prev) => ({ ...prev, isExpandable: false }));
    }

    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (elemRef.current === null) return;

    const newHeight = elemRef.current.offsetHeight;
    setState((prev) => ({ ...prev, dragging: true, height: newHeight, relCursorHeight: e.pageY }));

    e.stopPropagation();
    e.preventDefault();
  };

  const onExpand = () => {
    if (elemRef.current === null) return;

    // child height + maximum scrollbar height
    // Note: browsers have different heights for scrollbars min 12px and max 17px
    const newHeight = elemRef.current.children[0].clientHeight + 17;
    setState((prev) => ({ ...prev, height: newHeight, maxHeight: newHeight, isExpandable: false }));
  };

  const onCompress = () => {
    setState((prev) => ({ ...prev, height: defaultHeight, maxHeight: defaultHeight, isExpandable: true }));
  };

  useEffect(() => {
    if (state.dragging && !prevDraggingSate) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      return () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [state.dragging]);

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip disableFocusListener disableTouchListener title={state.isExpandable ? 'Expand' : 'Compress'}>
        <Button
          disableElevation
          sx={{
            minWidth: 'initial',
            padding: 1,
            position: 'absolute',
            zIndex: 5,
            left: 8,
            top: -15,
            borderRadius: 5,
          }}
          variant='contained'
          size='small'
          onClick={state.isExpandable ? onExpand : onCompress}>
          {state.isExpandable && <ExpandIcon sx={{ fontSize: 14 }} />}
          {!state.isExpandable && <CompressIcon sx={{ fontSize: 14 }} />}
        </Button>
      </Tooltip>

      {/* Resizable Container and wrapper */}
      <div style={{ overflow: 'hidden', borderRadius: 8 }}>
        <div ref={elemRef} style={{ height: state.height, overflow: 'auto' }}>
          {children}
        </div>
      </div>

      {/* Handle */}
      <div
        onMouseDown={onMouseDown}
        style={{
          position: 'absolute',
          userSelect: 'none',
          bottom: -7,
          left: '50%',
          transform: 'translateX(-50%)',
          cursor: 'row-resize',
          background: '#ffffff',
          width: 40,
          height: 14,
          border: '1px solid #e6e6e6',
          borderRadius: 50,
          overflow: 'hidden',
        }}>
        <div style={{ marginTop: -4, textAlign: 'center' }}>
          <MoreHorizIcon fontSize='small' />
        </div>
      </div>
    </div>
  );
};

export interface ColumnData {
  dataKey: string;
  label: string;
  /** Indicates if we want a tooltip for the data column */
  dataTooltip?: boolean;
  /** Indicates if we want a tooltips for the title column */
  titleTooltip?: string;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  wrapText?: boolean;
  width?: number;
  sortDirection?: 'asc' | 'desc';
  sortActive?: boolean;
  onColumnSort?: () => void;
  copyToClipboardIcon?: boolean;
}

export type RowData = { [key: string]: any };

interface TableProps {
  rows: RowData[];
  columns: ColumnData[];
  size?: 'small' | 'medium';
  padding?: 'none' | 'normal' | 'checkbox';
  /** height can be defined in either of the following ways 400px, 400, calc(100vh - 400px), 100%, etc. */
  height?: number;
  onRowClick?: (data: RowData) => void;
  noResultsMessage?: string;
}

const CustomizedTableHeaderCell = styled(TableCell)(({ theme }) => ({
  'backgroundColor': blueGrey[800],
  'color': '#ffffff',
  'cursor': 'initial',
  // For some reason in production builds material UI is not injecting their
  // specific header based off of the stickyHeader flag, this is a hack to apply the exact same styles
  // they would if it was working (minus the background color change)
  'top': 0,
  'left': 0,
  // zIndex: 2, - isn't needed at all, don't know why material UI would even set a zIndex for something like this
  'position': 'sticky',
  '&.Mui-active': {
    color: '#ffffff',
  },
}));

type CustomizedTableBodyCellProps = MUIStyledCommonProps<Theme> &
  TableCellProps & {
    isClickable: boolean;
    width: number | undefined;
    wrapText: boolean;
  };

const CustomizedTableBodyCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isClickable' && prop !== 'width' && prop !== 'wrapText',
})<CustomizedTableBodyCellProps>(({ isClickable, width, wrapText }) => ({
  cursor: isClickable ? 'pointer' : 'initial',
  width: width ?? 'inherit',
  minWidth: width ?? 'inherit',
  maxWidth: width ?? 'inherit',
  textOverflow: !wrapText ? 'ellipsis' : 'clip',
  whiteSpace: !wrapText ? 'nowrap' : 'normal',
  overflow: !wrapText ? 'hidden' : 'visible',
}));

export const BasicTable = ({
  rows,
  columns,
  size = 'medium',
  padding = 'normal',
  height = 400,
  onRowClick,
  noResultsMessage,
}: TableProps) => {
  const onRowClickFn = useCallback(
    (rowData: RowData) => () => {
      if (onRowClick === undefined) {
        throw new Error('onRowClick not defined');
      }

      onRowClick(rowData);
    },
    [onRowClick],
  );

  return (
    <Paper sx={{ borderRadius: 2 }}>
      <ResizableHeight defaultHeight={height}>
        <Table stickyHeader size={size} padding={padding}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                let content =
                  column.titleTooltip !== undefined ? (
                    <Tooltip disableFocusListener disableTouchListener title={column.titleTooltip}>
                      <span>{column.label}</span>
                    </Tooltip>
                  ) : (
                    <>{column.label}</>
                  );

                content =
                  column.onColumnSort != null ? (
                    <TableSortLabel
                      sx={{
                        'color': '#ffffff',
                        '&.Mui-active': { color: '#ffffff' },
                        '&.Mui-active .MuiTableSortLabel-icon': { color: '#ffffff' },
                        '&:hover': {
                          color: '#ffffff',
                        },
                      }}
                      active={column.sortActive}
                      direction={column.sortDirection}
                      onClick={column.onColumnSort}>
                      {content}
                    </TableSortLabel>
                  ) : (
                    <>{content}</>
                  );

                return (
                  <CustomizedTableHeaderCell key={index} variant='head' align={column.align || 'inherit'}>
                    {content}
                  </CustomizedTableHeaderCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <CustomizedTableBodyCell
                  variant='body'
                  isClickable={false}
                  wrapText={false}
                  width={undefined}
                  colSpan={columns.length}>
                  {noResultsMessage || 'No Records to show.'}
                </CustomizedTableBodyCell>
              </TableRow>
            )}

            {rows.length > 0 &&
              rows.map((row, rowIndex) => {
                const cells = columns.map((column, columnIndex) => {
                  const value = row[column.dataKey] ?? '';
                  // If the value is empty and/ or does not exist we do not want to show the copy to
                  // clipboard functionality. This is to cover scenarios where a value is optional within a table
                  // column.
                  const copyToClipboardIcon =
                    column.copyToClipboardIcon && value ? <CopyToClipboard value={value} /> : undefined;
                  const content = column.dataTooltip ? (
                    <>
                      <Tooltip disableFocusListener disableTouchListener title={value}>
                        <span>{value}</span>
                      </Tooltip>
                      {copyToClipboardIcon}
                    </>
                  ) : (
                    <>
                      <>{value}</>
                      {copyToClipboardIcon}
                    </>
                  );

                  return (
                    <CustomizedTableBodyCell
                      key={columnIndex}
                      isClickable={Boolean(onRowClick)}
                      variant='body'
                      width={column.width}
                      wrapText={Boolean(column.wrapText) || Boolean(copyToClipboardIcon)}
                      align={column.align || 'inherit'}>
                      {content}
                    </CustomizedTableBodyCell>
                  );
                });

                return (
                  <TableRow key={rowIndex} hover onClick={Boolean(onRowClick) ? onRowClickFn(row) : undefined}>
                    {cells}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </ResizableHeight>
    </Paper>
  );
};

// Prevents additional re-renders
// export const BasicTable = (props: TableProps) => {
//   const table = React.useMemo(() => (props.rows.length > 0 ? <TableBase {...props} /> : null), [props.rows]);
//
//   return table;
// };
