import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import red from '@mui/material/colors/red';
import { createTheme } from '@mui/material/styles';

export interface MuiColorRange {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
}

// Base Colors/ theme used for component override purposes
const baseTheme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: red[900],
    },
    background: {
      default: '#fafafa',
    },
  },
});

// Component overrides
const theme = createTheme({
  ...baseTheme,
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: blueGrey[900],
        },
        arrow: {
          color: blueGrey[900],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: baseTheme.spacing(1),
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: 19,
          lineHeight: 1.2,
        },
        subheader: {
          fontSize: 14,
          lineHeight: 1.43,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
  },
});

export default theme;
