import axios from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AgentStatsDay, GetAgentStatsDayDecoder } from './domain';

/**
 * Function: getAgentStatsForDay
 * @param day {string} should be in the following format YYYY-MM-DD
 * @param username {string} selected agents username
 * */
export const getAgentStatsForDay = async (day: string, username: string): Promise<AgentStatsDay | undefined> => {
  const path = `/api/agents/${username}/stats/${day}`;
  let resp;
  try {
    resp = await axios.request({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        return undefined;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetAgentStatsDayDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};
