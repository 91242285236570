import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { MUIStyledCommonProps, Theme } from '@mui/system';
import React, { ClassAttributes, HTMLAttributes, ReactNode, isValidElement } from 'react';

import { CopyToClipboard } from '~components/CopyToClipboard';

interface DataItemProps {
  title: string;
  value: string | ReactNode;
  tooltip?: string;
  stacked?: boolean;
  disableMargin?: boolean;
  /** If the value of DataItem is not a string this property is ignored if set */
  copyToClipboardIcon?: boolean;
}

type DataItemContainerProps = MUIStyledCommonProps<Theme> &
  ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    disableMargin?: boolean;
    stacked?: boolean;
  };

const DataItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disableMargin' && prop !== 'stacked',
})<DataItemContainerProps>(({ disableMargin, stacked }) => ({
  marginTop: disableMargin ? 0 : 8,
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: stacked ? 'column' : 'row',
  justifyContent: stacked ? 'center' : 'flex-start',
}));

export const DataItem = ({ title, value, tooltip, stacked, disableMargin, copyToClipboardIcon }: DataItemProps) => {
  const titleBlock =
    tooltip !== undefined ? (
      <Typography
        marginRight={2}
        fontSize={16}
        fontWeight='bold'
        sx={{ flex: stacked ? '1 1 100%' : '1 1 50%' }}
        align='left'
        color='textPrimary'>
        <Tooltip title={tooltip} arrow placement='bottom-start'>
          <span>{title}</span>
        </Tooltip>
      </Typography>
    ) : (
      <Typography
        marginRight={2}
        fontSize={16}
        fontWeight='bold'
        sx={{ flex: stacked ? '1 1 100%' : '1 1 50%' }}
        align='left'
        color='textPrimary'>
        {title}
      </Typography>
    );

  let valueBlock: string | ReactNode;

  if (isValidElement(value)) {
    valueBlock = (
      <Box
        // word break is used in case we have URLs passed in that
        // need to be broken to conform to parent container width
        sx={{ 'wordBreak': 'break-all', 'flex': stacked ? '1 1 100%' : '1 1 50%', '& a': { fontSize: 14 } }}>
        {value}
      </Box>
    );
  } else
    valueBlock = (
      <Typography
        fontSize={14}
        sx={{ wordBreak: 'break-word', flex: stacked ? '1 1 100%' : '1 1 50%', fontSize: 14 }}
        align='left'
        color='textSecondary'>
        {value ?? '-'}
        {copyToClipboardIcon && typeof value === 'string' ? <CopyToClipboard value={value} /> : undefined}
      </Typography>
    );

  return (
    <DataItemContainer disableMargin={disableMargin} stacked={stacked}>
      {titleBlock}
      {valueBlock}
    </DataItemContainer>
  );
};
