import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';

import { PredictiveAgentStatus } from '../../domain';

interface CreateEditStatusModalProps {
  open: boolean;
  submitting: boolean;
  predictiveStatus?: PredictiveAgentStatus;
  onAccept: (data: PredictiveAgentStatus) => void;
  onClose: () => void;
}

interface Form {
  statusName: string;
  description: string;
}

const CreateEditStatusModal = ({
  open,
  submitting,
  predictiveStatus,
  onAccept,
  onClose,
}: CreateEditStatusModalProps) => {
  const isLoading = submitting;
  const isEdit = Boolean(predictiveStatus);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      statusName: '',
      description: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  // Handles all async based actions
  useEffect(() => {
    if (open && predictiveStatus !== undefined) {
      setValue('statusName', predictiveStatus.agentStatus);
      setValue('description', predictiveStatus.description);
    }
    // Reset form on close
    return function cleanupCreateEditStatusModal() {
      reset();
    };
  }, [open, predictiveStatus]);

  const onSubmit = handleSubmit(async (data: Form) => {
    try {
      await onAccept({
        agentStatus: data.statusName,
        description: data.description,
        position: 1,
      });

      reset();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Agent Status`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='statusName'
              control={control}
              rules={{
                required: 'Status name is required.',
                pattern: {
                  value: /^[0-9a-zA-Z_\s\']+$/,
                  message: 'Status name can only be lowercase, alphanumeric and contain underscores.',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Status name'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.statusName)}
                  helperText={errors.statusName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: 'Status description is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Status description'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateEditStatusModal;
