import React from 'react';

import Audio from '~components/Audio';
import SectionCard from '~components/SectionCard';
import { VoiceRecording } from '~pages/ContactLog/domain';

interface ContactRecordingProps {
  recordings: VoiceRecording[];
  canDownload: boolean;
  canView: boolean;
}

const ContactRecording = ({ recordings, canDownload, canView }: ContactRecordingProps) => {
  // We want to skip fast, do none of the below logic, and not display anything
  if (!canView) return null;

  if (!recordings || recordings.length === 0) return null;

  let displayRecordingList;
  if (recordings.length == 1) {
    displayRecordingList = <Audio id='recording' src={recordings[0].location} canDownload={canDownload} />;
  } else {
    displayRecordingList = recordings.map((item, index) => (
      <div key={index}>
        <span>{item.type} </span>
        <Audio id={`recording${index}`} src={item.location} canDownload={canDownload} />
      </div>
    ));
  }

  return <SectionCard title='Call Recordings'>{displayRecordingList}</SectionCard>;
};

export default ContactRecording;
