import * as j from '@mojotech/json-type-validation';

export type GlobalSettings = {
  isDiallingPaused: boolean;
};

export const GetGlobalSettingsDecoder: j.Decoder<GlobalSettings> = j
  .object({
    is_dialling_paused: j.boolean(),
  })
  .map((i: any) => ({
    isDiallingPaused: i.is_dialling_paused,
  }));
