import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ListItemButton } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ChangeEvent, useCallback, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import useAgentList from '~hooks/useAgentList';
import useDebounce from '~hooks/useDebounce';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

type Props = {
  selectedAgents?: string[];
  assignedToGroup: boolean;
  updateAgentList: (value: string[]) => void;
  updateAssignedToGroup: (value: boolean) => void;
};

export const AgentFilter = ({
  selectedAgents = [],
  assignedToGroup,
  updateAgentList,
  updateAssignedToGroup,
}: Props) => {
  const { accessFilter } = useUserPreferences();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const {
    loading,
    agents: list,
    error,
    hasMore,
    intersectionObserverRef: lastDataElement,
  } = useAgentList(debouncedSearch, {
    shouldFetch: open,
    accessFilterId: accessFilter?.id,
  });

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const onSearchChange = useCallback(async (e: ChangeEvent<any>) => {
    setSearch(e.target.value);
  }, []);

  const selectionToggle = (id: string) => {
    const index = selectedAgents.indexOf(id);
    const newList = [...selectedAgents];

    if (index === -1) {
      newList.push(id);
    } else {
      newList.splice(index, 1);
    }

    updateAgentList(newList);
  };

  const onRemoveAll = () => {
    updateAgentList([]);
    updateAssignedToGroup(false);
  };

  const onAssignedToGroup = (value: boolean) => {
    updateAssignedToGroup(value);

    if (value) {
      updateAgentList([]);
    }
  };

  const displayList = list.map((item, index) => (
    <ListItem key={item.username} ref={index === list.length - 1 ? lastDataElement : null} disablePadding>
      <ListItemButton onClick={() => selectionToggle(item.username)} dense>
        <ListItemIcon>
          <Checkbox
            edge='start'
            checked={Boolean(selectedAgents.find((s) => s === item.username))}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={item.fullName} secondary={item.username} />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          disableRipple
          onClick={toggleOpen}
          size='small'
          endIcon={open ? <RemoveIcon fontSize='small' /> : <AddIcon fontSize='small' />}>
          Selected Agents {selectedAgents.length > 0 ? `(${selectedAgents.length})` : assignedToGroup ? `(1)` : null}
        </Button>

        <Button
          sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          disableRipple
          onClick={onRemoveAll}
          size='small'
          disabled={selectedAgents.length === 0 && !assignedToGroup}>
          Clear
        </Button>
      </Box>

      <Collapse in={open}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                name='assignedToGroup'
                checked={assignedToGroup}
                onChange={(e) => onAssignedToGroup(e.target.checked)}
              />
            }
            label='Assigned to group?'
          />

          {!assignedToGroup && (
            <>
              <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Search'
                  id='search'
                  name='search'
                  defaultValue={search}
                  onChange={onSearchChange}
                />
              </Box>

              <Divider variant='fullWidth' component='hr' />

              <Box sx={{ height: 400, overflow: 'auto' }}>
                <AsyncLoader isLoading={loading && list.length === 0}>
                  {displayList.length > 0 && (
                    <>
                      <List sx={{ paddingTop: 0, paddingBottom: 0 }}>{displayList}</List>
                      {loading && displayList.length > 0 && <DotLoader align='center' />}

                      {!loading && !hasMore && (
                        <Typography
                          sx={{ marginTop: 1, marginBottom: 1 }}
                          variant='body2'
                          align='center'
                          color='textSecondary'>
                          No more results to display
                        </Typography>
                      )}

                      {error && displayList.length > 0 && (
                        <Typography
                          sx={{ marginTop: 1, marginBottom: 1 }}
                          variant='body2'
                          align='center'
                          color='textSecondary'>
                          Failed to load agents
                        </Typography>
                      )}
                    </>
                  )}

                  {displayList.length === 0 && debouncedSearch !== '' && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      No agents found matching your search criteria.
                    </Typography>
                  )}

                  {displayList.length === 0 && selectedAgents.length === 0 && debouncedSearch === '' && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      No agents currently exist
                    </Typography>
                  )}

                  {displayList.length === 0 && selectedAgents.length > 0 && debouncedSearch === '' && (
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 1 }}
                      variant='body2'
                      align='center'
                      color='textSecondary'>
                      No more agents to choose from.
                    </Typography>
                  )}
                </AsyncLoader>
              </Box>
            </>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default AgentFilter;
