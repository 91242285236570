import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Link as RouterLink, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { TabPanel } from '~components/TabPanel';
import AccessFilterPreferences from '~pages/SystemManagement/AccessFilterDetails/AccessFilterPreferences';
import AccessFilterSettings from '~pages/SystemManagement/AccessFilterDetails/AccessFilterSettings';
import useAccessFilter from '~pages/SystemManagement/AccessFilterDetails/useAccessFilter';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';

// Used to manage visible tab selection
enum ViewTabsType {
  Preferences,
  Settings,
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

// default tab, fallback to this tab when no permission on specified tab
const defaultViewTab = ViewTabsType.Preferences;

const AccessFilterDetails = () => {
  const navigate = useNavigate();
  const { hasScope } = useAuth();

  // Map for restricted tabs(key: ViewTabsType, value: boolean(true means can access))
  const restrictedViewTabs = useMemo(
    () => new Map([[ViewTabsType.Settings, hasScope(AccessScope.CanViewAccessFilterSettings)]]),
    [hasScope],
  );

  // check whether we have permission on specified ViewTabsType
  const hasViewTabPermission = useCallback(
    (viewTabsType: ViewTabsType) => {
      const allowed = restrictedViewTabs.get(viewTabsType);
      return allowed === undefined || allowed;
    },
    [restrictedViewTabs],
  );

  const setPageTitleProps = useSetPageTitleProps();
  const { accessFilterId } = useParams() as { accessFilterId: string };
  const { loading, data, error, update, archive, reload } = useAccessFilter(+accessFilterId);
  const initialLoad = loading && !data;
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[defaultViewTab],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  useEffect(() => {
    // fallback to default one when no permission(can happen when show parameter in the URL is manually updated)
    if (!hasViewTabPermission(tabIndex)) {
      setSearchParams({
        show: ViewTabsType[defaultViewTab],
      });
    }
  }, []);

  useEffect(() => {
    reload();
  }, [tabIndex]);

  useEffect(() => {
    if (data) {
      // Set page title
      setPageTitleProps({ pageName: data.name });
    }
  }, [data]);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number) => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const errorAction = () => {
    navigate(generatePath(Routes.system.path));
  };

  const errorDisplay = error ? (
    <EmptyState
      type='error'
      text={error}
      subText='Click the button below to return to system configuration.'
      action={errorAction}
      actionText='System Configuration'
    />
  ) : null;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.system.path}>
          System Configuration
        </Link>
        <Typography color='textPrimary'>Access Filter Details</Typography>
      </Breadcrumbs>

      {data === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to campaign details.'
          action={errorAction}
          actionText='Campaigns Details'
        />
      )}

      {data !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {data.name}
          </Typography>

          <Tabs
            orientation='horizontal'
            variant='scrollable'
            onChange={onTabChange}
            value={tabIndex}
            indicatorColor='primary'
            aria-label='Vertical tabs example'>
            <Tab label='Preferences' {...a11yProps(ViewTabsType.Preferences)} value={ViewTabsType.Preferences} />
            {hasViewTabPermission(ViewTabsType.Settings) && (
              <Tab label='Settings' {...a11yProps(ViewTabsType.Settings)} value={ViewTabsType.Settings} />
            )}
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel value={tabIndex} index={ViewTabsType.Preferences}>
            <AccessFilterPreferences accessFilter={data} update={update} />
          </TabPanel>

          {hasViewTabPermission(ViewTabsType.Settings) && (
            <TabPanel value={tabIndex} index={ViewTabsType.Settings}>
              <AccessFilterSettings accessFilter={data} update={update} archive={archive} />
            </TabPanel>
          )}
        </>
      )}
    </AsyncLoader>
  );
};

export default AccessFilterDetails;
