import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';

import ActionDialog from '~components/ActionDialog';

type Props = {
  open: boolean;
  count: number;
  onClose: () => void;
  onAccept: () => Promise<void>;
};

export const BulkRemoveConfirmModal = ({ open, count, onClose, onAccept }: Props) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleAccept = async () => {
    setSubmitting(true);

    try {
      await onAccept();
    } catch (e) {
      // do nothing
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionDialog
      open={open}
      title='Are you sure you want to do this?'
      disableContentPadding
      loading={submitting}
      content={
        <Box sx={{ padding: '16px 24px 0 24px' }}>
          <DialogContentText sx={{ marginBottom: 2 }}>
            You are about to remove {count.toLocaleString()} selected lead{count > 1 ? 's' : ''}, once you have
            completed this action it cannot be undone.
          </DialogContentText>
        </Box>
      }
      onClose={onClose}
      onAccept={handleAccept}
      primaryActionTitle='Remove'
    />
  );
};

export default BulkRemoveConfirmModal;
