import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledTextField from '~components/Form/ControlledTextField';
import SectionCard from '~components/SectionCard';
import useSystemEndpointList, { DiallerType } from '~hooks/useSystemEndpointList';
import { CampaignPredictiveSettings } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';

interface PredictiveSettingsFormProps {
  predictiveSettings: CampaignPredictiveSettings;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit?: () => void;
  onSubmit: (data: CampaignPredictiveSettings) => Promise<void>;
}

interface Form {
  leaveMessageOnAttempt: string;
  answeringMachineMessage: string;
  allCallbacksAsPreview: boolean;
  connectSourcePhoneNumber: string;
}

const SSMLTags = '(speak|break|lang|mark|p|phoneme|prosody|s|say-as|sub|w|amazon:effect)';
const SSMLOpen = new RegExp('^<\\s*' + SSMLTags + '(\\s+[-\\w:]+="[^"]*")*\\s*>');
const SSMLSelfClose = new RegExp('^<\\s*' + SSMLTags + '(\\s+[-\\w:]+=("[^"]*")*)*\\s*/>');
const SSMLClose = new RegExp('^</\\s*' + SSMLTags + '\\s*>');

const ssmlValidate = (value: string): string | undefined => {
  var state = [];
  while (value) {
    value = value.replace(/^\s+/, '');
    var isOpen = SSMLOpen.exec(value);
    if (isOpen !== null) {
      state.push(isOpen[1]);
      value = value.slice(isOpen[0].length);
      continue;
    }

    var isSelfClose = SSMLSelfClose.exec(value);
    if (isSelfClose !== null) {
      value = value.slice(isSelfClose[0].length);
      continue;
    }

    var isClose = SSMLClose.exec(value);
    if (isClose) {
      if (state.length === 0 || state[state.length - 1] != isClose[1]) {
        return `Unmatched closing tag: ${isClose[1]} found, state [${state.join('>')}]`;
      }
      state.pop();
      value = value.slice(isClose[0].length);
      continue;
    }

    if (/^[<>]/.test(value)) {
      return `Could not read at ${value.slice(0, 10)}`;
    }
    value = value.replace(/^[^<>]+/, '');
  }
  if (state.length > 0) {
    return `Unclosed tags: [${state.join('>')}]`;
  }

  return undefined;
};

const PredictiveSettingsForm = ({
  predictiveSettings,
  isEdit,
  submitting,
  toggleEdit,
  onSubmit,
}: PredictiveSettingsFormProps) => {
  const appConfig = useAppConfiguration();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    loading: fetchingEndpoints,
    list: endpoints,
    intersectionObserverRef,
  } = useSystemEndpointList({
    shouldFetch: isEdit && appConfig.extensions.predictive !== undefined,
    diallerType: DiallerType.Connect,
  });
  const isLoading = fetchingEndpoints || submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      leaveMessageOnAttempt: '',
      answeringMachineMessage: '',
      allCallbacksAsPreview: false,
      connectSourcePhoneNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isEdit === true) {
      setValue('connectSourcePhoneNumber', predictiveSettings.connectSourcePhoneNumber || '');
      setValue('leaveMessageOnAttempt', predictiveSettings.leaveMessageOnAttempt || '');
      setValue('answeringMachineMessage', predictiveSettings.answeringMachineMessage || '');
      setValue('allCallbacksAsPreview', Boolean(predictiveSettings.allCallbacksAsPreview));
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  const onSubmitFn = handleSubmit(async (data: Form) => {
    let submitData: CampaignPredictiveSettings = {
      leaveMessageOnAttempt: data.leaveMessageOnAttempt || null,
      answeringMachineMessage: data.answeringMachineMessage || null,
      allCallbacksAsPreview: data.allCallbacksAsPreview,
      connectSourcePhoneNumber: data.connectSourcePhoneNumber || undefined,
    };

    try {
      await onSubmit(submitData);
    } catch (e) {
      // Just return on error as we only want to reset the form on a successful submission
      return;
    }
  });

  return (
    <SectionCard title='Predictive Settings' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem
            stacked={isExtraSmall}
            title='Leave message on answering machine - Which attempt'
            tooltip='On which attempts should we leave a message when finding an answering machine?'
            value={predictiveSettings.leaveMessageOnAttempt}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Leave message on answering machine - Message'
            tooltip='SSML of the message to be left on an answering machine.'
            value={predictiveSettings.answeringMachineMessage}
          />
          <DataItem
            stacked={isExtraSmall}
            title='All callbacks as preview'
            tooltip='Should callbacks assigned to the group be dialled as preview instead of predictive?'
            value={predictiveSettings.allCallbacksAsPreview ? 'Yes' : 'No'}
          />
          <DataItem
            stacked={isExtraSmall}
            title='Outbound Connect CLI'
            tooltip='The outbound CLI used for the campaign on the HVOC dialler.'
            value={predictiveSettings.connectSourcePhoneNumber || 'Default'}
          />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmitFn} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name='leaveMessageOnAttempt'
                control={control}
                rules={{ pattern: /^((\d+|\d+-\d+)(,(\d+|\d+-\d+))*)?$/ }}
                rows={1}
                label='Leave message on answering machine - Which attempt'
                disabled={isLoading}
                error={Boolean(errors.leaveMessageOnAttempt)}
                helperText={errors.leaveMessageOnAttempt?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                name='answeringMachineMessage'
                control={control}
                rules={{ validate: ssmlValidate }}
                rows={3}
                label='Leave message on answering machine - Message'
                disabled={isLoading}
                error={Boolean(errors.answeringMachineMessage)}
                helperText={errors.answeringMachineMessage?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledCheckbox
                name='allCallbacksAsPreview'
                label='Place group-assigned callbacks as preview calls?'
                control={control}
                disabled={isLoading}
              />
            </Grid>

            {appConfig.extensions.predictive !== undefined && (
              <Grid item xs={12}>
                <Controller
                  name='connectSourcePhoneNumber'
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      onChange={(e, data) => {
                        field.onChange(data);
                      }}
                      options={endpoints}
                      disabled={isLoading}
                      disableClearable
                      getOptionLabel={(option) => option || ''}
                      renderOption={(props, option) => (
                        <li {...props} ref={intersectionObserverRef} key={option}>
                          <Typography variant='body1' color='textPrimary' component='p'>
                            {option}
                          </Typography>
                        </li>
                      )}
                      renderInput={({ inputProps, ...rest }) => (
                        <TextField
                          {...rest}
                          label='Outbound Connect CLI'
                          variant='outlined'
                          error={Boolean(errors.connectSourcePhoneNumber)}
                          helperText={errors.connectSourcePhoneNumber?.message}
                          inputProps={{ ...inputProps, readOnly: true }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={isLoading}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default PredictiveSettingsForm;
