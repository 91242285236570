import React, { useState } from 'react';

import AgentFilterPreferences from '~pages/SystemManagement/AccessFilterDetails/AccessFilterPreferences/AgentFilterPreferences';
import AsyncQueueFilterPreferences from '~pages/SystemManagement/AccessFilterDetails/AccessFilterPreferences/AsyncQueueFilterPreferences';
import CampaignFilterPreferences from '~pages/SystemManagement/AccessFilterDetails/AccessFilterPreferences/CampaignFilterPreferences';
import DiallerGroupFilterPreferences from '~pages/SystemManagement/AccessFilterDetails/AccessFilterPreferences/DiallerGroupFilterPreferences';
import { AccessFilter, UpdateAccessFilter } from '~pages/SystemManagement/domain';
import { useNotification } from '~providers/NotificationProvider';

interface AccessFilterPreferencesProps {
  accessFilter: AccessFilter;
  update: (data: Partial<UpdateAccessFilter>) => void;
}

const enum EditType {
  Agents = 'agents',
  Groups = 'groups',
  Campaigns = 'campaigns',
  AsyncQueues = 'asyncQueues',
}

const AccessFilterPreferences = ({ accessFilter, update }: AccessFilterPreferencesProps) => {
  const { pushNotification } = useNotification();
  const [edit, setEdit] = useState<EditType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  const onSubmit = (formName: string, toggleValue: EditType) => async (data: Partial<UpdateAccessFilter>) => {
    setSubmitting(true);

    try {
      await update(data);
    } catch (e) {
      pushNotification('error', `Failed to update ${formName}.`);
      return;
    } finally {
      setSubmitting(false);
    }

    toggleEdit(toggleValue)();
  };

  return (
    <>
      <AgentFilterPreferences
        assignedAgent={accessFilter.agents || []}
        isEdit={edit === EditType.Agents}
        submitting={submitting}
        toggleEdit={toggleEdit(EditType.Agents)}
        update={onSubmit('agent filter preferences', EditType.Agents)}
      />

      <DiallerGroupFilterPreferences
        assignedGroups={accessFilter.groups || []}
        isEdit={edit === EditType.Groups}
        submitting={submitting}
        toggleEdit={toggleEdit(EditType.Groups)}
        update={onSubmit('group filter preferences', EditType.Groups)}
      />

      <CampaignFilterPreferences
        assignedCampaigns={accessFilter.campaigns || []}
        isEdit={edit === EditType.Campaigns}
        submitting={submitting}
        toggleEdit={toggleEdit(EditType.Campaigns)}
        update={onSubmit('campaign filter preferences', EditType.Campaigns)}
      />

      <AsyncQueueFilterPreferences
        assignedAsyncQueues={accessFilter.asyncQueues || []}
        isEdit={edit === EditType.AsyncQueues}
        submitting={submitting}
        toggleEdit={toggleEdit(EditType.AsyncQueues)}
        update={onSubmit('async queue filter preferences', EditType.AsyncQueues)}
      />
    </>
  );
};

export default AccessFilterPreferences;
