import * as j from '@mojotech/json-type-validation';

export const TransferType = {
  ARN: 'arn',
  PhoneNumber: 'phone-number',
} as const;

export type TransferTypeValues = typeof TransferType[keyof typeof TransferType];

export type Transfer = {
  id: number;
  name: string;
  endpoint: string;
  description: string | undefined;
  type: TransferTypeValues;
};

export type CreateTransfer = Omit<Transfer, 'id'>;
export type UpdateTransfer = Partial<Omit<Transfer, 'id'>>;

export type TransferResponse = {
  nextPageUrl: string | null;
  transfers: Transfer[];
};

const TransferResponseDecoder: j.Decoder<Transfer> = j
  .object({
    transfer_id: j.number(),
    name: j.string(),
    endpoint: j.string(),
    description: j.union(j.string(), j.constant(null)),
    type: j.string(),
  })
  .map((item: any) => ({
    id: item.transfer_id,
    name: item.name,
    endpoint: item.endpoint,
    description: item.description || null,
    type: item.type,
  }));

export const TransfersResponseDecoder: j.Decoder<TransferResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    transfers: j.array(TransferResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    transfers: item.transfers,
  }));
