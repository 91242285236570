import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import { GetGlobalSettingsDecoder, GlobalSettings } from './domain';

export const getGlobalSettings = async (cancelTokenSource?: CancelTokenSource) => {
  const path = '/api/engine/settings';
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelTokenSource?.token,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        return undefined;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetGlobalSettingsDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const updateGlobalSettings = async (options: Partial<GlobalSettings>, cancelTokenSource?: CancelTokenSource) => {
  const path = '/api/engine/settings';
  const body = {
    is_dialling_paused: options.isDiallingPaused,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelTokenSource?.token,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};
