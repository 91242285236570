import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import { MessageTemplateOmitId } from '~pages/AsyncManagement/QueueDetails/MessageTemplates/useMessageTemplate/domain';

import CreateEditMessageTemplateModal from './CreateEditMessageTemplateModal';
import MessageTemplateCard from './MessageTemplateCard';
import useMessageTemplate from './useMessageTemplate';

interface Query {
  search: string;
  filter: string | '';
}

interface MessageTemplatesProps {
  queue: string;
}

const MessageTemplates = ({ queue }: MessageTemplatesProps) => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editableRef, setEditableRef] = useState<number | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({
    search: '',
    filter: '',
  });
  const debouncedSearch = useDebounce(query.search, 500);
  const {
    loading,
    error,
    messageTemplates,
    categories,
    hasMore,
    getNextPage,
    createMessageTemplate,
    updateMessageTemplate,
    removeMessageTemplate,
  } = useMessageTemplate(queue, debouncedSearch, query.filter);
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const noSearchOrFilterSet = query.search === '' && query.filter === '';
  const lastDataElement = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const onQueryChange = useCallback((e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  }, []);

  const editableMessageTemplate = useMemo(
    () => messageTemplates.find((item) => item.id === editableRef),
    [editableRef],
  );

  const displayList = useMemo(
    () =>
      messageTemplates.map((item, index) => (
        <MessageTemplateCard
          ref={index === messageTemplates.length - 1 ? lastDataElement : undefined}
          key={item.id}
          messageTemplate={item}
          onEdit={() => setEditableRef(item.id)}
          onRemove={() => removeMessageTemplate(item.id)}
        />
      )),
    [messageTemplates, lastDataElement, removeMessageTemplate],
  );

  let filterListDisplay = [
    <MenuItem key='none-index' value=''>
      None
    </MenuItem>,
  ];
  filterListDisplay = [
    ...filterListDisplay,
    ...categories.map((val, index) => (
      <MenuItem key={index} value={val}>
        {val}
      </MenuItem>
    )),
  ];

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateEditModal = () => {
    setCreateModalOpen(false);
    setEditableRef(undefined);
  };

  const onAccept = useCallback(
    async (messageTemplate: MessageTemplateOmitId) => {
      setSubmittingData(true);
      try {
        if (editableMessageTemplate) {
          await updateMessageTemplate(editableMessageTemplate.id, messageTemplate);
        } else {
          await createMessageTemplate(messageTemplate);
        }
      } catch (e) {
        setSubmittingData(false);
        // Modal catches error to prevent form reset on create failure
        return Promise.reject();
      }

      setSubmittingData(false);
      closeCreateEditModal();
    },
    [editableMessageTemplate],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 2 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField fullWidth variant='outlined' label='Search' id='search' name='search' onChange={onQueryChange} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Template Category'
            value={query.filter}
            disabled={categories.length === 0}
            onChange={onQueryChange}>
            {filterListDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}></Grid>
        </Hidden>

        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={openCreateModal}>
            Add Template
          </Button>
        </Grid>
      </Grid>

      <AsyncLoader isLoading={loading && messageTemplates.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {messageTemplates.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && messageTemplates.length > 0 && <DotLoader align='center' />}
                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}
                {error && messageTemplates.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load message templates
                  </Typography>
                )}
              </>
            )}

            {messageTemplates.length == 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No message templates found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {messageTemplates.length === 0 && noSearchOrFilterSet && (
              <EmptyState type='no-items-1' text='No message templates available' />
            )}
          </Grid>
        </Grid>

        <CreateEditMessageTemplateModal
          open={createModalOpen || Boolean(editableMessageTemplate)}
          messageTemplate={editableMessageTemplate}
          categories={categories}
          submitting={submittingData}
          onClose={closeCreateEditModal}
          onAccept={onAccept}
        />
      </AsyncLoader>
    </>
  );
};

export default MessageTemplates;
