import axios, { CancelTokenSource } from 'axios';

import { AccessFilter, UpdateAccessFilter } from '~pages/SystemManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { AccessFilterResponseDecoder } from './domain';

export const getAccessFilter = async (
  accessFilterId: number,
  cancelToken?: CancelTokenSource,
): Promise<AccessFilter | undefined> => {
  const path = `/api/access-filter/${accessFilterId}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = AccessFilterResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const updateAccessFilter = async (accessFilterId: number, data: Partial<UpdateAccessFilter>): Promise<void> => {
  const path = `/api/access-filter/${accessFilterId}`;
  const body: any = {
    name: data.name || undefined,
    description: data.description || undefined,
    agents: data.agents || undefined,
    groups: data.groups || undefined,
    campaigns: data.campaigns || undefined,
    async_queues: data.asyncQueues || undefined,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const archiveAccessFilterById = async (accessFilterId: number): Promise<void> => {
  const path = `/api/access-filter/${accessFilterId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
