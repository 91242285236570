import { LoadingButton } from '@mui/lab';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import { CreateTransfer, TransferType } from '~hooks/useTransfers/domain';
import { transferTypeList, validateArn, validatePhoneNumber } from '~pages/SystemManagement/TransferList/helper';

type CreateTransferModalProps = {
  open: boolean;
  onAccept: (data: CreateTransfer) => void;
  onClose: () => void;
};

function CreateTransferModal({ open, onAccept, onClose }: CreateTransferModalProps) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<CreateTransfer>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const watchType = watch('type');
  const endpointValidationObj = {
    required: 'Endpoint is required.',
    validate: watchType ? (watchType === TransferType.ARN ? validateArn : validatePhoneNumber) : undefined,
  };

  // Reset form on toggle
  useEffect(() => {
    return function cleanupCreateTransferModal() {
      reset();
    };
  }, [open]);

  const onSubmit = handleSubmit(async (data: CreateTransfer) => {
    setSubmitting(true);

    try {
      await onAccept(data);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    } finally {
      setSubmitting(false);
    }

    reset();
  });

  const transferListDisplay = transferTypeList.map((item) => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <OberonDialog
      open={open}
      title='Create Transfer'
      onSubmit={onSubmit}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{ required: 'Name is required.' }}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Name'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='type'
              control={control}
              rules={{ required: 'Type is required.' }}
              defaultValue={TransferType.PhoneNumber}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Type'
                  select
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.type)}
                  helperText={errors.type?.message}
                  {...field}>
                  {transferListDisplay}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='endpoint'
              control={control}
              rules={endpointValidationObj}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Endpoint'
                  disabled={submitting}
                  required={true}
                  error={Boolean(errors.endpoint)}
                  helperText={errors.endpoint?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Description'
                  disabled={submitting}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button
            variant='text'
            disabled={submitting}
            onClick={() => {
              onClose();
            }}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={submitting}
            loading={submitting}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
}

export default CreateTransferModal;
