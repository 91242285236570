import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import {
  DiallerGroupCampaign,
  UpdateCampaignGroupAssignment,
} from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/useDiallerGroupCampaignSearch/domain';

interface Props {
  open: boolean;
  submitting: boolean;
  diallerGroupCampaign?: DiallerGroupCampaign;
  onAccept: (data: UpdateCampaignGroupAssignment) => void;
  onClose: () => void;
}

interface Form {
  priority: number;
  setStartOrEndTime: boolean;
  startTime: DateTime | null;
  endTime: DateTime | null;
}

const EditAssignedCampaignModal = ({ open, diallerGroupCampaign, submitting, onAccept, onClose }: Props) => {
  const isLoading = submitting;
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
  } = useForm<Form>({
    defaultValues: {
      priority: 1,
      setStartOrEndTime: false,
      startTime: null,
      endTime: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  // For some reason react hook form base property watch cannot seem to do its job, so we use the callback version and
  // manage the state ourselves (Hacky, but fixes the issue. Hopefully RHF fixes this in the future)
  const [canSetStartOrEndTime, setCanSetStartOrEndTime] = useState(false);
  useEffect(() => {
    if (open) {
      const subscription = watch((value) => {
        if (value.setStartOrEndTime !== undefined) {
          setCanSetStartOrEndTime(value.setStartOrEndTime);
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [open, watch]);

  useEffect(() => {
    if (open && diallerGroupCampaign) {
      setValue('priority', diallerGroupCampaign.priority);
      setValue('setStartOrEndTime', diallerGroupCampaign.startTime !== null || diallerGroupCampaign.endTime !== null);
      setValue('startTime', diallerGroupCampaign.startTime ? DateTime.fromISO(diallerGroupCampaign.startTime) : null);
      setValue('endTime', diallerGroupCampaign.endTime ? DateTime.fromISO(diallerGroupCampaign.endTime) : null);
    }

    return () => {
      reset();
    };
  }, [open, diallerGroupCampaign]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: UpdateCampaignGroupAssignment = {
      // Since diallerGroupCampaign is the condition for this model to be open, we can enforce that this value is set
      entryId: diallerGroupCampaign!.entryId,
      priority: data.priority,
      startTime: null,
      endTime: null,
    };

    if (data.setStartOrEndTime) {
      submitData = {
        ...submitData,
        startTime: data.startTime?.toFormat('HH:mm') || null,
        endTime: data.endTime?.toFormat('HH:mm') || null,
      };
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Edit Assigned Campaigns'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledNumberField
              name='priority'
              control={control}
              rules={{
                required: 'Priority is required.',
                min: {
                  value: 1,
                  message: 'Priority must be greater than 0.',
                },
              }}
              label='Priority'
              disabled={submitting}
              required={true}
              error={Boolean(errors.priority)}
              helperText={errors.priority?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledCheckbox
              name='setStartOrEndTime'
              label='Set Start or End Time?'
              control={control}
              disabled={submitting}
            />
          </Grid>

          {canSetStartOrEndTime && (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name='startTime'
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TimePicker
                      label='Start Time'
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.startTime)}
                          helperText={errors.startTime?.message}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name='endTime'
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TimePicker
                      label='End Time'
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          required={true}
                          error={Boolean(errors.endTime)}
                          helperText={errors.endTime?.message}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditAssignedCampaignModal;
