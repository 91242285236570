import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { TabPanel } from '~components/TabPanel';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getAsyncQueue } from '../api';
import { AsyncQueue } from '../domain';
import MessageTemplates from './MessageTemplates';
import QueueDispositions from './QueueDispositions';

// Used to manage visible tab selection
enum ViewTabsType {
  Dispositions,
  MessageTemplates,
}

interface Error {
  text: string;
  subText: string;
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const QueueDetails = () => {
  const { queue } = useParams() as { queue: string };
  const navigate = useNavigate();
  const setPageTitleProps = useSetPageTitleProps();
  const [asyncQueue, setAsyncQueue] = useState<AsyncQueue>();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[0],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const errorAction = () => {
    navigate(Routes.asyncQueues.path);
  };

  useEffect(() => {
    const axiosCancelRef = axios.CancelToken.source();

    (async () => {
      if (searchParams.get('show')) {
        setError(null);

        let resp: AsyncQueue | undefined;
        try {
          resp = await getAsyncQueue(queue, axiosCancelRef);
        } catch (e) {
          handleError(e);
          setInitialLoad(false);
          return;
        }

        // Returns undefined if request is canceled
        if (resp === undefined) return;

        setAsyncQueue(resp);
        // Set page title
        setPageTitleProps({ pageName: resp.title });
        setInitialLoad(false);
      }
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.cancel();
    };
  }, [searchParams.get('show')]);

  const errorDisplay = error ? (
    <EmptyState
      type='error'
      text={error.text}
      subText={`${error.subText} \n Click the button below to return to the campaigns list.`}
      action={errorAction}
      actionText='Async Queue List'
    />
  ) : null;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.asyncQueues.path}>
          Messaging Configuration
        </Link>
        <Typography color='textPrimary'>Queue Details</Typography>
      </Breadcrumbs>

      {asyncQueue === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to the async queue list.'
          action={errorAction}
          actionText='Async Queue List'
        />
      )}

      {asyncQueue !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {asyncQueue?.title}
          </Typography>

          <Tabs
            orientation='horizontal'
            variant='scrollable'
            onChange={onTabChange}
            value={tabIndex}
            indicatorColor='primary'
            aria-label='async tabs'>
            <Tab label='Dispositions' {...a11yProps(ViewTabsType.Dispositions)} value={ViewTabsType.Dispositions} />
            <Tab
              label='Message Templates'
              {...a11yProps(ViewTabsType.MessageTemplates)}
              value={ViewTabsType.MessageTemplates}
            />
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel value={tabIndex} index={ViewTabsType.Dispositions}>
            <QueueDispositions queue={queue} />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.MessageTemplates}>
            <MessageTemplates queue={queue} />
          </TabPanel>
        </>
      )}
    </AsyncLoader>
  );
};

export default QueueDetails;
