import { useRef } from 'react';

/**
 * usePrevious is a hook that gets previous state.
 *
 * This is a very rare use-case and should be avoided as per
 * Facebook's React documentation: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * example use:
 * function Counter() {
 *    const [count, setCount] = useState(0);
 *    const prevCount = usePrevious(count);
 *    return <h1>Now: {count}, before: {prevCount}</h1>;
 * }
 */
export const usePrevious = <T>(value: T) => {
  const currentRef = useRef<T>(value);
  const previousRef = useRef<T>();

  if (currentRef.current !== value) {
    previousRef.current = currentRef.current;
    currentRef.current = value;
  }

  return previousRef.current;
};
