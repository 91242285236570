import * as j from '@mojotech/json-type-validation';

import {
  Lead,
  LeadAttempt,
  LeadEndpointAttributes,
  LeadEndpointStatusType,
  LeadStatusType,
} from '~pages/CampaignManagement/domain';
import { AttemptCreationContext } from '~providers/AttemptProvider/domain';

export const GetLeadResponseDecoder: j.Decoder<Lead> = j
  .object({
    lead_id: j.number(),
    list_id: j.number(),
    campaign_id: j.number(),
    lead_external_id: j.string(),
    lead_name: j.string(),
    lead_status: j.string(),
    lead_status_detail: j.string(),
    priority: j.union(j.number(), j.constant(null)),
    timezone: j.string(),
    added_timestamp: j.union(j.string(), j.constant(null)),
    start_timestamp: j.union(j.string(), j.constant(null)),
    expiration_timestamp: j.union(j.string(), j.constant(null)),
    recheck_timestamp: j.union(j.string(), j.constant(null)),
    endpoints: j.array(j.string()),
    num_attempts: j.number(),
    attempts: j.array(
      j.object({
        timestamp: j.string(),
        outcome: j.union(j.string(), j.constant(null)),
        disposition_code: j.union(j.string(), j.constant(null)),
        disposition_sub_code: j.union(j.string(), j.constant(null)),
        contact_id: j.union(j.string(), j.constant(null)),
        callback_id: j.union(j.number(), j.constant(null)),
        endpoint: j.string(),
        creation_context: j.string(),
      }),
    ),
    required_skills: j.array(j.string()),
    attributes: j.union(j.dict(j.string()), j.constant(null)),
    endpoint_attributes: j.dict(
      j.object({
        type: j.string(),
        status: j.string(),
        recheck_timestamp: j.union(j.string(), j.constant(null)),
        attributes: j.dict(j.string()),
      }),
    ),
  })
  .map((item: any) => ({
    id: item.lead_id,
    listId: item.list_id,
    campaignId: item.camapignId,
    externalId: item.lead_external_id,
    name: item.lead_name,
    leadStatus: item.lead_status as LeadStatusType,
    leadStatusDetail: item.lead_status_detail,
    priority: item.priority,
    timezone: item.timezone,
    addedTimestamp: item.added_timestamp,
    startTimestamp: item.start_timestamp,
    expirationTimestamp: item.expiration_timestamp,
    recheckTimestamp: item.recheck_timestamp,
    endpoints: item.endpoints,
    numAttempts: item.num_attempts,
    attempts: item.attempts.map(
      (attempt: any): LeadAttempt => ({
        timestamp: attempt.timestamp,
        outcome: attempt.outcome,
        dispositionCode: attempt.disposition_code,
        dispositionSubCode: attempt.disposition_sub_code,
        contactId: attempt.contact_id,
        callbackId: attempt.callback_id,
        endpoint: attempt.endpoint,
        creationContext: attempt.creation_context as AttemptCreationContext,
      }),
    ),
    requiredSkills: item.required_skills,
    attributes: item.attributes,
    endpointAttributes: Object.keys(item.endpoint_attributes).reduce((state, cv) => {
      return {
        ...state,
        [cv]: {
          type: item.endpoint_attributes[cv].type,
          status: item.endpoint_attributes[cv].status as LeadEndpointStatusType,
          recheckTimestamp: item.endpoint_attributes[cv].recheck_timestamp,
          attributes: item.endpoint_attributes[cv].attributes,
        },
      };
    }, {} as Record<string, LeadEndpointAttributes>),
  }));
