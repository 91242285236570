// TODO: Maybe extend to include UI user friendly messages/ status codes for easy error identification
// for us via use of logrocket
class BaseError extends Error {
  constructor(message: string, proto: object) {
    super();
    // Set this class to the correct prototype data
    Object.setPrototypeOf(this, proto);
    // Creates stack property on the error object created
    Error.captureStackTrace(this, this.constructor);
    // Ensure that the name of the error is the same as the class name
    this.name = this.constructor.name;
    this.message = message;
  }
}

export class ValidationError extends BaseError {
  constructor(message: string) {
    super(message, ValidationError.prototype);
  }
}

export class RecordNotFoundError extends BaseError {
  constructor(message: string) {
    super(message, RecordNotFoundError.prototype);
  }
}

export class UnsupportedStructureError extends BaseError {
  constructor(message: string) {
    super(message, UnsupportedStructureError.prototype);
  }
}

export class APIError extends BaseError {
  /** status can be any API error status or -1 which usually falls under the browser blocked the response because of a
   * violation of Same Origin Policy
   * */
  status: number;

  constructor(status: number, message: string) {
    super(message, APIError.prototype);
    this.status = status;
  }
}
