import { amber } from '@mui/material/colors';
import React, { useState } from 'react';

import OberonSwitch from '~components/OberonSwitch';

export type ToggleActionProps = {
  checked: boolean;
  action: (checked: boolean) => Promise<void>;
  callback?: (checked: boolean) => void;
};

export default ({ checked, action, callback }: ToggleActionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <OberonSwitch
      checked={checked}
      disabled={isSubmitting}
      baseColor={amber[500]}
      onClick={(e) => e.stopPropagation()}
      onChange={async (_, v) => {
        let pChecked = checked;
        try {
          setIsSubmitting(true);
          await action(v);
          pChecked = v;
        } finally {
          if (callback) {
            callback(pChecked);
          }
          setIsSubmitting(false);
        }
      }}
    />
  );
};
