import { MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import React, { ChangeEvent } from 'react';

import { findIndexByProperty } from '~utils/Functions';

export interface SelectMultiItem {
  label: string;
  value: any;
}

interface SelectboxMultiProps {
  id: string;
  name: string;
  value?: any;
  defaultValue?: any;
  onChange(e: ChangeEvent<any>): void;
  items: SelectMultiItem[];
  title: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  style?: any;
}

const StandardSelectItem = (item: SelectMultiItem, index: number) => (
  <MenuItem key={index} value={item.value}>
    {item.label}
  </MenuItem>
);

/** Deprecated - DO NOT USE AS WE ARE PHASING IT OUT */
const SelectboxMulti = ({
  defaultValue,
  value,
  onChange,
  items,
  id,
  name,
  title,
  disabled,
  required,
  error,
  helperText,
  className,
  style,
}: SelectboxMultiProps) => {
  const list = items.map(StandardSelectItem);

  return (
    <TextField
      fullWidth
      select
      className={className}
      style={style}
      SelectProps={{
        multiple: true,
        renderValue: (selected: any) => {
          const chips = (selected as string[]).map((value) => {
            const labelIndex = findIndexByProperty(items, 'value', value);
            const label = items[labelIndex]?.label;
            return <Chip key={value} label={label} style={{ margin: 2 }} />;
          });
          return <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{chips}</Box>;
        },
      }}
      variant='outlined'
      id={id}
      name={name}
      label={title}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      required={required}
      error={error}
      helperText={helperText}
      onChange={onChange}>
      {list}
    </TextField>
  );
};

export default SelectboxMulti;
