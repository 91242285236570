import * as j from '@mojotech/json-type-validation';

export const CampaignFilterType = {
  LeadAttribute: 'lead-attribute',
  EndpointDiallingHours: 'endpoint-dialling-hours',
} as const;

export type CampaignFilterTypeValues = typeof CampaignFilterType[keyof typeof CampaignFilterType];

export type CampaignFilter = {
  id: number;
  name: string;
  field: string;
  match: string;
  type: CampaignFilterTypeValues;
  diallingHourProfile: string | null;
};

export type CreateCampaignFilter = Omit<CampaignFilter, 'id'>;
export type UpdateCampaignFilter = Omit<CampaignFilter, 'id'>;

export interface CampaignFiltersResponse {
  nextPageUrl: string | null;
  filters: CampaignFilter[];
}

const CampaignFilterItemResponseDecoder: j.Decoder<CampaignFilter> = j
  .object({
    filter_id: j.number(),
    name: j.string(),
    field: j.string(),
    match: j.string(),
    type: j.string(),
    dialling_hour_profile: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    id: item.filter_id,
    name: item.name,
    field: item.field,
    match: item.match,
    type: item.type as CampaignFilterTypeValues,
    diallingHourProfile: item.dialling_hour_profile || null,
  }));

export const CampaignFiltersResponseDecoder: j.Decoder<CampaignFiltersResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    filters: j.array(CampaignFilterItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    filters: item.filters,
  }));
