import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { ForwardedRef, MouseEvent, forwardRef, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';
import { CampaignFilter } from '~pages/CampaignManagement/CampaignDetails/useCampaignFilters/domain';

interface Props {
  filter: CampaignFilter;
  onEdit: () => void;
  onDelete: () => void;
}

const EndpointDiallingHourFilterCard = forwardRef(
  ({ filter, onEdit, onDelete }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);

    const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteConfirmationOpen = () => {
      setAnchorEl(null);
      setDeleteConfirmModalOpen(true);
    };

    const handleDeleteConfirmationClose = () => {
      setDeleteConfirmModalOpen(false);
    };

    const handleEdit = () => {
      setAnchorEl(null);
      onEdit();
    };

    const handleDelete = () => {
      onDelete();
      setDeleteConfirmModalOpen(false);
    };

    return (
      <>
        <OberonCard
          titleFontWeight={700}
          title={filter.name}
          subHeader={
            <>
              <div>
                <b>Pattern:</b> {filter.match}
              </div>
              <div>
                <b>Dialling Hour Profile:</b> {filter.diallingHourProfile}
              </div>
            </>
          }
          action={
            <>
              <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleMenuOpen}>
                <MoreHorizIcon />
              </IconButton>
              <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDeleteConfirmationOpen}>Delete</MenuItem>
              </Menu>
            </>
          }
        />

        <ActionDialog
          open={deleteConfirmModalOpen}
          title='Are you sure you want to do this?'
          content={`You are about to delete the ${filter.name} endpoint type setting, once you have completed this action it cannot be undone.`}
          onClose={handleDeleteConfirmationClose}
          onAccept={handleDelete}
          primaryActionTitle='Delete'
        />
      </>
    );
  },
);

export default EndpointDiallingHourFilterCard;
