import axios, { CancelTokenSource } from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  AddCampaignGroupAssignment,
  DiallerGroupCampaignsResponse,
  DiallerGroupCampaignsResponseDecoder,
  UpdateCampaignGroupAssignment,
} from './domain';

export const getDiallerGroupCampaignsByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroupCampaignsResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupCampaignsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getDiallerGroupCampaigns = async (
  diallerGroupId: number,
  search: string | undefined,
  archived: boolean | undefined,
  isActive: boolean | undefined,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroupCampaignsResponse | undefined> => {
  const path = `/api/group/${diallerGroupId}/campaigns`;
  const params = {
    search: search ? search.toLowerCase() : undefined,
    archived: archived !== undefined ? archived.toString() : undefined,
    is_active: isActive !== undefined ? isActive.toString() : undefined,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupCampaignsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const addDiallerGroupCampaign = async (
  diallerGroupId: number,
  data: AddCampaignGroupAssignment,
): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/campaigns`;
  const body = {
    campaign_id: data.campaignId,
    priority: data.priority,
    start_time: data.startTime,
    end_time: data.endTime,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateDiallerGroupCampaign = async (
  diallerGroupId: number,
  data: UpdateCampaignGroupAssignment,
): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/campaigns/${data.entryId}`;
  const body = {
    priority: data.priority,
    start_time: data.startTime,
    end_time: data.endTime,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const removeCampaignFromGroup = async (diallerGroupId: number, entryId: number): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/campaigns/${entryId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
