import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';

import OberonDialog from '~components/OberonDialog';
import useForm, { SubmitCallback, ValidatorType } from '~hooks/useForm';

import { CreateLeadList } from '../../../domain';

interface CreateLeadListModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateLeadList) => void;
  onClose: () => void;
}

const CreateLeadListModal = ({ open, submitting, onAccept, onClose }: CreateLeadListModalProps) => {
  const [endTimeValidationError, setEndTimeValidationError] = useState<string | undefined>(undefined);
  const {
    fields,
    errors,
    handleInputChange,
    handleUnconventionalInputChange,
    handleSubmit,
    addSchemaProperties,
    removeSchemaProperties,
    resetForm,
  } = useForm({
    name: {
      validators: [{ type: ValidatorType.Required, message: 'List name is required.' }],
      value: '',
    },
    description: {
      validators: [{ type: ValidatorType.Required, message: 'List description is required.' }],
      value: '',
    },
    priority: {
      validators: [{ type: ValidatorType.Required, message: 'List priority is required.' }],
      value: 1,
    },
    setStartEndTime: {
      validators: [],
      value: false,
    },
  });
  const fieldsDateToMinDate = useMemo(
    () => (fields.startTime === undefined ? undefined : DateTime.fromISO(fields.startTime.value)),
    [fields.startTime],
  );

  // Manages optional start and end time properties within schema
  useEffect(() => {
    if (fields.setStartEndTime.value === true) {
      addSchemaProperties({
        startTime: {
          validators: [],
          value: null,
        },
        endTime: {
          validators: [],
          value: null,
        },
      });

      return;
    }

    removeSchemaProperties(['startTime', 'endTime']);
    setEndTimeValidationError(undefined);
  }, [fields.setStartEndTime.value]);

  const onSubmit = handleSubmit(async (formData: SubmitCallback) => {
    const data: { [key: string]: any } = formData;

    try {
      await onAccept({
        name: data.name,
        description: data.description,
        priority: data.priority,
        startTime: data.startTime || undefined,
        endTime: data.endTime || undefined,
      });
      resetForm();
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
    }
  });

  // Hack of applying <DateTime> generic type on the DatePicker components as then it
  // inforces correct typing for this onChange function so it's typing is not messed up. This breaks visible
  // typing however even though it passed type checking. i.e. defers TDate generic from input value which is wrong
  // as minDate then also overrides this with its DateTime generic
  const handleDateTimeChange = (fieldName: string) => (date: DateTime | null) => {
    handleUnconventionalInputChange(fieldName, date);
  };

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create List'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='name'
              name='name'
              label='List Name'
              disabled={submitting}
              required={true}
              defaultValue={fields.name.value}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name[0]!}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant='outlined'
              id='description'
              name='description'
              label='List Description'
              disabled={submitting}
              required={true}
              defaultValue={fields.description.value}
              error={Boolean(errors.description)}
              helperText={errors.description && errors.description[0]!}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 1, max: 20 } }}
              variant='outlined'
              id='priority'
              name='priority'
              label='List Priority'
              disabled={submitting}
              required={true}
              defaultValue={fields.priority.value}
              error={Boolean(errors.priority)}
              helperText={errors.priority && errors.priority[0]!}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id='setStartEndTime'
                  name='setStartEndTime'
                  checked={fields.setStartEndTime.value}
                  onChange={handleInputChange}
                />
              }
              label='Set Start or End Time?'
            />
          </Grid>

          {fields.setStartEndTime.value && (
            <>
              <Grid item xs={12} md={6}>
                <DateTimePicker
                  disableMaskedInput
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  disabled={submitting}
                  label='Start Time'
                  inputFormat='dd/MM/yyyy hh:mm a'
                  value={fields.startTime?.value}
                  onChange={handleDateTimeChange('startTime')}
                  renderInput={(params) => <TextField {...params} fullWidth variant='outlined' />}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DateTimePicker
                  disableMaskedInput
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  minDate={fieldsDateToMinDate}
                  onError={(reason) => {
                    if (reason === 'minDate') {
                      setEndTimeValidationError('List End Time should not be before Start Time.');
                      return;
                    }

                    setEndTimeValidationError(undefined);
                  }}
                  disabled={submitting}
                  label='End Time'
                  inputFormat='dd/MM/yyyy hh:mm a'
                  value={fields.endTime?.value}
                  onChange={handleDateTimeChange('endTime')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant='outlined'
                      error={Boolean(endTimeValidationError)}
                      helperText={endTimeValidationError}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={submitting} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={submitting}
            loading={submitting}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateLeadListModal;
