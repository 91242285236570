import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { MouseEvent, useState } from 'react';

import { useAuth } from '~providers/AuthProvider';
import { useUserPreferences } from '~providers/UserPreferencesProvider';
import { convertToColor } from '~utils/Functions';

const UserMenu = () => {
  const { username, fullName, signOut } = useAuth();
  const { avatarUrl } = useUserPreferences();
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchor);

  const handleUserMenuClick = (e: MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(e.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };

  const handleLogout = () => {
    signOut();
  };

  return (
    <>
      <Button
        sx={{
          'padding': '3px 16px',
          'borderRadius': 1,
          'textTransform': 'none',
          'fontSize': 14,
          'color': 'white',
          ':hover': {
            color: '#ffffff',
            backgroundColor: '#ffffff12',
          },
        }}
        onClick={handleUserMenuClick}
        startIcon={
          <Avatar
            sx={{ backgroundColor: convertToColor(username), textTransform: 'capitalize', margin: 'auto' }}
            alt={fullName}
            src={avatarUrl}
          />
        }
        endIcon={<KeyboardArrowDownIcon sx={{ opacity: 0.5 }} />}>
        <Box sx={{ maxWidth: 200, textAlign: 'left' }}>
          <Typography
            fontSize={14}
            lineHeight={1}
            paddingTop={1}
            minWidth={0}
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
            fontWeight='medium'>
            {fullName}
          </Typography>

          <Typography
            fontSize={12}
            lineHeight={1.75}
            color='#ffffff80'
            minWidth={0}
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
            fontWeight='regular'>
            {username}
          </Typography>
        </Box>
      </Button>

      <Menu
        sx={{
          '& .MuiMenu-list': {
            width: 240,
            textAlign: 'center',
          },
        }}
        anchorEl={userMenuAnchor}
        keepMounted
        open={userMenuOpen}
        onClose={handleUserMenuClose}>
        <Avatar
          sx={{ backgroundColor: convertToColor(username), textTransform: 'capitalize', margin: 'auto' }}
          alt={fullName}
          src={avatarUrl}
        />
        <Typography
          fontSize={14}
          lineHeight={1}
          paddingTop={1}
          minWidth={0}
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          fontWeight='bold'>
          {fullName}
        </Typography>

        <Typography
          fontSize={14}
          lineHeight={1.75}
          color='#07091980'
          minWidth={0}
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          fontWeight='regular'>
          {username}
        </Typography>

        <Divider sx={{ marginTop: 1 }} component='hr' />

        <MenuItem sx={{ fontSize: 14, padding: '8px 16px' }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
