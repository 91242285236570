export const transferTypeList = [
  { label: 'Phone Number', value: 'phone-number' },
  { label: 'ARN', value: 'arn' },
];

// Loose Validation for arn
export const validateArn = (value: string | null | undefined): string | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (!value.startsWith('arn:')) {
    return 'Endpoint must be a valid arn.';
  }
};

// Loose Validation for phone number
export const validatePhoneNumber = (value: string | null | undefined): string | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (!value.startsWith('+')) {
    return 'Endpoint must be a valid phone number in E164 format.';
  }
};
