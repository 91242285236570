import * as j from '@mojotech/json-type-validation';

export interface MessageTemplate {
  id: number;
  category: string;
  title: string;
  template: string;
}

export type MessageTemplateOmitId = Omit<MessageTemplate, 'id'>;

export interface MessageTemplatesResponse {
  nextPageUrl: string | null;
  categories: string[];
  list: MessageTemplate[];
}

const GetMessageTemplateItemResponseDecoder: j.Decoder<MessageTemplate> = j
  .object({
    message_template_id: j.number(),
    category: j.string(),
    title: j.string(),
    template: j.string(),
  })
  .map((item) => ({
    id: item.message_template_id,
    category: item.category,
    title: item.title,
    template: item.template,
  }));

export const GetMessageTemplatesResponseDecoder: j.Decoder<MessageTemplatesResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    categories: j.array(j.string()),
    list: j.array(GetMessageTemplateItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    categories: item.categories,
    list: item.list,
  }));
