import * as j from '@mojotech/json-type-validation';

import { AgentListItem } from '~pages/SystemManagement/domain';

export interface AgentsResponse {
  nextPageUrl: string | null;
  totalAgents: number;
  agents: AgentListItem[];
}

type AgentBulkActionOptions = {
  selectAll: boolean;
  // Agents to be added, if selectAll is true, agents to add additional to the select all criteria
  agentsToInclude: string[];
  // If selectAll is true, agents that match the select all criteria that should be excluded
  agentsToExclude: string[];
  diallerGroupId?: number;
  search?: string;
};

export interface UpdateAsyncSettings {
  maxConcurrency: number;
  desiredConcurrency: number;
  queues: string[];
}

export type BatchAsyncSettingsUpdate = Partial<UpdateAsyncSettings> & AgentBulkActionOptions;

export type ChangeGroup = {
  groupId: number;
  routingProfileId: string | null;
};

export type BatchGroupChangeUpdate = AgentBulkActionOptions & {
  changeGroup: ChangeGroup | null;
};

export const GetAgentListItemResponseDecoder: j.Decoder<AgentListItem> = j
  .object({
    username: j.string(),
    full_name: j.string(),
    dialler_group_id: j.union(j.number(), j.constant(null)),
    dialler_group_name: j.union(j.string(), j.constant(null)),
    routing_profile_id: j.union(j.string(), j.constant(null)),
    disabled: j.union(j.string(), j.constant(null)),
    skills: j.array(j.string()),
    async_queues: j.array(j.string()),
  })
  .map((item) => {
    const nameSplit = item.full_name.split(' ');

    return {
      username: item.username,
      fullName: item.full_name,
      firstName: nameSplit[0] || '',
      lastName: nameSplit[1] || '',
      diallerGroupId: item.dialler_group_id ?? 0,
      diallerGroupName: item.dialler_group_name ?? '',
      routingProfileId: item.routing_profile_id,
      disabled: Boolean(item.disabled),
      skills: item.skills,
      asyncQueues: item.async_queues,
    };
  });

export const GetAgentsResponseDecoder: j.Decoder<AgentsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    total_agents: j.number(),
    agents: j.array(GetAgentListItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    totalAgents: item.total_agents,
    agents: item.agents,
  }));
