import * as j from '@mojotech/json-type-validation';

import { AttemptCreationContext } from '~providers/AttemptProvider/domain';

export const CallbackFilter = {
  Pending: 'pending',
  Attempted: 'attempted',
  Removed: 'removed',
} as const;

export type CallbackFilterValues = typeof CallbackFilter[keyof typeof CallbackFilter];

export const SortColumn = {
  CallbackId: 'callback-id',
  ScheduledTimestamp: 'scheduled-timestamp',
  LeadName: 'lead-name',
} as const;

export type SortColumnValues = typeof SortColumn[keyof typeof SortColumn];

export type SortOrder = 'asc' | 'desc';

export type FilterOptions = {
  search?: string;
  filter?: CallbackFilterValues;
  listId?: number[];
  campaignId?: number[];
  agentUsername?: string[];
  dateFrom?: string;
  dateTo?: string;
  sortColumn?: SortColumnValues;
  sortOrder?: SortOrder;
  /** if this property is set AND true AgentUsernames is ignored */
  assignedToGroup?: boolean;
};

export interface Callback {
  id: number;
  leadId: number;
  leadName: string;
  addedAttemptId: number | null;
  addedAttemptCreationContext: AttemptCreationContext | null;
  forAgent: string | null;
  scheduled: string;
  attemptId: number | null;
  endpoint: string;
  notes: string;
  timezone: string;
  disabled: string | null;
  nextAttemptTimestamp: string | null;
  campaignId: number;
  campaignName: string;
  listId: number;
  listName: string;
}

export type CreateCallback = Pick<Callback, 'leadId' | 'forAgent' | 'scheduled' | 'endpoint' | 'notes'>;
export type UpdateCallback = Pick<Callback, 'forAgent' | 'scheduled'>;

export interface CallbackResponse {
  nextPageUrl: string | null;
  callbacks: Callback[];
}

const CallbackItemResponseDecoder: j.Decoder<Callback> = j
  .object({
    callback_id: j.number(),
    lead_id: j.number(),
    lead_name: j.string(),
    added_attempt_id: j.union(j.number(), j.constant(null)),
    added_attempt_creation_context: j.string(),
    for_agent: j.union(j.string(), j.constant(null)),
    scheduled: j.string(),
    attempt_id: j.union(j.number(), j.constant(null)),
    endpoint: j.string(),
    notes: j.string(),
    timezone: j.string(),
    disabled: j.union(j.string(), j.constant(null)),
    next_attempt_timestamp: j.union(j.string(), j.constant(null)),
    campaign_id: j.number(),
    campaign_name: j.string(),
    list_id: j.number(),
    list_name: j.string(),
  })
  .map((item) => ({
    id: item.callback_id,
    leadId: item.lead_id,
    leadName: item.lead_name,
    addedAttemptId: item.added_attempt_id,
    addedAttemptCreationContext: (item.added_attempt_creation_context as AttemptCreationContext) || null,
    forAgent: item.for_agent,
    scheduled: item.scheduled,
    attemptId: item.attempt_id,
    endpoint: item.endpoint,
    notes: item.notes,
    timezone: item.timezone,
    disabled: item.disabled,
    nextAttemptTimestamp: item.next_attempt_timestamp,
    campaignId: item.campaign_id,
    campaignName: item.campaign_name,
    listId: item.list_id,
    listName: item.list_name,
  }));

export const CallbacksResponseDecoder: j.Decoder<CallbackResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    callbacks: j.array(CallbackItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    callbacks: item.callbacks,
  }));
