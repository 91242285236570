import { PhoneDisabled } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import ActionDialog from '~components/ActionDialog';

interface AgentStatusCardProps {
  color: string;
  id: string;
  username: string;
  status: string;
  timeInStatus: string;
  isAgentPrimaryChannel: boolean;
  disconnect: (id: string) => void;
}

const AgentStatusCard = ({
  color,
  id,
  username,
  status,
  timeInStatus,
  isAgentPrimaryChannel,
  disconnect,
}: AgentStatusCardProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const usernameColor: string = isAgentPrimaryChannel ? 'text' : 'textSecondary';
  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const confirmDisconnect = () => {
    disconnect(id);
    setOpen(false);
  };

  return (
    <Card style={{ borderTop: `8px solid ${color}` }}>
      <CardContent>
        <Typography
          fontWeight={700}
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          overflow='hidden'
          variant='body2'
          align='center'
          color={usernameColor}>
          {username}
        </Typography>

        <Typography variant='body2' align='center' color='textSecondary'>
          {id}
        </Typography>

        <Typography align='center'>
          <IconButton size={'small'} color={'warning'} disabled={!isAgentPrimaryChannel} onClick={toggleOpen}>
            <PhoneDisabled />
          </IconButton>
        </Typography>
      </CardContent>

      <Divider />

      <CardActions sx={{ padding: 1, backgroundColor: '#f5f5f5' }}>
        <div>{status}</div>
        <div style={{ marginLeft: 'auto' }}>{timeInStatus}</div>
      </CardActions>

      <ActionDialog
        open={open}
        title='Are you sure you want to do this?'
        content={"You are about to disconnect this agent's voice channel."}
        onClose={toggleOpen}
        onAccept={confirmDisconnect}
        primaryActionTitle='Disconnect'
      />
    </Card>
  );
};

export default AgentStatusCard;
