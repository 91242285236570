import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import { UpdateAsyncSettings } from '~hooks/useAgentList/domain';
import useDebounce from '~hooks/useDebounce';
import { AsyncQueue } from '~pages/AsyncManagement/domain';
import useAsyncQueueSearch from '~pages/AsyncManagement/useAsyncQueueSearch';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

import { AsyncConcurrency } from '../../domain';

type Props = {
  open: boolean;
  submitting: boolean;
  onAccept: (data: Partial<UpdateAsyncSettings>) => Promise<void>;
  onClose: () => void;
};

interface SearchQueries {
  queues: string;
}

interface Form {
  queues: AsyncQueue[] | undefined;
  changeQueues: boolean;
  changeConcurrency: boolean;
  maxConcurrency: number | null;
  desiredConcurrency: number | null;
}

const getNoOptionsText = (fetching: boolean, fetchError: boolean, fetchErrorText: string) => {
  if (fetching) {
    return <DotLoader align='center' />;
  }

  if (fetchError) {
    return (
      <Typography variant='body2' align='center' color='textSecondary'>
        {fetchErrorText}
      </Typography>
    );
  }

  return undefined;
};

function filterListByKey<T>(list: T[], watch: T[] | undefined, key: keyof T) {
  if (!watch) {
    return list;
  }

  return list.filter((listItem) => {
    return !watch.find((watchItem) => listItem[key] === watchItem[key]);
  });
}

const UpdateAsyncSettingsModal = ({ open, submitting, onAccept, onClose }: Props) => {
  const isLoading = submitting;
  const { accessFilter } = useUserPreferences();
  const [searchQueries, setSearchQueries] = useState<SearchQueries>({
    queues: '',
  });
  const debouncedSearchQueries = useDebounce(searchQueries, 500);
  const {
    loading: asyncQueueFetching,
    error: asyncQueueFetchError,
    list: asyncQueues,
    intersectionObserverRef: lastAsyncQueueDataElement,
  } = useAsyncQueueSearch(debouncedSearchQueries.queues, { accessFilterId: accessFilter?.id, shouldFetch: open });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
  } = useForm<Form>({
    defaultValues: {
      queues: undefined,
      changeQueues: false,
      changeConcurrency: false,
      maxConcurrency: null,
      desiredConcurrency: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const queuesWatch = watch('queues');
  const changeQueuesWatch = watch('changeQueues');
  const changeConcurrencyWatch = watch('changeConcurrency');

  const onSubmit = handleSubmit(async (data) => {
    const submitData: Partial<UpdateAsyncSettings> = {};

    if (data.changeQueues) {
      submitData.queues = data.queues?.map((item) => item.queue) || [];
    }

    if (data.changeConcurrency) {
      if (data.maxConcurrency) {
        submitData.maxConcurrency = data.maxConcurrency;
      }
      if (data.desiredConcurrency) {
        submitData.desiredConcurrency = data.desiredConcurrency;
      }
    }

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const onSearchChange = (key: keyof SearchQueries) => (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQueries((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const onSearchBlur = (key: keyof SearchQueries) => (e: FocusEvent<HTMLInputElement>) => {
    setSearchQueries((prev) => ({ ...prev, [key]: '' }));
  };

  const asyncQueuesNoOptionsText = useMemo(
    () => getNoOptionsText(asyncQueueFetching, asyncQueueFetchError, 'Failed to load queues'),
    [asyncQueueFetching, asyncQueueFetchError],
  );

  const asyncQueuesFilteredList = useMemo(
    () => filterListByKey(asyncQueues, queuesWatch || undefined, 'queue'),
    [asyncQueues, queuesWatch],
  );

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Update Messaging Settings'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='changeQueues'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  value='top'
                  control={
                    <Switch
                      {...field}
                      color='primary'
                      disabled={submitting}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label='Change Queues?'
                />
              )}
            />
          </Grid>

          {changeQueuesWatch && (
            <Grid item xs={12}>
              <Controller
                name='queues'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    multiple
                    onChange={(e, data) => {
                      field.onChange(data);
                    }}
                    options={asyncQueuesFilteredList}
                    noOptionsText={asyncQueuesNoOptionsText}
                    isOptionEqualToValue={(option, value) => option.queue === value.queue}
                    disabled={isLoading}
                    getOptionLabel={(option) => option.title || ''}
                    renderOption={(props, option) => (
                      <li {...props} ref={lastAsyncQueueDataElement} key={option.queue}>
                        <Box>
                          <Typography variant='body1' color='textPrimary' component='p'>
                            {option.title}
                          </Typography>
                          <Chip
                            sx={{
                              textTransform: 'uppercase',
                              fontSize: 10,
                              borderRadius: 1,
                              height: 'auto',
                              lineHeight: '21px',
                              color: '#ffffff',
                              fontWeight: 700,
                            }}
                            color='primary'
                            label={option.channelType}
                          />
                        </Box>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Queues'
                        variant='outlined'
                        onBlur={onSearchBlur('queues')}
                        onChange={onSearchChange('queues')}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name='changeConcurrency'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  value='top'
                  control={
                    <Switch
                      {...field}
                      color='primary'
                      disabled={submitting}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label='Change Concurrency?'
                />
              )}
            />
          </Grid>

          {changeConcurrencyWatch && (
            <>
              <Grid item xs={12}>
                <ControlledNumberField
                  name='maxConcurrency'
                  control={control}
                  rules={{
                    required: 'Max concurrency is required',
                    min: {
                      value: AsyncConcurrency.Min,
                      message: `Max concurrency cannot be lower than ${AsyncConcurrency.Min}.`,
                    },
                    max: {
                      value: AsyncConcurrency.Max,
                      message: `Max concurrency cannot be greater than ${AsyncConcurrency.Max}.`,
                    },
                  }}
                  label='Max Concurrency'
                  disabled={submitting}
                  error={Boolean(errors.maxConcurrency)}
                  helperText={errors.maxConcurrency?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledNumberField
                  name='desiredConcurrency'
                  control={control}
                  rules={{
                    required: 'Desired concurrency is required',
                    min: {
                      value: AsyncConcurrency.Min,
                      message: `Desired concurrency cannot be lower than ${AsyncConcurrency.Min}.`,
                    },
                    max: {
                      value: AsyncConcurrency.Max,
                      message: `Desired concurrency cannot be greater than ${AsyncConcurrency.Max}.`,
                    },
                    validate: (value) => {
                      if (!value || (value && value.length === 0)) return undefined;

                      const maxConcurrency = getValues('maxConcurrency');
                      if (!maxConcurrency) return undefined;

                      if (value > maxConcurrency) {
                        return 'Desired concurrency cannot be greater than max concurrency';
                      }

                      return undefined;
                    },
                  }}
                  label='Desired Concurrency'
                  disabled={submitting}
                  error={Boolean(errors.desiredConcurrency)}
                  helperText={errors.desiredConcurrency?.message}
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default UpdateAsyncSettingsModal;
