import React, { ReactNode, createContext, useCallback, useContext, useLayoutEffect, useRef, useState } from 'react';

import EmptyState from '~components/EmptyState';

import { getAssignedGroupConfig } from './api';
import { AssignedGroupConfig } from './domains';

interface Props {
  children: ReactNode;
}

type AssignedGroupContext = {
  group: AssignedGroupConfig;
  configuredGroup: AssignedGroupConfig | undefined;
  switchGroup: () => void;
};

const AssignedDiallerGroupContext = createContext<AssignedGroupContext | undefined>(undefined);

const pollingFrequency = 5_000;

export const useAssignedDiallerGroup = (): AssignedGroupContext => {
  return useContext(AssignedDiallerGroupContext) as AssignedGroupContext;
};

const fetchAssignedGroupConfigOrUndefined = async (): Promise<AssignedGroupConfig | undefined> => {
  try {
    return await getAssignedGroupConfig();
  } catch (e) {
    return undefined;
  }
};

export const AssignedDiallerGroupProvider = ({ children }: Props) => {
  const [group, setGroup] = useState<AssignedGroupConfig | undefined>(undefined);
  const [configuredGroup, setConfiguredGroup] = useState<AssignedGroupConfig | undefined>(undefined);
  const pendingActionRef = useRef<boolean>(false);

  // Manages initial run of group fetch
  useLayoutEffect(() => {
    (async () => {
      const assignedGroup = await fetchAssignedGroupConfigOrUndefined();
      setGroup(assignedGroup);
      setConfiguredGroup(assignedGroup);
    })();
  }, []);

  // Manages the event loop and triggering of action
  useLayoutEffect(() => {
    let currentLocalIntervalRef = window.setInterval(async () => {
      // We want to skip any backend calls if a pending action is still underway
      // This is helpful when it comes to spotty or slow internet connections
      // as we then won't spam and wait for multiple requests
      if (pendingActionRef.current === false) {
        pendingActionRef.current = true;
        const assignedGroup = await fetchAssignedGroupConfigOrUndefined();
        pendingActionRef.current = false;

        if (group === undefined) {
          setGroup(assignedGroup);
        }

        setConfiguredGroup(assignedGroup);
      }
    }, pollingFrequency);

    return () => {
      clearInterval(currentLocalIntervalRef);
    };
  }, [group]);

  const switchGroup = useCallback(() => {
    setGroup(configuredGroup);
  }, [configuredGroup]);

  if (group === undefined) {
    return (
      <EmptyState type='puzzle' text='User Configuration Error' subText='You are not assigned to a dialling group.' />
    );
  }

  const context = {
    group,
    configuredGroup,
    switchGroup,
  };

  return <AssignedDiallerGroupContext.Provider value={context}>{children}</AssignedDiallerGroupContext.Provider>;
};
