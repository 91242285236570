import * as j from '@mojotech/json-type-validation';

export interface ContactLogFilter {
  endpoint?: string;
  contactId?: string;
  externalId?: string;
  campaign?: string;
  channel?: string;
  disposition?: string;
  dispositionSubCode?: string;
  agent?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  disconnectReason?: string;
  initiationMethod?: string;
  attributeName?: string;
  attributeValue?: string;
}

export interface ContactLogFilterOptions {
  agents: string[];
  campaigns: string[];
  channelType: string[];
  dispositions: { [key: string]: string[] };
  disconnectReasons: string[];
  initiationMethods: string[];
}

export interface ContactLog {
  campaign: string;
  channel: string;
  dispositionCode: string;
  dispositionSubCode: string;
  dispositionTitle: string;
  agentUsername: string;
  contactId: string;
  initiationMethod: string;
  initiationTimestamp: string;
  customerCli: string;
  externalId: string;
  connectedToAgentTimestamp: string;
  connectedToAgentDuration: number;
  disconnectReason: string;
}

export interface ContactMetricItem {
  timestamp: string;
  agentUsername: string;
  packetsLost: number;
  packetsCount: number;
  jitterBufferMillis: number;
  roundTripTimeMillis: number;
  streamType: string;
}

export interface ScreenRecording {
  durationInSeconds: number;
  date: string;
  screenId: number;
}

export interface VoiceRecording {
  type: string;
  location: string;
}

export interface ContactDetails {
  contactId: string;
  originalContactId: string;
  previousContactId: string;
  nextContactId: string;
  agent: string;
  channel: string;
  connectionAttempts: number;
  interactionDuration: number;
  connectedToAgent: string;
  connectedToSystem: string;
  customerEndpointNumber: string;
  externalId: string;
  systemEndpointNumber: string;
  disconnectedTimestamp: string;
  initiationTimestamp: string;
  lastUpdatedTimestamp: string;
  initiationMethod: string;
  transcriptLocation: string;
  dispositionCode: string;
  dispositionSubCode: string;
  dispositionTitle: string;
  hasSystemIssue: boolean;
  systemIssueDescription: string;
  campaignId: number;
  campaignName: string;
  metrics: ContactMetricItem[];
  assessmentDetails: Record<string, string>;
  recordingInfoList: ScreenRecording[]; // screen
  disconnectReason: string;
  attributes: Record<string, string>;
  recordings: VoiceRecording[]; // voice
}

export enum MessageRoleType {
  Agent = 'AGENT',
  System = 'SYSTEM',
  Customer = 'CUSTOMER',
}

export enum MessageType {
  Message = 'MESSAGE',
  Event = 'EVENT',
}

export enum MessageContentType {
  TextPlain = 'text/plain',
  ConnectionAcknowledged = 'application/vnd.amazonaws.connect.event.connection.acknowledged',
  Typing = 'application/vnd.amazonaws.connect.event.typing',
  ParticipantJoined = 'application/vnd.amazonaws.connect.event.participant.joined',
  ParticipantLeft = 'application/vnd.amazonaws.connect.event.participant.left',
  TransferSucceeded = 'application/vnd.amazonaws.connect.event.transfer.succeeded',
  TransferFailed = 'application/vnd.amazonaws.connect.event.transfer.failed',
  ChatEnded = 'application/vnd.amazonaws.connect.event.chat.ended',
}

export interface Message {
  role: MessageRoleType;
  content: string;
  name: string;
  date: string;
  type: MessageType;
  contentType: MessageContentType;
}

const GetContactLogItemResponseDecoder: j.Decoder<ContactLog> = j
  .object({
    campaign_name: j.union(j.string(), j.constant(null)),
    channel: j.union(j.string(), j.constant(null)),
    disposition_code: j.union(j.string(), j.constant(null)),
    disposition_sub_code: j.union(j.string(), j.constant(null)),
    disposition_title: j.union(j.string(), j.constant(null)),
    agent_username: j.union(j.string(), j.constant(null)),
    contact_id: j.string(),
    initiation_method: j.union(j.string(), j.constant(null)),
    initiation_timestamp: j.union(j.string(), j.constant(null)),
    customer_cli: j.union(j.string(), j.constant(null)),
    external_id: j.union(j.string(), j.constant(null)),
    connected_to_agent_timestamp: j.union(j.string(), j.constant(null)),
    connected_to_agent_duration: j.union(j.number(), j.constant(null)),
    disconnect_reason: j.union(j.string(), j.constant(null)),
  })
  .map((item: any) => ({
    campaign: item.campaign_name,
    channel: item.channel,
    dispositionCode: item.disposition_code,
    dispositionSubCode: item.disposition_sub_code,
    dispositionTitle: item.disposition_title,
    agentUsername: item.agent_username,
    contactId: item.contact_id,
    initiationMethod: item.initiation_method,
    initiationTimestamp: item.initiation_timestamp,
    customerCli: item.customer_cli,
    externalId: item.external_id,
    connectedToAgentTimestamp: item.connected_to_agent_timestamp,
    connectedToAgentDuration: item.connected_to_agent_duration,
    disconnectReason: item.disconnect_reason,
  }));

export const GetContactLogsResponseDecoder: j.Decoder<ContactLog[]> = j
  .object({
    contacts: j.array(GetContactLogItemResponseDecoder),
  })
  .map((item: any) => item.contacts);

export const GetContactLogFilterOptionsResponseDecoder: j.Decoder<ContactLogFilterOptions> = j
  .object({
    agents: j.array(j.string()),
    campaigns: j.array(j.string()),
    channel_type: j.array(j.string()),
    dispositions: j.array(
      j.object({
        disposition_code: j.string(),
        disposition_sub_code: j.string(),
      }),
    ),
    disconnect_reasons: j.array(j.string()),
    initiation_methods: j.array(j.string()),
  })
  .map((item: any) => ({
    agents: item.agents,
    campaigns: item.campaigns,
    channelType: item.channel_type,
    dispositions: item.dispositions.reduce((accumulator: any, currentValue: any) => {
      const key = currentValue.disposition_code;

      if (!accumulator[key]) {
        accumulator[key] = [currentValue.disposition_sub_code];
      } else {
        accumulator[key].push(currentValue.disposition_sub_code);
      }

      return accumulator;
    }, {}),
    disconnectReasons: item.disconnect_reasons,
    initiationMethods: item.initiation_methods,
  }));

const GetContactMetricsItemResponseDecoder: j.Decoder<ContactMetricItem> = j
  .object({
    snapshot_timestamp: j.string(),
    agent_username: j.string(),
    jitter_buffer_milliseconds: j.number(),
    round_trip_time_milliseconds: j.number(),
    packets_lost: j.number(),
    packets_count: j.number(),
    stream_type: j.string(),
  })
  .map((item: any) => ({
    timestamp: item.snapshot_timestamp,
    agentUsername: item.agent_username,
    packetsLost: item.packets_lost,
    packetsCount: item.packets_count,
    jitterBufferMillis: item.jitter_buffer_milliseconds,
    roundTripTimeMillis: item.round_trip_time_milliseconds,
    streamType: item.stream_type,
  }));

const GetScreenRecordingItemResponseDecoder: j.Decoder<ScreenRecording> = j
  .object({
    duration_in_seconds: j.number(),
    date: j.string(),
    screen_id: j.number(),
  })
  .map((item: any) => ({
    durationInSeconds: item.duration_in_seconds,
    date: item.date,
    screenId: item.screen_id,
  }));

const GetContactRecordingItemResponseDecoder: j.Decoder<VoiceRecording> = j
  .object({
    type: j.string(),
    location: j.string(),
  })
  .map((item: any) => ({
    type: item.type,
    location: item.location,
  }));

export const GetContactDetailsResponseDecoder: j.Decoder<ContactDetails> = j
  .object({
    contact_id: j.string(),
    initial_contact_id: j.union(j.string(), j.constant(null)),
    previous_contact_id: j.union(j.string(), j.constant(null)),
    next_contact_id: j.union(j.string(), j.constant(null)),
    external_id: j.union(j.string(), j.constant(null)),
    agent_username: j.union(j.string(), j.constant(null)),
    channel: j.union(j.string(), j.constant(null)),
    agent_connection_attempts: j.optional(j.number()),
    agent_interaction_duration: j.optional(j.number()),
    connected_to_agent_timestamp: j.union(j.string(), j.constant(null)),
    connected_to_system_timestamp: j.union(j.string(), j.constant(null)),
    customer_cli: j.union(j.string(), j.constant(null)),
    system_entrypoint: j.union(j.string(), j.constant(null)),
    disconnection_timestamp: j.union(j.string(), j.constant(null)),
    initiation_timestamp: j.union(j.string(), j.constant(null)),
    initiation_method: j.union(j.string(), j.constant(null)),
    last_update: j.union(j.string(), j.constant(null)),
    recording_location: j.union(j.string(), j.constant(null)),
    disposition_code: j.union(j.string(), j.constant(null)),
    disposition_sub_code: j.union(j.string(), j.constant(null)),
    disposition_title: j.union(j.string(), j.constant(null)),
    has_system_issue: j.union(j.boolean(), j.constant(null)),
    system_issue_description: j.union(j.string(), j.constant(null)),
    campaign_id: j.union(j.number(), j.constant(null)),
    campaign_name: j.union(j.string(), j.constant(null)),
    metrics: j.array(GetContactMetricsItemResponseDecoder),
    assessment_details: j.union(j.dict(j.string()), j.constant(null)),
    recording_info_list: j.array(GetScreenRecordingItemResponseDecoder),
    disconnect_reason: j.union(j.string(), j.constant(null)),
    attributes: j.dict(j.string()),
    recordings: j.union(j.array(GetContactRecordingItemResponseDecoder), j.constant(null), j.constant(undefined)),
  })
  .map((item: any) => ({
    contactId: item.contact_id,
    originalContactId: item.initial_contact_id,
    previousContactId: item.previous_contact_id,
    nextContactId: item.next_contact_id,
    externalId: item.external_id,
    agent: item.agent_username,
    channel: item.channel,
    connectionAttempts: item.agent_connection_attempts,
    interactionDuration: item.agent_interaction_duration,
    connectedToAgent: item.connected_to_agent_timestamp,
    connectedToSystem: item.connected_to_system_timestamp,
    customerEndpointNumber: item.customer_cli,
    systemEndpointNumber: item.system_entrypoint,
    disconnectedTimestamp: item.disconnection_timestamp,
    initiationTimestamp: item.initiation_timestamp,
    lastUpdatedTimestamp: item.last_update,
    initiationMethod: item.initiation_method,
    transcriptLocation: item.recording_location,
    dispositionCode: item.disposition_code,
    dispositionSubCode: item.disposition_sub_code,
    dispositionTitle: item.disposition_title,
    hasSystemIssue: item.has_system_issue,
    systemIssueDescription: item.system_issue_description,
    campaignId: item.campaign_id,
    campaignName: item.campaign_name,
    metrics: item.metrics,
    assessmentDetails: item.assessment_details,
    recordingInfoList: item.recording_info_list,
    disconnectReason: item.disconnect_reason,
    attributes: item.attributes,
    recordings: item.recordings || [],
  }));

export const GetChatMessagesResponseDecoder: j.Decoder<Message[]> = j.array(
  j
    .object({
      Id: j.string(),
      AbsoluteTime: j.string(),
      ContentType: j.string(),
      Type: j.string(),
      Content: j.optional(j.string()),
      DisplayName: j.optional(j.string()),
      ParticipantId: j.optional(j.string()),
      ParticipantRole: j.optional(j.string()),
    })
    .map((item: any) => ({
      role: item.ParticipantRole as MessageRoleType,
      content: item.Content,
      name: item.DisplayName,
      date: item.AbsoluteTime,
      type: item.Type as MessageType,
      contentType: item.ContentType as MessageContentType,
    })),
);
