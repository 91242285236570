import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { deletePredictiveIvrMessage, getPredictiveIvrMessages, upsertPredictiveIvrMessage } from '../api';
import { PredictiveIvrMessage } from '../domain';
import CreateEditIVRMessageModal from './CreateIVRMessageModal';
import MessageCard from './MessageCard';

interface Query {
  search: string;
}

interface Error {
  text: string;
  subText: string;
}

const PredictiveIVRMessageList = () => {
  const { pushNotification } = useNotification();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editableIvrMessage, setEditableIvrMessage] = useState<PredictiveIvrMessage | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [ivrMessages, setIvrMessages] = useState<PredictiveIvrMessage[]>([]);
  const [query, setQuery] = useState<Query>({
    search: '',
  });

  useEffect(() => {
    (async () => {
      try {
        setIvrMessages(await getPredictiveIvrMessages());
      } catch (e) {
        handleError(e);
      } finally {
        setPageLoaded(true);
      }
    })();
  }, []);

  const handleError = (e: any) => {
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }

    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const openEditModal = (item: PredictiveIvrMessage) => () => {
    setEditableIvrMessage(item);
  };

  const closeModal = () => {
    setCreateModalOpen(false);
    setEditableIvrMessage(undefined);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const upsertMessage = async (isEdit: boolean, msg: PredictiveIvrMessage) => {
    setSubmittingData(true);

    try {
      await upsertPredictiveIvrMessage(isEdit, msg);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created predictive IVR message ${msg.key}`);

    try {
      setIvrMessages(await getPredictiveIvrMessages());
    } catch (e) {
      handleError(e);
    } finally {
      closeModal();
    }
  };

  const deleteMessage = (key: string) => async () => {
    setSubmittingData(true);

    try {
      await deletePredictiveIvrMessage(key);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Deleted predictive IVR message ${key}`);

    try {
      setIvrMessages(await getPredictiveIvrMessages());
    } catch (e) {
      handleError(e);
    } finally {
      closeModal();
    }
  };

  const displayList = ivrMessages
    // Search Fuzzy matching
    .filter((item: PredictiveIvrMessage) => {
      if (query.search) {
        const searchQuery = query.search.toLowerCase();
        return item.key.toLowerCase().includes(searchQuery);
      }

      return true;
    })
    // Component generation
    .map((item: PredictiveIvrMessage, index: number) => (
      <MessageCard key={index} ivrMessage={item} onEdit={openEditModal(item)} onDelete={deleteMessage(item.key)} />
    ));

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = query.search && displayList.length === 0;
  const noResults = ivrMessages.length === 0;

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : null;

  return (
    <AsyncLoader isLoading={!pageLoaded} error={errorDisplay}>
      {noResults && (
        <EmptyState
          type='no-items-3'
          text='No predictive IVR messages currently exist'
          subText='Create a  predictive IVR message by clicking the button below'
          action={openCreateModal}
          actionText='Create IVR Message'
        />
      )}

      {!noResults && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={5}></Grid>
          </Hidden>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={openCreateModal}>
              Create IVR Message
            </Button>
          </Grid>

          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No predictive IVR messages found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CreateEditIVRMessageModal
        open={createModalOpen || Boolean(editableIvrMessage)}
        submitting={submittingData}
        ivrMessage={editableIvrMessage}
        onClose={closeModal}
        onAccept={upsertMessage}
      />
    </AsyncLoader>
  );
};

export default PredictiveIVRMessageList;
