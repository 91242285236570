import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DataItem } from '~components/DataItem';
import SectionCard from '~components/SectionCard';
import { AccessFilter, UpdateAccessFilter } from '~pages/SystemManagement/domain';

interface Form {
  name: string;
  description: string;
}

interface GeneralSettingsFormProps {
  accessFilter: AccessFilter;
  isEdit: boolean;
  submitting: boolean;
  toggleEdit: () => void;
  update: (data: Partial<UpdateAccessFilter>) => Promise<void>;
}

const GeneralSettingsForm = ({ accessFilter, isEdit, submitting, toggleEdit, update }: GeneralSettingsFormProps) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isEdit) {
      setValue('name', accessFilter.name);
      setValue('description', accessFilter.description || '');
    }

    return () => {
      reset();
    };
  }, [isEdit]);

  const onSubmit = handleSubmit((data: Form) => {
    let submitData: Partial<UpdateAccessFilter> = {
      name: data.name,
      description: data.description,
    };

    update(submitData);
  });

  return (
    <SectionCard title='General' onEdit={toggleEdit}>
      {!isEdit && (
        <>
          <DataItem stacked={isExtraSmall} disableMargin title='Access Filter Name' value={accessFilter.name} />
          <DataItem stacked={isExtraSmall} title='Description' value={accessFilter.description || '-'} />
        </>
      )}

      {isEdit && (
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Name is required.',
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Name'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Description'
                    disabled={submitting}
                    required={true}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={12}>
              <Button onClick={toggleEdit}>Cancel</Button>

              <LoadingButton
                sx={{ marginLeft: 1 }}
                type='submit'
                variant='contained'
                disableElevation
                loading={submitting}
                color='primary'>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </SectionCard>
  );
};

export default GeneralSettingsForm;
