import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';

type LegendItem = {
  label: string;
  color: string;
};

type Props = {
  items: LegendItem[];
};

export const PreferenceLegend = ({ items }: Props) => {
  const display = items.map((item, index) => (
    <Box key={index} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', margin: 0.5 }}>
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: item.color,
          marginRight: 1,
        }}></Box>
      {item.label}
    </Box>
  ));

  return (
    <>
      <Typography variant='body1' fontWeight={500} color='textPrimary'>
        Legend
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: 0.5 }}>{display}</Box>
    </>
  );
};

export default PreferenceLegend;
