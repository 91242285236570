import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';

import { DotLoader } from '~components/DotLoader';
import SplitProgressBar from '~components/SplitProgressBar';
import ProgressItem from '~components/SplitProgressBar/ProgressItem';

import { getAgentStatsForDay } from './api';
import { AgentStatsDay } from './domain';

interface AgentStatsBarProps {
  /** date to be formatted as YYYY-MM-DD */
  date: string;
  username: string;
  spinnerType?: 'circle' | 'threeDot';
}

const getAgentStatsForDayOrUndefined = async (date: string, username: string) => {
  try {
    return await getAgentStatsForDay(date, username);
  } catch {
    return undefined;
  }
};

const pluralizeText = (value: number, text: string) => {
  return value === 0 || value > 1 ? `${text}s` : text;
};

const secondsToHumanReadableString = (seconds: number): string => {
  const timeframeSplit = new Date(seconds * 1000).toISOString().substr(11, 8).split(':');

  return `${timeframeSplit[0]} ${pluralizeText(parseInt(timeframeSplit[0], 10), 'hr')} ${
    timeframeSplit[1]
  } ${pluralizeText(parseInt(timeframeSplit[0], 10), 'min')} ${timeframeSplit[2]} ${pluralizeText(
    parseInt(timeframeSplit[0], 10),
    'sec',
  )}`;
};

// Formats dates to 12 hour time with no hour padding i.e. 2:00 AM/PM
const TIME_FORMAT = 'h:mm a';

export const agentStatsColors: { [key: string]: string } = {
  'Available': '#047857',
  'Internal Training': '#1D4ED8',
  'Client Training': '#1D4ED8',
  'Meeting': '#6D28D9',
  'Coaching': '#0E7490',
  'Meal': '#B45309',
  'Break': '#B45309',
  'Not Ready': '#334155',
  'Other': '#B91C1C',
};

const AgentStatsBar = ({ date, username, spinnerType = 'circle' }: AgentStatsBarProps) => {
  const [agentStatsDay, setAgentStatsDay] = useState<AgentStatsDay | undefined>();
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const totalDuration = useMemo(() => {
    return agentStatsDay !== undefined
      ? agentStatsDay.stats.reduce((state, currentValue) => state + currentValue.duration, 0)
      : 0;
  }, [agentStatsDay]);

  useEffect(() => {
    (async () => {
      // Fetch all data based off of dates at once
      setAgentStatsDay(await getAgentStatsForDayOrUndefined(date, username));
      setLoadingStats(false);
    })();
  }, []);

  return (
    <>
      {loadingStats && spinnerType === 'circle' && (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={24} />
        </Box>
      )}

      {loadingStats && spinnerType === 'threeDot' && <DotLoader align='center' />}

      {!loadingStats && agentStatsDay === undefined && (
        <Typography variant='caption'>No agent stats available for day.</Typography>
      )}

      {!loadingStats && agentStatsDay !== undefined && (
        <>
          <SplitProgressBar>
            {agentStatsDay.stats.map((stat, index) => {
              const tooltipPercentage = (stat.duration / totalDuration) * 100;
              const startTime = DateTime.fromISO(stat.eventStartTime).toFormat(TIME_FORMAT);

              return (
                <Tooltip
                  key={index}
                  arrow
                  title={
                    <>
                      <Typography variant='caption' component='h1' fontWeight='700' gutterBottom>
                        {stat.status}
                      </Typography>

                      <Typography variant='caption' component='p' fontWeight='400'>
                        {secondsToHumanReadableString(stat.duration)}
                      </Typography>

                      <Typography variant='caption' component='p' fontWeight='400'>
                        <b>Starting at:</b> {startTime}
                      </Typography>

                      <Typography variant='caption' component='p' fontWeight='400'>
                        <b>Duration of Day:</b> {tooltipPercentage.toFixed(2)}%
                      </Typography>
                    </>
                  }
                  placement='top'>
                  <ProgressItem
                    progress={tooltipPercentage}
                    color={agentStatsColors[stat.status] || agentStatsColors['Other']}
                    gaps
                  />
                </Tooltip>
              );
            })}
          </SplitProgressBar>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='caption' component='p'>
              {DateTime.fromISO(agentStatsDay.startTime).toFormat(TIME_FORMAT)}
            </Typography>

            <Typography variant='caption' component='p'>
              {DateTime.fromISO(agentStatsDay.endTime).toFormat(TIME_FORMAT)}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default AgentStatsBar;
