import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { useMemo } from 'react';

import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAsync } from '~providers/AsyncProvider';
import { useConnect } from '~providers/ConnectProvider';
import { AsyncAgentState } from '~services/AsyncManager/domain';

import ConversationContainer from './ConversationContainer';
import MessageContainer from './MessageContainer';

// If the status bar's height OR margin's change these properties will have to be updated
const statusBarHeight = 100;
const statusBarBottomMargin = 16;

const AsyncView = () => {
  const { web, orgReference } = useAppConfiguration();
  const { customers, selectedCustomerKey, setActiveCustomer, agent: asyncAgent, asyncManager } = useAsync();
  const { agent: connectAgent } = useConnect();
  const conversationPopLinks = useMemo(
    () =>
      web.contactPopLinks.reduce(
        (state, currentValue) =>
          currentValue.contactType === 'messaging' ? { ...state, [currentValue.label]: currentValue.value } : state,
        {} as { [key: string]: string },
      ),
    [web.contactPopLinks],
  );
  const isMissedConversation = asyncAgent.state === AsyncAgentState.MissedConversation;

  const goBackToAvailable = () => {
    asyncManager.setAgentState(AsyncAgentState.Available);
    connectAgent.setOnline();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        // below properties is a flexbox hack so that child elements will never be greater than
        // the specified parent height
        height: 0,
        maxHeight: `calc(100% - ${statusBarHeight + statusBarBottomMargin}px)`,
        minHeight: `calc(100% - ${statusBarHeight + statusBarBottomMargin}px)`,
      }}>
      <Backdrop sx={{ padding: 2, position: 'absolute', borderRadius: '10px', zIndex: 1 }} open={isMissedConversation}>
        <Paper sx={{ padding: 2 }} elevation={3}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography gutterBottom variant='h5' component='div'>
              Are you still here?
            </Typography>

            <Typography variant='body2' color='text.secondary'>
              You have missed a conversation. Click the button below to continue messaging.
            </Typography>
          </Box>

          <Button
            sx={{ textTransform: 'none' }}
            variant='contained'
            fullWidth
            disableElevation
            onClick={goBackToAvailable}
            color='primary'>
            Still here
          </Button>
        </Paper>
      </Backdrop>

      <ConversationContainer
        customers={customers}
        selectedCustomerKey={selectedCustomerKey}
        setActiveCustomer={setActiveCustomer}
      />

      <MessageContainer
        orgReference={orgReference}
        attributeConfiguration={web.additionalConversationAttributes}
        contactPopLinks={conversationPopLinks}
        customer={customers[selectedCustomerKey]}
        markAsRead={asyncManager.markMessagesAsRead}
        sendMessage={asyncManager.sendMessage}
        loadMoreFrom={asyncManager.loadMoreConversationsFrom}
        transferTo={asyncManager.transferTo}
        markConversationAsDisposed={asyncManager.markConversationAsDisposed}
      />
    </Box>
  );
};

export default AsyncView;
