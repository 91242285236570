import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { MouseEvent, useState } from 'react';

import OberonCard from '~components/OberonCard';

import { ExclusionList } from '../../domain';

interface ExclusionListCardProps {
  exclusionList: ExclusionList;
  onAddEndpoint: () => void;
  onBulkAddEndpoints: () => void;
  onCheckEndpointExists: () => void;
  onRemoveEndpoint: () => void;
}

const ExclusionListCard = ({
  exclusionList,
  onAddEndpoint,
  onBulkAddEndpoints,
  onCheckEndpointExists,
  onRemoveEndpoint,
}: ExclusionListCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const triggerBulkAddEndpoints = () => {
    setAnchorEl(null);
    onBulkAddEndpoints();
  };

  const triggerAddEndpoint = () => {
    setAnchorEl(null);
    onAddEndpoint();
  };

  const triggerCheckEndpoint = () => {
    setAnchorEl(null);
    onCheckEndpointExists();
  };

  const triggerRemoveEndpoint = () => {
    setAnchorEl(null);
    onRemoveEndpoint();
  };

  return (
    <OberonCard
      titleFontWeight={700}
      title={exclusionList.name}
      subHeader={exclusionList.description}
      action={
        <>
          <IconButton aria-controls='agent-menu' aria-haspopup='true' onClick={handleMenuOpen}>
            <MoreHorizIcon />
          </IconButton>

          <Menu id='agent-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={triggerAddEndpoint}>Add Endpoint</MenuItem>
            <MenuItem onClick={triggerBulkAddEndpoints}>Bulk Add Endpoints</MenuItem>
            <MenuItem onClick={triggerCheckEndpoint}>Check Endpoint Exists</MenuItem>
            <MenuItem onClick={triggerRemoveEndpoint}>Remove Endpoint</MenuItem>
          </Menu>
        </>
      }
    />
  );
};

export default ExclusionListCard;
