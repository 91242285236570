import * as j from '@mojotech/json-type-validation';

import { AccessFilter } from '~pages/SystemManagement/domain';

export interface AccessFilterResponse {
  nextPageUrl: string | null;
  filters: AccessFilter[];
}

const AccessFilterItemResponseDecoder: j.Decoder<AccessFilter> = j
  .object({
    access_filter_id: j.number(),
    name: j.string(),
    description: j.string(),
    archived: j.optional(j.string()),
    num_agents: j.number(),
    num_campaigns: j.number(),
    num_groups: j.number(),
    num_async_queues: j.number(),
  })
  .map((item) => ({
    id: item.access_filter_id,
    name: item.name,
    description: item.description,
    archived: item.archived || undefined,
    numAgents: item.num_agents,
    numCampaigns: item.num_campaigns,
    numGroups: item.num_groups,
    numAsyncQueues: item.num_async_queues,
    agents: undefined,
    campaigns: undefined,
    groups: undefined,
    asyncQueues: undefined,
  }));

export const AccessFiltersResponseDecoder: j.Decoder<AccessFilterResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    filters: j.array(AccessFilterItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    filters: item.filters,
  }));
