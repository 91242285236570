import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';

import { ValidatorType } from '~hooks/useForm';
import { OutcomesFormProps } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';

const OutcomesForm = ({ form, formSubmitting }: OutcomesFormProps) => {
  useEffect(() => {
    form.addSchemaProperties({
      nmi: {
        value: '',
        validators: [],
      },
      phone_number: {
        value: '',
        validators: [
          {
            type: ValidatorType.Regex,
            pattern: /^[0]\d{9}$/,
            message: 'Must be 10 digits numbers starting with 0 with no spaces. eg 0498765437',
          },
        ],
      },
      state: {
        value: '',
        validators: [
          {
            type: ValidatorType.Required,
            message: 'State is required',
          },
        ],
      },
      comment: {
        value: '',
        validators: [],
      },
    });

    return () => {
      form.removeSchemaProperties(['nmi', 'phone_number', 'state', 'comment']);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='nmi'
          name='nmi'
          label='NMI'
          disabled={formSubmitting}
          value={form.fields?.nmi?.value || ''}
          error={Boolean(form.errors.nmi)}
          helperText={form.errors.nmi && form.errors.nmi[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='phone_number'
          name='phone_number'
          label='Phone Number'
          disabled={formSubmitting}
          value={form.fields?.phone_number?.value || ''}
          error={Boolean(form.errors.phone_number)}
          helperText={form.errors.phone_number && form.errors.phone_number[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='state'
          name='state'
          label='State'
          disabled={formSubmitting}
          value={form.fields?.state?.value || ''}
          error={Boolean(form.errors.state)}
          helperText={form.errors.state && form.errors.state[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='outlined'
          id='comment'
          name='comment'
          label='Comment'
          disabled={formSubmitting}
          value={form.fields?.comment?.value || ''}
          error={Boolean(form.errors.comment)}
          helperText={form.errors.comment && form.errors.comment[0]!}
          onChange={form.handleInputChange}
        />
      </Grid>
    </>
  );
};

export default OutcomesForm;
