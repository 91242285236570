import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/system/Box';
import React, { KeyboardEvent, MouseEvent, useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import OberonCard from '~components/OberonCard';

interface DispositionCardProps {
  code: string;
  subCode: string;
  title: string;
  outcome: string;
  canDelete: boolean;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const DispositionCard = ({
  code,
  subCode,
  title,
  outcome,
  canDelete,
  onClick,
  onEdit,
  onDelete,
}: DispositionCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);
  const [deleteBlockModalOpen, setDeleteBlockModalOpen] = useState<boolean>(false);
  const isSystem = code === 'system';

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteConfirmationOpen = () => {
    setAnchorEl(null);
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmModalOpen(false);
  };

  const handleDeleteBlockOpen = () => {
    setAnchorEl(null);
    setDeleteBlockModalOpen(true);
  };

  const handleDeleteBlockClose = () => {
    setDeleteBlockModalOpen(false);
  };

  const handleEdit = (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
    onEdit();
  };

  // Chooses which variation of modal should be opened for deleting purposes
  const handleDeleteModalOpen = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (canDelete) {
      handleDeleteConfirmationOpen();
      return;
    }
    handleDeleteBlockOpen();
  };

  const handleDelete = () => {
    onDelete();
    setDeleteConfirmModalOpen(false);
  };

  return (
    <>
      <OberonCard
        titleFontWeight={700}
        reverseTitles
        title={
          <>
            {title}
            {outcome && (
              <Box sx={{ marginTop: 1 }}>
                <Chip
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: 10,
                    borderRadius: 1,
                    height: 20,
                    lineHeight: '21px',
                  }}
                  color='primary'
                  label={outcome}
                />
              </Box>
            )}
          </>
        }
        subHeader={`${code} ${String.fromCharCode(9679)} ${subCode}`}
        action={
          <>
            <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              {!isSystem && <MenuItem onClick={handleDeleteModalOpen}>Delete</MenuItem>}
            </Menu>
          </>
        }
        onClick={onClick}
      />

      <ActionDialog
        open={deleteConfirmModalOpen}
        title='Are you sure you want to do this?'
        content={`You are about to delete the ${title} disposition, once you have completed this action it cannot be undone.`}
        onClose={handleDeleteConfirmationClose}
        onAccept={handleDelete}
        primaryActionTitle='Delete'
      />

      <ActionDialog
        open={deleteBlockModalOpen}
        title='You are unable to perform this action?'
        content='A campaign must have at least one disposition.'
        onClose={handleDeleteBlockClose}
      />
    </>
  );
};

export default DispositionCard;
