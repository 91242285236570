import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DiallerGroupAccessFilterDict } from '~pages/CampaignManagement/GroupsDashboard/useDiallerGroupsByAccessFilter/domain';

import { getDiallerGroupByAccessFilter } from './api';

const useDiallerGroupsByAccessFilter = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<DiallerGroupAccessFilterDict | undefined>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const reload = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    let resp: DiallerGroupAccessFilterDict | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getDiallerGroupByAccessFilter(axiosCancelRef.current);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setData(resp);
    setLoading(false);
  }, []);

  useEffect(() => {
    setData(undefined);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(undefined);

      let resp: DiallerGroupAccessFilterDict | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallerGroupByAccessFilter(axiosCancelRef.current);
      } catch (e) {
        setError((e as Error).message);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setData(resp);
      setLoading(false);
    })();

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, []);

  return { loading, error, data, reload };
};

export default useDiallerGroupsByAccessFilter;
