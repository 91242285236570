import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccessFilterArchiveForm from '~pages/SystemManagement/AccessFilterDetails/AccessFilterSettings/AccessFilterArchiveForm';
import { AccessFilter, UpdateAccessFilter } from '~pages/SystemManagement/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';

import GeneralSettingsForm from './GeneralSettingsForm';

interface AccessFilterSettingsProps {
  accessFilter: AccessFilter;
  update: (data: Partial<UpdateAccessFilter>) => void;
  archive: () => void;
}

const enum EditType {
  General = 'general',
}

const AccessFilterSettings = ({ accessFilter, update, archive }: AccessFilterSettingsProps) => {
  const { pushNotification } = useNotification();
  const navigate = useNavigate();
  const [edit, setEdit] = useState<EditType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  const onSubmit = (formName: string, toggleValue: EditType) => async (data: Partial<UpdateAccessFilter>) => {
    setSubmitting(true);

    try {
      await update(data);
    } catch (e) {
      pushNotification('error', `Failed to update ${formName}.`);
      return;
    } finally {
      setSubmitting(false);
    }

    toggleEdit(toggleValue)();
  };

  const archiveAccessFilter = async () => {
    try {
      await archive();
    } catch (e) {
      pushNotification('error', 'Failed to archive access filter.');
      return;
    }

    navigate(`${Routes.system.path}?show=AccessFilters`);
    pushNotification('success', `The access filter ${accessFilter.name} has been archived.`);
  };

  return (
    <>
      <GeneralSettingsForm
        accessFilter={accessFilter}
        isEdit={edit === EditType.General}
        submitting={submitting}
        toggleEdit={toggleEdit(EditType.General)}
        update={onSubmit('general settings', EditType.General)}
      />

      <AccessFilterArchiveForm archived={accessFilter.archived} archiveAccessFilter={archiveAccessFilter} />
    </>
  );
};

export default AccessFilterSettings;
