import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Box from '@mui/system/Box';
import React, { ComponentClass, FunctionComponent, ReactNode } from 'react';

enum InfoCardVariantType {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
}

interface InfoCardProps {
  variant: 'success' | 'danger' | 'warning' | 'info';
  icon: ComponentClass<SvgIconProps> | FunctionComponent<SvgIconProps>;
  text: ReactNode;
  subText?: ReactNode;
}

const InfoCard = ({ variant, icon, text, subText }: InfoCardProps) => {
  const Icon = icon;
  let iconColor;
  let borderColor;

  if (variant === InfoCardVariantType.Success) {
    iconColor = green[600];
    borderColor = green[600];
  }

  if (variant === InfoCardVariantType.Danger) {
    iconColor = red[600];
    borderColor = red[600];
  }

  if (variant === InfoCardVariantType.Warning) {
    iconColor = amber[600];
    borderColor = amber[600];
  }

  if (variant === InfoCardVariantType.Info) {
    iconColor = blue[600];
    borderColor = blue[600];
  }

  return (
    <Card
      sx={{
        marginTop: 1,
        marginBottom: 1,
        borderRadius: 2,
        border: '1px solid rgba(7,9,25,.125)',
        borderBottomWidth: 4,
        borderBottomColor: borderColor,
      }}>
      <CardContent
        sx={{
          'textAlign': 'center',
          ':last-child': {
            paddingBottom: 2,
          },
        }}>
        <Icon sx={{ display: 'inline-block', marginTop: 0.5, fontSize: 27, color: iconColor }} />

        <Box sx={{ marginTop: 2 }}>
          <Typography
            fontSize={19}
            fontWeight='bold'
            marginRight={0.5}
            lineHeight={1.3}
            component='span'
            color='textPrimary'>
            {text}
          </Typography>

          <Typography fontSize={14} fontWeight='regular' lineHeight={1.3} component='span' color='textSecondary'>
            {subText}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
