import { useEffect, useState } from 'react';

import { Message } from '~services/AsyncManager/domain';
import { getOrderedMessageKeysByMessageIdAndCreatedTimestamp } from '~services/AsyncManager/helpers';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getAsyncTranscript } from './api';

const useAsyncTranscript = (conversationUUID: string) => {
  const [data, setData] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError('');

      let res: { [key: string]: Message };
      try {
        res = await getAsyncTranscript(conversationUUID);
      } catch (e) {
        setLoading(false);

        if (e instanceof APIError) {
          setError(e.message);
          return;
        }

        if (e instanceof UnsupportedStructureError) {
          setError(e.message);
          return;
        }

        setError((e as Error).message);
        return;
      }

      const orderedMessageKeys = getOrderedMessageKeysByMessageIdAndCreatedTimestamp(res);
      const messages = orderedMessageKeys.map((key) => res[key]);

      setData(messages);
      setLoading(false);
    })();
  }, [conversationUUID]);

  return { data, loading, error };
};

export default useAsyncTranscript;
