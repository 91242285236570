import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';

import OberonCard from '~components/OberonCard';
import { EndpointSequence } from '~pages/CampaignManagement/domain';

interface EndpointTypeCardProps {
  endpointSeq: EndpointSequence;
  index: number;
  submitting: boolean;
  edit: (seq: EndpointSequence) => void;
}

const EndpointTypeCard = ({ endpointSeq, index, edit, submitting }: EndpointTypeCardProps) => {
  const endpointType = endpointSeq.endpointType;
  const footer = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '2em' }}>
      {endpointSeq.start > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography component='span' variant='body1' color='textPrimary'>
            Wait Until Attempt: {endpointSeq.start}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography component='span' variant='body1' color='textPrimary'>
          Priority: {endpointSeq.priority}
        </Typography>
      </Box>
    </Box>
  );
  return (
    <OberonCard
      title={`Type: ${endpointType}`}
      footer={footer}
      action={
        <Button variant='contained' disableElevation disabled={submitting} onClick={() => edit(endpointSeq)}>
          Edit
        </Button>
      }
    />
  );
};

export default EndpointTypeCard;
