import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef, useState } from 'react';

import { Lead } from '~pages/CampaignManagement/domain';

import { getLead } from './api';

/* Will only fetch lead if leadId exists */
const useLead = (listId: number, leadId?: number) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<Lead | undefined>(undefined);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    setData(undefined);
  }, [listId, leadId]);

  useEffect(() => {
    const load = async (listId: number, leadId: number) => {
      setLoading(true);
      setError(undefined);

      let resp: Lead | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getLead(listId, leadId, axiosCancelRef.current);
      } catch (e) {
        setError((e as Error).message);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setData(resp);
      setLoading(false);
    };

    if (leadId) {
      load(listId, leadId);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [listId, leadId]);

  return { loading, error, data };
};

export default useLead;
