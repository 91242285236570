import * as j from '@mojotech/json-type-validation';

export interface List {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  campaignId: number;
  campaignName: string;
}

export interface ListsResponse {
  nextPageUrl: string | null;
  list: List[];
}

export const GetListResponseDecoder: j.Decoder<List> = j
  .object({
    id: j.number(),
    name: j.string(),
    description: j.string(),
    is_active: j.boolean(),
    campaign_id: j.number(),
    campaign_name: j.string(),
  })
  .map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
    isActive: item.is_active,
    campaignId: item.campaign_id,
    campaignName: item.campaign_name,
  }));

export const GetListsResponseDecoder: j.Decoder<ListsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    list: j.array(GetListResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    list: item.list,
  }));
