import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useState } from 'react';

import DirectoryCard from '~components/CallDirectory/DirectoryCard';
import SectionCard from '~components/SectionCard';
import { CallDirectoryItem } from '~providers/AppConfigurationProvider/api';

interface CallDirectoryProps {
  list: CallDirectoryItem[];
  onDial: (phoneNumber: string, extension?: number) => Promise<void>;
}

const CallDirectory = ({ list, onDial }: CallDirectoryProps) => {
  const [search, setSearch] = useState<string>('');

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const directoryListFiltered = list.filter((item) => {
    // If search is empty we want everything
    if (!search) return true;
    // If search is not empty we want to see if value exists in the list or not and filter
    if (item.name.toLowerCase().includes(search.toLowerCase())) return true;

    return false;
  });

  const directoryList = directoryListFiltered.map((item, index) => (
    <DirectoryCard
      key={index}
      name={item.name}
      extension={item.extension}
      divider={index !== directoryListFiltered.length - 1}
      onDial={() => onDial(item.phoneNumber, item.extension)}
    />
  ));

  return (
    <SectionCard title='Directory'>
      <TextField
        style={{ marginTop: 8 }}
        fullWidth
        variant='outlined'
        id='search'
        name='search'
        label='Search'
        onChange={onSearchChange}
      />

      <Divider sx={{ marginTop: 1 }} component='hr' />

      <div
        style={{
          overflow: 'auto',
          height: 500,
        }}>
        {directoryList.length === 0 && (
          <Typography marginTop={1} variant='body1' component='p' align='center'>
            {search.length === 0 ? 'Directory is empty.' : 'No search results found.'}
          </Typography>
        )}

        {directoryList.length > 0 && <List sx={{ padding: 0 }}>{directoryList}</List>}
      </div>
    </SectionCard>
  );
};

export default CallDirectory;
