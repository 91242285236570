import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useState } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

interface ControlledFileUploadProps extends UseControllerProps<any, any> {
  label: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const ControlledFileUpload = ({
  label,
  disabled,
  required,
  error,
  helperText,
  ...controllerProps
}: ControlledFileUploadProps) => {
  const [fileName, setFileName] = useState<string>('');
  const id = uuidv4();

  const onSelectionChange = (field: any, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      setFileName('');
      field.onChange(null);
      return;
    }

    setFileName(e.target.files[0].name);
    field.onChange(e.target.files[0]);
  };

  return (
    <Controller
      {...controllerProps}
      render={({ field }) => (
        <>
          <TextField
            {...field}
            id={id}
            style={{ display: 'none' }}
            type='file'
            disabled={disabled}
            required={required}
            onChange={(e) => onSelectionChange(field, e as ChangeEvent<HTMLInputElement>)}
            value=''
          />

          <label style={{ position: 'relative', display: 'block' }} htmlFor={id}>
            <TextField
              fullWidth
              disabled={disabled}
              label={label}
              variant='outlined'
              InputProps={{
                sx: { '& .MuiOutlinedInput-notchedOutline': { borderColor: error ? 'error.main' : undefined } },
                readOnly: true,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Button
                      sx={{
                        borderRadius: '0 4px 4px 0',
                        marginRight: '-14px',
                        marginLeft: '4px',
                        padding: '16px 16px',
                      }}
                      variant='contained'
                      color='primary'
                      component='span'
                      disableElevation
                      disabled={disabled}>
                      Select File
                    </Button>
                  </InputAdornment>
                ),
              }}
              required={required}
              value={fileName}
              error={error}
              helperText={helperText}
            />
          </label>
        </>
      )}
    />
  );
};

export default ControlledFileUpload;
