import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

import SiteHeader from '../SiteHeader';

interface SiteLayoutProps {
  children: ReactNode;
}

const headerHeight = 64;

const SiteLayout = ({ children }: SiteLayoutProps) => {
  return (
    <div style={{ display: 'flex' }}>
      <SiteHeader />
      <Box
        sx={{
          marginTop: `${headerHeight}px`,
          paddingTop: 5,
          paddingLeft: 3,
          paddingRight: 3,
          paddingBottom: 5,
          width: '100%',
          marginRight: 'auto',
          marginLeft: 'auto',
          minWidth: 320,
          maxWidth: 1440,
          minHeight: `calc(100vh - ${headerHeight}px)`,
        }}>
        {children}
      </Box>
    </div>
  );
};

export default SiteLayout;
