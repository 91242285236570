import * as j from '@mojotech/json-type-validation';

export enum DiallerType {
  Connect,
  SIP,
}

export interface SystemEndpointsResponse {
  nextPageUrl: string | null;
  endpoints: string[];
}

export const GetSystemEndpointsResponseDecoder: j.Decoder<SystemEndpointsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    endpoints: j.array(j.string()),
  })
  .map((item) => ({
    nextPageUrl: item.next_page_url,
    endpoints: item.endpoints,
  }));
