import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AsyncLoader from '~components/AsyncLoader';
import { BasicTable, ColumnData } from '~components/BasicTable';
import ContentSpacer from '~components/ContentSpacer';
import EmptyState from '~components/EmptyState';
import ChannelInfo from '~pages/AsyncManagement/AsyncDashboard/ChannelInfo';
import { channelIdToName } from '~pages/AsyncManagement/AsyncDashboard/helper';
import { AsyncStatsManager } from '~services/AsyncStatsManager';
import { AgentStats, AsyncAllStats, ChannelStats, ChannelType, QueueStats } from '~services/AsyncStatsManager/domain';
import { exponentialDelay, getDurationFromSeconds, sortObject } from '~utils/Functions';

type sortOrder = 'asc' | 'desc';

type MetricsPerQueueFilters = {
  channels: ChannelStats[];
  queues: QueueStats[];
};

type MetricsPerQueueColumns = {
  queue: sortOrder;
  title: sortOrder;
  channel: sortOrder;
  conversationsInQueue: sortOrder;
  conversationsAbandonedInQueue: sortOrder;
  averageWaitTime: sortOrder;
  averageHandlingTime: sortOrder;
  averageFirstResponseTime: sortOrder;
};

type MetricsPerAgentFilters = {
  channels: ChannelStats[];
  queues: QueueStats[];
};

type MetricsPerAgentColumns = {
  username: sortOrder;
  name: sortOrder;
  state: sortOrder;
  timeInState: sortOrder;
  requestedState: sortOrder;
  requestedStateChange: sortOrder;
  maxConcurrency: sortOrder;
  desiredConcurrency: sortOrder;
  currentConcurrency: sortOrder;
  transferQueueCount: sortOrder;
  abandonedConversations: sortOrder;
  averageHandlingTime: sortOrder;
  averageFirstResponseTime: sortOrder;
};

type MetricsPerConversationFilters = {
  channels: ChannelStats[];
  queues: QueueStats[];
  agents: AgentStats[];
};

type MetricsPerConversationColumns = {
  queueTitle: sortOrder;
  customerName: sortOrder;
  agentName: sortOrder;
  agentUsername: sortOrder;
  createdTimestamp: sortOrder;
  dequeuedTimestamp: sortOrder;
  waitTime: sortOrder;
  handlingTime: sortOrder;
  firstResponseTime: sortOrder;
};

type TableFilter<TSort, TFilter> = {
  filters: TFilter;
  sortBy: keyof TSort;
  sortOrder: { [key in keyof TSort]: sortOrder };
};

const conversationStatusToName: { [key: string]: string } = {
  'queued': 'Queued',
  'in-progress': 'In Progress',
};

const url = new URL(window.document.location.href);
const proto = url.protocol === 'https:' ? 'wss' : 'ws';
const socketUrl = `${proto}://${url.host}/api/async/stats`;

const AsyncDashboard = () => {
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const [state, setState] = useState<AsyncAllStats | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const retryTimeoutRef = useRef<number | undefined>(undefined);
  const retryCount = useRef<number>(0);
  const [metricsPerQueueFilter, setMetricsPerQueueFilter] = useState<
    TableFilter<MetricsPerQueueColumns, MetricsPerQueueFilters>
  >({
    filters: {
      channels: [],
      queues: [],
    },
    sortBy: 'title',
    sortOrder: {
      queue: 'desc',
      title: 'desc',
      channel: 'desc',
      conversationsInQueue: 'desc',
      conversationsAbandonedInQueue: 'desc',
      averageWaitTime: 'desc',
      averageHandlingTime: 'desc',
      averageFirstResponseTime: 'desc',
    },
  });
  const [metricsPerAgentFilter, setMetricsPerAgentFilter] = useState<
    TableFilter<MetricsPerAgentColumns, MetricsPerAgentFilters>
  >({
    filters: {
      channels: [],
      queues: [],
    },
    sortBy: 'username',
    sortOrder: {
      username: 'desc',
      name: 'desc',
      state: 'desc',
      timeInState: 'desc',
      requestedState: 'desc',
      requestedStateChange: 'desc',
      maxConcurrency: 'desc',
      desiredConcurrency: 'desc',
      currentConcurrency: 'desc',
      transferQueueCount: 'desc',
      abandonedConversations: 'desc',
      averageHandlingTime: 'desc',
      averageFirstResponseTime: 'desc',
    },
  });
  const [metricsPerConversationFilter, setMetricsPerConversationFilter] = useState<
    TableFilter<MetricsPerConversationColumns, MetricsPerConversationFilters>
  >({
    filters: {
      channels: [],
      queues: [],
      agents: [],
    },
    sortBy: 'createdTimestamp',
    sortOrder: {
      queueTitle: 'desc',
      customerName: 'desc',
      agentName: 'desc',
      agentUsername: 'desc',
      createdTimestamp: 'desc',
      dequeuedTimestamp: 'desc',
      waitTime: 'desc',
      handlingTime: 'desc',
      firstResponseTime: 'desc',
    },
  });

  useEffect(() => {
    const connection = new AsyncStatsManager(socketUrl);
    connection.connect();
    // AsyncStatsManagerInstance.connect();

    connection.on('open', () => {
      clearTimeout(retryTimeoutRef.current);
      retryCount.current = 0;
      setSocketConnected(true);
    });
    connection.on('message', (data) => {
      setState(data);
      setInitialLoad(false);
      console.log(data);
    });
    connection.on('closed', (closeCode) => {
      clearTimeout(retryTimeoutRef.current);
      setSocketConnected(false);
      setState(undefined);

      // Attempt to reconnect the socket at an exponential delay
      if (closeCode > 1000) {
        retryTimeoutRef.current = exponentialDelay(retryCount.current, () => {
          retryCount.current += 1;
          connection.connect();
        });
      }
    });

    return () => {
      connection.disconnect();
    };
  }, []);

  const onFilterChange = useCallback(
    <TSort, TFilter>(key: keyof TFilter, setState: Dispatch<SetStateAction<TableFilter<TSort, TFilter>>>) =>
      (e: ChangeEvent<{}>, value: any) => {
        setState((prev) => ({ ...prev, filters: { ...prev.filters, [key]: value } }));
      },
    [],
  );

  const handleColumnSort = useCallback(
    <TSort, TFilter>(setState: Dispatch<SetStateAction<TableFilter<TSort, TFilter>>>, column: keyof TSort) =>
      () => {
        setState((prev) => {
          if (column === prev.sortBy) {
            return {
              ...prev,
              sortOrder: { ...prev.sortOrder, [column]: prev.sortOrder[column] === 'asc' ? 'desc' : 'asc' },
            };
          }

          return { ...prev, sortBy: column, sortOrder: { ...prev.sortOrder, [column]: 'asc' } };
        });
      },
    [],
  );

  const channels = useMemo(() => {
    if (!state || !state.channels) {
      return [];
    }

    return (
      Object.values(state.channels)
        // We only want to display the webchat channel info for now as, that is all that is currently supported
        .filter((item) => item.channel === ChannelType.WebChat)
        .map((item) => ({
          ...item,
        }))
    );
  }, [state]);

  const queues = useMemo(() => {
    if (!state || !state.queues) {
      return [];
    }

    return Object.values(state.queues).map((item) => ({
      ...item,
    }));
  }, [state]);

  const agents = useMemo(() => {
    if (!state || !state.agents) {
      return [];
    }

    return Object.values(state.agents).map((item) => ({
      ...item,
    }));
  }, [state]);

  const metricsPerQueueColumns: ColumnData[] = useMemo(() => {
    return [
      {
        wrapText: false,
        label: 'Queue ID',
        dataKey: 'queue',
        align: 'left',
      },
      {
        wrapText: false,
        label: 'Queue Name',
        dataKey: 'title',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.title,
        sortActive: metricsPerQueueFilter.sortBy === 'title',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'title'),
      },
      {
        wrapText: false,
        label: 'Channel',
        dataKey: 'channel',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.channel,
        sortActive: metricsPerQueueFilter.sortBy === 'channel',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'channel'),
      },
      {
        wrapText: true,
        label: 'In Queue',
        dataKey: 'conversationsInQueue',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.conversationsInQueue,
        sortActive: metricsPerQueueFilter.sortBy === 'conversationsInQueue',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'conversationsInQueue'),
      },
      {
        wrapText: true,
        label: 'Abandoned',
        dataKey: 'conversationsAbandonedInQueue',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.conversationsAbandonedInQueue,
        sortActive: metricsPerQueueFilter.sortBy === 'conversationsAbandonedInQueue',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'conversationsAbandonedInQueue'),
      },
      {
        wrapText: true,
        label: 'AVG Wait Time',
        dataKey: 'averageWaitTime',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.averageWaitTime,
        sortActive: metricsPerQueueFilter.sortBy === 'averageWaitTime',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'averageWaitTime'),
      },
      {
        wrapText: true,
        label: 'AVG Handling Time',
        dataKey: 'averageHandlingTime',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.averageHandlingTime,
        sortActive: metricsPerQueueFilter.sortBy === 'averageHandlingTime',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'averageHandlingTime'),
      },
      {
        wrapText: false,
        label: 'AVG First Response Time',
        dataKey: 'averageFirstResponseTime',
        align: 'left',
        sortDirection: metricsPerQueueFilter.sortOrder.averageFirstResponseTime,
        sortActive: metricsPerQueueFilter.sortBy === 'averageFirstResponseTime',
        onColumnSort: handleColumnSort(setMetricsPerQueueFilter, 'averageFirstResponseTime'),
      },
    ];
  }, [metricsPerQueueFilter.sortBy, metricsPerQueueFilter.sortOrder]);

  const metricsPerQueueRows = useMemo(() => {
    if (state === undefined) {
      return [];
    }

    let channelFilters: string[] | undefined = undefined;
    for (let item of metricsPerQueueFilter.filters.channels) {
      const prevArray: string[] = channelFilters != undefined ? channelFilters : [];
      channelFilters = [...new Set([...prevArray, item.channel])];
    }

    let queueFilters: string[] | undefined = undefined;
    for (let item of metricsPerQueueFilter.filters.queues) {
      const prevArray: string[] = queueFilters != undefined ? queueFilters : [];
      queueFilters = [...new Set([...prevArray, item.queue])];
    }

    return Object.values(state.queues)
      .filter((item) => {
        return (
          (!channelFilters || channelFilters.includes(item.channel)) &&
          (!queueFilters || queueFilters.includes(item.queue))
        );
      })
      .map((item) => ({
        ...item,
        channel: channelIdToName[item.channel] || item.channel,
        averageWaitTime: getDurationFromSeconds(item.averageWaitTimeSec),
        averageHandlingTime: getDurationFromSeconds(item.averageHandlingTimeSec),
        averageFirstResponseTime: getDurationFromSeconds(item.averageFirstResponseTimeSec),
      }))
      .sort(sortObject(metricsPerQueueFilter.sortBy, metricsPerQueueFilter.sortOrder[metricsPerQueueFilter.sortBy]));
  }, [state, metricsPerQueueFilter]);

  const metricsPerAgentColumns: ColumnData[] = useMemo(() => {
    return [
      {
        wrapText: false,
        label: 'Username',
        dataKey: 'username',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.username,
        sortActive: metricsPerAgentFilter.sortBy === 'username',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'username'),
      },
      {
        wrapText: false,
        label: 'Name',
        dataKey: 'name',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.name,
        sortActive: metricsPerAgentFilter.sortBy === 'name',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'name'),
      },
      {
        wrapText: false,
        label: 'State',
        dataKey: 'state',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.state,
        sortActive: metricsPerAgentFilter.sortBy === 'state',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'state'),
      },
      {
        wrapText: false,
        label: 'Time in State',
        dataKey: 'timeInState',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.timeInState,
        sortActive: metricsPerAgentFilter.sortBy === 'timeInState',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'timeInState'),
      },
      {
        wrapText: false,
        label: 'Requested State',
        dataKey: 'requestedState',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.requestedState,
        sortActive: metricsPerAgentFilter.sortBy === 'requestedState',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'requestedState'),
      },
      {
        wrapText: false,
        label: 'Requested State Change',
        dataKey: 'requestedStateChange',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.requestedStateChange,
        sortActive: metricsPerAgentFilter.sortBy === 'requestedStateChange',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'requestedStateChange'),
      },
      {
        wrapText: false,
        label: 'Max Concurrency',
        dataKey: 'maxConcurrency',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.maxConcurrency,
        sortActive: metricsPerAgentFilter.sortBy === 'maxConcurrency',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'maxConcurrency'),
      },
      {
        wrapText: false,
        label: 'Desired Concurrency',
        dataKey: 'desiredConcurrency',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.desiredConcurrency,
        sortActive: metricsPerAgentFilter.sortBy === 'desiredConcurrency',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'desiredConcurrency'),
      },
      {
        wrapText: false,
        label: 'Current Concurrency',
        dataKey: 'currentConcurrency',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.currentConcurrency,
        sortActive: metricsPerAgentFilter.sortBy === 'currentConcurrency',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'currentConcurrency'),
      },
      {
        wrapText: false,
        label: 'Personal Queue Count',
        dataKey: 'transferQueueCount',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.transferQueueCount,
        sortActive: metricsPerAgentFilter.sortBy === 'transferQueueCount',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'transferQueueCount'),
      },
      {
        wrapText: false,
        label: 'Abandoned',
        dataKey: 'abandonedConversations',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.abandonedConversations,
        sortActive: metricsPerAgentFilter.sortBy === 'abandonedConversations',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'abandonedConversations'),
      },
      {
        wrapText: false,
        label: 'AVG Handling Time',
        dataKey: 'averageHandlingTime',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.averageHandlingTime,
        sortActive: metricsPerAgentFilter.sortBy === 'averageHandlingTime',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'averageHandlingTime'),
      },
      {
        wrapText: false,
        label: 'AVG First Response Time',
        dataKey: 'averageFirstResponseTime',
        align: 'left',
        sortDirection: metricsPerAgentFilter.sortOrder.averageFirstResponseTime,
        sortActive: metricsPerAgentFilter.sortBy === 'averageFirstResponseTime',
        onColumnSort: handleColumnSort(setMetricsPerAgentFilter, 'averageFirstResponseTime'),
      },
    ];
  }, [metricsPerAgentFilter.sortBy, metricsPerAgentFilter.sortOrder]);

  const metricsPerAgentRows = useMemo(() => {
    if (state === undefined) {
      return [];
    }

    let channelFilters: string[] | undefined = undefined;
    for (let item of metricsPerAgentFilter.filters.channels) {
      const prevArray: string[] = channelFilters != undefined ? channelFilters : [];
      channelFilters = [...new Set([...prevArray, item.channel])];
    }

    let queueFilters: string[] | undefined = undefined;
    for (let item of metricsPerAgentFilter.filters.queues) {
      const prevArray: string[] = queueFilters != undefined ? queueFilters : [];
      queueFilters = [...new Set([...prevArray, item.queue])];
    }

    return Object.values(state.agents)
      .filter((item) => {
        return (
          (!channelFilters || channelFilters.some((cf) => item.channels.includes(cf))) &&
          (!queueFilters || queueFilters.some((qf) => item.queues.includes(qf)))
        );
      })
      .map((item) => {
        const timeInStateSec = DateTime.fromJSDate(new Date()).diff(DateTime.fromISO(item.stateTimestamp), 'seconds');

        return {
          ...item,
          timeInState: getDurationFromSeconds(timeInStateSec.seconds),
          requestedStateChange: item.requestedStateTimestamp
            ? DateTime.fromISO(item.requestedStateTimestamp).toRelative()
            : null,
          averageHandlingTime: getDurationFromSeconds(item.averageHandlingTimeSec),
          averageFirstResponseTime: getDurationFromSeconds(item.averageFirstResponseTimeSec),
        };
      })
      .sort(sortObject(metricsPerAgentFilter.sortBy, metricsPerAgentFilter.sortOrder[metricsPerAgentFilter.sortBy]));
  }, [state, metricsPerAgentFilter]);

  const metricsPerConversationColumns: ColumnData[] = useMemo(() => {
    return [
      {
        wrapText: false,
        label: 'Contact ID',
        dataKey: 'conversationUUID',
        align: 'left',
      },
      {
        wrapText: false,
        label: 'Assigned Queue',
        dataKey: 'queueTitle',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.queueTitle,
        sortActive: metricsPerConversationFilter.sortBy === 'queueTitle',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'queueTitle'),
      },
      {
        wrapText: false,
        label: 'Status',
        dataKey: 'status',
        align: 'left',
      },
      {
        wrapText: false,
        label: 'Created',
        dataKey: 'createdTimestamp',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.createdTimestamp,
        sortActive: metricsPerConversationFilter.sortBy === 'createdTimestamp',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'createdTimestamp'),
      },
      {
        wrapText: false,
        label: 'Time In Queue',
        dataKey: 'waitTime',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.waitTime,
        sortActive: metricsPerConversationFilter.sortBy === 'waitTime',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'waitTime'),
      },
      {
        wrapText: false,
        label: 'Dequeued',
        dataKey: 'dequeuedTimestamp',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.dequeuedTimestamp,
        sortActive: metricsPerConversationFilter.sortBy === 'dequeuedTimestamp',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'dequeuedTimestamp'),
      },
      {
        wrapText: false,
        label: 'Handling Time',
        dataKey: 'handlingTime',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.handlingTime,
        sortActive: metricsPerConversationFilter.sortBy === 'handlingTime',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'handlingTime'),
      },
      {
        wrapText: false,
        label: 'First Response Time',
        dataKey: 'firstResponseTime',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.firstResponseTime,
        sortActive: metricsPerConversationFilter.sortBy === 'firstResponseTime',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'firstResponseTime'),
      },
      {
        wrapText: false,
        label: 'Customer Name',
        dataKey: 'customerName',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.customerName,
        sortActive: metricsPerConversationFilter.sortBy === 'customerName',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'customerName'),
      },
      {
        wrapText: false,
        label: 'Agent Name',
        dataKey: 'agentName',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.agentName,
        sortActive: metricsPerConversationFilter.sortBy === 'agentName',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'agentName'),
      },
      {
        wrapText: false,
        label: 'Agent Username',
        dataKey: 'agentUsername',
        align: 'left',
        sortDirection: metricsPerConversationFilter.sortOrder.agentUsername,
        sortActive: metricsPerConversationFilter.sortBy === 'agentUsername',
        onColumnSort: handleColumnSort(setMetricsPerConversationFilter, 'agentUsername'),
      },
    ];
  }, [metricsPerConversationFilter.sortBy, metricsPerConversationFilter.sortOrder]);

  const metricsPerConversationRows = useMemo(() => {
    if (state === undefined) {
      return [];
    }

    let channelFilters: string[] | undefined = undefined;
    for (let item of metricsPerConversationFilter.filters.channels) {
      const prevArray: string[] = channelFilters != undefined ? channelFilters : [];
      channelFilters = [...new Set([...prevArray, item.channel])];
    }

    let queueFilters: string[] | undefined = undefined;
    for (let item of metricsPerConversationFilter.filters.queues) {
      const prevArray: string[] = queueFilters != undefined ? queueFilters : [];
      queueFilters = [...new Set([...prevArray, item.queue])];
    }

    let agentFilters: string[] | undefined = undefined;
    for (let item of metricsPerConversationFilter.filters.agents) {
      const prevArray: string[] = agentFilters != undefined ? agentFilters : [];
      agentFilters = [...new Set([...prevArray, item.username])];
    }

    return Object.values(state.conversations)
      .filter((item) => {
        let agentFilterCondition = !agentFilters;
        if (agentFilters && item.agentUsername != null) {
          agentFilterCondition = agentFilters.includes(item.agentUsername);
        } else if (agentFilters && item.agentQueue != null) {
          agentFilterCondition = agentFilters.includes(item.agentQueue);
        }

        return (
          (!channelFilters || channelFilters.includes(item.channelType)) &&
          (!queueFilters || queueFilters.includes(item.queue)) &&
          agentFilterCondition
        );
      })
      .map((item) => {
        return {
          ...item,
          createdTimestamp: DateTime.fromISO(item.createdTimestamp).toFormat('FFF'),
          dequeuedTimestamp: item.dequeuedTimestamp ? DateTime.fromISO(item.dequeuedTimestamp).toFormat('FFF') : null,
          waitTime: getDurationFromSeconds(item.waitTimeSec),
          handlingTime: item.handlingTimeSec ? getDurationFromSeconds(item.handlingTimeSec) : null,
          firstResponseTime: item.firstResponseTimeSec ? getDurationFromSeconds(item.firstResponseTimeSec) : null,
          status: conversationStatusToName[item.status] || item.status,
        };
      })
      .sort(
        sortObject(
          metricsPerConversationFilter.sortBy,
          metricsPerConversationFilter.sortOrder[metricsPerConversationFilter.sortBy],
        ),
      );
  }, [state, metricsPerConversationFilter]);

  const channelDisplay = channels.map((channel, index) => (
    <Grid key={index} item xs={12} md={4}>
      <ChannelInfo channel={channel} />
    </Grid>
  ));

  return (
    <AsyncLoader isLoading={initialLoad}>
      {!socketConnected && <EmptyState type='error' text='Not Connected' subText='Unable to get messaging stats' />}

      {socketConnected && state && (
        <>
          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Metrics Per Channels
            </Typography>

            {channelDisplay.length === 0 && (
              <Typography variant='body1' align='left'>
                <i>No channel metrics to display.</i>
              </Typography>
            )}

            {channelDisplay.length > 0 && (
              <Grid container spacing={2}>
                {channelDisplay}
              </Grid>
            )}
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Metrics Per Queue
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('channels', setMetricsPerQueueFilter)}
                  value={metricsPerQueueFilter.filters.channels}
                  options={channels}
                  filterSelectedOptions
                  getOptionLabel={(option) => channelIdToName[option.channel] || option.channel}
                  isOptionEqualToValue={(option, value) =>
                    channelIdToName[option.channel] === channelIdToName[value.channel]
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={channelIdToName[option.channel] || option.channel}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='channels' name='channels' label='Channels' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('queues', setMetricsPerQueueFilter)}
                  value={metricsPerQueueFilter.filters.queues}
                  options={queues}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.title || ''}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={option.title}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='queues' name='queues' label='Queues' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <BasicTable
                  columns={metricsPerQueueColumns}
                  rows={metricsPerQueueRows}
                  noResultsMessage={
                    metricsPerQueueFilter.filters.channels.length > 0
                      ? 'No queues matching filter criteria'
                      : 'No queue data available'
                  }
                />
              </Grid>
            </Grid>
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Metrics Per Agent
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('channels', setMetricsPerAgentFilter)}
                  value={metricsPerAgentFilter.filters.channels}
                  options={channels}
                  filterSelectedOptions
                  getOptionLabel={(option) => channelIdToName[option.channel] || option.channel}
                  isOptionEqualToValue={(option, value) =>
                    channelIdToName[option.channel] === channelIdToName[value.channel]
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={channelIdToName[option.channel] || option.channel}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='channels' name='channels' label='Channels' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('queues', setMetricsPerAgentFilter)}
                  value={metricsPerAgentFilter.filters.queues}
                  options={queues}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.title || ''}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={option.title}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='queues' name='queues' label='Queues' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <BasicTable
                  columns={metricsPerAgentColumns}
                  rows={metricsPerAgentRows}
                  noResultsMessage={
                    metricsPerAgentFilter.filters.channels.length > 0 || metricsPerAgentFilter.filters.queues.length > 0
                      ? 'No agents matching filter criteria'
                      : 'No agent data available'
                  }
                />
              </Grid>
            </Grid>
          </ContentSpacer>

          <ContentSpacer spacing={3}>
            <Typography fontWeight={500} marginBottom={2} variant='h5' component='h1'>
              Metrics Per Conversation
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('channels', setMetricsPerConversationFilter)}
                  value={metricsPerConversationFilter.filters.channels}
                  options={channels}
                  filterSelectedOptions
                  getOptionLabel={(option) => channelIdToName[option.channel] || option.channel}
                  isOptionEqualToValue={(option, value) =>
                    channelIdToName[option.channel] === channelIdToName[value.channel]
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={channelIdToName[option.channel] || option.channel}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='channels' name='channels' label='Channels' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('queues', setMetricsPerConversationFilter)}
                  value={metricsPerConversationFilter.filters.queues}
                  options={queues}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.title || ''}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={option.title}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='queues' name='queues' label='Queues' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  onChange={onFilterChange('agents', setMetricsPerConversationFilter)}
                  value={metricsPerConversationFilter.filters.agents}
                  options={agents}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderOption={(props, option) => (
                    <li {...props} key={option.username}>
                      <div>
                        <Typography variant='body2' color='textPrimary' component='p'>
                          {option.name}
                        </Typography>

                        <Typography variant='caption' color='textSecondary' component='p'>
                          {option.username}
                        </Typography>
                      </div>
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        deleteIcon={<CancelIcon />}
                        label={option.name}
                        {...getTagProps({ index })}
                        variant='filled'
                        color='primary'
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} id='agents' name='agents' label='Agents' variant='outlined' />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <BasicTable
                  columns={metricsPerConversationColumns}
                  rows={metricsPerConversationRows}
                  noResultsMessage={
                    metricsPerConversationFilter.filters.channels.length > 0 ||
                    metricsPerConversationFilter.filters.queues.length > 0
                      ? 'No conversations matching filter criteria'
                      : 'No conversations data available'
                  }
                />
              </Grid>
            </Grid>
          </ContentSpacer>
        </>
      )}
    </AsyncLoader>
  );
};

export default AsyncDashboard;
