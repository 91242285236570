import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppConfiguration } from '~providers/AppConfigurationProvider';

interface PageTitleProviderProps {
  children: ReactNode;
}

interface UpdateTitleProps {
  pageName?: string;
  notificationCount?: number;
}

type SetPageTitlePropsFn = (obj: UpdateTitleProps) => void;

const PageTitleContext = createContext<SetPageTitlePropsFn | undefined>(undefined);

export const useSetPageTitleProps = (): SetPageTitlePropsFn => {
  return useContext(PageTitleContext) as SetPageTitlePropsFn;
};

const PageTitleProvider = ({ children }: PageTitleProviderProps) => {
  const appConfig = useAppConfiguration();
  const { pathname } = useLocation();
  const [pageName, setPageName] = useState<string>('');
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [currentPathname, setCurrentPathname] = useState<string>(pathname);

  useEffect(() => {
    let newTitle = appConfig.web.name;

    // If path hasn't change update the existing
    if (currentPathname === pathname) {
      if (pageName !== '') {
        newTitle = `${appConfig.web.name} | ${pageName}`;
      }

      if (notificationCount > 99) {
        newTitle = `(+99) ${newTitle}`;
      } else if (notificationCount > 0 && notificationCount <= 99) {
        newTitle = `(${notificationCount}) ${newTitle}`;
      }
    } else {
      // If route has changed we want to strip the existing pageName portion from the document title and wait for
      // the next pageName update. This is because we dont want an incorrect page name floating around if someone has just
      // forgotten to set a page name for the page that has been navigated too.
      setCurrentPathname(pathname);
      // This conditional check is to catch the racey behaviour between setPageTitleProps and this effect running with old data
      setPageName((prev) => (prev !== pageName ? prev : ''));
      return;
    }

    document.title = newTitle;
  }, [pageName, notificationCount, pathname]);

  const setPageTitleProps = ({ pageName, notificationCount }: UpdateTitleProps) => {
    if (pageName !== undefined) {
      setPageName(pageName);
    }

    if (notificationCount !== undefined) {
      setNotificationCount(notificationCount);
    }
  };

  return <PageTitleContext.Provider value={setPageTitleProps}>{children}</PageTitleContext.Provider>;
};

export default PageTitleProvider;
