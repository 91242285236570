import axios, { CancelTokenSource } from 'axios';

import { CreateDiallerGroup } from '~pages/CampaignManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { DiallerGroupResponse, DiallerGroupsResponseDecoder } from './domain';

export const getDiallerGroupsByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroupResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getDiallerGroups = async (
  search: string,
  archived: boolean | undefined,
  isActive: boolean | undefined,
  accessFilterId: number | undefined,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroupResponse | undefined> => {
  const path = `/api/group/`;
  const params = {
    search: search.toLowerCase() || undefined,
    archived: archived !== undefined ? archived.toString() : undefined,
    is_active: isActive !== undefined ? isActive.toString() : undefined,
    access_filter_id: accessFilterId,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupsResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createDiallerGroup = async (data: CreateDiallerGroup): Promise<void> => {
  const path = '/api/group/';

  let body: any = {
    name: data.name,
    description: data.description,
    dialler_type: data.diallerType,
    campaign_type: data.campaignType,
    routing_profiles: data.routingProfiles,
    enable_manual_outbound: data.enableManualOutbound,
    manual_outbound_require_details: data.manualOutboundRequireDetails,
    enable_manual_outbound_when_offline: data.enableManualOutboundWhenOffline,
    system_disposed_inbound_calls: data.systemDisposedInboundCalls,
    voicemail_message_arn: data.voicemailMessageARN,
    access_filter_ids: data.accessFilterIds,
  };

  if (data.previewSettings) {
    body = {
      ...body,
      preview_settings: {
        enable_endpoint_selection: data.previewSettings.enableEndpointSelection,
        push_preview_seconds: data.previewSettings.pushPreviewSeconds,
        ring_out_seconds: data.previewSettings.ringOutSeconds,
      },
    };
  }

  if (data.predictiveSettings) {
    body = {
      ...body,
      predictive_settings: {
        ring_time_seconds: data.predictiveSettings.ringTimeSeconds,
        hold_drop_seconds: data.predictiveSettings.holdDropSeconds,
        max_lines_per_agent: data.predictiveSettings.maxLinesPerAgent,
        lookahead_time_seconds: data.predictiveSettings.lookaheadTimeSeconds,
        num_reserved_agents: data.predictiveSettings.numReservedAgents,
        outbound_queue: data.predictiveSettings.outboundQueue,
      },
    };
  }

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
