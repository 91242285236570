import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ControlledNumberField from '~components/Form/ControlledNumberField';
import OberonDialog from '~components/OberonDialog';
import { EndpointSequence } from '~pages/CampaignManagement/domain';

interface EditEndpointModalProps {
  submitting: boolean;
  editing: EndpointSequence | null;
  onClose: () => void;
  onSubmit: (seq: EndpointSequence) => void;
}

interface Form {
  priority: number;
  start: number;
}

const EditEndpointModal = ({ submitting, editing, onClose, onSubmit }: EditEndpointModalProps) => {
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    defaultValues: {
      priority: 5,
      start: 0,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (editing == null) {
      reset();
    } else {
      setValue('priority', editing.priority);
      setValue('start', editing.start);
    }
  }, [editing]);

  const submitCallback = useCallback(
    (data: Form) => {
      if (editing !== null) {
        onSubmit({ endpointType: editing.endpointType, ...data });
      }
    },
    [editing, onSubmit],
  );

  return (
    <OberonDialog
      open={Boolean(editing)}
      onSubmit={handleSubmit(submitCallback)}
      onClose={onClose}
      title='Edit Endpoint Sequence'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledNumberField
              name='priority'
              control={control}
              rules={{
                required: 'Priority is required.',
              }}
              label='Priority'
              disabled={submitting}
              required={true}
              error={Boolean(errors.priority)}
              helperText={errors.priority?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledNumberField
              name='start'
              control={control}
              rules={{
                required: 'Wait until attempt is required.',
              }}
              label='Wait until attempt'
              disabled={submitting}
              required={true}
              error={Boolean(errors.start)}
              helperText={errors.start?.message}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disableElevation disabled={submitting} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={submitting}
            loading={submitting}>
            Save
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditEndpointModal;
