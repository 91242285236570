import axios, { CancelTokenSource } from 'axios';

import {
  CampaignFilterTypeValues,
  CampaignFiltersResponse,
  CampaignFiltersResponseDecoder,
  CreateCampaignFilter,
  UpdateCampaignFilter,
} from '~pages/CampaignManagement/CampaignDetails/useCampaignFilters/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

export const getCampaignFiltersByNextUrl = async (
  nextUrl: string,
  cancelToken?: CancelTokenSource,
): Promise<CampaignFiltersResponse | undefined> => {
  const path = nextUrl;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = CampaignFiltersResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getCampaignFilters = async (
  campaignId: number | undefined,
  search: string | undefined,
  filter: CampaignFilterTypeValues | undefined,
  cancelToken?: CancelTokenSource,
): Promise<CampaignFiltersResponse | undefined> => {
  const path = `/api/campaign/${campaignId}/filters`;
  const params = {
    search: search ? search.toLowerCase() : undefined,
    filter: filter,
  };
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: params,
      paramsSerializer: {
        indexes: null,
      },
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = CampaignFiltersResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export async function createCampaignFilter(campaignId: number, data: CreateCampaignFilter) {
  const path = `/api/campaign/${campaignId}/filters`;
  const body = {
    name: data.name,
    field: data.field,
    match: data.match,
    type: data.type,
    dialling_hour_profile: data.diallingHourProfile,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
}

export async function updateCampaignFilter(campaignId: number, filterId: number, data: UpdateCampaignFilter) {
  const path = `/api/campaign/${campaignId}/filters/${filterId}`;
  const body = {
    name: data.name,
    field: data.field,
    match: data.match,
    type: data.type,
    dialling_hour_profile: data.diallingHourProfile,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
}

export async function removeCampaignFilter(campaignId: number, filterId: number) {
  const path = `/api/campaign/${campaignId}/filters/${filterId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
}
