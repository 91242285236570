import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { useEffect, useRef, useState } from 'react';

import SectionCard from '~components/SectionCard';
import { APIError } from '~services/Errors';

import { getChatMessagesFromUrl } from '../../api';
import { Message, MessageType } from '../../domain';
import MessageEventItem from './MessageEventItem';
import MessageItem from './MessageItem';

interface ContactChatTranscriptProps {
  transcriptLocation: string;
  canView: boolean;
}

const ContactChatTranscript = ({ transcriptLocation, canView }: ContactChatTranscriptProps) => {
  // We want to skip fast, do none of the below logic, and not display anything
  if (!canView) return null;

  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (transcriptLocation) {
        try {
          const messages = await getChatMessagesFromUrl(transcriptLocation);
          setMessages(messages);
        } catch (e) {
          if (e instanceof APIError && e.status === 404) {
            setError('Unable to get chat transcription as it does not exist.');
          } else {
            setError('An unexpected error occured when fetching chat transcription.');
          }
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const messageItems = messages.map((item: Message, index: number) =>
    item.type !== MessageType.Event ? (
      <MessageItem key={index} role={item.role} name={item.name} date={item.date}>
        {item.content}
      </MessageItem>
    ) : (
      <MessageEventItem key={index} contentType={item.contentType} name={item.name} />
    ),
  );

  useEffect(() => {
    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTop = contentContainerRef.current.scrollHeight;
    }
  }, [messageItems]);

  return (
    <SectionCard title='Chat Transcript'>
      {!loading && !error && (
        <Box
          sx={{ flex: 1, height: '100%', minHeight: 200, maxHeight: 400, overflow: 'auto', padding: '0 16px' }}
          ref={contentContainerRef}>
          {messageItems}
        </Box>
      )}

      {!loading && error && (
        <Typography variant='body1' component='p'>
          {error}
        </Typography>
      )}
    </SectionCard>
  );
};

export default ContactChatTranscript;
