import { findIndexByProperty } from '~utils/Functions';

type JanusCallbackFn = (msg: JanusMessage) => void;

interface JanusMessageBody {
  request: string;
  name?: string;
  videocodec?: string;
}

/*
{
   "janus": "event",
   "session_id": 6545736739550511,
   "transaction": "k1sAuhTqsNhk",
   "sender": 6629843288911152,
   "plugindata": {
      "plugin": "janus.plugin.recordplay",
      "data": {
         "recordplay": "event",
         "result": {
            "status": "recording",
            "id": 1006304919552918
         }
      }
   },
   "jsep": {
      "type": "answer",
      "sdp": "v=0\r\no=- 1615616628213029 1 IN IP4 172.17.0.2\r\ns=Recording 1006304919552918\r\nt=0 0\r\na=group:BUNDLE 0\r\na=msid-semantic: WMS janus\r\nm=video 9 UDP/TLS/RTP/SAVPF 98 99\r\nc=IN IP4 172.17.0.2\r\na=recvonly\r\na=mid:0\r\na=rtcp-mux\r\na=ice-ufrag:DUzZ\r\na=ice-pwd:CrWCi8LG+sJfRJmeymRySQ\r\na=ice-options:trickle\r\na=fingerprint:sha-256 79:46:1C:DD:15:68:A7:75:CC:4D:78:78:0B:E6:20:B7:A1:E4:BE:9D:11:D9:78:07:56:42:77:D7:0D:BB:9C:7F\r\na=setup:active\r\na=rtpmap:98 VP9/90000\r\na=rtcp-fb:98 ccm fir\r\na=rtcp-fb:98 nack\r\na=rtcp-fb:98 nack pli\r\na=rtcp-fb:98 goog-remb\r\na=rtcp-fb:98 transport-cc\r\na=extmap:3 urn:3gpp:video-orientation\r\na=extmap:4 http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01\r\na=extmap:9 urn:ietf:params:rtp-hdrext:sdes:mid\r\na=extmap:10 urn:ietf:params:rtp-hdrext:sdes:rtp-stream-id\r\na=extmap:11 urn:ietf:params:rtp-hdrext:sdes:repaired-rtp-stream-id\r\na=fmtp:98 profile-id=0\r\na=rtpmap:99 rtx/90000\r\na=fmtp:99 apt=98\r\na=msid:janus janusv0\r\na=ssrc:1853536236 cname:janus\r\na=ssrc:1853536236 msid:janus janusv0\r\na=ssrc:1853536236 mslabel:janus\r\na=ssrc:1853536236 label:janusv0\r\na=ssrc:2795312626 cname:janus\r\na=ssrc:2795312626 msid:janus janusv0\r\na=ssrc:2795312626 mslabel:janus\r\na=ssrc:2795312626 label:janusv0\r\na=candidate:1 1 udp 2015363327 172.17.0.2 45121 typ host\r\na=end-of-candidates\r\n"
   }
}
 */
interface JanusMessageData {
  id: number;
}

interface JanusMessagePluginDataResult {
  status: string;
  id: number;
}

interface JanusMessagePluginDataPayload {
  result: JanusMessagePluginDataResult;
}

interface JanusMessagePluginData {
  plugin: string;
  data: JanusMessagePluginDataPayload;
}

interface JanusMessage {
  janus: string;
  plugin?: string;
  body?: JanusMessageBody;
  handle_id?: number;
  candidate?: RTCIceCandidate;
  jsep?: RTCSessionDescriptionInit;
  transaction?: string;
  session_id?: number;
  data?: JanusMessageData;
  plugindata?: JanusMessagePluginData;
  // Not sure on structure
  error?: any;
}

interface ScreenInfo {
  // ID reference to associated connection (sid)
  sessionId: number;
  // ID of the screen shared media (second property in the media stream track label after split on ':')
  screenStreamId: string;
  // ID of the janus record play plugin associated with this screen share
  handleId: number;
  // The Track associated to the rtc stream
  track: MediaStreamTrack;
  timestamp: Date;
  // `${username}:${sessionId}:${screenStreamId}`
  // e.g. csesta:2605170409046623:459085175
  recordingName: string;
  // the recording id, which is, vitally, the filename we are streaming into
  recordingId: number;
}

interface ScreenShareManagerCallbacksOptional {
  onShareSuccess?: (screenInfoItem: ScreenInfo) => void;
  onShareFailure?: (errorMessage: string) => void;
  onStopShareSuccess?: (sessionId: number) => void;
  onDisplayMediaRejection?: (errorMessage: string) => void;
  onSelectedScreenError?: (errorMessage: string) => void;
  onConnectionError?: (errorMessage: string) => void;
  onConnectionLost?: (errorMessage: string) => void;
}

interface ScreenShareManagerCallbacks {
  onShareSuccess: (screenInfoItem: ScreenInfo) => void;
  onShareFailure: (errorMessage: string) => void;
  onStopShareSuccess: (sessionId: number) => void;
  onDisplayMediaRejection: (errorMessage: string) => void;
  onSelectedScreenError: (errorMessage: string) => void;
  onConnectionError: (errorMessage: string) => void;
  onConnectionLost: (errorMessage: string) => void;
}

const randomString = (len: number): string => {
  const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < len; i++) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }

  return randomString;
};

const ScreenInfoModel = (
  sessionId: number,
  screenStreamId: string,
  handleId: number,
  track: MediaStreamTrack,
  recordingName: string,
  recordingId: number,
): ScreenInfo => {
  return {
    sessionId: sessionId,
    screenStreamId: screenStreamId,
    handleId: handleId,
    track: track,
    recordingName: recordingName,
    recordingId: recordingId,
    timestamp: new Date(),
  };
};

class JanusConnection {
  private ws: WebSocket | undefined = undefined;
  private transactions: { [key: string]: JanusCallbackFn } = {};
  private listeners: JanusCallbackFn[] = [];
  private keepAliveIntervalHandle: number | undefined = undefined;

  public sid: number = 0;

  constructor(address: string) {
    const ws = new WebSocket(address, 'janus-protocol');

    ws.onmessage = (e) => {
      const msg = JSON.parse(e.data);
      const tid = msg.transaction;

      if (msg.janus === 'ack') {
        // ack from server
      } else if (tid in this.transactions) {
        // response to a transaction
        this.transactions[tid](msg);
      } else if (msg.janus === 'event') {
        // other event
        for (const k in this.listeners) {
          this.listeners[k](msg);
        }
      } else if (msg.janus === 'webrtcup') {
        /**
         * {
         *   janus: "webrtcup"
         *   sender: 1048425022738133
         *   session_id: 440874700038304
         * }
         */
        // TODO: event handler
      } else if (msg.janus === 'media') {
        /**
         * {
         *   janus: "media"
         *   receiving: true
         *   sender: 1048425022738133
         *   session_id: 440874700038304
         *   type: "video"
         * }
         */
        // TODO: catch reciving true/ false
      }
    };

    ws.onclose = (e: CloseEvent) => {
      window.clearInterval(this.keepAliveIntervalHandle);
      this.ws = undefined;
      this.onClose(e);
    };

    ws.onerror = (e: Event) => {
      this.onError(e);
    };

    ws.onopen = async (e) => {
      let msg: JanusMessage;

      try {
        msg = await this.send({
          janus: 'create',
        });
      } catch (e) {
        console.error('Unable to create janus session. ', e);
        return;
      }

      if (msg.data !== undefined) {
        const sid = msg.data.id;
        this.sid = sid;

        this.keepAliveIntervalHandle = window.setInterval(() => {
          this.send({
            janus: 'keepalive',
            session_id: sid,
          });
        }, 30 * 1000);

        this.onOpen(e);
      }
    };

    this.ws = ws;
  }

  public endSession() {
    if (this.ws !== undefined) {
      this.ws.close(1000);
    }
  }

  public onOpen(e: Event) {
    // To be overriden
  }

  public onClose(e: CloseEvent) {
    // To be overriden
  }

  public onError(e: Event) {
    // To be overriden
  }

  public addListener(callback: JanusCallbackFn) {
    this.listeners.push(callback);
  }

  // send send the server a message and awaits a response
  public send(msg: JanusMessage): Promise<JanusMessage> {
    return new Promise((resolve, reject) => {
      if (this.ws) {
        const tid = randomString(12);
        msg.transaction = tid;

        if (this.sid > 0) {
          msg.session_id = this.sid;
        }

        this.transactions[tid] = (msg) => {
          if ('error' in msg) {
            reject(msg);
          } else {
            resolve(msg);
          }

          delete this.transactions[tid];
        };

        this.ws.send(JSON.stringify(msg));
      } else {
        reject();
      }
    });
  }
}

// 'ws://localhost:8188/'
export class ScreenShareManager {
  private address: string;
  private username: string;
  // sid as key
  private connections: { [key: number]: JanusConnection } = {};
  private sharedScreenInfoList: ScreenInfo[] = [];
  // Empty defaults so we dont have to perform undefined checks
  private callbacks: ScreenShareManagerCallbacks = {
    onShareSuccess: (screenInfoItem: ScreenInfo) => {},
    onShareFailure: (errorMessage: string) => {},
    onStopShareSuccess: (sessionId: number) => {},
    onDisplayMediaRejection: (errorMessage: string) => {},
    onSelectedScreenError: (errorMessage: string) => {},
    onConnectionError: (errorMessage: string) => {},
    onConnectionLost: (errorMessage: string) => {},
  };

  constructor(address: string, username: string, callbacks: ScreenShareManagerCallbacksOptional) {
    this.address = address;
    this.username = username;

    // Assign optional functions else default to empty default values
    this.callbacks.onShareSuccess = callbacks.onShareSuccess ?? this.callbacks.onShareSuccess;
    this.callbacks.onShareFailure = callbacks.onShareFailure ?? this.callbacks.onShareFailure;
    this.callbacks.onStopShareSuccess = callbacks.onStopShareSuccess ?? this.callbacks.onStopShareSuccess;
    this.callbacks.onDisplayMediaRejection =
      callbacks.onDisplayMediaRejection ?? this.callbacks.onDisplayMediaRejection;
    this.callbacks.onSelectedScreenError = callbacks.onSelectedScreenError ?? this.callbacks.onSelectedScreenError;
    this.callbacks.onConnectionError = callbacks.onConnectionError ?? this.callbacks.onConnectionError;
    this.callbacks.onConnectionLost = callbacks.onConnectionLost ?? this.callbacks.onConnectionLost;

    console.log('ScreenShare Manager Started');
  }

  public addScreen() {
    const janus = new JanusConnection(this.address);
    let existingConnection = false;
    let handleId: number | undefined = undefined;

    janus.onOpen = async (e: Event) => {
      let msg;
      const sessionId = janus.sid;

      // Once connection is setup add the janus object to the connections list
      this.connections = {
        ...this.connections,
        [sessionId]: janus,
      };

      try {
        msg = await janus.send({
          janus: 'attach',
          plugin: 'janus.plugin.recordplay',
        });
      } catch (e) {
        console.error("Unable to attach plugin 'janus.plugin.recordplay'. ", e);
        this.callbacks.onShareFailure('Failed to start screenshare');
        return;
      }

      if (msg.data !== undefined) {
        handleId = msg.data.id;
        let ms;

        try {
          ms = await navigator.mediaDevices.getDisplayMedia();
        } catch (e) {
          console.error('Unable to get display media stream. ', e);
          this.callbacks.onDisplayMediaRejection('You must share your screens to continue.');
          return;
        }

        // Assumes we should always have a track to pop from the list as it ignores the potentially undefined error
        // check via !
        const track = ms.getVideoTracks().pop()!;
        const labelSplit = track.label.split(':');
        const typeOfShare = labelSplit[0];
        const screenStreamId = labelSplit[1];

        if (typeOfShare !== 'screen') {
          console.error('Can only share type of screen only');
          // Stops the media stream track
          track.stop();
          this.callbacks.onSelectedScreenError("Can only share items from the 'Your entire screen' section.");
          return;
        }

        const streamScreenIndex = findIndexByProperty(this.sharedScreenInfoList, 'screenStreamId', screenStreamId);

        if (streamScreenIndex !== -1) {
          console.error('Screen already shared');
          // Stops the media stream track
          track.stop();
          this.callbacks.onSelectedScreenError('You are already sharing the selected screen.');
          return;
        }

        const conn = new RTCPeerConnection();

        track.addEventListener('ended', () => {
          try {
            janus.send({
              janus: 'message',
              plugin: 'janus.plugin.recordplay',
              body: {
                request: 'stop',
              },
              handle_id: handleId,
            });
          } catch (e) {
            console.error('Unable to stop screen recording. ', e);
          }

          // End janus websocket connection and local globals cleanup
          janus.endSession();
          delete this.connections[sessionId];
          // Removes specified screenshareItem from the list
          this.sharedScreenInfoList = this.sharedScreenInfoList.filter((item) => item.sessionId !== sessionId);

          console.log('screen sharing stopped');
          this.callbacks.onStopShareSuccess(sessionId);
        });

        conn.addEventListener('icecandidate', (e) => {
          console.log('got ice', e);
          if (e.candidate) {
            janus.send({
              janus: 'trickle',
              candidate: e.candidate,
              handle_id: handleId,
            });
          }
        });

        conn.addEventListener('negotiationneeded', async (e) => {
          let offer: RTCSessionDescriptionInit;

          try {
            offer = await conn.createOffer();
          } catch (e) {
            console.error('Unable to get connection create offer ', e);
            this.callbacks.onShareFailure('Failed to start screenshare');
            return;
          }

          try {
            await conn.setLocalDescription(offer);
          } catch (e) {
            console.error('Unable to set connection local description. ', e);
            this.callbacks.onShareFailure('Failed to start screen share');
            return;
          }

          let recordMessage;
          // when used in janus: e.g. csesta:2605170409046623:459085175
          const recordingName = `${this.username}:${sessionId}:${screenStreamId}`;

          try {
            // let's see if we can't record something
            recordMessage = await janus.send({
              janus: 'message',
              plugin: 'janus.plugin.recordplay',
              body: {
                request: 'record',
                name: recordingName,
                videocodec: 'vp9',
              },
              handle_id: handleId,
              jsep: conn.localDescription as RTCSessionDescriptionInit,
            });
          } catch (e) {
            console.error('Unable to initiate screen recording. ', e);
            return;
          }

          const recordingId = recordMessage.plugindata?.data.result.id || 0;
          const screenInfoItem = ScreenInfoModel(
            sessionId,
            screenStreamId,
            handleId || 0,
            track,
            `${recordingId}:${recordingName}`,
            recordingId,
          );

          this.sharedScreenInfoList.push(screenInfoItem);
          this.callbacks.onShareSuccess(screenInfoItem);

          try {
            await conn.setRemoteDescription(recordMessage.jsep as RTCSessionDescriptionInit);
          } catch (e) {
            console.log('Unable to set connection remote description. ', e);
            return;
          }
        });

        conn.addTrack(track);
        existingConnection = true;
      } else {
        console.error('Unexpected message response from janus. Missing data object');
        this.callbacks.onShareFailure('Failed to start screenshare');
        return;
      }
    };

    janus.onError = (e: Event) => {
      // We only want this callback triggered if there was no existing connections
      if (!existingConnection) {
        console.error('Unable to communicate with recording server. ', e);
        this.callbacks.onConnectionError('Unable to communicate with recording server.');
      }
    };

    janus.onClose = (e: CloseEvent) => {
      // TODO: Fix message spam that occurs when connection with server is lots and multiple connections drop at once
      // Might be fixed by having ONE connection that handles multiple recordings IF thats even possible?
      if (e.code !== 1000) {
        if (existingConnection) {
          // Reset local globals
          this.connections = {};
          this.sharedScreenInfoList = [];
          this.callbacks.onConnectionLost('Connection with remote server lost. Screenshare has ended');
          return;
        }
      }
    };
  }

  public async removeScreen(sessionId: number) {
    if (sessionId === undefined || sessionId === null) {
      console.error('Unable to end screenshare as screenshareList session ID is undefined or null.');
      return;
    }

    const shareItemIndex = findIndexByProperty(this.sharedScreenInfoList, 'sessionId', sessionId);
    const sharedScreenInfo = this.sharedScreenInfoList[shareItemIndex];

    if (sharedScreenInfo === undefined) {
      console.error('Unable to end screenshare as requested stream does not exist.');
      return;
    }

    const janusConnection = this.connections[sharedScreenInfo.sessionId];

    if (janusConnection === undefined) {
      console.error('Unable to end screenshare as janus connection does not exist for this screenshareList item.');
      return;
    }

    try {
      await janusConnection.send({
        janus: 'message',
        plugin: 'janus.plugin.recordplay',
        body: {
          request: 'stop',
        },
        handle_id: sharedScreenInfo.handleId,
      });
    } catch (e) {
      console.error('Unable to stop screen recording. ', e);
      return;
    }

    // Stops the media stream track
    sharedScreenInfo.track.stop();

    // End janus websocket connection and local globals cleanup
    janusConnection.endSession();
    delete this.connections[sessionId];
    // Removes specified screenshareItem from the list
    this.sharedScreenInfoList = this.sharedScreenInfoList.filter((item) => item.sessionId !== sessionId);

    console.log('screen sharing stopped');
    this.callbacks.onStopShareSuccess(sessionId);
  }

  // Cleansup all active connections
  public destroy() {
    for (let i = 0; i < this.sharedScreenInfoList.length; i++) {
      this.removeScreen(this.sharedScreenInfoList[i].sessionId);
    }
  }
}
