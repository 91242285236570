import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  addDiallerGroupCampaign,
  getDiallerGroupCampaigns,
  getDiallerGroupCampaignsByNextUrl,
  removeCampaignFromGroup,
  updateDiallerGroupCampaign,
} from './api';
import {
  AddCampaignGroupAssignment,
  DiallerGroupCampaign,
  DiallerGroupCampaignsResponse,
  UpdateCampaignGroupAssignment,
} from './domain';

type Options = {
  search?: string;
  archived?: boolean;
  isActive?: boolean;
  shouldFetch?: boolean;
};

const useDiallerGroupCampaignSearch = (
  diallerGroupId: number,
  options: Options = {
    search: undefined,
    archived: undefined,
    isActive: undefined,
    shouldFetch: true,
  },
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [list, setList] = useState<DiallerGroupCampaign[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const search = options.search;
  const archived = options.archived;
  const isActive = options.isActive;
  const shouldFetch = options.shouldFetch ?? true;

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      setLoading(true);
      setError(false);

      let resp: DiallerGroupCampaignsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallerGroupCampaignsByNextUrl(nextUrl, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.campaigns]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    setLoading(true);
    setError(false);

    let resp: DiallerGroupCampaignsResponse | undefined;
    try {
      axiosCancelRef.current = axios.CancelToken.source();
      resp = await getDiallerGroupCampaigns(diallerGroupId, search, archived, isActive, axiosCancelRef.current);
    } catch (e) {
      setError(true);
      setLoading(false);
      return;
    }

    // Returns undefined if request is canceled
    if (resp === undefined) return;

    setList(resp.campaigns);
    setHasMore(resp.nextPageUrl !== null);
    setNextUrl(resp.nextPageUrl);
    setLoading(false);
  }, [diallerGroupId, search, archived, isActive]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  const add = useCallback(
    async (data: AddCampaignGroupAssignment) => {
      try {
        await addDiallerGroupCampaign(diallerGroupId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  const update = useCallback(
    async (data: UpdateCampaignGroupAssignment) => {
      try {
        await updateDiallerGroupCampaign(diallerGroupId, data);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  const remove = useCallback(
    async (entryId: number) => {
      try {
        await removeCampaignFromGroup(diallerGroupId, entryId);
      } catch (e) {
        // Do nothing
        return Promise.reject(e);
      }

      await reload();
    },
    [diallerGroupId],
  );

  useEffect(() => {
    setList([]);
  }, [diallerGroupId, search, archived, isActive, shouldFetch]);

  useEffect(() => {
    const load = async (
      diallerGroupId: number,
      search: string | undefined,
      archived: boolean | undefined,
      isActive: boolean | undefined,
    ) => {
      setLoading(true);
      setError(false);

      let resp: DiallerGroupCampaignsResponse | undefined;
      try {
        axiosCancelRef.current = axios.CancelToken.source();
        resp = await getDiallerGroupCampaigns(diallerGroupId, search, archived, isActive, axiosCancelRef.current);
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }

      // Returns undefined if request is canceled
      if (resp === undefined) return;

      setList((prev) => [...prev, ...resp!.campaigns]);
      setHasMore(resp.nextPageUrl !== null);
      setNextUrl(resp.nextPageUrl);
      setLoading(false);
    };

    if (shouldFetch) {
      load(diallerGroupId, search, archived, isActive);
    }

    return () => {
      // Cancel request if it has already been executed
      axiosCancelRef.current.cancel();
    };
  }, [diallerGroupId, search, archived, isActive, shouldFetch]);

  return { loading, error, list, hasMore, add, update, remove, intersectionObserverRef };
};

export default useDiallerGroupCampaignSearch;
