import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ReactNode } from 'react';

interface AsyncLoaderProps {
  isLoading: boolean;
  children: ReactNode;
  error?: ReactNode;
}

const AsyncLoader = ({ isLoading, error, children }: AsyncLoaderProps) => {
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  if (error) {
    return <>{error}</>;
  }

  return <>{children}</>;
};

export default AsyncLoader;
