import * as j from '@mojotech/json-type-validation';

export interface Queue {
  queueId: string;
  name: string;
}

export interface ListQueuesResponse {
  queues: { [key: string]: Queue };
}

export const QueueDecoder = j
  .object({
    name: j.string(),
    queue_id: j.string(),
  })
  .map((q) => ({
    name: q.name,
    queueId: q.queue_id,
  }));

export const ListQueueResponseDecoder: j.Decoder<ListQueuesResponse> = j
  .object({
    queues: j.union(j.dict(QueueDecoder), j.constant(null)),
  })
  .map((r) => ({ queues: r.queues || {} }));
