import { Duration } from 'luxon';
import { useEffect, useState } from 'react';

interface useCountdownProps {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const useCountdown = (targetDate: string): useCountdownProps => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState<number>(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1_000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number): useCountdownProps => {
  const { days, hours, minutes, seconds } = Duration.fromMillis(countDown)
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();

  const daysFloor = days ? Math.floor(days) : undefined;
  const hoursFloor = hours ? Math.floor(hours) : undefined;
  const minutesFloor = minutes ? Math.floor(minutes) : undefined;
  const secondsFloor = seconds ? Math.floor(seconds) : undefined;

  return { days: daysFloor, hours: hoursFloor, minutes: minutesFloor, seconds: secondsFloor };
};

export default useCountdown;
