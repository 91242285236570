import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps, Theme } from '@mui/system';

import { MuiColorRange } from '~theme';

type ActionButtonProps = MUIStyledCommonProps<Theme> & ButtonProps & { colorRange: MuiColorRange };

const ActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'colorRange',
})<ActionButtonProps>(({ theme, colorRange }) => ({
  'display': 'block',
  'margin': `${theme.spacing(1)} 0`,
  'color': theme.palette.getContrastText(colorRange[800]),
  'backgroundColor': colorRange[800],
  ':hover': {
    color: theme.palette.getContrastText(colorRange[700]),
    backgroundColor: colorRange[700],
  },
  ':disabled': {
    color: theme.palette.getContrastText(`${colorRange[800]}80`),
    backgroundColor: `${colorRange[800]}80`,
  },
  ':last-of-type': {
    marginBottom: 0,
  },
}));

export default ActionButton;
