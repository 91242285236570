import SocketManagerBase from '~services/SocketManagerBase';

import { AsteriskAllStatsDecoder, AsteriskStats, AsteriskStatsDecoder } from './domain';

interface StatCallbacks {
  onConnected: () => void;
  onMessage: (asteriskStats: AsteriskStats | AsteriskStats[]) => void;
  onDisconnected: (reconnect: () => void) => void;
}

export class AsteriskStatsManager extends SocketManagerBase {
  private groupId: number | null;
  private callbacks: StatCallbacks = {
    onConnected: () => {},
    onMessage: () => {},
    onDisconnected: () => {},
  };

  constructor(address: string, groupId: number | null, callbacks: Partial<StatCallbacks>) {
    super('AsteriskStatsManager', address);
    this.groupId = groupId;

    // Assign optional functions else default to empty default values
    this.callbacks.onConnected = callbacks.onConnected || this.callbacks.onConnected;
    this.callbacks.onMessage = callbacks.onMessage || this.callbacks.onMessage;
    this.callbacks.onDisconnected = callbacks.onDisconnected || this.callbacks.onDisconnected;
  }

  /** Updates the graph buckets value */
  public setGraphBucket = (buckets: number): void => {
    console.log('AsteriskStatsManager action set graph bucket triggered.');

    if (this.ws === undefined) {
      console.error('setGraphBucket: websocket missing.');
      return;
    }

    // If negative number or zero we want to skip sending
    if (buckets <= 0) {
      console.error('setGraphBucket: bucket value cannot be zero or less.');
      return;
    }

    const req = {
      configuration: {
        groupId: this.groupId,
        graphBuckets: buckets,
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Pauses asterisk dialling */
  public pauseDialling = (): void => {
    console.log('AsteriskStatsManager action pause dialling triggered.');

    this.setPauseDialling(true);
  };

  /** Resumes asterisk dialling */
  public resumeDialling = (): void => {
    console.log('AsteriskStatsManager action resume dialling triggered.');

    this.setPauseDialling(false);
  };

  /** Disconnect a channel */
  public disconnectChannel = (chan: string): void => {
    if (this.ws === undefined) {
      console.error('disconnectChannel: websocket missing.');
      return;
    }

    const req = {
      disconnect: { groupId: this.groupId, channel: chan },
    };

    this.send(JSON.stringify(req));
  };

  /** Toggle for pausing/ resuming asterisk dialling */
  private setPauseDialling = (pause: boolean): void => {
    if (this.ws === undefined) {
      console.error('setPauseDialling: websocket missing.');
      return;
    }

    const req = {
      configuration: {
        groupId: this.groupId,
        pauseDialling: pause,
      },
    };

    this.send(JSON.stringify(req));
  };

  /** Event that is triggered on the successful connection of the websocket */
  protected onOpen = () => {
    if (this.ws === undefined) {
      console.error('onOpen: websocket missing.');
      return;
    }

    const callReq = {
      groupId: this.groupId || undefined,
    };

    this.send(JSON.stringify(callReq));

    this.callbacks.onConnected();
  };

  /** Event handler that manages all responses from the connected websocket */
  protected onMessage = (message: MessageEvent): void => {
    let msg: AsteriskStats | AsteriskStats[];

    try {
      const rawMessage = JSON.parse(message.data);
      if (this.groupId !== null) {
        msg = AsteriskStatsDecoder.runWithException(rawMessage);
      } else {
        msg = AsteriskAllStatsDecoder.runWithException(rawMessage);
      }
    } catch (e) {
      console.error('onMessage: Unable to decode message payload: ', e);
      return;
    }

    this.callbacks.onMessage(msg);
  };

  /** Event handler that is triggered if the websocket is closed/ ended */
  protected onClose = (event: CloseEvent) => {
    console.log('AsteriskStatsManager websocket closed with code: ', event.code);

    this.ws = undefined;

    if (event.code > 1000) {
      this.callbacks.onDisconnected(this.connect);
    }
  };
}
