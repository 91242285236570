import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OberonDialog from '~components/OberonDialog';
import { getTimezoneList } from '~pages/Dialler/api';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';

export interface PrepareLeadFormData {
  endpoint: string;
  leadName: string;
  externalId: string;
  timezone: string;
}

// We do this as we need the value of timezone to be able to be null by default for the material UI auto select component to
// stop having a fit over "" does not exist in list as a selectable option
interface FormDefaults extends Omit<PrepareLeadFormData, 'timezone'> {
  timezone: string | null;
}

interface PrepareOutboundCallModalProps {
  open: boolean;
  manualOutboundRequireDetails: boolean;
  onAccept: (data: PrepareLeadFormData) => Promise<void>;
  onClose: () => void;
}

const PrepareOutboundCallModal = ({
  open,
  manualOutboundRequireDetails,
  onAccept,
  onClose,
}: PrepareOutboundCallModalProps) => {
  const appConfig = useAppConfiguration();
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm<FormDefaults>({
    defaultValues: {
      endpoint: '',
      leadName: '',
      externalId: '',
      timezone: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [timezoneList, setTimezoneList] = useState<string[]>([]);

  // Handles form cleanup on modal close
  useEffect(() => {
    // Reset form on close
    return function cleanupPrepareLeadModal() {
      reset();
    };
  }, [open]);

  // Fetch timezone list
  useEffect(() => {
    if (open && manualOutboundRequireDetails) {
      const fetchTimezoneList = async () => {
        clearErrors('timezone');

        let resp: string[];
        try {
          resp = await getTimezoneList(appConfig.web.timezonePrefixes);
        } catch (e) {
          setError('timezone', { type: 'manual', message: 'Unable to fetch timezone list' });
          return;
        }

        setTimezoneList(resp);
        setValue('timezone', appConfig.defaultTimezone);
      };

      fetchTimezoneList();

      return () => {
        clearErrors('timezone');
        setTimezoneList([]);
      };
    }
  }, [open, manualOutboundRequireDetails, appConfig.web.timezonePrefixes]);

  const onSubmit = handleSubmit(async (data: FormDefaults) => {
    setIsSubmitting(true);

    try {
      await onAccept({
        endpoint: data.endpoint,
        leadName: data.leadName || data.endpoint,
        externalId: data.externalId || data.endpoint,
        timezone: data.timezone || DateTime.local().zoneName,
      });
    } catch (e) {
      return;
    }

    setIsSubmitting(false);
    reset();
    onClose();
  });

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Prepare Outbound Call'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='endpoint'
              control={control}
              rules={{ required: 'Lead Endpoint is required.' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Lead Endpoint'
                  disabled={isSubmitting}
                  required={true}
                  error={Boolean(errors.endpoint)}
                  helperText={errors.endpoint?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          {manualOutboundRequireDetails && (
            <>
              <Grid item xs={12}>
                <Controller
                  name='leadName'
                  control={control}
                  rules={{ required: 'Lead Name is required.' }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Lead Name'
                      disabled={isSubmitting}
                      required={true}
                      error={Boolean(errors.leadName)}
                      helperText={errors.leadName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='externalId'
                  control={control}
                  rules={{ required: 'External ID is required.' }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='External ID'
                      disabled={isSubmitting}
                      required={true}
                      error={Boolean(errors.externalId)}
                      helperText={errors.externalId?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='timezone'
                  control={control}
                  rules={{ required: 'Timezone is required.' }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value}
                      onChange={(e, data) => field.onChange(data)}
                      fullWidth
                      options={timezoneList}
                      filterSelectedOptions
                      disabled={isSubmitting}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          name='timezone'
                          label='Timezone'
                          required={true}
                          error={Boolean(errors.timezone)}
                          helperText={errors.timezone?.message}
                          variant='outlined'
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isSubmitting} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isSubmitting}
            loading={isSubmitting}>
            Dial Lead
          </LoadingButton>
        </>
      }
    />
  );
};

export default PrepareOutboundCallModal;
