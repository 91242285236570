import TextField from '@mui/material/TextField';
import React, { KeyboardEvent } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';

// TODO: We lose type saftey here but not sure how else to handle this
// Fix once figured out
interface ControlledNumberFieldProps extends UseControllerProps<any, any> {
  label: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  isFloat?: boolean;
}

const removeBlackListedChars = (pattern: RegExp) => (string: string) => {
  return string.replace(pattern, '');
};

const parseToFullNumber = (string: string) => {
  // If field is empty we want to make sure its stays marked as empty for required form validation to work
  if (string === '') return string;
  const parsedNumber = parseInt(string, 10);
  if (isNaN(parsedNumber)) return '';

  return parsedNumber;
};

const parseToFloat = (string: string) => {
  // If field is empty we want to make sure its stays marked as empty for required form validation to work
  if (string === '') return string;
  // Float string cannot start with a '.'
  if (string.length === 1 && string[string.length - 1] === '.') return '';
  // We should not run parseFloat for cases were string end in a . i.e. 0. OR 2.
  // as that will prevent us from defining a float in the first place
  if (string.length > 1 && string[string.length - 1] === '.') return string;

  const parsedNumber = parseFloat(string);
  if (isNaN(parsedNumber)) return '';

  return parsedNumber;
};

const blockEntry = (invalidCharList: string[]) => (e: KeyboardEvent<HTMLInputElement>) => {
  if (invalidCharList.includes(e.key)) {
    e.preventDefault();
  }
};

/**
 * NOTE: Field will always be typeof number unless we fail to parse it's contents or it is not set by default.
 * If it falls under one of the parse error states we default it to an empty string to keep semantic HTML
 * input element's happy and allow for the required validation of react-hook-form to work correctly.
 * */
const ControlledNumberField = ({
  label,
  disabled,
  required,
  error,
  helperText,
  isFloat,
  ...controllerProps
}: ControlledNumberFieldProps) => {
  const parseFn = isFloat ? parseToFloat : parseToFullNumber;
  // Blocks entrty of any blacklisted chars
  const blockFn = isFloat ? blockEntry(['-', '+', 'e']) : blockEntry(['-', '+', 'e', '.']);
  // Blocks then copying of any blacklisted chars
  const removeBlackListedCharsFn = isFloat
    ? removeBlackListedChars(/[e\+\-]/gi)
    : removeBlackListedChars(/[e\+\-\.]/gi);

  return (
    <Controller
      {...controllerProps}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          InputProps={{
            onKeyDown: blockFn,
          }}
          variant='outlined'
          label={label}
          disabled={disabled}
          required={required}
          error={error}
          helperText={helperText}
          onChange={(e) => field.onChange(parseFn(removeBlackListedCharsFn(e.target.value)))}
        />
      )}
    />
  );
};

export default ControlledNumberField;
