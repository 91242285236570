import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { FormEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ControlledCheckbox from '~components/Form/ControlledCheckbox';
import OberonDialog from '~components/OberonDialog';
import { getTimezoneList } from '~pages/Dialler/api';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { assertUnreachable } from '~utils/Functions';

import { ExclusionEntry } from '../../domain';

export const enum EndpointActionType {
  Add = 'add',
  Check = 'check',
  Remove = 'remove',
}

type AddExclusionForm = ExclusionEntry & {
  setExpiry: boolean;
};

interface EndpointActionModalProps {
  open: boolean;
  endpointActionType?: EndpointActionType;
  submitting: boolean;
  onClose: () => void;
  onAddEndpoint: (data: ExclusionEntry) => Promise<void>;
  onCheckEndpoint: (endpoint: string) => Promise<void>;
  onRemoveEndpoint: (endpoint: string) => Promise<void>;
}

const EndpointActionModal = ({
  open,
  endpointActionType,
  submitting,
  onClose,
  onAddEndpoint,
  onCheckEndpoint,
  onRemoveEndpoint,
}: EndpointActionModalProps) => {
  const appConfig = useAppConfiguration();
  const [timezoneList, setTimezoneList] = useState<string[]>([]);
  const isLoading = submitting;
  const addEndpoint = useForm<AddExclusionForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      number: '',
      reason: '',
      expiryTimestamp: '',
      timezone: '',
      setExpiry: false,
    },
  });
  const addEndpointWatchSetExpiry = addEndpoint.watch('setExpiry');
  const checkEndpoint = useForm<{ endpoint: number }>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const removeEndpoint = useForm<{ endpoint: number }>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const isAddForm = endpointActionType === EndpointActionType.Add;
  const isCheckForm = endpointActionType === EndpointActionType.Check;
  const isRemoveForm = endpointActionType === EndpointActionType.Remove;

  // Will always be one of the three EndpointActionType Options if modal is open, else empty
  let title = '';

  if (isAddForm) {
    title = 'Add Endpoint';
  } else if (isCheckForm) {
    title = 'Check endpoints in Exclusion List';
  } else if (isRemoveForm) {
    title = 'Remove Endpoint';
  }

  useEffect(() => {
    return () => {
      addEndpoint.reset();
      checkEndpoint.reset();
      removeEndpoint.reset();
    };
  }, [open]);

  // Fetch timezone list
  useEffect(() => {
    if (open && isAddForm) {
      const fetchTimezoneList = async () => {
        addEndpoint.clearErrors('timezone');

        let resp: string[];
        try {
          resp = await getTimezoneList(appConfig.web.timezonePrefixes);
        } catch (e) {
          addEndpoint.setError('timezone', { type: 'manual', message: 'Unable to fetch timezone list' });
          return;
        }

        setTimezoneList(resp);
      };

      fetchTimezoneList();

      return () => {
        addEndpoint.clearErrors('timezone');
        setTimezoneList([]);
      };
    }
  }, [open, isAddForm, JSON.stringify(appConfig.web.timezonePrefixes)]);

  const onAddEndpointSubmit = addEndpoint.handleSubmit(async (data: AddExclusionForm) => {
    let submitData: ExclusionEntry = {
      number: data.number,
      reason: data.reason,
      expiryTimestamp: undefined,
      timezone: undefined,
    };

    if (data.setExpiry) {
      submitData = {
        ...submitData,
        // Should not be undefined at this point due to form validation
        expiryTimestamp: DateTime.fromISO(data.expiryTimestamp!).toISO(),
        // Should not be undefined at this point due to form validation
        timezone: data.timezone,
      };
    }

    try {
      await onAddEndpoint(submitData);
    } catch (e) {
      return;
    }

    addEndpoint.reset();
  });

  const onCheckEndpointSubmit = checkEndpoint.handleSubmit(async (data: { [key: string]: any }) => {
    try {
      await onCheckEndpoint(data.endpoint);
    } catch (e) {
      return;
    }

    checkEndpoint.reset();
  });

  const onRemoveEndpointSubmit = removeEndpoint.handleSubmit(async (data: { [key: string]: any }) => {
    try {
      await onRemoveEndpoint(data.endpoint);
    } catch (e) {
      return;
    }

    checkEndpoint.reset();
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (endpointActionType === undefined) {
      console.error('EndpointActionType not specified');
      return;
    }

    switch (endpointActionType) {
      case EndpointActionType.Add: {
        onAddEndpointSubmit();
        break;
      }
      case EndpointActionType.Check: {
        onCheckEndpointSubmit();
        break;
      }
      case EndpointActionType.Remove: {
        onRemoveEndpointSubmit();
        break;
      }
      default: {
        assertUnreachable(endpointActionType);
      }
    }
  };

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title={title}
      content={
        <Grid container spacing={2}>
          {isAddForm && (
            <>
              <Grid item xs={12}>
                <Controller
                  name='number'
                  control={addEndpoint.control}
                  rules={{ required: 'Endpoint is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Endpoint'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(error)}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name='reason'
                  control={addEndpoint.control}
                  rules={{ required: 'Reason is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Reason'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(error)}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledCheckbox
                  name='setExpiry'
                  label='Set Expiry?'
                  control={addEndpoint.control}
                  disabled={isLoading}
                />
              </Grid>

              {addEndpointWatchSetExpiry && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name='expiryTimestamp'
                      control={addEndpoint.control}
                      rules={{ required: 'Expiry timestamp is required.' }}
                      render={({ field, fieldState: { error } }) => (
                        <DateTimePicker
                          disableMaskedInput
                          disablePast
                          label='Expiry Timestamp'
                          inputFormat='dd/MM/yyyy hh:mm a'
                          disabled={submitting}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required={true}
                              variant='outlined'
                              error={Boolean(error)}
                              helperText={error?.message}
                            />
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='timezone'
                      control={addEndpoint.control}
                      rules={{ required: 'Timezone is required.' }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          {...field}
                          value={field.value}
                          onChange={(e, data) => field.onChange(data)}
                          fullWidth
                          options={timezoneList}
                          filterSelectedOptions
                          disabled={isLoading}
                          renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                              name='timezone'
                              label='Timezone'
                              required={true}
                              error={Boolean(error)}
                              helperText={error?.message}
                              variant='outlined'
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          {isCheckForm && (
            <>
              <Grid item xs={12}>
                <Controller
                  name='endpoint'
                  control={checkEndpoint.control}
                  rules={{ required: 'Endpoint is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      variant='outlined'
                      label='Endpoint'
                      disabled={isLoading}
                      required={true}
                      error={Boolean(error)}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  type='submit'
                  variant='contained'
                  disableElevation
                  color='primary'
                  disabled={isLoading}
                  loading={isLoading}>
                  Check Endpoint
                </LoadingButton>
              </Grid>
            </>
          )}

          {isRemoveForm && (
            <Grid item xs={12}>
              <Controller
                name='endpoint'
                control={removeEndpoint.control}
                rules={{ required: 'Endpoint is required.' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Endpoint'
                    disabled={isLoading}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          {(endpointActionType === EndpointActionType.Add || endpointActionType === EndpointActionType.Remove) && (
            <LoadingButton
              type='submit'
              variant='contained'
              disableElevation
              color='primary'
              disabled={isLoading}
              loading={isLoading}>
              Submit
            </LoadingButton>
          )}
        </>
      }
    />
  );
};

export default EndpointActionModal;
