import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getGlobalSettings, updateGlobalSettings } from './api';
import { GlobalSettings } from './domain';

type Options = {
  shouldFetch?: boolean;
};

const useGlobalSettings = (options: Options = {}) => {
  const { shouldFetch = true } = options;
  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<GlobalSettings>();
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  const reload = useCallback(async () => {
    try {
      setLoading(true);
      axiosCancelRef.current.cancel();
      const cancelTokenSource = (axiosCancelRef.current = axios.CancelToken.source());
      const res = await getGlobalSettings(cancelTokenSource);
      setSettings(res);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const update = useCallback(async (settings: Partial<GlobalSettings>) => {
    try {
      axiosCancelRef.current.cancel();
      const cancelTokenSource = (axiosCancelRef.current = axios.CancelToken.source());
      await updateGlobalSettings(settings, cancelTokenSource);
    } catch (e) {
      return Promise.reject(e);
    }
    await reload();
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      reload();
    }
  }, [shouldFetch]);

  return { loading, settings, update, reload };
};

export default useGlobalSettings;
