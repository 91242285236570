import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import React, { useState } from 'react';

import { SortColumn, SortColumnValues, SortOrder } from '~pages/Callbacks/useCallbacks/domain';

type Props = {
  sortColumn?: SortColumnValues;
  sortOrder?: SortOrder;
  updateSortColumn: (value: SortColumnValues | undefined) => void;
  updateSortOrder: (value: SortOrder | undefined) => void;
};

const columns = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Callback ID',
    value: SortColumn.CallbackId,
  },
  {
    label: 'Scheduled Timestamp',
    value: SortColumn.ScheduledTimestamp,
  },
  {
    label: 'Lead Name',
    value: SortColumn.LeadName,
  },
];

const columnListDisplay = columns.map((item, index) => (
  <MenuItem key={index} value={item.value}>
    {item.label}
  </MenuItem>
));

export const SortOptions = ({ sortColumn, sortOrder, updateSortColumn, updateSortOrder }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  let selectedCount = 0;
  if (sortColumn) {
    selectedCount++;
  }
  if (sortOrder) {
    selectedCount++;
  }

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const onRemoveAll = () => {
    updateSortColumn(undefined);
    updateSortOrder(undefined);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          disableRipple
          onClick={toggleOpen}
          size='small'
          endIcon={open ? <RemoveIcon fontSize='small' /> : <AddIcon fontSize='small' />}>
          Sort Options {selectedCount > 0 && `(${selectedCount})`}
        </Button>

        <Button
          sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          disableRipple
          onClick={onRemoveAll}
          size='small'
          disabled={!Boolean(selectedCount)}>
          Clear
        </Button>
      </Box>

      <Collapse in={open}>
        <Box sx={{}}>
          <TextField
            fullWidth
            select
            onChange={(e) => updateSortColumn((e.target.value as SortColumnValues) || undefined)}
            variant='outlined'
            label='Sort By Column'
            value={sortColumn || ''}>
            {columnListDisplay}
          </TextField>

          <FormControlLabel
            control={
              <Checkbox
                name='sortOrder'
                checked={sortOrder === 'asc'}
                disabled={Boolean(sortOrder) && sortOrder !== 'asc'}
                onChange={(e) => updateSortOrder(e.target.checked ? 'asc' : undefined)}
              />
            }
            label='Ascending'
          />
          <FormControlLabel
            control={
              <Checkbox
                name='sortOrder'
                checked={sortOrder === 'desc'}
                disabled={Boolean(sortOrder) && sortOrder !== 'desc'}
                onChange={(e) => updateSortOrder(e.target.checked ? 'desc' : undefined)}
              />
            }
            label='Descending'
          />
        </Box>
      </Collapse>
    </>
  );
};

export default SortOptions;
