import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Controller } from 'react-hook-form';

import { AdditionalFieldsProps } from '~pages/Dialler/ConnectDialler/AsyncView/MessageContainer/Conversation';

const AdditionalFields = ({ control, formSubmitting, errors }: AdditionalFieldsProps) => {
  return (
    <Grid item xs={12}>
      <Controller
        name='store_id'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant='outlined'
            label='Store ID'
            disabled={formSubmitting}
            required={true}
            error={Boolean(errors.store_id)}
            helperText={errors.store_id?.message}
          />
        )}
      />
    </Grid>
  );
};

export default AdditionalFields;
