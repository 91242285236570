import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { useEffect, useMemo, useState } from 'react';

import ContentSpacer from '~components/ContentSpacer';

import { getRealtimeTranscriptRecords, getRules } from './api';
import { Rule, TranscriptRecord } from './domain';
import TranscriptCarousel from './TranscriptCarousel';

interface CallCategoriesProps {
  campaignId?: number;
  contactId?: string;
}

const pollFrequency = 8_000;

interface MatchedRule {
  rule: Rule;
  triggers: TranscriptRecord[];
}

const getTranscriptOrUndefined = async (contactId: string): Promise<TranscriptRecord[] | undefined> => {
  try {
    return await getRealtimeTranscriptRecords(contactId);
  } catch (e) {
    return undefined;
  }
};

const getRulesOrUndefined = async (campaignId: number): Promise<Rule[] | undefined> => {
  try {
    return await getRules(campaignId);
  } catch (e) {
    return undefined;
  }
};

const CallCategories = ({ campaignId, contactId }: CallCategoriesProps) => {
  const [transcriptRecords, setTranscriptRecords] = useState<TranscriptRecord[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [rules, setRules] = useState<Rule[]>([]);
  const [error, setError] = useState<string>('');

  const matchedRules: MatchedRule[] = useMemo(() => {
    return rules.reduce((state, currentValue) => {
      // Hits per word(s)
      const triggers = transcriptRecords.filter((record) => {
        for (let i = 0; i < currentValue.phrases.length; i++) {
          // Only return if matched
          if (record.content.toLowerCase().includes(currentValue.phrases[i])) {
            return true;
          }
        }

        // Do not return
        return false;
      });

      if (triggers.length > 0) {
        return [...state, { rule: currentValue, triggers }];
      }

      return state;
    }, [] as MatchedRule[]);
  }, [rules]);

  useEffect(() => {
    if (campaignId && contactId) {
      const dataLoad = (campaignId: number, contactId: string) => async () => {
        let transcriptList: TranscriptRecord[] | undefined;
        let ruleList: Rule[] | undefined;

        [transcriptList, ruleList] = await Promise.all([
          await getTranscriptOrUndefined(contactId),
          await getRulesOrUndefined(campaignId),
        ]);

        // If we get no records we just want to set this value to an empty array
        if (transcriptList === undefined) {
          console.error('! Unable to fetch realtime transcript list');
          transcriptList = [] as TranscriptRecord[];
        }

        // We error here as without the rule list data this component cannot perform its primary function
        if (ruleList === undefined) {
          console.error('! Unable to fetch call category rules');
          setError('Unable to fetch Call Categories');
          setInitialLoad(false);
          return;
        }

        setTranscriptRecords(transcriptList);
        setRules(ruleList);
        setError('');
        setInitialLoad(false);
      };

      // Initial load
      dataLoad(campaignId, contactId)();
      // Subsequent requests
      const timer = window.setInterval(dataLoad(campaignId, contactId), pollFrequency);
      return () => window.clearInterval(timer);
    }
  }, [campaignId, contactId]);

  return (
    <ContentSpacer spacing={2}>
      <Typography fontWeight='bold' variant='h6' gutterBottom>
        Call Categories
      </Typography>

      {initialLoad && <CircularProgress disableShrink size={16} />}

      {!initialLoad && (
        <>
          {matchedRules.length === 0 && error && (
            <Typography variant='body2' component='p' color='textSecondary'>
              {error}
            </Typography>
          )}

          {matchedRules.length === 0 && !error && (
            <Typography variant='body2' component='p' color='textSecondary'>
              No matches found.
            </Typography>
          )}

          {matchedRules.length > 0 && (
            <Box
              sx={{
                overflow: 'auto',
                minHeight: 0,
                height: '100%',
                maxHeight: 400,
                border: '1px solid #e6e6e6',
                padding: 2,
                borderRadius: 2,
              }}>
              {matchedRules.map((matched, index) => (
                <ContentSpacer key={index} spacing={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}>
                    <Typography variant='h6' component='span'>
                      Category:
                    </Typography>

                    <Chip
                      key={index}
                      sx={{ marginLeft: 1, background: matched.rule.bgColor, color: matched.rule.textColor }}
                      label={matched.rule.category}
                      size='small'
                    />
                  </Box>

                  <Box
                    sx={{ '& img': { width: '100%' }, 'marginBottom': 2 }}
                    dangerouslySetInnerHTML={{ __html: matched.rule.content }}
                  />

                  <Typography variant='h6' component='span' paragraph>
                    Triggers
                  </Typography>

                  <TranscriptCarousel transcripts={matched.triggers} phrases={matched.rule.phrases} />
                </ContentSpacer>
              ))}
            </Box>
          )}
        </>
      )}
    </ContentSpacer>
  );
};

export default CallCategories;
