import { useEffect, useState } from 'react';

import { getDurationFromSeconds } from '../../utils/Functions';

const useTimer = (timestamp: Date): string => {
  let interval: any = null;
  const [timer, setTimer] = useState<number>(0);
  const counter = (value: Date) => Math.max(0, new Date().getTime() - value.getTime());

  useEffect(() => {
    const start = new Date(timestamp);

    interval = setInterval(() => {
      const r = Math.round(counter(start) / 1_000);
      setTimer(r);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [timestamp]);

  return getDurationFromSeconds(timer);
};

export default useTimer;
