import CallEndIcon from '@mui/icons-material/CallEnd';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PauseIcon from '@mui/icons-material/Pause';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/system';
import React, { useState } from 'react';

import ContentSpacer from '~components/ContentSpacer';
import Keypad from '~components/Keypad';
import useTimer from '~hooks/useTimer';
import { ConnectVoiceConnection } from '~providers/ConnectProvider/domain';
import theme from '~theme';

interface ConnectVoiceConnectionCardProps {
  connection: ConnectVoiceConnection;
  isConference: boolean;
  initialConnectionTimestamp?: Date;
  isHangUpDisabled?: boolean;
  onHangUp: () => Promise<void>;
}

const sxStyles = {
  connecting: {
    cardSx: {
      backgroundColor: green[800],
    },
    statusIcon: RingVolumeIcon,
  },
  connected: {
    cardSx: {
      backgroundColor: blue[800],
    },
    statusIcon: PhoneInTalkIcon,
  },
  hold: {
    cardSx: {
      backgroundColor: blueGrey[800],
    },
    statusIcon: PhonePausedIcon,
  },
  mute: {
    cardSx: {
      backgroundColor: blue[800],
    },
    statusIcon: PhoneInTalkIcon,
  },
};

const buttonSx: SxProps<Theme> = {
  'color': theme.palette.common.white,
  '&.Mui-disabled': {
    color: 'rgba(255, 255, 255, .26)',
  },
};

const textColor = '#ffffff';

const ConnectVoiceConnectionCard = ({
  connection,
  isConference,
  initialConnectionTimestamp,
  isHangUpDisabled,
  onHangUp,
}: ConnectVoiceConnectionCardProps) => {
  const [openKeypad, setOpenKeyPad] = useState(false);
  const stateTimer = useTimer(connection.stateTimestamp);
  const isKeypadDisabled = false;

  const onKeypadClick = async (digit: string) => {
    try {
      await connection.sendDTMF(digit);
    } catch (e) {
      console.error(`Failed to send dtmf ${digit} to connection ${connection.connectionId}`, e);
    }
  };

  const toggleKeyPad = () => {
    setOpenKeyPad((prev) => !prev);
  };

  let sxStyle, timerIcon;
  if (connection.isConnecting) {
    sxStyle = sxStyles.connecting.cardSx;
    timerIcon = sxStyles.connecting.statusIcon;
  } else if (connection.onHold) {
    sxStyle = sxStyles.hold.cardSx;
    timerIcon = sxStyles.hold.statusIcon;
  } else if (connection.onMute) {
    sxStyle = sxStyles.mute.cardSx;
    timerIcon = sxStyles.mute.statusIcon;
  } else {
    sxStyle = sxStyles.connected.cardSx;
    timerIcon = sxStyles.connected.statusIcon;
  }

  let initialTimer;
  if (initialConnectionTimestamp) {
    initialTimer = useTimer(initialConnectionTimestamp);
  }

  let timer;
  if (connection.onHold || !connection.isInitialConnection) {
    timer = stateTimer;
  } else {
    timer = initialTimer;
  }

  const shouldDisableMute = !isConference || connection.isConnecting;
  const shouldDisableUnmute = !isConference || connection.isConnecting || connection.endpoint === 'INTERNAL-TRANSFER';
  const connectionTitle = connection.endpoint === 'INTERNAL-TRANSFER' ? 'Internal Transfer' : connection.endpoint;

  return (
    <ContentSpacer spacing={1}>
      <Card sx={sxStyle}>
        <CardContent sx={{ padding: '8px 16px 0 16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color={textColor} variant='body2'>
              {connectionTitle}
            </Typography>

            {timer && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {timerIcon && (
                  <SvgIcon component={timerIcon} fontSize='small' sx={{ marginRight: 1, color: textColor }} />
                )}

                <Typography color={textColor} variant='body2'>
                  {timer}
                </Typography>
              </Box>
            )}
          </Box>

          <Collapse in={openKeypad} mountOnEnter unmountOnExit>
            <Box sx={{ marginTop: 1, marginBottom: 1 }}>
              <Keypad translucentButtons disabled={isKeypadDisabled} onKeyClick={onKeypadClick} />
            </Box>
          </Collapse>
        </CardContent>

        <CardActions sx={{ padding: '0 8px 4px 8px', width: '100%' }}>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-evenly', alignItems: 'center' }}>
            <Tooltip title='Number Pad'>
              <span>
                <IconButton
                  sx={buttonSx}
                  aria-label='Number Pad'
                  disabled={connection.isConnecting}
                  onClick={toggleKeyPad}>
                  <KeyboardIcon />
                </IconButton>
              </span>
            </Tooltip>

            {!connection.onMute && (
              <Tooltip title='Mute'>
                <span>
                  <IconButton sx={buttonSx} aria-label='Mute' disabled={shouldDisableMute} onClick={connection.mute}>
                    <MicIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {connection.onMute && (
              <Tooltip title='Unmute'>
                <span>
                  <IconButton
                    sx={buttonSx}
                    aria-label='Unmute'
                    disabled={shouldDisableUnmute}
                    onClick={connection.unmute}>
                    <MicOffIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {!connection.onHold && (
              <Tooltip title='Hold'>
                <span>
                  <IconButton
                    sx={buttonSx}
                    aria-label='Put on hold'
                    disabled={connection.isConnecting}
                    onClick={connection.putOnHold}>
                    <PauseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {connection.onHold && (
              <Tooltip title='Resume'>
                <span>
                  <IconButton
                    sx={buttonSx}
                    aria-label='Take off hold'
                    disabled={connection.isConnecting}
                    onClick={connection.takeOffHold}>
                    <PlayArrowIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            <Tooltip title='Hang Up'>
              <span>
                <IconButton sx={buttonSx} aria-label='Disconnect' disabled={isHangUpDisabled} onClick={onHangUp}>
                  <CallEndIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </CardActions>
      </Card>
    </ContentSpacer>
  );
};

export default ConnectVoiceConnectionCard;
