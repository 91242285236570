import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import ActionDialog from '~components/ActionDialog';
import SectionCard from '~components/SectionCard';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';

interface Props {
  archived: string | undefined;
  archiveGroup: () => Promise<void>;
}

const DiallerGroupArchiveForm = ({ archived, archiveGroup }: Props) => {
  const { hasScope } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const canArchiveDiallerGroup = hasScope(AccessScope.CanArchiveDiallerGroup);

  return (
    <>
      <SectionCard title='Archive Group'>
        {!archived && (
          <>
            <Typography variant='body1' component='p' paragraph>
              Click below to archive this group.
            </Typography>

            <Button
              disableElevation
              variant='contained'
              onClick={toggleOpen}
              color='secondary'
              disabled={!canArchiveDiallerGroup}>
              Archive
            </Button>
          </>
        )}

        {archived && (
          <Typography variant='body1' component='p' paragraph>
            This group has been archived as of {DateTime.fromISO(archived).toFormat('FFF')}.
          </Typography>
        )}
      </SectionCard>

      {canArchiveDiallerGroup && (
        <ActionDialog
          open={open}
          title='Are you sure you want to do this?'
          content='You are about to archive this group, once you have completed this action it cannot be undone.'
          onClose={toggleOpen}
          onAccept={archiveGroup}
          primaryActionTitle='Archive'
        />
      )}
    </>
  );
};

export default DiallerGroupArchiveForm;
