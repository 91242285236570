import * as j from '@mojotech/json-type-validation';

export const enum ChannelType {
  WebChat = 'webchat',
  SMS = 'sms',
  Email = 'email',
  AndroidRCS = 'android-rcs',
  AppleBusinessChat = 'apple-business-chat',
}

export const enum TransferTarget {
  Queue = 'queue',
  Agent = 'agent',
}

export const enum AuthorRole {
  Agent = 'agent',
  Customer = 'customer',
  System = 'system',
}

export const enum QueueDispositionOutcome {
  Completed = 'completed',
  FollowUp = 'follow-up',
  Abandoned = 'abandoned',
  Transferred = 'transferred',
}

export const enum MessageType {
  Message = 'message',
  Event = 'event',
}

export const enum ContentType {
  MessageTypePlain = 'plain',
  MessageTypeMIME = 'mime',
  MessageTypeRSCAgentMessage = 'rcs-agent-message',
  MessageTypeRSCUserMessage = 'rcs-user-message',
  EventTypeAgentAvailable = 'agents-available',
  EventTypeConversationEnded = 'conversation-ended',
  EventTypeParticipantJoined = 'participant-joined',
  EventTypeParticipantLeft = 'participant-left',
  EventTypeAgentsBusy = 'agents-busy',
  EventTypeTransferred = 'transferred',
}

export const enum AsyncAgentState {
  Available = 'Available',
  NotReady = 'Not Ready',
  MissedConversation = 'Missed Conversation',
}

export interface Message {
  id: number;
  conversationId: number;
  createdTimestamp: string;
  readTimestamp: string | undefined;
  deliveredTimestamp: string | undefined;
  sentTimestamp: string | undefined;
  customerPreviousMessageId: number | undefined;
  authorRole: AuthorRole;
  name: string;
  text: string;
  type: MessageType;
  contentType: ContentType;
}

export interface QueueDisposition {
  code: string;
  subCode: string;
  outcome: string;
  title: string;
  description: string;
}

export interface Conversation {
  id: number;
  uuid: string;
  createdTimestamp: string;
  previousConversationId: number | undefined;
  channelType: ChannelType;
  status: string;
  systemEndpoint: string;
  customerEndpoint: string;
  messages: { [key: string]: Message };
  attributes: { [key: string]: string };
  dispositionCode: string | undefined;
  dispositionSubCode: string | undefined;
  disposedTimestamp: string | undefined;
  canDispose: boolean;
  autoDisposesAtTimestamp: string | undefined;
  queueDispositions: QueueDisposition[];
  customerName: string;
  queue: string;
}

export interface Customer {
  conversations: { [key: number]: Conversation };
}

export interface Agent {
  username: string;
  maxConcurrency: number;
  desiredConcurrency: number;
  state: string;
  stateTimestamp: string;
  onVoiceCall: boolean;
  requestedState: string | undefined;
  requestedStateTimestamp: string | undefined;
}

export interface ServerErrorMessage {
  code: string;
  message: string;
  referenceId: string;
}

export interface ServerError {
  messages: ServerErrorMessage[];
}

export interface ServerMessage {
  customers: { [key: string]: Customer } | undefined;
  disposedCustomers: string[] | undefined;
  agent: Agent | undefined;
  error: ServerError | undefined;
}

export const MessageResponseDecoder: j.Decoder<Message> = j
  .object({
    id: j.number(),
    conversation_id: j.number(),
    created_timestamp: j.string(),
    read_timestamp: j.union(j.string(), j.constant(null)),
    delivered_timestamp: j.union(j.string(), j.constant(null)),
    sent_timestamp: j.union(j.string(), j.constant(null)),
    customer_previous_message_id: j.union(j.number(), j.constant(null)),
    author_role: j.string(),
    name: j.string(),
    text: j.string(),
    type: j.string(),
    content_type: j.string(),
  })
  .map((item) => ({
    id: item.id,
    conversationId: item.conversation_id,
    createdTimestamp: item.created_timestamp,
    readTimestamp: item.read_timestamp || undefined,
    deliveredTimestamp: item.delivered_timestamp || undefined,
    sentTimestamp: item.sent_timestamp || undefined,
    customerPreviousMessageId: item.customer_previous_message_id || undefined,
    authorRole: item.author_role as AuthorRole,
    name: item.name,
    text: item.text,
    type: item.type as MessageType,
    contentType: item.content_type as ContentType,
  }));

const QueueDispositionResponseDecoder: j.Decoder<QueueDisposition> = j
  .object({
    code: j.string(),
    sub_code: j.string(),
    outcome: j.string(),
    title: j.string(),
    description: j.string(),
  })
  .map((item) => ({
    code: item.code,
    subCode: item.sub_code,
    outcome: item.outcome,
    title: item.title,
    description: item.description,
  }));

const ConversationResponseDecoder: j.Decoder<Conversation> = j
  .object({
    conversation_id: j.number(),
    conversation_uuid: j.string(),
    created_timestamp: j.string(),
    previous_conversation_id: j.union(j.number(), j.constant(null)),
    channel_type: j.string(),
    conversation_status: j.string(),
    system_endpoint: j.string(),
    customer_endpoint: j.string(),
    messages: j.union(j.dict(MessageResponseDecoder), j.constant(null)),
    attributes: j.union(j.dict(j.string()), j.constant(null)),
    disposition_code: j.union(j.string(), j.constant(null)),
    disposition_sub_code: j.union(j.string(), j.constant(null)),
    disposed_timestamp: j.union(j.string(), j.constant(null)),
    can_dispose: j.boolean(),
    auto_disposes_at_timestamp: j.union(j.string(), j.constant(null)),
    queue_dispositions: j.union(j.array(QueueDispositionResponseDecoder), j.constant(null)),
    customer_name: j.union(j.string(), j.constant(null)),
    queue: j.string(),
  })
  .map((item) => ({
    id: item.conversation_id,
    uuid: item.conversation_uuid,
    createdTimestamp: item.created_timestamp,
    previousConversationId: item.previous_conversation_id || undefined,
    channelType: item.channel_type as ChannelType,
    status: item.conversation_status,
    systemEndpoint: item.system_endpoint,
    customerEndpoint: item.customer_endpoint,
    messages: item.messages || {},
    attributes: item.attributes || {},
    dispositionCode: item.disposition_code || undefined,
    dispositionSubCode: item.disposition_sub_code || undefined,
    disposedTimestamp: item.disposed_timestamp || undefined,
    canDispose: item.can_dispose ?? undefined,
    autoDisposesAtTimestamp: item.auto_disposes_at_timestamp || undefined,
    queueDispositions: item.queue_dispositions || [],
    customerName: item.customer_name || '',
    queue: item.queue,
  }));

const CustomerResponseDecoder: j.Decoder<Customer> = j
  .object({
    conversations: j.dict(ConversationResponseDecoder),
  })
  .map((item) => ({
    conversations: item.conversations || {},
  }));

const AgentResponseDecoder: j.Decoder<Agent> = j
  .object({
    username: j.string(),
    max_concurrency: j.number(),
    desired_concurrency: j.number(),
    state: j.string(),
    state_timestamp: j.string(),
    on_voice_call: j.boolean(),
    requested_state: j.union(j.string(), j.constant(null)),
    requested_state_timestamp: j.union(j.string(), j.constant(null)),
  })
  .map((item) => ({
    username: item.username,
    maxConcurrency: item.max_concurrency,
    desiredConcurrency: item.desired_concurrency,
    state: item.state,
    stateTimestamp: item.state_timestamp,
    onVoiceCall: item.on_voice_call,
    requestedState: item.requested_state || undefined,
    requestedStateTimestamp: item.requested_state_timestamp || undefined,
  }));

const ServerErrorMessageDecoder: j.Decoder<ServerErrorMessage> = j
  .object({
    code: j.string(),
    message: j.string(),
    reference_id: j.string(),
  })
  .map((item) => ({
    code: item.code,
    message: item.message,
    referenceId: item.reference_id,
  }));

const ServerErrorDecoder: j.Decoder<ServerError> = j
  .object({
    messages: j.array(ServerErrorMessageDecoder),
  })
  .map((item) => ({
    messages: item.messages,
  }));

export const ServerMessageResponseDecoder: j.Decoder<ServerMessage> = j
  .object({
    customers: j.union(j.dict(CustomerResponseDecoder), j.constant(null)),
    disposed_customers: j.union(j.array(j.string()), j.constant(null)),
    agent: j.union(AgentResponseDecoder, j.constant(null)),
    error: j.union(ServerErrorDecoder, j.constant(null)),
  })
  .map((item) => ({
    customers: item.customers || undefined,
    disposedCustomers: item.disposed_customers || undefined,
    agent: item.agent || undefined,
    error: item.error || undefined,
  }));
