import * as j from '@mojotech/json-type-validation';

import { Agent } from '~pages/SystemManagement/domain';

export type AssignedAgents = Pick<Agent, 'username' | 'fullName'>;

export interface UpdateAgentGroupAssignment {
  agents: string[];
  routingProfile: string;
}

export interface AgentsResponse {
  nextPageUrl: string | null;
  agents: AssignedAgents[];
}

export const GetAgentResponseDecoder: j.Decoder<AssignedAgents> = j
  .object({
    username: j.string(),
    full_name: j.string(),
  })
  .map((item) => ({
    username: item.username,
    fullName: item.full_name,
  }));

export const GetAgentsResponseDecoder: j.Decoder<AgentsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    agents: j.array(GetAgentResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    agents: item.agents,
  }));
